import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { useCallback, useEffect } from 'react'
import { SCENES, useShowSceneCallback, wineriesSceneAtom } from '../Canvas/sceneState'
import Link from '../Link'

export function WineryListing ({ data }) {
  const { wineries } = data
  const showScene = useShowSceneCallback()
  const { scroller, slideWidth } = useAtomValue(wineriesSceneAtom)

  useEffect(() => {
    showScene(SCENES.WINERY_LISTING, wineries)
    return () => { showScene(SCENES.NONE, wineries) }
  }, [wineries, showScene])

  const buttonClasses = 'absolute bottom-0 p-4 uppercase font-550 text-10 block md:hidden hover:opacity-50 transition-opacity duration-300 ease-expo-out'

  const onNextClick = useCallback(() => {
    if (scroller?.current) {
      const currentIndex = Math.floor(Math.round(-scroller?.current.scroll.x / slideWidth))
      let nextIndex = currentIndex + 1
      if (currentIndex > wineries.length) nextIndex = 0
      scroller.current.target.x = nextIndex * slideWidth
    }
  }, [scroller, slideWidth, wineries?.length])

  const onPrevClick = useCallback(() => {
    if (scroller?.current) {
      const currentIndex = Math.floor(Math.round(-scroller?.current.scroll.x / slideWidth))
      const nextIndex = currentIndex - 1
      if (nextIndex < 0) {
        scroller.current.target.x -= (slideWidth - scroller?.current.scroll.x)
      } else {
        scroller.current.target.x = nextIndex * slideWidth
      }
    }
  }, [scroller, slideWidth])

  return (
    <div>
      <button onClick={onNextClick} className={cn(buttonClasses, 'right-[-0.5rem]')}>Next</button>
      <button onClick={onPrevClick} className={cn(buttonClasses, 'left-0')}>Prev</button>
      <ul className="srOnly">
        {wineries.map(winery => (
          <li key={winery._id}><Link link={winery} /></li>
        ))}
      </ul>
    </div>
  )
}
