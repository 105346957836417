
import cn from 'clsx'

export default function CheckboxField ({ name, label, checked, error, ...props }) {
  return (
    <span className='block mb-4'>
      <input name={name} id={name} type='checkbox' checked={checked} {...props} className='hidden' />
      <label htmlFor={name} className={cn('text-10 font-normal cursor-pointer uppercase flex items-center', error && 'text-error')}>
        <div className={cn('w-4 h-4 border mr-2 flex items-center justify-center', checked && 'bg-current')}>
          {checked && (
            <svg viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg' className='w-2 h-2'>
              <path d='M6.33333 1L2.66667 4.66667L1 3' stroke='black' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
          )}
        </div>
        {label}
      </label>
    </span>
  )
}
