import cn from 'clsx'
import { useSite } from '../../siteState'
import { useSetAtom } from 'jotai'
import { NAV_VIEWS, menuOpenAtom, navViewAtom } from '../menuState'
import { useCallback } from 'react'

export function useOpenLogin () {
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const setMenuView = useSetAtom(navViewAtom)

  return useCallback(() => {
    setMenuOpen(true)
    setMenuView(NAV_VIEWS.login)
  }, [setMenuOpen, setMenuView])
}

export default function MembersOnly ({ page }) {
  const site = useSite()
  const openSignUp = useOpenLogin()

  return (
    <>
      <div className='flex justify-between col-span-full items-center h-[max(2.083333333333333vw,30px)] md:h-[42px]'>
        <div className={cn('uppercase font-serif font-2550 text-12 md:text-[14px] tracking-slight')}>
          {site?.labels?.membersOnlyLabel}
        </div>

        <button
          onClick={openSignUp}
          data-event='site interaction'
          data-type='button'
          data-description='sign up'
          data-location='body'
        >
          <div className={cn('uppercase font-550 text-12 md:text-[14px] tracking-slight text-right')}>
            {site?.labels?.membersLoginLabel}
          </div>
        </button>
      </div>
    </>
  )
}
