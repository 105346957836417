import { atom, useAtomValue } from 'jotai'
import compact from 'lodash/compact'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import remove from 'lodash/remove'
import uniq from 'lodash/uniq'
import values from 'lodash/values'
import { useCallback, useMemo } from 'react'
import { ArrayParam, BooleanParam, NumberParam, StringParam, withDefault } from 'use-query-params'
import useQueryParamsWithRoute from '../../../../hooks/useQueryParamsWithRoute'

export const categories = [
  {
    title: 'All',
    value: 'all'
  },
  {
    title: 'Wine',
    value: 'wine'
  },
  {
    title: 'Objects',
    value: 'object'
  }
]

export const layouts = [
  {
    title: 'Grid',
    value: 'grid'
  },
  {
    title: 'List',
    value: 'list'
  }
]

export const sortOptions = [
  {
    value: 'latest',
    text: 'Latest'
  },
  {
    value: 'oldest',
    text: 'Oldest'
  },
  {
    value: 'lowest',
    text: 'Price low to high'
  },
  {
    value: 'highest',
    text: 'Price high to low'
  }
]

export const FILTERS = {
  type: {
    type: 'tag',
    title: 'Type',
    slug: 'type',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    expanded: true,
    filter: (products, types) => {
      if (isEmpty(types)) return products
      return products.filter((product) => {
        const matching = product ? types.findIndex((x) => x === product.type) !== -1 : []
        return matching
      })
    }
  },
  price: {
    type: 'range',
    title: 'price range',
    slug: 'price',
    min: null,
    max: null,
    isCurrency: true,
    options: [{ min: 0, max: 50 }, { min: 50, max: 75 }, { min: 75, max: 100 }, { min: 100, max: 150 }, { min: 150, max: 200 }, { min: 200 }],
    visible: true,
    expanded: true,
    filter: (products, min, max) => {
      if (!min && !max) return products
      return products
        .filter(product => !min || product?.priceRange?.minVariantPrice >= min)
        .filter(product => !max || product?.priceRange?.maxVariantPrice < max)
    }
  },
  winery: {
    type: 'tag',
    title: 'Winery',
    slug: 'winery',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    filter: (products, wineries) => {
      if (isEmpty(wineries)) return products
      return products.filter((product) => {
        const matching = product ? wineries.findIndex((x) => x === product.winery) !== -1 : []
        return matching
      })
    }
  },
  varietal: {
    type: 'tag',
    title: 'Varietal',
    slug: 'varietal',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    filter: (products, varietals) => {
      if (isEmpty(varietals)) return products
      return products.filter((product) => {
        const matching = product ? varietals.findIndex((x) => x === product.varietal) !== -1 : []
        return matching
      })
    }
  },
  range: {
    type: 'tag',
    title: 'Range',
    slug: 'range',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    filter: (products, ranges) => {
      if (isEmpty(ranges)) return products
      return products.filter((product) => {
        const matching = product ? ranges.findIndex((x) => x === product.range) !== -1 : []
        return matching
      })
    }
  },
  country: {
    type: 'tag',
    title: 'country',
    slug: 'country',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    filter: (products, countries) => {
      if (isEmpty(countries)) return products
      return products.filter((product) => {
        const matching = product ? countries.findIndex((x) => x === product.country) !== -1 : []
        return matching
      })
    }
  },
  region: {
    type: 'tag',
    title: 'Region',
    slug: 'region',
    options: [],
    viewAll: false,
    productCategory: 'wine',
    filter: (products, regions) => {
      if (isEmpty(regions)) return products
      return products.filter((product) => {
        const matching = product ? regions.findIndex((x) => x === product.region) !== -1 : []
        return matching
      })
    }
  },
  year: {
    type: 'range',
    title: 'Vintage',
    slug: 'year',
    min: null,
    max: null,
    isCurrency: false,
    visible: true,
    productCategory: 'wine',
    filter: (products, min, max) => {
      if (!min && !max) return products
      return products
        .filter(product => !min || product?.yearVintage >= min)
        .filter(product => !max || product?.yearVintage <= max)
    }
  },
  sales: {
    type: 'tag',
    title: 'Sales',
    slug: 'sales',
    options: [],
    visible: false,
    productCategory: 'wine',
    filter: (products, options) => {
      if (isEmpty(options)) return products
      return products?.filter((product) => {
        return options?.findIndex((x) => x === product?.sales) !== -1
      })
    }
  },
  membersOnly: {
    type: 'boolean',
    title: 'Members Only',
    slug: 'membersOnly',
    visible: false,
    productCategory: 'wine',
    filter: (products, value) => {
      if (!value) return products
      return products.filter((product) => product.membersOnly)
    }
  },
  awardWinning: {
    type: 'boolean',
    title: 'Award Winning',
    slug: 'awardWinning',
    visible: true,
    productCategory: 'wine',
    filter: (products, value) => {
      if (!value) return products
      return products.filter((product) => product.awardWinning)
    }
  }
}

export const filtersAtom = atom(FILTERS)
export const filtersCountAtom = atom({})

export const resultsCountAtom = atom(null)

export const useHumanReadableFilterText = () => {
  const [filters] = useFilterQueryParams()

  return useMemo(() => {
    const humanReadableFilters = { ...omit(filters, ['page', 'category', 'membersOnly']) }
    if (humanReadableFilters.awardWinning) humanReadableFilters.awardWinning = 'Award Winning'
    humanReadableFilters.sortBy = null
    humanReadableFilters.layout = null
    return compact(flatMap(humanReadableFilters))
  }, [filters])
}

const useFilters = (filters) => {
  const [{ category }] = useFilterQueryParams()
  return useMemo(() => {
    return category === 'object' ? filters?.filter(f => f.productCategory !== 'wine') : filters
  }, [category, filters])
}

export const useMainFilters = () => {
  const filters = useAtomValue(filtersAtom)
  const mainFilters = useMemo(() => {
    return values(filters)
  }, [filters])
  return useFilters(mainFilters)
}

export const useFilterQueryParams = () => {
  const createQueryParamsFromFilters = () => {
    return reduce(FILTERS, (result, filter, key) => {
      if (filter.type === 'range') {
        result[`${key}Min`] = withDefault(NumberParam, null)
        result[`${key}Max`] = withDefault(NumberParam, null)
      } else if (filter.type === 'tag') {
        result[key] = withDefault(ArrayParam, [])
      } else if (filter.type === 'boolean') {
        result[key] = withDefault(BooleanParam, null)
      } else {
        result[key] = withDefault(StringParam, null)
      }
      return result
    }, {})
  }
  return useQueryParamsWithRoute({
    ...createQueryParamsFromFilters(),
    category: withDefault(StringParam, categories[0].value),
    layout: withDefault(StringParam, layouts[0].value),
    sortBy: withDefault(StringParam, sortOptions[0].value),
    page: withDefault(NumberParam, 0)

  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true, skipUpdateWhenNoChange: true })
}

export const createQueryInitialFilters = () => {
  return reduce(FILTERS, (result, filter, key) => {
    if (filter.type === 'range') {
      result[`${key}Min`] = null
      result[`${key}Max`] = null
    } else if (filter.type === 'tag') {
      result[key] = []
    } else {
      result[key] = null
    }
    return result
  }, {})
}

export const initialFiltersState = {
  ...createQueryInitialFilters(),
  category: categories[0].value,
  sortBy: sortOptions[0].value,
  page: 0
}

export const filterStateAtom = atom(initialFiltersState)

export function useFilterActions (slug) {
  const [query, setQuery] = useFilterQueryParams()

  const selected = query[slug] || []

  const handleAdd = (option) => {
    setQuery({
      ...query,
      page: 0,
      [slug]: uniq([
        ...query[slug] || [],
        option
      ])
    })
  }

  const handleRemove = (option) => {
    const results = [...query[slug] || []]
    remove(results, (o) => o === option)
    setQuery({
      ...query,
      page: 0,
      [slug]: results
    })
  }

  return [selected, handleAdd, handleRemove]
}

export function useResetFilters () {
  const [query, setQuery] = useFilterQueryParams()
  return useCallback((defaults = {}) => {
    setQuery({
      ...initialFiltersState,
      category: query.category,
      ...defaults
    })
  }, [query, setQuery])
}
