export const grid = {
  sm: {
    columns: 12,
    gutter: 1,
    margin: 1,
  },
  md: {
    columns: 12,
    gutter: 1,
    margin: 1,
  },
}

export const span = (
  cols,
  breakpoint = 'sm',
  internal = true,
  offset = 0,
  columnOveride,
  padding = 0,
  containerSize = '100%',
  wrap = true
) => {
  if (!grid[breakpoint]) return null
  const gridGutter = grid[breakpoint].gutter
  const gridColumns = columnOveride || grid[breakpoint].columns
  const gutters = internal ? gridGutter * (Math.abs(cols) - 1) : gridGutter * Math.abs(cols)
  const calc = `(((${containerSize} - ${
    gridGutter * (gridColumns - 1)
  }rem - ${padding}rem) / ${gridColumns}) * ${cols}) + ${gutters + offset}rem`
  return wrap ? `calc(${calc})` : calc
}
