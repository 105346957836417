import { useLoader, useThree } from '@react-three/fiber'
import { isArray } from 'lodash'
import { useEffect, useLayoutEffect, useMemo } from 'react'
import * as THREE from 'three'
import { ImageBitmapLoader, TextureLoader } from 'three'

export const IsObject = (url) =>
  url === Object(url) && !Array.isArray(url) && typeof url !== 'function'

class ImageBitmapLoaderFlipY extends ImageBitmapLoader {
  constructor (manager) {
    super(manager)
    this.options = { premultiplyAlpha: 'none', imageOrientation: 'flipY' }
  }
}

class ImageBitmapLoaderWrapper extends ImageBitmapLoader {
  constructor (manager) {
    super(manager)
    this.options = { premultiplyAlpha: 'none' }
  }
}

export default function useImageBitmapLoader (
  input,
  onLoad,
  flip = true
) {
  const gl = useThree((state) => state.gl)
  const loader = createImageBitmap ? flip ? ImageBitmapLoaderFlipY : ImageBitmapLoaderWrapper : TextureLoader
  const images = useLoader(loader, IsObject(input) ? Object.values(input) : (input))

  useLayoutEffect(() => {
    onLoad?.(images)
  }, [onLoad])

  const textures = useMemo(() => {
    if (!images) return null
    let _textures = []
    const createTexture = (imageBitmap) => {
      return new THREE.CanvasTexture(imageBitmap)
    }
    if (loader === TextureLoader) {
      _textures = images
    } else {
      _textures = Array.isArray(images)
        ? images?.map(createTexture)
        : createTexture(images)
    }
    if ('initTexture' in gl) {
      if (Array.isArray(images)) {
        _textures.forEach(gl.initTexture)
      } else {
        gl.initTexture(_textures)
      }
    }
    return _textures
  }, [gl, images])

  useEffect(() => {
    return () => {
      if (IsObject(input)) {
        Object.values(input).map(v => useLoader.clear(v))
      } else {
        useLoader.clear(input)
      }
      if (isArray(textures)) {
        textures?.forEach?.(t => t.dispose())
      } else {
        textures?.dispose()
      }
    }
  }, [textures])

  if (IsObject(input)) {
    const keys = Object.keys(input)
    const keyed = {}
    keys.forEach((key) => Object.assign(keyed, { [key]: textures[keys.indexOf(key)] }))
    return keyed
  } else {
    return textures
  }
}

useImageBitmapLoader.preload = (url) => useLoader.preload(ImageBitmapLoader, url)
useImageBitmapLoader.clear = (input) => useLoader.clear(ImageBitmapLoader, input)
