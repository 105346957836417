/* eslint-disable jsx-a11y/alt-text */
import compact from 'lodash/compact'
import { useMemo } from 'react'
import { isLandscapeImage } from '../../../../helpers/image'
import transform from '../../../ResponsiveImage/transform'
import { ASPECT_LANDSCAPE, ASPECT_PORTRAIT } from '../../../Slices/Feed/feedRows'
import Draggable from '../Draggable'
import Image from '../Image'

export default function FloatingImages ({
  images,
  containerWidth,
  containerHeight,
  config,
  preload,
  blur,
  darken,
  ...props
}) {
  const floatingImages = useMemo(() => {
    if (!images) return null
    if (!config) return null

    return compact(images.slice(0, 2).map((image, i) => {
      const settings = config[i]
      if (!settings) return null
      const landscape = isLandscapeImage(image)
      const aspect = landscape ? ASPECT_LANDSCAPE : ASPECT_PORTRAIT
      const w = containerWidth * settings.size
      const width = landscape ? w * aspect : w
      const height = landscape ? w : w / aspect
      const z = settings.position[2]
      const x = containerWidth * settings.position[0]
      const y = containerHeight * settings.position[1]

      return {
        image: transform(image, aspect, [160, 320, 640]),
        aspect,
        width,
        height,
        position: [x, y, z]
      }
    }))
  }, [images, config, containerWidth, containerHeight])

  return floatingImages?.map(({ image, width, height, position }, i) => (
    <Draggable key={i}>
      {() => {
        return (
          <group position={position}>
          {/* <Float position={position} rotationIntensity={0} floatIntensity={1} speed={1.5}> */}
            <Image
              image={image}
              scale={[width, height, 1]}
              preload={preload}
              segments={8}
              blur={blur}
              darken={darken}
              disableHover
              {...props}
            />
          {/* </Float> */}
          </group>
        )
      }}
    </Draggable>
  ))
}
