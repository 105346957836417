import Link from '../Link'
import Section from '../Section'
import { useShowSceneCallback, SCENES } from '../Canvas/sceneState'
import { useEffect, useRef, useState } from 'react'
import { useLabels } from '../siteState'
import { DroppingCountdown } from './ProductListing/ProductTile'
import gsap from 'gsap'

export default function Dropping ({ data, page }) {
  const ref = useRef()
  // const image = page?.droppingImage || page?.productImages?.featuredBgImage
  const [mounted, setMounted] = useState(false)

  const { droppingInLabel = 'Dropping In', nonVintageText } = useLabels()

  const year = page.yearVintage || nonVintageText

  const showScene = useShowSceneCallback()

  useEffect(() => {
    // if (!image) return
    // showScene(SCENES.ABOUT, { image, isDroppingPage: true, isErrorPage: true })
    setMounted(true)
    // return () => showScene(SCENES.NONE)
  }, [data, showScene])

  useEffect(() => {
    if (!mounted) return
    const tl = gsap.timeline()
    tl.from([...ref.current.children[0].children, ...ref.current.children[1].children], {
      y: '110%',
      opacity: 0,
      duration: 0.6,
      ease: 'expo.out',
      stagger: 0.1,
      delay: 0.3,
    })
    tl.from(ref.current.children[2].children, {
      y: '110%',
      opacity: 0,
      duration: 0.6,
      ease: 'expo.out',
      stagger: 0.07,
    }, '-=0.4')

    tl.play()

    return () => tl.revert()
  }, [mounted])

  return (
    <Section noGutter noBottomMargin className='h-[100vh] md:h-[60vh] flex px-4 flex-col justify-center md:justify-end relative z-[10] items-center w-full' ref={ref}>
      <div className='overflow-hidden pt-[0.2em] mt-[-0.2em]'>
        <div className='font-serif font-100 flex items-center tracking-[-0.03em] font-serif-feature-zero text-center text-[calc((100vw-48px)*0.13)] md:text-[calc((100vw-48px)*0.15)] leading-[0.8em] md:leading-[0.9em] uppercase'>
          {droppingInLabel}
        </div>
      </div>
      <div className='overflow-hidden pt-[0.2em] mt-[-0.2em] w-full'>
        <div className='font-serif font-100 flex items-center tracking-[-0.03em] font-serif-feature-zero text-center text-[calc((100vw-48px)*0.13)] md:text-[calc((100vw-48px)*0.15)] leading-[0.8em] md:leading-[0.9em] uppercase'>
          <DroppingCountdown releasedDate={page.releasedDate} isDroppingPage className='flex justify-between w-full px-[9vw] md:px-2 md:w-full' />
        </div>
      </div>
      <div className='text-12 tracking-slight uppercase font-550 flex justify-between w-full px-[9vw] md:px-2 md:grid md:grid-cols-2 md:mt-2'>
        {page.winery && <span className='md:col-start-1 md:col-end-1 md:row-start-1'>{page.winery?.title}</span>}
        {page.range && <span className='md:col-start-1 md:col-end-1 md:row-start-2'>{page.range?.title}</span>}
        {page.varietal && <span className='md:col-start-2 md:col-end-2 md:row-start-1'>{page.varietal?.title}</span>}
        {page.country && <span className='md:col-start-2 md:col-end-2'>{page.country?.title}</span>}
        {year && <span className='md:col-start-2 md:col-end-2' >{year}</span>}
        {page.format && <span className='md:col-start-2 md:col-end-2'>{page.format?.title}</span>}
      </div>
    </Section>
  )
}
