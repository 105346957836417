'use client'
import dayjs from 'dayjs'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useEffect, useRef } from 'react'
import { accountAtom, ACCOUNT_STATES, useAccessTokenAtom } from './accountState'
import { useAccountActions } from './useAccountActions'

export default function AccountProvider ({ children }) {
  const { getAccount, clear } = useAccountActions()
  const localsRef = useRef({ running: false })
  const accessTokenAtom = useAccessTokenAtom()
  const readAccountState = useAtomCallback(useCallback((get) => get(accountAtom)?.state, []))
  const readAccessToken = useAtomCallback(useCallback((get) => get(accessTokenAtom), []))

  const checkAccessToken = useCallback(async () => {
    if (localsRef.current.running) return
    localsRef.current.running = true

    const state = await readAccountState()
    const accessToken = await readAccessToken()

    if (state === ACCOUNT_STATES.uninitialized) {
      if (accessToken === null || dayjs().isAfter(dayjs(accessToken.expiresAt))) {
        await clear()
      } else {
        await getAccount()
      }
    }
    localsRef.current.running = false
  }, [])

  useEffect(() => {
    checkAccessToken()
  }, []) // This is here for dev so the cart isn't load on hot reloads

  return children
}
