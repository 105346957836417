import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'

export default function InputField ({ text = 'text-12', className, name, label, children, ...props }) {
  return (
    <div className='flex items-center border-b pb-1'>
      <label htmlFor={name} className={cn(text, 'font-550 uppercase w-28 min-w-[7rem] mr-2')}>{label}</label>
      {isEmpty(children) ? <input name={name} id={name} className={cn(className, text, 'hide-spinner font-normal bg-transparent outline-none w-full')} {...props} /> : children}
    </div>
  )
}
