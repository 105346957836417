import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useState } from 'react'
import { isUnder18 } from '../../helpers/dateValidator'
import { replaceTemplateVariables } from '../../helpers/template'
import useStopKeyPropagation from '../../hooks/useStopKeyPropagation'
import countries from '../../lib/countryList'
import reCAPTCHA from '../../lib/recaptcha'
import { ButtonWithSpinner } from '../Button'
import CheckboxField from '../Inputs/CheckboxField'
import DateInput from '../Inputs/DateInput'
import InputField from '../Inputs/InputField'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import Section from '../Section'
import { useAccountActions } from '../Shop/useAccountActions'
import { AnimatedTextRows } from '../Slices/TextRows'
import { useLabels, useSite } from '../siteState'

export default function CreateAccount ({ data }) {
  const {
    accountForm: {
      image,
      textRows,
      copy,
      newsletterMessage,
      termsMessage,
      buttonMessage
    }
  } = data

  const { definitionLabels } = useSite()
  const { accountActivation } = useLabels()

  const site = useSite()
  const recaptchaAction = 'login'

  const { createAccount, login } = useAccountActions()
  const [errorMessage, setErrorMessage] = useState('')

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [recaptcha, setRecaptcha] = useState(null)
  const [birthDate, setBirthDate] = useState(null)

  const [formData, setFormData] = useState({
    values: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password: '',
      country: site.countryCode.toUpperCase(),
      newsletter: true,
      terms: false
    },
    errors: {}
  })

  useEffect(() => {
    // eslint-disable-next-line new-cap
    setRecaptcha(new reCAPTCHA((process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3_SITEKEY), 'login'))
  }, [])

  const validateForm = useCallback((values) => {
    const { terms } = values
    const errors = {}
    if (!terms) {
      errors.terms = 'Please accept the terms and conditions'
    }
    if (!birthDate || !birthDate.isValid()) {
      errors.birthDate = 'Please enter a valid date of birth'
    } else if (isUnder18(birthDate)) {
      errors.birthDate = 'You must be over 18 to create an account'
    }

    if (!isEmpty(errors)) {
      setFormData(state => ({
        ...state,
        errors
      }))
      setErrorMessage(first(errors))
      return errors
    }
    setFormData(state => ({ ...state, errors: {} }))
    return null
  }, [birthDate])

  const onFormSubmit = useCallback(async (e) => {
    e.preventDefault()
    const { email, password } = formData.values
    const errors = validateForm(formData.values)
    if (errors) return

    try {
      setLoading(true)
      const recaptchaToken = await recaptcha.getToken()
      await createAccount({
        ...formData.values,
        dateOfBirth: birthDate.format('YYYY-MM-DD'),
        recaptchaToken,
        recaptchaAction
      })
      setSuccess('created')
      const url = new URL(window.location.href)
      // This will skip the intro when the users gets redirected back to the site
      url.hash = '#skip'
      await login({ email, password, returnUrl: url.toString() })
    } catch (e) {
      if (e) {
        const errorMessage = e.toString().replace('Error: ', '')
        if (errorMessage.toLowerCase().includes('verify your email address')) {
          setSuccess('verify')
        } else {
          setErrorMessage(errorMessage)
          console.error('error', e)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [validateForm, recaptcha, createAccount, formData.values, birthDate, login])

  const onFieldChanged = useCallback((e) => {
    const values = {
      ...formData.values,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }
    setFormData(state => ({
      ...state,
      values
    }))
  }, [formData.values])

  const rootRef = useStopKeyPropagation()

  return (
    <Section grid className="relative" ref={rootRef}>
      {image && <ResponsiveImage image={image} className='!absolute pointer-events-none top-[-24rem] md:top-[-8rem] left-0 right-0 z-[0]' mobileAspect={394 / 1711} showPreview={false} style={{
        maskImage: 'linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 60%)',
        WebkitMaskImage: 'linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 60%)'
      }}/>}

      <div className='col-span-full z-10 mb-[11.25rem] md:mb-[3.625rem]'>
        {/* {textRows?.map(row => (
          <div
            key={row._key}
            className={cn(
              'font-serif font-250 tracking-[-0.06em] text-[calc((100vw-48px)*0.095)] md:text-[calc((100vw-48px)*0.138)] uppercase leading-[0.8em]',
              row.align === 'right' && 'text-right',
              row.indent && 'indent-[20vw]'
            )}
          >
            {row.text}
          </div>
        ))} */}
        <AnimatedTextRows textRows={textRows}/>
      </div>

      {copy && <RichContent content={copy} className='col-span-3 md:col-span-full relative z-10 text-14 [&_p:first-child]:indent-[9vw] md:[&_p:first-child]:indent-[22vw] pr-8 md:pr-0 [&_p]:mt-4 [&_p:first-child]:mt-0'/>}

      <div className='col-start-8 col-span-3 md:mt-[6.125rem] relative !z-10 md:col-span-full'>
        {!success && (
          <form onSubmit={onFormSubmit} className='[&>div]:mt-6 [&>div:first-child]:mt-0 placeholder-opacity-40 w-full'>
            <InputField name='firstName' label='First Name*' onChange={onFieldChanged} required placeholder='REQUIRED' />
            <InputField name='lastName' label='Last Name*' onChange={onFieldChanged} required placeholder='REQUIRED' />
            <InputField name='mobile' label='Mobile*' type='number' onChange={onFieldChanged} required placeholder='REQUIRED' />
            <InputField name='email' label='Email*' type='email' onChange={onFieldChanged} required placeholder='REQUIRED' />
            <InputField name='password' label='Password*' type='password' onChange={onFieldChanged} required placeholder='REQUIRED' />
            <InputField name='country' label='Country*'>
              <select name='country' onChange={onFieldChanged} className='text-12 font-normal bg-transparent outline-none w-56' value={formData.values.country}>
                {Object.keys(countries).map((k) => <option key={k} value={k} className='text-black'>{countries[k]}</option>)}
              </select>
            </InputField>
            <DateInput label='Date Of Birth*' setValue={setBirthDate} error={formData.errors.birthDate} />
            <CheckboxField name='newsletter' onChange={onFieldChanged} checked={formData.values.newsletter} label={newsletterMessage} error={formData.errors.newsletter} />
            <CheckboxField name='terms' onChange={onFieldChanged} checked={formData.values.terms} label={<RichContent content={termsMessage} className='[&_a]:underline [&_a:hover]:opacity-50' />} error={formData.errors.terms} />
            {errorMessage && <div className='my-4 text-10 uppercase text-error'>{errorMessage}</div>}
            <ButtonWithSpinner className='mt-8 mb-4' loading={loading} disabled={loading}>{buttonMessage}</ButtonWithSpinner>
            <div className={'mb-4 text-[10px] leading-[1em] max-w-[30em] uppercase [&_a]:underline [&_a:hover]:opacity-50'}>
              <RichContent content={site.recaptchaText} />
            </div>
          </form>
        )}
        {success && (
          <>
            {success === 'verify' && (
              <div className='text-12 font-550'>
                <div className='border-glass border p-14 text-center'>
                  <h2 className='text-16 mb-20 mx-4 uppercase'>{replaceTemplateVariables(accountActivation?.title, formData.values)}</h2>
                  <div className='whitespace-pre-wrap mb-20'>{replaceTemplateVariables(accountActivation?.copy, formData.values)}</div>
                  {definitionLabels?.title && <RichContent className='text-10' content={definitionLabels.title} />}
                </div>
                <div className='border-glass border border-t-0 p-4 text-center uppercase'>{accountActivation?.footer}</div>
              </div>
            )}
            {success === 'created' && (
              <div className='text-12 font-550'>
                <div className='border-glass border p-14 text-center'>
                  <h2 className='text-16 mb-20 mx-4 uppercase'>Account created</h2>
                  {definitionLabels?.title && <RichContent className='text-10' content={definitionLabels.title} />}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Section>
  )
}
