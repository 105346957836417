import pixelToViewport from '../../../helpers/pixelToViewport'
import { useIsMobile, useViewport } from '../../../hooks'
import Stack from '../../../ui/Stack'
import BodyFont from '../../../ui/Text/BodyFont'
import SansSerifFont from '../../../ui/Text/SansSerifFont'

const TITLE_FONT_SIZES = { lg: 0.45, md: 0.35, sm: 1 }
const FONT_SIZES = { lg: 0.12, md: 0.14, sm: 0.3 }

export default function FeatureTileMeta ({ tile, width, height }) {
  const viewport = useViewport()
  const padding = pixelToViewport(16, viewport)
  const isMobile = useIsMobile()
  const { featureLayout } = tile
  const { featuredSubtitle, featuredDetails, featuredTag } = featureLayout
  const title = featureLayout.featuredTitle || tile.title

  return (
    <group position={[0, 0, 0.001]} name='meta'>
      <Stack anchorY='center'>
        {title?.split('\n').filter(t => t).map((t, i) => (
          <BodyFont
            key={i}
            fontSizes={TITLE_FONT_SIZES}
            maxWidth={width - (isMobile ? padding * 8 : padding * 2) }
            textAlign='center'
            anchorX='center'
            anchorY='center'
            lineHeight={0.8}
          >
            {t.toUpperCase()}
          </BodyFont>
        ))}
        <SansSerifFont
          fontSizes={TITLE_FONT_SIZES}
          maxWidth={width - (isMobile ? padding * 8 : padding * 2) }
          textAlign='center'
          anchorX='center'
          anchorY='center'
        >
          {featuredSubtitle?.toUpperCase()}
        </SansSerifFont>
      </Stack>
      <group position={[-width / 2, -height / 2, 0]} name='meta'>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[padding, padding, 0]} anchorY='bottom' >
          {featuredTag?.toUpperCase()}
        </BodyFont>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[width / 2, padding, 0]} anchorY='bottom' anchorX='center' textAlign='center' >
          {featuredDetails?.toUpperCase()}
        </BodyFont>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[width - padding, padding, 0]} anchorY='bottom' anchorX='right' textAlign='left'>
          {featuredTag?.toUpperCase()}
        </BodyFont>
      </group>
    </group>
  )
}
