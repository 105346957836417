import defer from 'lodash/defer'
import delay from 'lodash/delay'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import ScrollHistory from './scrollHistory'

const scrollHistory = new ScrollHistory('site')

let paused = false

export function useScrollHistory (page, onRestoreScrollState) {
  const router = useRouter()

  useEffect(() => {
    router.beforePopState(state => {
      state.options.scroll = false
      state.options.popState = true
      return true
    })

    const handleRouteChangeStart = () => { paused = true }
    const handleRouteChanged = () => {
      delay(() => { paused = false }, 250)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChanged)
    router.events.on('routeChangeError', handleRouteChanged)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChanged)
      router.events.on('routeChangeError', handleRouteChanged)
    }
  }, [])

  useEffect(() => {
    const state = window?.history?.state
    if (state?.key && state.options.popState) {
      const y = scrollHistory.get(state.key, state.as) || 0
      // The page gets set on this tick, so we need to restore it on the next tick when it has all the content
      defer(() => onRestoreScrollState(y))
    } else {
      onRestoreScrollState(0)
    }
  }, [onRestoreScrollState, page])

  return useMemo(() => ({
    get: () => scrollHistory.get(window.history.state?.key, window.history.state?.as) || 0,
    set: (y) => {
      if (!paused) {
        const state = window?.history?.state
        if (state) {
          scrollHistory.set(state.key, state.as, y)
        }
      }
    }
  }), [])
}
