import MuxPlayer from '@mux/mux-player-react'
import cn from 'clsx'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../hooks/useComposeRefs'

export const getVideoElement = (videoElement) => {
  if (videoElement.shadowRoot) {
    return videoElement.shadowRoot.querySelector('mux-video').shadowRoot.querySelector('video')
  }
  return videoElement
}

export const useVideoPlayerControls = (videoRef, initialState) => {
  const currentVideoStateRef = useRef(initialState)
  const currentVideoToggleRunningRef = useRef(false)

  const toggleVideo = async () => {
    if (!currentVideoToggleRunningRef.current) {
      currentVideoToggleRunningRef.current = true
      if (currentVideoStateRef.current === 'play') {
        await videoRef.current.play()
        if (currentVideoStateRef.current !== 'play') toggleVideo()
      } else {
        await videoRef.current.pause()
        if (currentVideoStateRef.current !== 'pause') toggleVideo()
      }
      currentVideoToggleRunningRef.current = false
    }
  }

  return {
    playVideo: useCallback(async () => {
      currentVideoStateRef.current = 'play'
      toggleVideo()
    }, []),
    pauseVideo: useCallback(async () => {
      currentVideoStateRef.current = 'pause'
      toggleVideo()
    }, [])
  }
}

const Video = forwardRef(({ video, controls, autoPlay, title, background, backgroundColor = '#000', className, style, ...props }, ref) => {
  const [ready, setReady] = useState(false)
  const videoRef = useRef()

  const onCanPlay = useCallback(() => {
    setReady(true)
  }, [])

  const { playVideo, pauseVideo } = useVideoPlayerControls(videoRef, autoPlay ? 'play' : 'pause')

  const { ref: inViewRef } = useInView({
    threshold: 0,
    onChange: async (inView) => {
      if (videoRef.current && background) {
        if (inView) {
          await playVideo()
        } else {
          await pauseVideo()
        }
      }
    }
  })

  const { playbackId, data } = video?.asset || {}
  const aspectRatio = data?.aspect_ratio
  const composedRefs = useComposeRefs(videoRef, inViewRef, ref)
  // useWindowResize(useCallback(() => {
  //   if (background && videoRef.current) {
  //     const [videoWidth, videoHeight] = aspectRatio.split(':')
  //     const aspect = videoWidth / videoHeight
  //     const { width, height } = videoRef.current.parentNode.getBoundingClientRect()
  //     if (width / aspect < height) {
  //       videoRef.current.style.width = `${height * aspect}px`
  //       videoRef.current.style.height = `${height}px`
  //     } else {
  //       videoRef.current.style.width = `${width}px`
  //       videoRef.current.style.height = `${width / aspect}px`
  //     }
  //     videoRef.current.style.top = '50%'
  //     videoRef.current.style.left = '50%'
  //     videoRef.current.style.transform = 'translate(-50%, -50%)'
  //   }
  // }, [aspectRatio, background]), true, true)

  const playerProps = (background || autoPlay) ? { autoPlay: 'muted', muted: true, loop: true, playsInline: true, nohotkeys: true } : {}
  const videoStyle = useMemo(() => {
    let s = style || {}
    if (controls === false) {
      s['--controls'] = 'none'
    }
    if (background) {
      s = {
        ...s,
        '--media-object-fit': 'cover',
        '--media-object-position': 'center',
        '--controls': 'none',
        '--controls-backdrop-color': 'transparent',
        '--media-background-color': backgroundColor
      }
    }
    return s
  }, [style, controls, background, backgroundColor])

  const [videoWidth, videoHeight] = aspectRatio.split(':')

  if (!playbackId) return null

  return (
    <div className={className} style={{ aspectRatio: !background && `${videoWidth}/${videoHeight}` }}>
      <MuxPlayer
        ref={composedRefs}
        streamType='on-demand'
        playbackId={playbackId}
        metadata={{ video_title: title }}
        onCanPlay={onCanPlay}
        {...playerProps}
        className={cn(
          background && 'absolute top-0 left-0 w-auto h-auto min-w-full min-h-full transition-opacity duration-200 !object-cover',
          !background || ready ? 'opacity-100' : 'opacity-0'
        )}
        style={videoStyle}
        {...props}
      />
    </div>
  )
})

function WrappedVideo ({ videoRef, ...props }) {
  return <Video {...props} ref={videoRef} />
}

export default WrappedVideo
