import useIsHovering from '../../../../hooks/useIsHovering'
import Link from '../../../Link'
import RichContent from '../../../RichContent'
import { useIsMobile } from '../../hooks'
import HtmlBlock from '../../ui/HtmlBlock'
import FloatingImages from './FloatingImages'
import TextCursor from './TextCusrsor'

export default function TextTile ({ tile, floatingImagesConfig, ...props }) {
  const { eventListeners, hovering } = useIsHovering()
  const { text, link, images } = tile
  const isMobile = useIsMobile()

  return (
    <group {...props} >
      <HtmlBlock htmlProps={{ center: true }} glChildren={({ width, height }) => (
        floatingImagesConfig && !isMobile
          ? (
        <FloatingImages
          images={images}
          containerWidth={width}
          containerHeight={height}
          config={floatingImagesConfig}
        />
            )
          : null)}
      >
        <Link link={link} showText={false} {...eventListeners}>
          <TextCursor show={hovering} link={link} />
          <RichContent
            content={text}
            className='text-[2.222vw] md:text-24 text-left md:text-center w-[36vw] md:w-screen leading-[1.09em] px-0 md:px-4'
          />
          {link && link.text && (
            <div className='flex justify-center'>
              <span className='hidden md:inline-block mx-auto mt-8 bg-background text-current border border-current uppercase text-12 px-2 py-1'>
                {link.text}
              </span>
            </div>
          )}
        </Link>
      </HtmlBlock>
    </group>
  )
}
