import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'
import useDelayedState from '../../hooks/useDelayedState'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import { useSite } from '../siteState'
import NoiseBackground from './NoiseBackground'
import DroppingNav from './SecondaryNavs/DroppingNav'
import GeoPopup from './SecondaryNavs/GeoPopup'
import MembersOnly from './SecondaryNavs/MembersOnly'
import ProductNav from './SecondaryNavs/ProductNav'
import SessionsNav from './SecondaryNavs/SessionsNav'
import ShopFilter from './SecondaryNavs/ShopFilter'
import { SECONDARY_MENUS, geoPopupAtom, geoPopupDismissedAtom, menuOpenAtom, secondMenuOpenAtom, secondMenuTypeAtom, showSecondMenuAtom } from './menuState'

export default function SecondaryNav ({ hasBlur, isDarkMode, page }) {
  const site = useSite()
  const rootRef = useRef()
  const mainMenuOpen = useAtomValue(menuOpenAtom)
  const showSecondMenu = useAtomValue(showSecondMenuAtom)
  const [_navType, setSecondNavType] = useAtom(secondMenuTypeAtom)
  const [isOpen, setIsOpen] = useAtom(secondMenuOpenAtom)

  const [geoPopup, setGeoPopup] = useAtom(geoPopupAtom)
  const geoPopupDismissed = useAtomValue(geoPopupDismissedAtom)
  const navType = !!geoPopup && !geoPopupDismissed ? SECONDARY_MENUS.geoPopup : _navType

  const visuallyOpen = useDelayedState(isOpen, 250)
  const mainMenuVisuallyOpen = useDelayedState(mainMenuOpen, 400)

  useOnClickOutside(rootRef, () => setIsOpen(false), isOpen)
  const isGeoPopup = navType === SECONDARY_MENUS.geoPopup

  useEffect(() => {
    const target = ((showSecondMenu || isGeoPopup) && !mainMenuVisuallyOpen) ? 1 : 0
    if (target) {
      gsap.to(rootRef.current,
        {
          autoAlpha: target,
          duration: 1.2,
          ease: 'sine.inOut'
        }
      )
    }
  }, [navType, showSecondMenu, mainMenuVisuallyOpen, isGeoPopup])

  function getGeoPopup () {
    const popups = site?.geoPopups?.popups
    if (!popups) return
    const country = Cookies.get('country')?.toLowerCase()

    if (country === site.countryCode) {
      return false
    }
    const countryPopup = popups?.find(p => p.countryCode?.toLowerCase() === country || p.countryCode?.toLowerCase() === 'row')
    return countryPopup
  }

  useEffect(() => {
    setSecondNavType(page.slug === 'home' ? SECONDARY_MENUS.home : SECONDARY_MENUS.none)
    const popup = getGeoPopup()
    if (!popup) return
    setGeoPopup(popup)
  }, [page])
  return (
    <nav
      ref={rootRef}
      id='secondary-nav'
      className={
        cn(
          'w-[calc(100%-32px)] max-w-[max(20vw,288px)] md:max-w-[100%] min-h-[30px] md:min-h-[47px] px-[0.5rem] fixed left-1/2 top-[calc(1rem+1.875rem+4px)] md:top-[calc(1rem+47px+4px)] -translate-x-1/2 z-second-menu bg-transparent',
          (visuallyOpen || ((hasBlur && (showSecondMenu || isGeoPopup)) && !mainMenuVisuallyOpen)) && 'backdrop-blur-menu backdrop-saturate-menu'
        )}
      style={{ color: 'var(--foreground)' }}
    >
      {isGeoPopup && <GeoPopup data={geoPopup}/>}
      {navType === SECONDARY_MENUS.product && <ProductNav page={page} />}
      {/* {navType === SECONDARY_MENUS.simpleProduct && <SimpleProductNav page={page} />} */}
      {navType === SECONDARY_MENUS.membersOnly && <MembersOnly page={page} />}
      {navType === SECONDARY_MENUS.shopFilter && <ShopFilter page={page} />}
      {/* {navType === SECONDARY_MENUS.jumpTo && <JumpTo page={page} />} */}
      {navType === SECONDARY_MENUS.sessions && <SessionsNav page={page} />}
      {/* {navType === SECONDARY_MENUS.article && <ArticleProgressNav page={page} />} */}
      {navType === SECONDARY_MENUS.dropping && <DroppingNav page={page} />}
      <div className={cn(
        'blur-bg fixed inset-0 opacity-0 transition-opacity duration-300 -z-1 ease-out-expo bg-background',
        (visuallyOpen || ((hasBlur && (showSecondMenu || isGeoPopup)) && !mainMenuVisuallyOpen)) && '!opacity-100'
      )}/>
      <div>
        <NoiseBackground light={isDarkMode} visible={(hasBlur && (showSecondMenu || isGeoPopup))} />
      </div>
    </nav>
  )
}
