import { Html, shaderMaterial } from '@react-three/drei'
import { extend, useThree } from '@react-three/fiber'
import Color from 'color'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import last from 'lodash/last'
import * as React from 'react'
import { Suspense, useEffect, useMemo, useState } from 'react'
import * as THREE from 'three'
import useComposeRefs from '../../../hooks/useComposeRefs'
import useImageBitmapLoader from '../../../hooks/useImageBitmapLoader'
import { getVideoElement } from '../../Video'
import VideoElement from '../../VideoElement'
import { IMAGE_OPACITY_BY_NAME } from '../../usePageTransition'
import { useViewport } from '../hooks'
import BlurTexture from './BlurTexture'
import useIntersect from './useIntersect'

// import { useTrailTexture } from './useTrailTexture'

const TRANSPARENT_BG = [1, 1, 1, 0]

const ImageMaterialImpl = shaderMaterial(
  {
    // time: 0,
    offsetIntensity: 0,
    backgroundColor: TRANSPARENT_BG,
    scale: [1, 1],
    imageBounds: [1, 1],
    map: null,
    zoom: 1,
    trailOpacity: 1,
    crossOver: 1,
    map2: null,
    uDarken: 1,
    opacity: 1
  },
  /* glsl */ `
  uniform float offsetIntensity;
  varying vec2 vUv;
  attribute float aOffsetX;
  attribute float aOffsetY;

  void main() {
    vec3 pos = position;
    pos.x += offsetIntensity * aOffsetX;
    pos.y += offsetIntensity * aOffsetY;
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(pos, 1.);
    vUv = uv;
  }
`,
  /* glsl */ `
  // mostly from https://gist.github.com/statico/df64c5d167362ecf7b34fca0b1459a44
  varying vec2 vUv;
  uniform vec2 scale;
  uniform vec2 imageBounds;
  uniform vec4 backgroundColor;
  uniform sampler2D map;
  // uniform sampler2D map2;
  // uniform float trailOpacity;
  uniform float zoom;
  uniform float crossOver;
  uniform float uDarken;
  uniform float opacity;

  const vec3 luma = vec3(0.2126, 0.7152, 0.0722);

  vec4 toGrayscale(vec4 color, float intensity) {
    return vec4(mix(color.rgb, vec3(dot(color.rgb, luma)), intensity), color.a);
  }
  vec2 aspect(vec2 size) {
    return size / min(size.x, size.y);
  }
  vec4 darken(vec4 color, float amount) {
    return vec4(color.r * amount, color.g * amount, color.b * amount, color.a);
  }

  void main() {
    vec2 s = aspect(scale);
    vec2 i = aspect(imageBounds);
    float rs = s.x / s.y;
    float ri = i.x / i.y;
    vec2 new = rs < ri ? vec2(i.x * s.y / i.y, s.y) : vec2(s.x, i.y * s.x / i.x);
    vec2 offset = (rs < ri ? vec2((new.x - s.x) / 2.0, 0.0) : vec2(0.0, (new.y - s.y) / 2.0)) / new;
    vec2 uv = vUv * s / new + offset;
    vec2 zUv = (uv - vec2(0.5, 0.5)) / zoom + vec2(0.5, 0.5);
    
    // float g = texture2D(map2, zUv).r * trailOpacity;
    vec4 img = texture2D(map, zUv);
    // vec4 invert  = texture2D(map, (zUv - 0.5) * -1. + 0.5);
    // vec4 result = vec4(mix(img.rgb, invert.rgb, g), img.a);
    // Applies the background color
    img = mix(backgroundColor, img, crossOver);

    float gamma = 1.9;
    img.rgb = pow(img.rgb, vec3(gamma));

    gl_FragColor = darken(img, uDarken);
    gl_FragColor.a *= opacity;
    
    #include <tonemapping_fragment>
    #include <encodings_fragment>
  }
`
)

// function circleOut (t) {
//   return Math.sqrt(1 - --t * t)
// }

const ImageBase = React.forwardRef(
  (
    {
      children,
      backgroundColor,
      segments = 4,
      scale = 1,
      texture,
      width,
      height,
      toneMapped,
      transparent = true,
      // debug = false,
      onPointerMove,
      onPointerLeave,
      onPointerEnter,
      disableHover,
      materialRef,
      darken = 1,
      animateDrag = false,
      animate = false,
      animateIn = false,
      ...props
    },
    ref
  ) => {
    extend({ ImageMaterial: ImageMaterialImpl })
    const planeBounds = Array.isArray(scale) ? [scale[0], scale[1]] : [scale, scale]
    const imageBounds = width && height ? [width, height] : [texture?.image.width || 0, texture?.image.height || 0]
    const imageMaterialRef = React.useRef()
    const localsRef = React.useRef({ trailOpacity: 1 })

    // const trailConfig = {
    //   size: 256,
    //   radius: 0.1,
    //   maxAge: 1000,
    //   interpolate: 0,
    //   smoothing: 0.6,
    //   minForce: 0.9,
    //   blend: 'screen',
    //   maxTailLength: 1000,
    //   ease: circleOut
    // }

    // const [trailTexture, onMove, clear] = useTrailTexture(trailConfig)

    const onLeave = React.useCallback(() => {
      onPointerLeave?.()
      localsRef.current.tl?.kill()
      if (!disableHover && imageMaterialRef.current) {
        const tl = gsap.timeline()
        localsRef.current.tl = tl
        gsap.to(imageMaterialRef.current.uniforms.zoom, { value: 1, duration: 0.6, ease: 'expo.out' })
      }
      // tl.to(localsRef.current, {
      //   trailOpacity: 0,
      //   duration: 1,
      //   delay: 2,
      //   onUpdate: () => {
      //     if (imageMaterialRef.current) {
      //       imageMaterialRef.current.trailOpacity = localsRef.current.trailOpacity
      //     }
      //   },
      //   onComplete: () => {
      //     clear()
      //   }
      // })
    }, [onPointerLeave, disableHover])

    const onEnter = React.useCallback(() => {
      onPointerEnter?.()
      localsRef.current.tl?.kill()
      if (!disableHover && imageMaterialRef.current) {
        const tl = gsap.timeline()
        localsRef.current.tl = tl
        gsap.to(imageMaterialRef.current.uniforms.zoom, { value: 1.1, duration: 0.6, ease: 'expo.out' })
      }
      // tl.to(localsRef.current, {
      //   trailOpacity: 1,
      //   duration: 0.3,
      //   onUpdate: () => {
      //     if (imageMaterialRef.current) {
      //       imageMaterialRef.current.trailOpacity = localsRef.current.trailOpacity
      //     }
      //   }
      // })
    }, [onPointerEnter, disableHover])

    const meshRef = React.useRef()
    // React.useLayoutEffect(() => {
    //   const rand = new Random(Math.random() * 10)
    //   const geometry = meshRef.current.geometry
    //   const elementsCount = meshRef.current.geometry.attributes.position.count
    //   const offsetsX = [elementsCount * 3]
    //   const offsetsY = [elementsCount * 3]
    //   for (let i = 0; i < elementsCount; i += 6) {
    //     const rx = rand.generate(-0.1, 0.1)
    //     const ry = rand.generate(-0.1, 0.1)
    //     offsetsX[i] = rx
    //     offsetsX[i + 1] = rx
    //     offsetsX[i + 2] = rx

    //     // This will make it a square shape
    //     offsetsX[i + 3] = rx
    //     offsetsX[i + 4] = rx
    //     offsetsX[i + 5] = rx

    //     offsetsY[i] = ry
    //     offsetsY[i + 1] = ry
    //     offsetsY[i + 2] = ry

    //     // This will make it a square shape
    //     offsetsY[i + 3] = ry
    //     offsetsY[i + 4] = ry
    //     offsetsY[i + 5] = ry
    //   }

    //   geometry.setAttribute('aOffsetX', new THREE.Float32BufferAttribute(offsetsX, 1))
    //   geometry.setAttribute('aOffsetY', new THREE.Float32BufferAttribute(offsetsY, 1))
    // }, [])

    const geometry = React.useMemo(() => {
      let planeGeometry = new THREE.PlaneGeometry(1, 1, segments, segments)
      planeGeometry = planeGeometry.toNonIndexed()
      return planeGeometry
    }, [segments])

    const handlePointerMove = React.useCallback((...args) => {
      if (primaryInput === 'touch') return
      if (!disableHover) {
        // onMove?.(...args)
      }
      const [e] = args
      if (localsRef.current.pointerDown) {
        if (Math.abs(e.clientX - localsRef.current.pointerPosition.x) > 10 ||
          Math.abs(e.clientY - localsRef.current.pointerPosition.y) > 10) {
          if (animateDrag) {
            localsRef.current.dragTimeLine?.kill()
            const tl = gsap.timeline()
            tl.to(imageMaterialRef.current, { offsetIntensity: 1, duration: 1, ease: 'power2.out' })
            localsRef.current.dragTimeLine = tl
          }
        }
      }
      onPointerMove?.(...args)
    }, [onPointerMove, disableHover, animateDrag])

    const handlerPointerDown = React.useCallback((e) => {
      if (primaryInput === 'touch') return
      localsRef.current.pointerDown = true
      localsRef.current.pointerPosition = { x: e.clientX, y: e.clientY }
      const mouseUp = () => {
        window.document.removeEventListener('mouseup', mouseUp)
        localsRef.current.pointerDown = false
        if (animateDrag) {
          localsRef.current.dragTimeLine?.kill()
          const tl = gsap.timeline()
          tl.to(imageMaterialRef.current, { offsetIntensity: 0, duration: 0.5, ease: 'expo.out' })
          localsRef.current.dragTimeLine = tl
        }
      }
      window.document.addEventListener('mouseup', mouseUp)
    }, [animateDrag])

    const composedRefs = useComposeRefs(imageMaterialRef, materialRef)

    useEffect(() => {
      if (animate && imageMaterialRef.current && animateIn) {
        gsap.to(imageMaterialRef.current.uniforms.crossOver, { value: 1, duration: 0.3, ease: 'sine.inOut' })
      }
    }, [animate, animateIn, imageMaterialRef.current])

    // WARNING WARNING WARNING
    // Very hacky code below, we are animating the opacity of this mesh from the usePageTransition hook.
    // But i kept getting a issue where the opacity kept resetting, i think because this component keeps rerendering and
    // resetting the opacity value
    useEffect(() => {
      const { name } = props
      if (imageMaterialRef.current && name && IMAGE_OPACITY_BY_NAME[name] !== undefined) {
        imageMaterialRef.current.opacity = IMAGE_OPACITY_BY_NAME[name]
      }
    })

    return (
      <mesh
        geometry={geometry}
        ref={useComposeRefs(ref, meshRef)}
        scale={Array.isArray(scale) ? [...scale, 1] : scale}
        {...props}
        onPointerMove={handlePointerMove}
        onPointerLeave={onLeave}
        onPointerEnter={onEnter}
        onPointerDown={handlerPointerDown}
      >
        {/* <planeGeometry args={[1, 1, segments, segments]} /> */}
        {!texture && <meshBasicMaterial color={backgroundColor || TRANSPARENT_BG} transparent={transparent} />}
        {texture && (
          <imageMaterial
            // map2={trailTexture}
            backgroundColor={backgroundColor || TRANSPARENT_BG}
            map={texture}
            map-encoding={THREE.LinearEncoding}
            zoom={1}
            crossOver={animate ? 0 : 1}
            scale={planeBounds}
            imageBounds={imageBounds}
            toneMapped={toneMapped}
            transparent={transparent}
            uDarken={darken}
            ref={composedRefs}
          />
        )}
        {/* {debug && <meshBasicMaterial map={trailTexture} />} */}
        {children}
      </mesh>
    )
  }
)

const ImageWithTexture = React.forwardRef(({ preload, image, url, scale, width, height, blur = 0, ...props }, ref) => {
  const [animateIn, setAnimateIn] = useState(false)
  const texture = useImageBitmapLoader(image?.url || url, () => { setAnimateIn(true) }, blur === 0)
  const blurTexture = useMemo(() => {
    if (blur > 0) {
      return (new BlurTexture({ size: image, blur, texture })).blurTexture
    }
    return texture
  }, [texture, image, blur])

  return <ImageBase {...props} texture={blurTexture} width={image?.width || width} height={image.height || height} scale={scale} ref={ref} animate={!preload} animateIn={animateIn} />
})

const VideoWithTexture = React.forwardRef(({ preload, image, video, scale, ...props }, ref) => {
  const videoRef = React.useRef()
  const imageTexture = useImageBitmapLoader(image?.url)
  const [texture, setTexture] = useState(imageTexture)
  const [{ width, height }, setSize] = useState({ width: image?.width || maxWidth, height: image.height || maxHeight })

  const { max_width: maxWidth, max_height: maxHeight } = video?.asset?.data?.tracks?.[0] || {}

  const onCanPlay = React.useCallback(() => {
    const videoTexture = new THREE.VideoTexture(getVideoElement(videoRef.current))

    videoTexture.encoding = THREE.LinearEncoding
    setTexture(videoTexture)
    setSize({ width: maxWidth, height: maxHeight })
    return () => {
      videoTexture.dispose()
    }
  }, [])

  return (
    <>
      <Html portal={{ current: document.getElementById('canvas-feed-html') }} >
        <div className='invisible pointer-events-none -translate-x-1/2 -translate-y-1/2' style={{ width: maxWidth, aspectRatio: `${maxWidth}/${maxHeight}` }}>
          <VideoElement className='w-full' video={video} ref={videoRef} autoPlay controls loop onCanPlay={onCanPlay} />
        </div>
      </Html>
      <ImageBase {...props} texture={texture} width={width} height={height} scale={scale} ref={ref} animate={!preload} animateIn />
    </>
  )
})

export default React.forwardRef(({ preload, image, video, scale, onInView, showPreview = true, backgroundColor, name, ...props }, ref) => {
  const [load, setLoad] = React.useState(preload)
  const size = useThree(({ size }) => size)
  const viewport = useViewport()
  const currentImageSize = React.useRef()
  const imageSource = React.useMemo(() => {
    const imageContainerWidth = ((scale?.[0] || 1) / viewport.width) * size.width * window.devicePixelRatio * 1.5
    const imageSize = (image.sizes.find(({ width }) => width >= imageContainerWidth) || last(image.sizes))
    if (!currentImageSize.current || currentImageSize.current.width < imageSize.width) {
      currentImageSize.current = imageSize
    }
    return currentImageSize.current
  }, [image, size.width])

  const bg = React.useMemo(() => {
    const color = image.palette.dominant.background
    if (color) {
      return new Color(color).unitArray()
    }
  }, [image])

  const intersectionRef = useIntersect((visible) => {
    // setLoad(value => value || visible)
    setLoad(visible)
    onInView?.(visible)
  })

  return (
    <group ref={ref} name={name}>
      {showPreview && (
        <ImageBase {...props} scale={scale} position={[0, 0, -0.0001]} ref={intersectionRef} image={imageSource} backgroundColor={backgroundColor || bg} />
      )}
      {load && (
        <Suspense>
          {video && <VideoWithTexture scale={scale} {...props} name={name ? `${name}-img` : null} image={imageSource} video={video} preload={preload} backgroundColor={backgroundColor || bg} />}
          {!video && <ImageWithTexture scale={scale} {...props} name={name ? `${name}-img` : null} image={imageSource} preload={preload} />}
        </Suspense>
      )}
    </group>
  )
})
