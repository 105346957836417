/* eslint-disable jsx-a11y/alt-text */
import { useCursor } from '@react-three/drei'
import Color from 'color'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSelectItemTracker, useTrackProductImpressionCallback } from '../../../../gtm'
import { isLandscapeImage } from '../../../../helpers/image'
import { isInStock, isProduct, isReleased } from '../../../../helpers/product'
import { linkToUrl, resolveLink } from '../../../../lib/resolveLink'
import transform from '../../../ResponsiveImage/transform'
import { useViewport } from '../../hooks'
import Draggable from '../Draggable'
import Image from '../Image'
import FloatingImages from './FloatingImages'
import Meta from './Meta'

export default function ProductTile ({ enabled, tile, width, metadataWidth = 1, floatingImagesConfig, preload, ...props }) {
  const { images, video } = tile
  const viewport = useViewport()
  const router = useRouter()

  const image = useMemo(() => {
    const img = images.feedImage || images.featureImage || tile.image
    if (!img) return null
    const size = width[isLandscapeImage(img) ? 'landscape' : 'portrait'] || width.landscape
    const source = transform(img, size.aspect, [256, 512, 1024, 2048])
    return { source, size }
  }, [images, width])

  const backgroundColor = useMemo(() => {
    const img = images.feedImage || images.featureImage || tile.image
    const bg = img?.asset?.metadata?.palette?.dominant?.background
    if (bg) {
      return [...Color(bg).unitArray(), 1]
    }
  }, [images])

  const [hovered, setHover] = useState()
  useCursor(hovered, 'pointer', 'auto')

  const { blur, darken } = useMemo(() => {
    return {
      blur: isProduct(tile) && (!isReleased(tile) || !isInStock(tile)) ? 25 : 0,
      darken: (tile._type === 'page' && tile.pageType === 'winery')
        ? 0.65
        : isProduct(tile) && !isReleased(tile)
          ? 0.7
          : isProduct(tile) && !isInStock(tile) ? 0.75 : 1
    }
  }, [tile])

  const selectItemTracker = useSelectItemTracker()
  const onClick = useCallback(() => {
    if (enabled) {
      selectItemTracker(tile)
      router.push(linkToUrl(resolveLink(tile)))
    }
  }, [router, selectItemTracker, tile, enabled])

  const trackedRef = useRef({ tracked: false })
  const trackProductImpression = useTrackProductImpressionCallback()
  const onInView = useCallback((visible) => {
    if (isProduct(tile) && visible && !trackedRef.current.tracked) {
      trackProductImpression(tile, tile.index)
      trackedRef.current.tracked = true
    }
  }, [tile, trackProductImpression])

  if (!image) return null

  const imageWidth = viewport.width * image?.size?.width
  const imageHeight = imageWidth / image?.size?.aspect

  return (
    <>
      <Draggable enabled={enabled} onClick={onClick}>
        {() => (
          <group name='tile'>
            <Image
              image={image.source}
              video={video}
              scale={[imageWidth, imageHeight, 1]}
              onPointerOver={enabled ? () => setHover(true) : null}
              onPointerOut={enabled ? () => setHover(false) : null}
              disableHover={true}
              backgroundColor={backgroundColor}
              preload={preload}
              segments={16}
              blur={blur}
              darken={darken}
              name='tile-image'
              onInView={onInView}
              {...props}
            />
            <Meta
              tile={tile}
              tileWidth={imageWidth}
              tileHeight={imageHeight}
              metadataWidth={metadataWidth}
            />
          </group>
        )}
      </Draggable>
      {floatingImagesConfig && (
        <FloatingImages
          images={images?.images}
          containerWidth={imageWidth}
          containerHeight={imageHeight}
          config={floatingImagesConfig}
          preload={preload}
          blur={isReleased ? 0 : 25}
          darken={isReleased ? 1 : 0.75}
          name='tile-image'
        />
      )}
    </>
  )
}
