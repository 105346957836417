import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import orderBy from 'lodash/orderBy'
import _sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

export const dropdownOptions = [
  {
    value: 'latest',
    text: 'Latest'
  },
  {
    value: 'oldest',
    text: 'Oldest'
  },
  {
    value: 'lowest',
    text: 'Price low to high'
  },
  {
    value: 'highest',
    text: 'Price high to low'
  }
]

const useSortProducts = (products, sortBy, curatedProducts) => {
  return useMemo(() => {
    if (!sortBy || sortBy === 'latest') {
      // The curated products will always appear at the top of the page when we are sorting by latest
      const filteredCuratedProducts = _sortBy(
        filter(products, p => curatedProducts?.find(pp => pp._ref === p._id)), // Find the curated products in the list of products
        p => findIndex(curatedProducts, pp => pp._ref === p._id) // Sort the products using the index in the curated list
      )
      const filteredProducts = filter(products, p => !curatedProducts?.find(pp => pp._ref === p._id))
      return [
        ...filteredCuratedProducts,
        ...orderBy(filteredProducts, ['releasedDate'], ['desc'])
      ]
    } else if (sortBy === 'oldest') {
      return orderBy(products, ['releasedDate'], ['asc'])
    } else if (sortBy === 'highest' || sortBy === 'lowest') {
      return orderBy(products, ['priceRange.minVariantPrice'], sortBy === 'highest' ? ['desc'] : ['asc'])
    }
    return products
  }, [curatedProducts, products, sortBy])
}

export default useSortProducts
