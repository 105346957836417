import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import reverse from 'lodash/reverse'
import * as THREE from 'three'
import Random from '../../../helpers/Random'

const seed = Math.random() * 10

const generateRandomOffset = (random) => {
  return { x: random.generate(-0.05, 0.05), y: random.generate(-0.05, 0.05) }
}

export const generateFeedRows = (tiles) => {
  const random = new Random(seed)
  const rows = []
  const queue = [...tiles]
  let index = 0
  reverse(queue)

  while (!isEmpty(queue)) {
    const tile = queue.pop()

    const addFullWidthRow = (row) => {
      const lastRow = last(rows)
      const rowCells = { ...row, tiles: [{ ...row.cells[0], tile, z: 0 }] }
      if (lastRow?.tiles?.length < lastRow.cells.length) {
        rows[rows.length - 1] = rowCells
        rows.push(lastRow)
      } else {
        rows.push(rowCells)
      }
    }

    tile.index = index
    index++

    if (tile._type === 'quote') {
      addFullWidthRow(feedRowFullWidth('quote'))
      continue
    }

    if (tile._type === 'footerTile') {
      addFullWidthRow(feedRowFullWidth('footer'))
      continue
    }

    if (tile.layout === 'feature') {
      rows.push({ ...feedRowFeature, tiles: [{ ...feedRowFeature.cells[0], tile, z: 0 }] })
      continue
    }

    let row = last(rows)
    if (!row || row?.tiles?.length >= row.cells.length) {
      row = { ...feedRowTypes[rows.filter(x => x.type !== 'quote').length % feedRowTypes.length], tiles: [] }
      rows.push(row)
    }
    row.tiles.push({
      z: Math.round(random.generate(-2, 0.5)),
      ...row.cells[row.tiles.length],
      tile,
      offset: generateRandomOffset(random)
    })
  }

  // Preload the top 4 tile and the last 4 tiles
  let items = compact(flatten(rows.map(({ tiles }) => tiles)))
    .filter(item => item?.tile._type === 'product' || item?.tile._type === 'page' || item?.tile._type === 'imageTile')
  items = [
    ...items.slice(0, 4),
    ...items.slice(items.length - 4)
  ]
  forEach(items, i => { i.preload = true })

  return rows
}

export const ASPECT_PORTRAIT = 0.749
export const ASPECT_LANDSCAPE = 1.539

export const cellWidths = {
  lg: {
    desktop: {
      portrait: { width: 0.318, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.354271, aspect: ASPECT_LANDSCAPE }
    },
    mobile: {
      portrait: { width: 0.857, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.814, aspect: ASPECT_LANDSCAPE }
    }
  },
  md: {
    desktop: {
      portrait: { width: 0.234, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.332, aspect: ASPECT_LANDSCAPE }
    },
    mobile: {
      portrait: { width: 0.655, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.814, aspect: ASPECT_LANDSCAPE }
    }
  },
  sm: {
    desktop: {
      portrait: { width: 0.178, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.246, aspect: ASPECT_LANDSCAPE }
    },
    mobile: {
      portrait: { width: 0.6, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.814, aspect: ASPECT_LANDSCAPE }
    }
  },
  smMd: {
    desktop: {
      portrait: { width: 0.178, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.246, aspect: ASPECT_LANDSCAPE }
    },
    mobile: {
      portrait: { width: 0.655, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.814, aspect: ASPECT_LANDSCAPE }
    }
  },
  mdSm: {
    desktop: {
      portrait: { width: 0.234, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.332, aspect: ASPECT_LANDSCAPE }
    },
    mobile: {
      portrait: { width: 0.52, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.814, aspect: ASPECT_LANDSCAPE }
    }
  },
  feature: {
    desktop: {
      landscape: { width: 0.568, aspect: 1.92 }
    },
    mobile: {
      landscape: { width: 0.857, aspect: 1.92 }
    }
  },
  fullWidth: {
    desktop: {
      landscape: { width: 1 }
    },
    mobile: {
      landscape: { width: 1 }
    }
  },
  winery: {
    mobile: {
      portrait: { width: 0.9, aspect: ASPECT_PORTRAIT },
      landscape: { width: 0.9, aspect: ASPECT_LANDSCAPE }
    }
  }
}

const feedRow1 = {
  cells: [
    {
      z: 0,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.302, 0),
      floatingImages: [{
        size: 0.28,
        position: [-0.45, 0.25, 1]
      }]
    },
    {
      z: -1,
      width: cellWidths.md,
      position: new THREE.Vector2(0.732, -0.06),
      floatingImages: [{
        size: 0.5,
        position: [0.8, 0.4, -2]
      }]
    },
    {
      z: -0.5,
      width: cellWidths.sm,
      position: new THREE.Vector2(0.508, -0.5),
      // metadata: {
      //   width: 1.5
      // },
      floatingImages: [{
        size: 0.8,
        position: [-0.8, 0, -0.3]
      }, {
        size: 0.9,
        position: [0.5, 0.1, -0.6]
      }]
    }
  ]
}

const feedRow2 = {
  cells: [
    {
      z: -1,
      width: cellWidths.smMd,
      position: new THREE.Vector2(0.125, 0) // [271, 226]
    },
    {
      z: 0,
      width: cellWidths.mdSm,
      position: new THREE.Vector2(0.734, -0.1), // [1057, 360]
      floatingImages: [{
        size: 0.4,
        position: [0.8, 0.5, -1]
      }]
    },
    {
      z: -2,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.353, -0.342), // [450, 852]
      floatingImages: [{
        size: 0.4,
        position: [0.6, -0.2, -1]
      }]
    }
  ]
}

const feedRow3 = {
  cells: [
    {
      z: -3,
      width: cellWidths.md,
      position: new THREE.Vector2(0.728, 0) // [1049, 238]
    },
    {
      z: 0,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.299, -0.3) // [431, 656]
    },
    {
      z: -0.5,
      width: cellWidths.md,
      position: new THREE.Vector2(0.778, -0.58) // [1121, 908]
    }
  ]
}

const feedRow4 = {
  cells: [
    {
      z: 0.5,
      width: cellWidths.md,
      position: new THREE.Vector2(0.181, 0.1) // [188, 241]
    },
    {
      z: -1,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.639, -0.167), // [920, 428]
      floatingImages: [{
        size: 0.4,
        position: [0.5, 0.6, -1]
      }]
    }
  ]
}

const feedRow5 = {
  cells: [
    {
      z: -0.5,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.219, 0), // [315, 406]
      surroundingImages: false
    },
    {
      z: -1,
      width: cellWidths.md,
      position: new THREE.Vector2(0.785, -0), // [986, 320]
      floatingImages: [{
        size: 0.4,
        position: [-0.5, -0.4, -2]
      }]
    },
    {
      z: -2,
      width: cellWidths.sm,
      position: new THREE.Vector2(0.490, -0.238), // [705, 775]
      surroundingImages: false
    }
  ]
}

const feedRow6 = {
  cells: [
    {
      z: -1.5,
      width: cellWidths.lg,
      position: new THREE.Vector2(0.687, -0.0), // [989, 212]
      surroundingImages: false
    },
    {
      z: 0.5,
      width: cellWidths.md,
      position: new THREE.Vector2(0.241, -0.23) // [635, 536]
    }
  ]
}

export const feedRowFullWidth = (type) => ({
  cells: [{ position: new THREE.Vector2(0.5, -0.5), width: cellWidths.fullWidth }],
  type
})

export const feedRowFeature = {
  cells: [
    {
      position: new THREE.Vector2(0.329, -0.20), // [474, 364]
      width: cellWidths.feature,
      surroundingImages: false
    },
    {
      position: new THREE.Vector2(0.86, -0.33), // [1148, 295]
      width: cellWidths.md
    },
    {
      z: -0.5,
      width: cellWidths.sm,
      position: new THREE.Vector2(0.508, -0.6),
      metadata: {
        width: 1.5
      },
      floatingImages: [{
        size: 0.8,
        position: [-0.8, 0, -0.3]
      }, {
        size: 0.9,
        position: [0.5, 0.1, -0.6]
      }]
    }
  ]
}

export const feedRowTypes = [
  feedRow1,
  feedRow2,
  feedRow3,
  feedRow4,
  feedRow5,
  feedRow6
]
