import cn from 'clsx'
import { useSetAtom } from 'jotai'
import { LINK_HOVER_CLASSES } from '..'
import Button from '../../Button'
import Link from '../../Link'
import RichContent from '../../RichContent'
import { useLabels } from '../../siteState'
import { geoPopupDismissedAtom } from '../menuState'

export default function GeoPopup ({ data }) {
  const { link, message } = data
  const setGeoPopupDismissed = useSetAtom(geoPopupDismissedAtom)
  const { closeLabel } = useLabels()

  const onClose = () => {
    setGeoPopupDismissed(true)
  }

  return (
    <div className='w-full col-span-full'>
      <div className='text-12 py-4 text-center [&_a]:underline [&_a]:font-normal [&_a_span]:hidden'>
        <RichContent content={message}/>
      </div>
      <div className='w-full h-[1px] bg-current opacity-10' />
      <div className='flex justify-between py-4 gap-4'>
        <button className={cn(LINK_HOVER_CLASSES, 'text-10 uppercase font-550 tracking-slight')} onClick={onClose}>{closeLabel}</button>
        <Button
          tag={Link}
          link={link}
          className='z-[2] [&_span]:ml-[0.2em]'
          px='px-4'
          py='py-2'
          w='w-auto'
          filled
          invert
          border='border-none'
          text='text-10 tracking-slight leading-none'
          showText={false}
        >
          <span>{link?.text || link?.page?.title}</span>
        </Button>
      </div>
    </div>
  )
}
