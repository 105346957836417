import { useFrame, useThree } from '@react-three/fiber'
import { useAtomValue } from 'jotai'
import last from 'lodash/last'
import { useCallback, useMemo, useRef } from 'react'
import useScrollPosition from '../../../hooks/useScrollPosition'
import transform from '../../ResponsiveImage/transform'
import ProductHeroImage from '../ProductHero/Image'
import { useIsMobile, useViewport } from '../hooks'
import { SCENES, sceneAtom } from '../sceneState'

const mobileWidth = 0.8
const desktopWidth = 0.25

function DeformedImage ({ image, index = 0, data, ...props }) {
  const groupRef = useRef()
  const viewport = useViewport()
  const size = useThree(({ size }) => size)
  const isMobile = useIsMobile()
  const mouseRef = useRef({
    scroll: 0,
    progress: 0
  })

  const slideCount = data.sessions?.length

  const updateScrollPosition = useCallback(({ progress, velocity }) => {
    if (mouseRef.current) {
      mouseRef.current.scroll = window.scrollY
      mouseRef.current.progress = progress
      mouseRef.current.velocity = velocity
    }
  }, [])

  useScrollPosition(updateScrollPosition, true)

  const aspect = image?.asset?.metadata?.dimensions?.aspectRatio || 1

  const imageWidth = isMobile ? viewport.width * mobileWidth : viewport.width * desktopWidth
  const imageHeight = imageWidth / aspect
  const slideOffset = isMobile ? 0.85 : 0.55

  useFrame(() => {
    if (groupRef.current) {
      const progress = mouseRef.current.progress || 0
      const scrollY = (mouseRef.current.scroll / size.height) * viewport.height

      groupRef.current.position.y = scrollY * -1
      groupRef.current.position.x = -progress * (viewport.width * slideOffset * (slideCount - 1))
    }
  })

  const primaryImage = useMemo(() => {
    if (!image) return null
    return transform(image, aspect, [320, 420, 640, 1024, 1200])
  }, [aspect, image])

  return <group ref={groupRef}>
    <ProductHeroImage
      url={last(primaryImage.sizes).url}
      width={primaryImage.width}
      height={primaryImage.height}
      scale={[imageWidth, imageHeight, 1]}
      position={[viewport.width * slideOffset * index, 0, 0]}
      segments={1}
      {...props}
    />
  </group>
}

export default function SessionListing () {
  const data = useAtomValue(sceneAtom)?.data[SCENES.SESSIONS_LISTING]

  if (!data) return
  return (
    <group>
      {data?.sessions?.map((session, i) => (
        <DeformedImage key={session._id} image={session?.images?.featureImage} data={data} index={i}/>
      ))}
    </group>
  )
}
