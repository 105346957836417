import cn from 'clsx'

export default function Spinner ({ className, size = 80 }) {
  return (
    <div className={cn('spinner', className)} style={{ fontSize: size }}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
