import { useEffect } from 'react'

export default function useOnClickOutside (ref, handler, enabled, ignoredClassName) {
  useEffect(() => {
    if (enabled) {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        if (ignoredClassName && event.target.classList.contains(ignoredClassName)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [ref, handler, enabled])
}
