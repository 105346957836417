import { useEffect, useRef } from 'react'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'
import Link from '../../Link'
import useIsHovering from '../../../hooks/useIsHovering'
import useStickyCursorPosition from '../../../hooks/useStickyCursorPosition'
import { Parallax } from '../../Parallax'
import { ProductMeta } from '../ProductListing/ProductTile'
import useComposeRefs from '../../../hooks/useComposeRefs'
import gsap from 'gsap'
import cn from 'clsx'

function Hotspot ({ hotspot }) {
  const ref = useRef()
  const popupRef = useRef()
  const stickyRef = useStickyCursorPosition()
  const combinedRefs = useComposeRefs(ref, stickyRef)

  const { eventListeners, hovering } = useIsHovering()

  useEffect(() => {
    gsap.to(popupRef.current, {
      autoAlpha: hovering ? 1 : 0,
      duration: 0.6,
      ease: 'expo.out'
    })
    gsap.to(ref.current.children[0], {
      scale: hovering ? 1.5 : 1,
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [hovering])

  return (
    <Link
      ref={combinedRefs}
      link={hotspot?.product}
      className='absolute z-10 md:hidden'
      style={{
        top: hotspot.y + '%',
        left: hotspot.x + '%',
      }}
      showText={false}
      {...eventListeners} 
    >
      <div className={cn('w-[1rem] h-[1rem] rounded-full border-[2px] border-white bg-transparent transition-colors duration-300 ease-out-expo', hovering && 'bg-white')} />
      <div ref={popupRef} className='bg-white py-2 px-3 absolute left-6 top-6 opacity-0 min-w-[15em] invisible'>
        <ProductMeta product={hotspot?.product} className='text-10'/>
      </div>
    </Link>
  )
}

export default function ArticleHotspot ({ data }) {
  const { image, caption, hotspots } = data

  return (
    <Section grid>
      <div className={cn('col-span-10 relative overflow-hidden md:col-span-full', !caption && '!col-span-full')}>
        <ResponsiveImage image={image}/>
        {hotspots?.map(hotspot => (
          <Hotspot key={hotspot._key} hotspot={hotspot} />
        ))}
      </div>
      {caption && <div className='text-14 col-span-2 self-end tracking-[-0.02em] md:col-span-full md:uppercase md:font-550 md:text-10 md:tracking-slight md:flex md:gap-1'>
        <div className='upMd:hidden w-[0.5rem] h-[0.5rem] rounded-full bg-current shrink-0'/>
        {caption}
      </div>}
      {hotspots?.map(hotspot => (
        <Link link={hotspot?.product} showText={false} key={hotspot._key} className='hidden md:col-span-full md:uppercase md:font-550 md:text-10 md:tracking-slight md:flex md:gap-1'>
          <div className='upMd:hidden w-[0.5rem] h-[0.5rem] rounded-full bg-current shrink-0'/>
          in photo: {hotspot.product.title}
        </Link>
      ))}
    </Section>
  )
}
