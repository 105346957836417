import { useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import Button from '../../Button'
import { navViewAtom, navViewDataAtom, NAV_VIEWS } from '../../Menu/menuState'
import { useCartSettings, useGiftWrappingProducts } from '../../siteState'
import useCart from '../useCart'

function GiftWrappingButton ({ product }) {
  const setNavView = useSetAtom(navViewAtom)
  const setNavVIewData = useSetAtom(navViewDataAtom)
  const { addLabel } = useCartSettings()

  const { title } = product
  const variant = product.variants?.[0]
  const onClick = useCallback(async () => {
    setNavView(NAV_VIEWS.giftMessage)
    setNavVIewData(product)
  }, [product, setNavVIewData, setNavView])

  return (
    <li className='grid my-2 text-10 font-semibold'>
      <Button onClick={onClick}
        className='text-10 z-[2]'
        px='px-4'
        border='border border-border hover:border-foreground'
        justify='justify-between'
      >
        <span>{addLabel} {title}</span>
        <span>{formatCurrency(variant.price)}</span>
      </Button>
    </li>
  )
}

export default function GiftWrappingButtons () {
  const giftWrappingProducts = useGiftWrappingProducts()
  const { lines } = useCart()
  const items = useMemo(() => {
    // Remove any gift products already in the cart
    return giftWrappingProducts.filter(({ shopifyId }) => !lines
      .find((item) => item.product?.shopifyId === shopifyId))
  }, [giftWrappingProducts, lines])

  return items?.map((product) => (
    <GiftWrappingButton product={product} key={product._id} />
  ))
}
