import Link from '../../../Link'
import RichContent from '../../../RichContent'
import Section from '../../../Section'
import { useSubscriptionCartToolbar } from '../../SubscriptionListing/subscriptionState'
import DynamicBundleListing from './DynamicBundleListing'
import PresetBundleListing from './PresetBundleListing'

const Header = ({ data, ...props }) => {
  const { title, copy, cta } = data
  return (
    <div className='border-t border-solid pt-4' {...props}>
      <Section as='div' grid noBottomMargin className='mb-14 md:mb-8'>
        <h3 className='uppercase font-550 text-18 md:text-14 col-span-3 md:col-span-4 row-start-1'>{title}</h3>
        <RichContent content={copy} className='col-span-6 md:col-span-full text-14 md:text-12 indent-indent-sm md:indent-indent-lg' />
        <Link link={cta} className='uppercase font-550 text-14 md:text-12 col-span-3 md:col-span-4 md:row-start-1 hover:underline justify-self-end self-start ' />
      </Section>
    </div>
  )
}

export default function SubscriptionsTab ({ data, showDynamicBundle }) {
  const { subscriptions } = data
  const { presetBundles: presetBundlesData, dynamicBundle: dynamicBundleData } = subscriptions
  useSubscriptionCartToolbar(showDynamicBundle)

  return (
    <div>
      {!showDynamicBundle && (
        <>
          <Header data={presetBundlesData} id='preset' />
          <PresetBundleListing data={presetBundlesData} />
        </>
      )}
      {showDynamicBundle && (
        <>
          <Header data={dynamicBundleData} id='dynamic' />
          <DynamicBundleListing data={data} />
        </>
      )}
    </div>
  )
}
