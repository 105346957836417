import Section from '../../Section'
import Tabs from '../../Tabs'
import Tab from '../../Tabs/Tab'

export default function SupportSliceHeader ({ tabs, setTab, index }) {
  const moveToIndex = (_index) => {
    if (_index > tabs.length) return
    setTab(tabs[_index])
  }

  return (
    <Section className='flex justify-center !mb-32 md:!mb-24 md:px-0'>
      {tabs && (
        <Tabs
          index={index}
          moveToIndex={moveToIndex}
          // className='text-10 font-550 uppercase tracking-slight flex upMd:gap-12 relative'
        >
          {tabs.map((tab, k) => (
            <Tab key={`tab-${k}`} value={tab} onClick={setTab} title={tab.title} />
          ))}
        </Tabs>
      )}
    </Section>
  )
}
