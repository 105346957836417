import cn from 'clsx'
import gsap from 'gsap'
import { useCallback, useEffect, useRef, useState } from 'react'
import useWindowResize from '../../hooks/useWindowResize'
import { useCursor } from '../Cursor'
import Link from '../Link'
import Section from '../Section'
import { useSite } from '../siteState'

const textClassees = 'relative leading-[0.8em] font-serif uppercase text-[calc((100vw-48px)*0.149)] font-100 tracking-[-0.06em]'
const mobileLabelClasses = 'hidden md:block pointer-events-none whitespace-nowrap absolute top-1/2 left-0 text-10 tracking-slight font-550 font-sans -translate-y-1/2 px-[0.5rem] py-[0.25rem] pt-[0.35rem] leadding-none bg-white text-black rounded-full'

function Item ({ title, code, link, setCursorText, isRight = false, inline = false, filterSlug, filterIsRange = false }) {
  const site = useSite()
  const { shopLink } = site

  const query = () => {
    if (!filterSlug) return
    if (filterIsRange) {
      if (typeof title === 'string') return
      return {
        [`${filterSlug}Min`]: parseInt(title),
        [`${filterSlug}Max`]: parseInt(title)
      }
    }
    return {
      [filterSlug]: title
    }
  }

  const linkProps = {
    link: link || shopLink,
    queryParams: query(),
    showText: false
  }

  const cursorProps = {
    onMouseEnter: () => setCursorText(`shop ${title}`),
    onMouseLeave: () => setCursorText('')
  }

  if (inline) {
    return (
      <Link {...linkProps} {...cursorProps}>
        {code}
      </Link>
    )
  }
  return (
    <Link {...linkProps}>
      <div className={textClassees} {...cursorProps}>
        <div className={cn(mobileLabelClasses, isRight && 'left-[unset] right-0')}>shop {title}</div>
        {code}
      </div>
    </Link>
  )
}

export default function ProductWineCode ({ page }) {
  const site = useSite()
  const { country, yearVintage, varietal, winery, range } = page
  const year = yearVintage || site.labels?.nonVintageText
  const yearTitle = yearVintage || site.labels?.nonVintageTextFull

  const [cursorText, setCursorText] = useState('')
  const [isOffScreen, setOffScreen] = useState(false)

  const localsRef = useRef({ x: '-600', y: '-600' })
  const cursorRef = useRef()

  useCursor(
    useCallback((data) => {
      localsRef.current.x = data.last.x
      localsRef.current.y = data.last.y
    }, [])
  )

  useWindowResize(useCallback(() => {
    localsRef.current.windowWidth = window.innerWidth
  }, []), true)

  useEffect(() => {
    let animationId
    const loop = () => {
      if (cursorRef.current) {
        setOffScreen(localsRef.current.x > localsRef.current.windowWidth - localsRef.current.textWidth - 20)
        cursorRef.current.style.top = `${localsRef.current.y}px`
        cursorRef.current.style.left = `${localsRef.current.x}px`
      }
      animationId = window.requestAnimationFrame(loop)
    }
    loop()
    return () => {
      window.cancelAnimationFrame(animationId)
    }
  }, [])

  useEffect(() => {
    gsap.to(cursorRef.current.children[0], {
      x: isOffScreen ? '-100%' : 0,
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [isOffScreen])

  useEffect(() => {
    localsRef.current.textWidth = cursorRef.current.children[0].getBoundingClientRect().width
  }, [cursorText])

  return (
    <Section grid className='mt-[12.5rem] mb-[12.5rem] gap-y-0 z-[1] relative'>
      <div ref={cursorRef} className='fixed uppercase text-14 font-550 tracking-slight z-[13] mix-blend-difference pointer-events-none whitespace-nowrap' style={{ color: 'white' }}>
        <div>
          {cursorText}
        </div>
      </div>

      <div className="col-span-full flex items-center gap-2">
        <div className={cn(textClassees, 'md:hidden')}>
          <Item title={winery?.title} code={winery?.code} setCursorText={setCursorText} inline filterSlug='winery'/>
          .
          <Item title={range?.title} code={range?.code} setCursorText={setCursorText} inline filterSlug='range'/>
        </div>

        <Link className='hidden md:block'>
          <div className={textClassees}>
            <div className={mobileLabelClasses}>shop {winery?.title}</div>
            {winery?.code}.{range?.code}
          </div>
        </Link>
        <div className="flex-1 h-[0.4em] md:h-[0.15em] bg-current w-full"/>
        <Item title={yearTitle} code={year} setCursorText={setCursorText} isRight filterSlug='year' filterIsRange/>
      </div>
      <div className="col-span-full flex items-center gap-2 justify-between">
        <Item title={varietal?.title} code={varietal?.code} setCursorText={setCursorText} filterSlug='varietal'/>
        <Item title={country?.title} code={country?.code} setCursorText={setCursorText} isRight filterSlug='country'/>
      </div>
    </Section>
  )
}
