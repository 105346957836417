import MuxPlayer from '@mux/mux-player-react'

import cn from 'clsx'
import { forwardRef, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import useComposeRefs from '../hooks/useComposeRefs'
import { useVideoPlayerControls } from './Video'

const VideoElement = forwardRef(({ video, controls, loop, autoPlay, title, backgroundColor = 'bg-transparent', className, ...props }, ref) => {
  const videoRef = useRef()
  const { playVideo, pauseVideo } = useVideoPlayerControls(videoRef, autoPlay ? 'play' : 'pause')

  const { ref: inViewRef } = useInView({
    threshold: 0,
    onChange: async (inView) => {
      if (videoRef.current) {
        if (inView) {
          await playVideo()
        } else {
          await pauseVideo()
        }
      }
    }
  })

  const { playbackId } = video?.asset || {}
  const composedRefs = useComposeRefs(videoRef, inViewRef, ref)

  if (!playbackId) return null

  return (
    <MuxPlayer
      className={cn(className, backgroundColor)}
      ref={composedRefs}
      controls={controls}
      autoPlay={autoPlay}
      muted={autoPlay}
      streamType='on-demand'
      playbackId={playbackId}
      metadata={{ video_title: title }}
      playsInline
      loop={loop}
      {...props}
    />
  )
})

export default VideoElement
