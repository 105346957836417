import { useThree } from '@react-three/fiber'
import forEach from 'lodash/forEach'
import React, { forwardRef, useEffect, useRef } from 'react'
import useComposeRefs from '../../../hooks/useComposeRefs'
import { useInvalidateElement } from './LayoutManager'

const isText = (element) => !!element.font

const Block = forwardRef(({ children, uiType = 'block', invalidateOnResize = true, ...props }, ref) => {
  const groupRef = useRef()
  const invalidate = useInvalidateElement()
  const size = useThree(({ size }) => size)

  useEffect(() => {
    if (invalidateOnResize) {
      invalidate(groupRef.current)
    }
  }, [size.width])

  useEffect(() => {
    if (groupRef.current.children) {
      forEach(groupRef.current.children, child => {
        if (isText(child) && !child.skipReflowSync) {
          child.addEventListener('synccomplete', () => {
            invalidate(groupRef.current)
          })
        }
      })
    }
  }, [children])

  const composedRefs = useComposeRefs(groupRef, ref)

  return (
    <group ref={composedRefs} ui={true} uiType={uiType} {...props}>
      {children}
    </group>
  )
})

export default Block
