import { useCallback } from 'react'
import { formatCurrency } from '../../helpers/currencyFormatters'
import { getProductPriceWithDiscount, getProductSubscriptionSellingPlan, getSubscriptionProductPrice, useIsReleased } from '../../helpers/product'
import Button from '../Button'
import { useOpenLogin } from '../Menu/SecondaryNavs/MembersOnly'
import { useIsInSubscriptionJourney } from '../Menu/SecondaryNavs/ProductNav'
import useAddProductToCartCallback from '../Menu/SecondaryNavs/useAddProductCallback'
import useCanAddProductToCart from '../Menu/SecondaryNavs/useCanAddProductToCart'
import { useIsAuthenticated } from '../Shop/accountState'
import { useCartSettings, useLabels } from '../siteState'
import { useAddToSubscriptionCartCallback, useSubscriptionCartFull } from './SubscriptionListing/subscriptionHooks'

export default function ProductAddToCartButton ({ product, className }) {
  const addToCart = useAddProductToCartCallback(product, 1)
  const canAddToCart = useCanAddProductToCart(product, 1)
  const { bundleFullLabel, addToBundleLabel, soldOutLabel, membersOnlyLabel, membersLoginLabel } = useLabels()
  const { addToCartLabel } = useCartSettings()
  const isReleased = useIsReleased(product)
  const authenticated = useIsAuthenticated()
  const showMembersOnly = product?.membersOnly && !authenticated
  const openLoginCallback = useOpenLogin()

  const subscriptionCartFull = useSubscriptionCartFull()
  const sellingPlan = getProductSubscriptionSellingPlan(product)

  const addToSubscriptionCartCallback = useAddToSubscriptionCartCallback()
  const addToSubscriptionPack = useCallback(() => {
    addToSubscriptionCartCallback(product)
  }, [addToSubscriptionCartCallback, product])

  const showAddToPack = useIsInSubscriptionJourney(product)
  const { totalAmount, subtotalAmount } = showAddToPack
    ? getSubscriptionProductPrice(product)
    : getProductPriceWithDiscount(product, sellingPlan)

  return (
    <div className={className}>
      {isReleased && (
        <>
          {showMembersOnly && (
            <Button px='px-6 py-[1.3125rem]' className='z-10 items-center upMd:max-w-[24vw]' onClick={openLoginCallback}>
              <div className='text-12'>{membersLoginLabel}</div>
              <div className='font-serif font-250 text-12 tracking-slight ml-2'>{membersOnlyLabel}</div>
            </Button>
          )}
          {!showMembersOnly && (
            <>
              {!showAddToPack && (
                <Button px='px-6 py-[1.3125rem]' className='z-10 items-center upMd:max-w-[24vw]' disabled={!canAddToCart} onClick={addToCart} invert filled
                  borderColor='border-foreground'
                >
                  <div className='text-12'>{canAddToCart ? addToCartLabel : soldOutLabel}</div>
                  <div className='font-serif font-250 text-12 tracking-slight ml-2'>{formatCurrency(subtotalAmount)}</div>
                </Button>
              )}

              {showAddToPack && (
                <Button px='px-6 py-[1.3125rem]' className='mt-4 z-10 items-center upMd:max-w-[24vw]' disabled={!canAddToCart || subscriptionCartFull} onClick={addToSubscriptionPack}>
                  <div className='text-12'>{canAddToCart ? subscriptionCartFull ? bundleFullLabel : addToBundleLabel : soldOutLabel}</div>
                  <div className='font-serif font-250 text-12 tracking-slight ml-2'>{formatCurrency(totalAmount)}</div>
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
