import { atom, useAtomValue } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'
import { useSiteId } from '../siteState'

export const ACCOUNT_STATES = {
  uninitialized: 'uninitialized',
  loading: 'loading',
  ready: 'ready'
}

export const recoverAtom = atom({
  loading: false,
  error: null
})

export const accountAtom = atom({
  state: ACCOUNT_STATES.uninitialized,
  authenticated: false,
  customer: null
})

const accessTokenAtoms = {}

export function useAccessTokenAtom () {
  const siteId = useSiteId()
  return useMemo(() => {
    const id = `vntnr_accessToken_v1_${siteId}`
    if (!accessTokenAtoms[id]) {
      accessTokenAtoms[id] = atomWithStorage(id, null)
    }
    return accessTokenAtoms[id]
  }, [siteId])
}

export const useIsAuthenticated = () => {
  return useAtomValue(accountAtom)?.authenticated
}

export const useCustomer = () => {
  return useAtomValue(accountAtom)?.customer
}

export const useAccountState = () => {
  return useAtomValue(accountAtom)?.state
}
