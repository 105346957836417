import { useAtomValue } from 'jotai'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import sumBy from 'lodash/sumBy'
import { useMemo } from 'react'
import { cartAtom } from '../../Shop/cartState'

const getCartVariantQuantity = (cart, variantId) => {
  return sumBy(filter(cart.lines, line => line?.merchandise && line.merchandise.id.endsWith(variantId)), line => line.quantity) || 0
}

const hasInventoryFor = (variant, quantity, cart) => {
  const id = variant.shopifyId
  const remainingInventory = variant.inventoryQuantity - getCartVariantQuantity(cart, id)
  return remainingInventory >= quantity
}

const useCanAddProductToCart = (product, quantity = 1) => {
  const cart = useAtomValue(cartAtom)

  return useMemo(() => {
    if (product.productCategory === 'bundle') {
      const bundleProducts = compact(product?.bundle?.products)
      if (product.isBundleOnceOff || product?.bundle?.details?.bundleType === 'preset') {
        const productsWithQuantity = map(groupBy(bundleProducts, p => p.shopifyId), (items) => ({ ...items[0], quantity: items.length }))
        const result = reduce(productsWithQuantity, (result, item) => {
          return result && hasInventoryFor(item.variants[0], item.quantity, cart)
        }, true)
        return result
      }
      return true
    } else {
      if (product.variants) {
        return hasInventoryFor(product.variants[0], quantity, cart)
      }
    }
  }, [cart, quantity, product])
}

export default useCanAddProductToCart
