/* eslint-disable jsx-a11y/alt-text */
import { useCursor } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import Color from 'color'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { linkToUrl, resolveLink } from '../../../../lib/resolveLink'
import transform from '../../../ResponsiveImage/transform'
import { useIsMobile, useViewport } from '../../hooks'
import Draggable from '../Draggable'
import Image from '../Image'
import FeatureTileMeta from './Meta/FeatureTileMeta'

export default function FeatureTile ({ width, tile, preload, enabled, ...props }) {
  const { images } = tile
  const viewport = useViewport()
  const router = useRouter()
  const isMobile = useIsMobile()
  const size = useThree(({ size }) => size)

  const image = useMemo(() => {
    const img = tile.featureLayout?.featuredImage || images.feedImage || images.featureImage
    if (!img) return null
    const size = width.landscape
    const aspect = isMobile ? window.innerWidth / window.innerHeight : size.aspect
    const source = transform(img, aspect, [256, 512, 1024, 2048])
    return { source, size: { width: size.width, aspect } }
  }, [tile, images, width, isMobile, size])

  const backgroundColor = useMemo(() => {
    const img = tile.featureLayout?.featuredImage || images.feedImage || images.featureImage
    const bg = img?.asset?.metadata?.palette?.dominant?.background
    if (bg) {
      return [...Color(bg).unitArray(), 1]
    }
  }, [tile, images])

  const [hovered, setHover] = useState()
  useCursor(hovered, 'pointer', 'auto')

  const onClick = useCallback(() => {
    if (enabled) {
      router.push(linkToUrl(resolveLink(tile)))
    }
  }, [router, tile, enabled])

  if (!image) return null

  const imageWidth = viewport.width * image?.size?.width
  const imageHeight = imageWidth / image?.size?.aspect

  return (
    <Draggable onClick={onClick}>
      {(dragging) => (
        <group>
          <Image
            image={image.source}
            scale={[imageWidth, imageHeight, 1]}
            onPointerOver={() => setHover(true)}
            onPointerOut={() => setHover(false)}
            disableHover={dragging}
            backgroundColor={backgroundColor}
            preload={preload}
            segments={16}
            name='tile-image'
            {...props}
          />
          <FeatureTileMeta
            tile={tile}
            width={imageWidth}
            height={imageHeight}
            metadataWidth={1}
          />
        </group>
      )}
    </Draggable>
  )
}
