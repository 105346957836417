import dayjs from 'dayjs'

// Need to use customParseFormat plugin to do a strict check; reference here: https://day.js.org/docs/en/parse/is-valid
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const format = 'YYYY-MM-DD'

export function isDateValid (date) {
  return dayjs(date, format, true).isValid()
}

export const isUnder18 = (date) => {
  const birthDate = dayjs(date)
  const cutOffDate = dayjs().add(-18, 'years')
  return cutOffDate.isBefore(birthDate)
}
