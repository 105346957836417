import cn from 'clsx'
import gsap from 'gsap'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import Dropdown from '../../Dropdown'
import { layouts } from '../../Menu/SecondaryNavs/ShopFilter/filterState'
import Section from '../../Section'
import Tabs from '../../Tabs'
import Tab from '../../Tabs/Tab'
import { filterByCategory } from './useFilterProducts'
import { dropdownOptions } from './useSortProducts'

export default function ProductListingHeader ({
  showCategories,
  showSortAndLayout = true,
  sortBy,
  setSortBy,
  categories,
  category,
  setCategory,
  layout,
  setLayout,
  tab,
  subscriptionsData = {},
  onSubscriptionsClick,
  products
}) {
  const { showSubscriptions } = subscriptionsData || {}
  const subscriptionsTab = tab === 'subscriptions' || tab === 'subscriptions-dynamic'

  const ref = useRef()
  const localsRef = useRef({ isFirstRun: true })

  useEffect(() => {
    const { isFirstRun } = localsRef.current

    if (localsRef.current.tl) {
      localsRef.current.tl.kill()
    }

    const tl = gsap.to([ref.current.children[0], ref.current.children[3]], {
      autoAlpha: showSortAndLayout ? 1 : 0,
      ease: 'expo.out',
      duration: isFirstRun ? 0 : 0.6,
      onComplete: () => { localsRef.current.isFirstRun = false }
    })

    localsRef.current.tl = tl
  }, [showSortAndLayout])

  const tabs = useMemo(() => {
    const nonEmptyCategories = categories.filter(cat => !isEmpty(filterByCategory(products, cat.value)))
    const t = nonEmptyCategories?.map((category) => {
      const value = category.value || category.slug?.current
      return { title: category.title, value, onClick: setCategory }
    }) || []
    if (showSubscriptions) {
      t.push({ title: subscriptionsData.tabTitle, value: 'subscription', onClick: onSubscriptionsClick })
    }
    return t
  }, [categories, products, onSubscriptionsClick, setCategory, showSubscriptions, subscriptionsData])

  const moveToIndex = useCallback((i) => {
    if (i >= tabs.length) return
    const value = tabs[i]?.value
    const callback = value === 'subscription' ? onSubscriptionsClick : setCategory
    callback(value)
  }, [tabs, setCategory])

  const index = useMemo(
    () => subscriptionsTab ? tabs.length - 1 : findIndex(tabs, tabs.find(x => x.value === category || x.slug?.current === category)),
    [tabs, category, subscriptionsTab]
  )

  return (
    <Section noGutter className='z-[2] relative grid grid-cols-menu justify-between md:grid md:grid-cols-8 md:gap-4' ref={ref}>
      <div className={cn('flex gap-4 md:text-18 text-10 font-550 uppercase tracking-slight md:col-span-5 items-center pl-4 md:pl-4')} style={{ visibility: 'invisible' }}>
        {!subscriptionsTab && (
          <>
            <div className='shrink-0 font-normal'>Sort By</div>
            <Dropdown
              options={dropdownOptions}
              value={sortBy}
              onChange={setSortBy}
              padding='pl-2 pt-2'
              width='md:w-full'
            />
          </>
        )}
      </div>

      {showCategories && (
        <Tabs
          index={index}
          moveToIndex={moveToIndex}
          className='md:row-start-1 md:col-span-full'
        >
          {tabs?.map((tab, i) => (
            <Tab key={i} {...tab} className='md:text-18' />
          ))}
        </Tabs>
      )}

      {!subscriptionsTab && !!setLayout && (
        <div className={cn('flex gap-4 text-right justify-end md:text-18 text-10 font-550 uppercase tracking-slight md:col-span-3 pr-4 md:pr-4')} style={{ visibility: 'invisible' }}>
          {layouts?.map((x) => (
            <button key={x.value} onClick={() => setLayout(x.value)} className={cn('uppercase tracking-slight transition-opacity duration-600 ease-out-expo', layout !== x.value && 'opacity-50')}>{x.title}</button>
          ))}
        </div>
      )}
    </Section>
  )
}
