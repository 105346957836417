import cn from 'clsx'
import gsap from 'gsap'
import { useAtomValue } from 'jotai'
import { useRouter } from 'next/router'
import { useCallback, useContext, useRef } from 'react'
import useLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import Button from '../../Button'
import { introCompletedAtom } from '../../Intro/introState'
import Link from '../../Link'
import useAddProductToCartCallback from '../../Menu/SecondaryNavs/useAddProductCallback'
import useCanAddProductToCart from '../../Menu/SecondaryNavs/useCanAddProductToCart'
import useProductPriceFormatted from '../../Menu/SecondaryNavs/useProductPriceFormatted'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'
import { SmoothScrollerContext } from '../../SmoothScroller'
import { useLabels } from '../../siteState'

export function Tile ({ product, onClick, hasImage = false, animateIn, i }) {
  const tileRef = useRef()
  const canAddToCart = useCanAddProductToCart(product)
  const addProductToCart = useAddProductToCartCallback(product, 1)
  const handleOnClick = useCallback((e) => {
    if (onClick) onClick(e)
    else addProductToCart()
  }, [addProductToCart, onClick])
  const { soldOutLabel } = useLabels()

  const data = product.bundle?.details
  const priceFormatted = useProductPriceFormatted(product)

  const introCompleted = useAtomValue(introCompletedAtom)

  useLayoutEffect(() => {
    if (!animateIn) return
    if (!introCompleted) {
      gsap.set(tileRef.current, { opacity: 0 })
      return
    }

    const tl = gsap.fromTo(tileRef.current, {
      y: 40,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      delay: 0.1 * (i % 3),
      ease: 'expo.out',
      duration: 1.2,
      scrollTrigger: {
        trigger: tileRef.current,
        start: 'top bottom',
        scrub: false
      }
    })

    return () => tl.revert()
  }, [animateIn, introCompleted])

  return (
    <div ref={tileRef} className={cn('col-span-4 md:col-span-full relative group', !hasImage && 'border aspect-[458/595]')} style={{ opacity: 0 }}>
      <div className={cn('flex flex-col h-full p-4 absolute top-0 left-0 z-[10] w-full text-black ', hasImage && '!text-white')}>
        <Link link={product} nonLinkTag='div' className='flex flex-col h-full p-4 -m-4' showText={false} onClick={onClick}>
          <div className='flex-1 flex flex-col justify-center items-center gap-4 text-center'>
            <div className='uppercase font-550 tracking-slight text-32 leading-none'>{data?.code}</div>
            <div className='font-serif uppercase font-250 tracking-[-0.02em] text-center leading-none flex items-end whitespace-pre-wrap'>
              {data?.subheading}
            </div>
          </div>
          <div className='flex flex-col gap-3'>
            <div className='flex justify-between uppercase tracking-slight font-550 text-12'>
              <div>{data?.productCountLabel}</div>
              <div>{data?.priceLabel || priceFormatted} / {data?.subscriptionIntervalLabel}</div>
            </div>
            <div className='flex justify-between uppercase tracking-slight leading-none font-550 text-16'>
              {product.title}
            </div>
          </div>
        </Link>
        <Button
          justify='justify-center'
          py='py-2'
          px='px-2'
          invert={hasImage}
          className={cn('mt-3')}
          disabled={!canAddToCart}
          onClick={handleOnClick}
        >
          {canAddToCart ? data?.buttonLabel : soldOutLabel}
        </Button>
      </div>
      {hasImage && product?.images.featureImage && (
        <>
          <div className='absolute inset-0 bg-[rgba(51, 51, 51, 0.1)] group-hover:!bg-[rgba(0,0,0,0.7)] z-1 transition-colors duration-600 ease-out-expo'/>
          <ResponsiveImage className='h-full z-0 relative align-bottom overflow-hidden [&_img]:blur-2xl' image={product?.images?.featureImage} aspect={458 / 595} />
        </>
      )}
    </div>
  )
}

export default function SubscriptionPacks ({ data, page }) {
  const router = useRouter()
  const { products } = data
  const subscriptionPage = page?.slices?.filter(x => x._type === 'subscriptionListing')[0]
  const scrollContext = useContext(SmoothScrollerContext)

  const goToDiyPage = (e) => {
    const baseSlug = page.slug
    const finalUrl = `/${baseSlug}/${subscriptionPage?.slug?.current}`
    router.push(finalUrl, finalUrl, { shallow: true })
    if (scrollContext.current?.lenis) {
      scrollContext.current?.lenis.scrollTo(0)
    }
    e.preventDefault()
  }

  return (
    <Section grid className=''>
      {products?.map((product, i) => {
        const isDynamicBundle = product.bundle?.details?.bundleType === 'dynamic'
        return (
          <Tile
            i={i}
            animateIn
            key={product._id}
            product={product}
            hasImage={!isDynamicBundle}
            onClick={isDynamicBundle ? goToDiyPage : null}
          />
        )
      })}
    </Section>
  )
}
