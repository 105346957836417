import gsap from 'gsap'
import CustomEase from 'gsap/dist/CustomEase'
import { useAtomValue, useSetAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import { forEach } from 'lodash'
import flatten from 'lodash/flatten'
import omit from 'lodash/omit'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { parse, stringify } from 'query-string'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three'
import Random from '../../helpers/Random'
import { isHomepage } from '../../helpers/pageContent'
import { canvasStateAtom } from '../Canvas/canvasState'
import { siteAtom } from '../siteState'
import AgeGate from './AgeGate'
import IntroText from './IntroText'
import IntroTitle from './IntroTitle'
import { ageGatePassedAtom, introCompletedAtom } from './introState'

gsap.registerPlugin(CustomEase)
const feedEase = CustomEase.create('feedEase', 'M0,0 C0.29,0 0.294,0.018 0.365,0.103 0.434,0.186 0.466,0.362 0.498,0.502 0.518,0.592 0.513,0.782 0.576,0.876 0.651,0.987 0.704,1 1,1 ')

const random = new Random(Math.random())

const animateFeedSceneIn = (scene, layout) => {
  const tl = gsap.timeline()
  tl.set({}, {
    onComplete: () => {
      layout?.pauseReflow()
    }
  })
  tl.set(document.getElementById('canvas-feed-html'), { opacity: 0, y: -20 })
  const tileImages = []
  let metadata = []
  scene.traverse(child => {
    if (child.name === 'tile-image') {
      tileImages.push(child)
      if (!child.originalPosition) child.originalPosition = child.position.clone()
      if (!child.originalScale) child.originalScale = child.scale.clone()
      child.position.copy(child.originalPosition)
      child.scale.copy(child.originalScale)
    }
    if (child.name === 'meta') {
      metadata.push(child)
    }
  })
  const tilesSortedByDistance = sortBy(tileImages, (tile) => {
    // Order this by the closest to the center of the page
    const worldPos = new Vector3()
    tile.getWorldPosition(worldPos)
    return Math.sqrt((worldPos.x * worldPos.x) + (worldPos.y * worldPos.y))
  })
  const TILE_COUNT = 8
  const tilesToAnimate = uniq([
    ...tilesSortedByDistance.slice(0, TILE_COUNT / 2),
    ...tilesSortedByDistance.slice(tileImages.length - TILE_COUNT / 2)
  ])

  metadata = flatten(metadata.map(obj => {
    const meshes = []
    obj?.traverse(child => {
      if (child.isMesh && child.name !== 'text-placeholder-mesh') {
        meshes.push(child)
      }
    })
    return meshes
  }))

  const SCATTER_MAX_DISTANCE = 3.2
  // We want to position the tiles randomly but we still want a more control of the positioning, so this will position them evenly around the center
  const randomPositioning = [
    { x: [-SCATTER_MAX_DISTANCE, 0], y: [-SCATTER_MAX_DISTANCE, 0] },
    { x: [-SCATTER_MAX_DISTANCE, 0], y: [0, SCATTER_MAX_DISTANCE] },
    { x: [0, SCATTER_MAX_DISTANCE], y: [0, SCATTER_MAX_DISTANCE] },
    { x: [0, SCATTER_MAX_DISTANCE], y: [-SCATTER_MAX_DISTANCE, 0] }
  ]

  // Animate the scale from the bottom one to the top one
  forEach(tilesToAnimate, (tile, i) => {
    const worldPos = new Vector3()
    tile.getWorldPosition(worldPos)

    const target = {
      x: -worldPos.x + random.generate(...randomPositioning[i % 4].x),
      y: -worldPos.y + random.generate(...randomPositioning[i % 4].y),
      z: -worldPos.z + (-20 + (i * 0.1)),
      scaleX: tile.originalScale.x,
      scaleY: tile.originalScale.y
    }

    tile.target = target

    tile.scale.x = 0
    tile.scale.y = 0

    // We move the elements a little
    tile.position.x = target.x + random.generate(-2, 2)
    tile.position.y = target.y + random.generate(-2, 2)

    tile.position.z = target.z

    const current = {
      scaleX: 0,
      scaleY: 0,
      x: tile.position.x,
      y: tile.position.y
    }

    tl.to(current, {
      scaleX: target.scaleX,
      scaleY: target.scaleY,
      x: target.x,
      y: target.y,
      duration: 0.8,
      ease: 'expo.out',
      delay: i * 0.04,
      onUpdate: () => {
        tile.scale.x = current.scaleX
        tile.scale.y = current.scaleY
        tile.position.x = current.x
        tile.position.y = current.y
      }
    }, 0)
  })

  // We want the last tile to animate out first as it is on top of the stack
  reverse(tilesToAnimate)

  forEach(tilesToAnimate, (tile, i) => {
    const targetPosition = tile.originalPosition.clone()

    const target = tile.target
    // const obj = tile.position.clone()
    tl.to(target, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z,
      duration: 1.2 + (0.2 * (i / 16)), // Elements that are further away will have a bigger duration
      ease: feedEase,
      delay: i * 0.005,
      onUpdate: () => {
        tile.position.x = target.x
        tile.position.y = target.y
        tile.position.z = target.z
      }
    }, (0.5 + (0.04 * tilesToAnimate.length))) // This is at the end of the scale animations but ads a little delay to it as well
  })

  forEach(metadata, (mesh, i) => {
    mesh.material.opacity = 0
    tl.fromTo(mesh.material, { opacity: 0 }, {
      opacity: 1,
      duration: 1.2,
      ease: 'power4.inOut'
    }, i === 0 ? '-=0.6' : '-=1.2')
  })

  tl.set({}, {
    onComplete: () => {
      layout?.resumeReflow()
      layout?.reflow(true)
    }
  }, '-=1')

  tl.to(document.getElementById('canvas-feed-html'), { opacity: 1, y: 0, duration: 1.5, ease: 'power4.out' }, '-=1')

  return tl
}

export default function Intro ({ page }) {
  const ref = useRef()
  const settings = useAtomValue(siteAtom)
  const introTextRef = useRef()
  const ageGateRef = useRef()
  const introTitleRef = useRef()
  const [inAnimationDone, setInAnimateDone] = useState()
  const localsRef = useRef({ isHomepage: isHomepage(page), animatedFeed: false })
  const { scene, sceneReady, layoutRef } = useAtomValue(canvasStateAtom)
  const backgroundRef = useRef()

  const [isAndroid, setIsAndroid] = useState(false)
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase()
    setIsAndroid(ua.indexOf('android') > -1)
  }, [])

  const setAgeGatePassed = useSetAtom(ageGatePassedAtom)
  const readAgeGatePassed = useAtomCallback(useCallback((get) => get(ageGatePassedAtom), []))
  const setIntroComplete = useSetAtom(introCompletedAtom)

  const animateOut = (tl) => {
    const menu = document.getElementById('menu')
    if (localsRef.current.isHomepage) {
      tl.set(menu, { opacity: 0 }, 0)
      tl.set(backgroundRef.current, { opacity: 0 })
      tl.add(animateFeedSceneIn(scene, layoutRef?.current))
      tl.add(introTitleRef.current.animateOutTimeline(), '-=2.6')
      tl.to(menu, { opacity: 1, duration: 0.6, ease: 'sine.out' }, '-=1.3')
      tl.set(ref.current, { pointerEvents: 'none' }, '-=2')
      tl.set(ref.current, { opacity: 0 })
    } else {
      tl.to(ref.current, { opacity: 0, pointerEvents: 'none', duration: 1.2, ease: 'sine.inOut' }, '-=1.4')
    }

    localsRef.current.animatedFeed = true
  }

  const startIntroAnimation = async () => {
    if (localsRef.current.tl) localsRef.current.tl.kill()
    const tl = gsap.timeline()
    const passed = await readAgeGatePassed()
    tl.set(ref.current, { opacity: 1, pointerEvents: 'all' })
    tl.set(backgroundRef.current, { opacity: 1 })
    if (introTextRef.current && !passed) {
      tl.add(introTextRef.current.animateInTimeline())
    }
    tl.add(introTextRef.current.animateOutTimeline(passed), passed ? 0 : '+=0.2')

    if (!localsRef.current.isHomepage) {
      if (passed) {
        animateOut(tl)
      } else {
        if (introTextRef.current) {
          tl.add(ageGateRef.current.animateInTimeline(introTextRef.current.getLogoElement()), '-=1.2')
        }
      }
    } else {
      if (passed) {
        tl.add(introTitleRef.current.animateInTimeline(), '-=1')
        if (inAnimationDone && scene) {
          animateOut(tl)
        } else {
          tl.set({ }, { onComplete: () => { setInAnimateDone(true) } })
        }
      } else {
        if (introTextRef.current) {
          tl.add(ageGateRef.current.animateInTimeline(introTextRef.current.getLogoElement()), '-=1.2')
        }
      }
    }
    tl.add(() => setIntroComplete(true), '-=1.2')

    localsRef.current.tl = tl
  }

  useEffect(() => {
    if (localsRef.current.isHomepage) {
      // The sceneReady is set in the FeedLayout component once the feed tiles have be positioned and are ready to be animated
      if (inAnimationDone && !localsRef.current.animatedFeed && sceneReady) {
        if (localsRef.current.tl) localsRef.current.tl.kill()
        const tl = gsap.timeline()
        animateOut(tl)
        localsRef.current.tl = tl
      }
    }
  }, [sceneReady, scene, inAnimationDone])

  useEffect(() => {
    const run = async () => {
      const hash = parse(window.location.hash)
      const skipAgeGate = hash?.agegatepassed !== undefined
      if (skipAgeGate) {
        setIntroComplete(true)
        setAgeGatePassed(true)
      }
      const passed = await readAgeGatePassed()
      const skip = hash?.skip !== undefined
      if (skip || skipAgeGate) {
        // const url = new URL(window.location.href)
        window.location.hash = stringify(omit(hash, ['skip', 'agegatepassed']))
      }
      if (skip && passed) {
        setIntroComplete(true)
        gsap.to(ref.current, { opacity: 0, pointerEvents: 'none', duration: 0.25, ease: 'sine.inOut' })
      } else {
        startIntroAnimation()
      }
    }
    run()
  }, [])

  const onAgeGatePassed = () => {
    setAgeGatePassed(true)
    const tl = gsap.timeline()
    tl.add(ageGateRef.current.animateOutTimeline())
    if (localsRef.current.isHomepage) {
      tl.add(introTitleRef.current.animateInTimeline())
    }
    animateOut(tl)
  }

  return (
    <>
    {/* <button className='absolute top-0 bg-error z-[1000000]' onClick={startIntroAnimation}>Re-run animation</button>
    <button className='absolute top-[40px] bg-error z-[1000000]' onClick={() => { animateFeedSceneIn(scene, layoutRef.current) }}>Re-run Feed Animation</button>
    <button className='absolute top-[80px] bg-error z-[1000000]' onClick={() => { layoutRef.current.reflow() }}>Reflow</button> */}
    <div className='fixed inset-0 z-cursor flex justify-center md:items-start p-4 text-white' ref={ref}>
      <div className='bg-black absolute inset-0' ref={backgroundRef} />
      <IntroText ref={introTextRef} definitionLabels={settings.definitionLabels}/>
      <AgeGate onAgeGatePassed={onAgeGatePassed} ref={ageGateRef} isAndroid={isAndroid}/>
      <IntroTitle ref={introTitleRef} />
    </div>
    </>
  )
}
