import { forwardRef, useCallback, useEffect, useRef } from 'react'
import forEach from 'lodash/forEach'

import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'

import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import useComposeRefs from '../hooks/useComposeRefs'
import { useInView } from 'react-intersection-observer'
import cn from 'clsx'

gsap.registerPlugin(SplitText)

const ROTATE = 0

function useSplitText(animateWhenInView) {
  const splitTextRef = useRef({})
  const locals = splitTextRef.current

  const constructSplitText = useCallback(
    (el) => {
      locals.split = new SplitText(el, { type: 'lines, words', linesClass: 'split-line' })
      gsap.set(locals.split.words, { yPercent: 150, rotate: ROTATE, opacity: 1 })
      locals.blurElements = locals.split.words.map((element) => ({
        yPercent: 150,
        rotate: ROTATE,
        element,
        opacity: 1,
      }))
      el.classList.remove('pre-splittext')
      if (!animateWhenInView) gsap.set(el, { opacity: 1 })
      if (locals.animatedIn) {
        gsap.set(locals.split.words, { yPercent: 0, rotate: 0, opacity: 1 })
      } else {
        gsap.set(locals.split.words, { yPercent: 150, rotate: ROTATE, opacity: 1 })
      }

      locals.el = el
    },
    [locals]
  )

  const destructSplitText = useCallback(() => {
    if (locals.split) {
      locals.split.revert()
      delete locals.targets
      delete locals.split
    }
  }, [locals])

  return [splitTextRef, constructSplitText, destructSplitText]
}

const createTimeline = (splitText, duration = 1, stagger = 0.7, delay = 0) => {
  const timeline = gsap.timeline()
  forEach(splitText.blurElements, (blurElement, i) => {
    timeline.to(
      blurElement.element,
      {
        yPercent: 0,
        rotate: 0,
        duration: duration,
        ease: 'expo.out',
      },
      i === 0 ? delay : `-=${stagger}`
    )
  })

  timeline.eventCallback('onComplete', () => {
    splitText.animatedIn = true
  })

  return timeline
}

const RotateUpText = forwardRef(
  (
    {
      children,
      onTimelineCreated,
      tag = 'div',
      duration = 1,
      stagger = 0.7,
      delay = 0,
      text,
      animateWhenInView = true,
      className,
      revertOnComplete = false,
    },
    ref
  ) => {
    const containerRef = useRef()
    const timelineRef = useRef()
    const [inViewRef, inView] = useInView({
      threshold: 0,
      triggerOnce: true,
    })

    const [splitTextRef, constructSplitText, destructSplitText] = useSplitText(animateWhenInView)

    const initSplitText = useCallback(
      (rerunTimeline) => {
        destructSplitText()
        constructSplitText(containerRef.current)
        if (timelineRef.current) timelineRef.current.kill()
        timelineRef.current = createTimeline(splitTextRef.current, duration, stagger, delay)
        if (!rerunTimeline && splitTextRef.current.animatedIn) {
          if (revertOnComplete) {
            destructSplitText()
            return
          }
          // We pause it here so the animation does not run again when the timeline is recreated because of a window resize
          timelineRef.current.pause()
        }
        if (onTimelineCreated) onTimelineCreated(timelineRef.current)
      },
      [destructSplitText, constructSplitText, onTimelineCreated]
    )

    useEffect(() => {
      initSplitText(false)
    }, [constructSplitText])

    useWindowResize(
      useDebouncedCallback(
        () => {
          if (splitTextRef.current.el) {
            initSplitText(false)
          }
        },
        150,
        [initSplitText]
      ),
      false,
      true
    )

    useEffect(() => {
      if (animateWhenInView && inView) {
        gsap.set(containerRef.current, { opacity: 1 })
        timelineRef.current.restart()
      }
    }, [animateWhenInView, inView, splitTextRef, constructSplitText])

    useEffect(() => {
      return () => {
        destructSplitText()
      }
    }, [destructSplitText])

    return (
      <div
        className={cn(className, 'pre-splittext opacity-0 [&_.split-line]:overflow-hidden [&_.split-line]:pt-[0.25em] [&_.split-line]:mt-[-0.25em]')}
        ref={useComposeRefs(ref, inViewRef, containerRef)}
      >
        {text}
        {children}
      </div>
    )
  }
)

RotateUpText.displayName = 'RotateUpText'

export default RotateUpText
