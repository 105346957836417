import cn from 'clsx'
import { DroppingCountdown } from '../../Slices/ProductListing/ProductTile'
import { useLabels } from '../../siteState'

export default function DroppingNav ({ page }) {
  const { dropsInLabel = 'Drops In' } = useLabels()
  return (
    <>
      <div className='flex justify-between col-span-full items-center h-[1.875rem] md:h-[2.625rem]'>
        <div className={cn('uppercase font-serif font-250 text-12 md:text-16 tracking-slight flex gap-2')}>
          <span>{dropsInLabel}</span>
        </div>

        <div className={cn('uppercase font-serif font-250 text-12 md:text-16 tracking-slight')}>
          <DroppingCountdown releasedDate={page.releasedDate} className='inline-block' />
        </div>

        {/* <button
          onClick={openSignUp}
          data-event='site interaction'
          data-type='button'
          data-description='sign up'
          data-location='body'
        >
          <div className={cn('uppercase font-550 text-12 md:text-16 tracking-slight text-right')}>
            Sign Up
          </div>
        </button> */}
      </div>
    </>
  )
}
