import cn from 'clsx'
import { forwardRef, useCallback, useRef } from 'react'
import useComposeRefs from '../../hooks/useComposeRefs'

const Input = forwardRef(({ uppercase = true, className, containerClassName, name, error, required, label, ...props }, ref) => {
  const inputRef = useRef()
  const composedRef = useComposeRefs(inputRef, ref)
  const onLabelClick = useCallback(() => { inputRef.current.focus() }, [])
  return (
    <div className={cn('flex items-center relative border-b', error && 'border-error', containerClassName)}>
      {label && <label className='opacity-50 text-12 uppercase block mr-2 w-48 py-2' htmlFor={name} onClick={onLabelClick}>{label}</label>}
      <input ref={composedRef} className={cn(className, 'w-full outline-0 py-1 text-12 font-550 placeholder-current bg-transparent text-current', { uppercase })} required={required} id={name} name={name} {...props} />
      {required && <span className='absolute top-2 right-0 text-12 text-error'>*</span>}
    </div>
  )
})

export default Input
