import { atom, useAtomValue } from 'jotai'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'

export const siteAtom = atom(null)

export const useSite = () => {
  return useAtomValue(siteAtom)
}

export const useSiteId = () => {
  const { _id } = useAtomValue(siteAtom)
  return _id
}

export const useGiftWrappingProducts = () => {
  const { giftWrappingProducts } = useAtomValue(siteAtom)
  return giftWrappingProducts
}

export const useLabels = () => {
  const { labels } = useAtomValue(siteAtom)
  return labels
}

export const useCartSettings = () => {
  const { cart } = useAtomValue(siteAtom)
  return cart
}

export const useCustomSubscriptionBundle = () => {
  return useSite()?.customSubscriptionBundle
}

export const useIsProductInCustomSubscriptionBundle = (product) => {
  const customBundle = useCustomSubscriptionBundle()
  return customBundle?.bundle?.products?.find(x => x?._id === product._id)
}

export const useHasProductsInCustomBundle = () => {
  const customBundle = useCustomSubscriptionBundle()
  return !isEmpty(compact(customBundle?.bundle?.products))
}
