import { primaryInput } from 'detect-it'
import { useMemo, useState } from 'react'

const useIsHovering = () => {
  const [hovering, setHovering] = useState(false)

  const eventListeners = useMemo(() => ({
    onMouseEnter: primaryInput === 'touch' ? null : () => setHovering(true),
    onMouseLeave: primaryInput === 'touch' ? null : () => setHovering(false)
  }), [setHovering])

  return {
    eventListeners,
    hovering
  }
}

export default useIsHovering
