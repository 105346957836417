import Section from '../../Section'
import SplitTextAnimation from '../../SplitTextAnimation'

export default function ArticleQuote ({ data }) {
  const { quote, attribution } = data

  return (
    <Section noGutter className='bg-offwhite p-4'>
      <figure className='flex flex-col gap-[22rem] md:gap-[12.5rem]'>
        <blockquote className='uppercase font-serif font-250 tracking-[-0.02em] text-[5.5rem] md:text-[1.5rem] text-justify leading-[0.89em]'>
          <SplitTextAnimation
            animateWhenInView={true}
            duration={0.6}
            stagger={0.58}
            delay={0.25}
            tag="div"
          >
            {quote}
          </SplitTextAnimation>
        </blockquote>
        {attribution && <figcaption className='leading-none'>&mdash; {attribution}</figcaption>}
      </figure>
    </Section>
  )
}
