import cn from 'clsx'
import { useCallback, useRef } from 'react'
import { BooleanParam, withDefault } from 'serialize-query-params'
import { LINK_HOVER_CLASSES } from '..'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { getProductPriceWithDiscount, getProductSubscriptionSellingPlan, getSubscriptionProductPrice } from '../../../helpers/product'
import useQueryParamsWithRoute from '../../../hooks/useQueryParamsWithRoute'
import { useAddToSubscriptionCartCallback, useSubscriptionCartFull } from '../../Slices/SubscriptionListing/subscriptionHooks'
import { useIsSubscriptionCartShown } from '../../Slices/SubscriptionListing/subscriptionState'
import { useCartSettings, useIsProductInCustomSubscriptionBundle, useLabels } from '../../siteState'
import useAddProductToCartCallback from './useAddProductCallback'
import useCanAddProductToCart from './useCanAddProductToCart'

export const useIsInSubscriptionJourney = (page) => {
  const sellingPlan = getProductSubscriptionSellingPlan(page)
  const isProductInCustomBundle = useIsProductInCustomSubscriptionBundle(page)
  const [query] = useQueryParamsWithRoute({
    subscription: withDefault(BooleanParam, false)
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true, skipUpdateWhenNoChange: true })

  const subscriptionQueryString = query.subscription
  const showCart = useIsSubscriptionCartShown()
  return sellingPlan && isProductInCustomBundle && (showCart || subscriptionQueryString)
}

export default function ProductNav ({ page }) {
  const topRef = useRef()
  const { bundleFullLabel, addToBundleLabel, soldOutLabel } = useLabels()
  const { addToCartLabel } = useCartSettings()

  const sellingPlan = getProductSubscriptionSellingPlan(page)

  const addToCart = useAddProductToCartCallback(page, 1)
  const canAddToCart = useCanAddProductToCart(page, 1)
  const isPackFull = useSubscriptionCartFull()

  const addToSubscriptionCartCallback = useAddToSubscriptionCartCallback()
  const addToSubscriptionCart = useCallback(() => {
    addToSubscriptionCartCallback(page)
  }, [page, addToSubscriptionCartCallback])

  const showAddToPack = useIsInSubscriptionJourney(page)
  const { totalAmount } = showAddToPack
    ? getSubscriptionProductPrice(page)
    : getProductPriceWithDiscount(page, sellingPlan)

  return (
    <>
      <div className='col-span-full items-center h-[1.875rem] md:h-[42px] flex justify-center' ref={topRef}>
        {showAddToPack && (
          <button onClick={addToSubscriptionCart}
            className={cn(LINK_HOVER_CLASSES, 'overflow-hidden w-full h-full flex justify-center items-center')}
            data-event='site interaction'
            data-type='button'
            data-description={canAddToCart ? addToBundleLabel : soldOutLabel}
            data-location='header'
            disabled={!canAddToCart || isPackFull}
          >
            <div className='uppercase font-550 text-12 md:text-[16px] tracking-slight text-right'>
              {canAddToCart ? isPackFull ? bundleFullLabel : addToBundleLabel : soldOutLabel}
              <span className='font-serif font-250 inline-block ml-2'>
                {formatCurrency(totalAmount)}
              </span>
            </div>
          </button>
        )}
        {!showAddToPack && (
          <button onClick={addToCart}
            disabled={!canAddToCart}
            className={cn(LINK_HOVER_CLASSES, 'overflow-hidden w-full h-full flex justify-center items-center')}
            data-event='site interaction'
            data-type='button'
            data-description={canAddToCart ? addToCartLabel : soldOutLabel}
            data-location='header'
          >
            <div className='uppercase font-550 text-12 md:text-[16px] tracking-slight text-right'>
              {canAddToCart ? addToCartLabel : soldOutLabel}
              <span className='font-serif font-250 inline-block ml-2'>
                {formatCurrency(totalAmount)}
              </span>
            </div>
          </button>
        )}
      </div>
    </>
  )
}
