import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'

const storage = createJSONStorage(() => localStorage)
storage.delayInit = true
export const subscriptionCartAtom = atomWithStorage('subscriptionCart', [], storage)
export const subscriptionCartOpenAtom = atom(false)
export const subscriptionCartToolbarOpenAtom = atom(false)

export const useSubscriptionCartToolbar = (open = true) => {
  const setToolbarOpen = useSetAtom(subscriptionCartToolbarOpenAtom)

  useEffect(() => {
    if (open) {
      setToolbarOpen(true)
      return () => {
        setToolbarOpen(false)
      }
    }
  }, [setToolbarOpen, open])
}

export const useIsSubscriptionCartShown = () => {
  const cart = useAtomValue(subscriptionCartAtom)
  return !isEmpty(cart)
}
