import BlockContent from '@sanity/block-content-to-react'
import cn from 'clsx'
import gsap from 'gsap'
import React, { useRef } from 'react'
import breakpoints from '../../../../theme/breakpoints'
import useLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent, { serializers } from '../../RichContent'
import Section, { gridClasses } from '../../Section'
import { ArticleProductMeta, ProductMeta } from '../ProductListing/ProductTile'

const styles = {
  heading: 'uppercase font-550 text-[2.5rem] leading-[0.8em] mb-[4.5rem] md:text-24 md:mb-[2rem]',
  normal: 'mb-4',
  indent: 'indent-[10vw]'
}

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node
  const defaultClasses = 'col-start-1 col-span-6 md:col-span-full max-w-xl'

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return React.createElement(style, { className: cn(styles.heading, `heading-${level}`, defaultClasses) }, props.children)
  }
  if (style === 'indentedText') {
    return React.createElement('p', { className: cn(styles.indent, styles.normal, defaultClasses) }, props.children)
  }
  if (style === 'normal') {
    return React.createElement('p', { className: cn(styles.normal, defaultClasses) }, props.children)
  }

  return BlockContent.defaultSerializers.types.block(props)
}

const InlineProduct = ({ node, animateIn = true }) => {
  const ref = useRef()
  const { product } = node
  const featuredBgImage = product.images?.featuredBgImage
  const bottleImage = product?.images?.featureImage

  useLayoutEffect(() => {
    if (!animateIn) return

    const tl = gsap.fromTo(ref.current.children, {
      y: 40,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      delay: 0.2,
      ease: 'expo.out',
      duration: 1.2,
      stagger: 0.03,
      scrollTrigger: {
        trigger: ref.current,
        start: 'top bottom',
        scrub: false
      }
    })

    return () => tl.revert()
  }, [animateIn])

  return (
    <Link ref={ref} link={product} className={cn('mr-4 group upMd:absolute upMd:right-0 upMd:w-[45vw] upMd:grid upMd:grid-cols-2 upMd:gap-4')} showText={false}>
      <div className='aspect-[340/453] h-auto flex flex-col w-full relative overflow-hidden py-[4vw] md:py-[10vw]'>
        {featuredBgImage && (
          <ResponsiveImage
            className='!absolute top-0 left-0 right-0 bottom-0 z-[1] group-hover:scale-110 scale-100 transition-transform duration-600 ease-out-expo'
            image={featuredBgImage}
            imageSizes={`(max-width: ${breakpoints.md}px) 20vw, 8vw`}
          />)
        }
        {bottleImage && (
        <ResponsiveImage
          className='w-full h-full z-[2]'
          image={bottleImage}
          contain={true}
          showPreview={false}
          imageSizes={`(max-width: ${breakpoints.md}px) 30vw, 12vw`}
        />)}
      </div>
      <ProductMeta product={product} isSmall={false} className='upMd:hidden' />
      <ArticleProductMeta product={product} className='md:hidden' />
    </Link>
  )
}

const articleSerializer = {
  types: {
    ...serializers.types,
    block: BlockRenderer,
    inlineProduct: InlineProduct
  },
  marks: {
    ...serializers.marks
  }
}

export default function ArticleText ({ data }) {
  const { content } = data

  return (
    <Section>
      <RichContent
        blockContentClassName={gridClasses}
        content={content}
        serializer={articleSerializer}
      />
    </Section>
  )
}
