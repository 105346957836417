import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import forEach from 'lodash/forEach'
import reverse from 'lodash/reverse'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import RichContent from '../RichContent'
import { useSite } from '../siteState'

gsap.registerPlugin(SplitText)

function wrap (el, className) {
  const div = document.createElement('div')
  div.className = className
  el.parentNode.insertBefore(div, el)
  div.appendChild(el)
  return div
}

function unwrap (el) {
  const div = el.parentNode
  div.parentNode.insertBefore(el, div)
  div.remove()
  return el
}

// The subs and sups will not animate correctly because of the overflow hidden
function fixSubsAndSups (container) {
  const subs = container.querySelectorAll('sub')
  forEach(subs, sub => {
    const wrapper = sub.querySelector('.wrap')
    if (wrapper) {
      wrapper.style.overflow = 'visible'
      wrapper.style.position = 'relative'
      wrapper.style.bottom = '0.4em'
    }
    sub.style.opacity = 0
  })
  const sups = container.querySelectorAll('sup')
  forEach(sups, sup => {
    const wrapper = sup.querySelector('.wrap')
    if (wrapper) {
      wrapper.style.overflow = 'visible'
    }
    sup.style.opacity = 0
  })
}

const IntroTitle = forwardRef((props, ref) => {
  const { introText } = useSite()
  const containerRef = useRef()
  const textRef = useRef()
  const splitLinesRef = useRef()

  const revert = () => {
    if (splitLinesRef.current) {
      forEach(splitLinesRef.current.words, (word) => {
        unwrap(word)
      })
      splitLinesRef.current.revert()
    }
  }

  const animateIn = () => {
    revert()
    const tl = gsap.timeline()
    tl.set(containerRef.current, { opacity: 1 })

    const splitItem = new SplitText(textRef.current.querySelectorAll('p'), { type: 'words' })
    splitLinesRef.current = splitItem
    forEach(splitLinesRef.current.words, (word) => {
      wrap(word, 'inline-block overflow-hidden wrap')
    })

    tl.fromTo(splitLinesRef.current?.words, {
      rotate: 10,
      transformOrigin: 'bottom left',
      y: '100%'
    }, {
      opacity: 1,
      rotate: 0,
      y: '0%',
      duration: 1.2,
      ease: 'expo.out',
      stagger: 0.05
    })

    fixSubsAndSups(containerRef.current)
    tl.to(containerRef.current.querySelectorAll('sup, sub'), { opacity: 1 }, '-=1.2')
    return tl
  }

  useImperativeHandle(
    ref,
    () => ({
      animateInTimeline: () => {
        return animateIn()
      },
      animateOutTimeline: () => {
        const tl = gsap.timeline()
        const words = [...splitLinesRef.current.words]
        reverse(words)
        tl.to(containerRef.current.querySelectorAll('sup, sub'), { opacity: 0, duration: 1.6, ease: 'expo.out' }, 0.5)
        tl.to(words, {
          rotate: 10,
          transformOrigin: 'bottom left',
          y: '100%',
          duration: 0.8,
          ease: 'power4.in',
          stagger: 0.05
        }, 0)
        tl.set(containerRef.current, { opacity: 0 })
        return tl
      }
    }),
    []
  )

  useEffect(() => {
    return () => {
      revert()
    }
  }, [])

  return (
    <div className='flex justify-center items-center w-[30rem] mx-0 md:w-full md:mx-16 opacity-0 self-center' ref={containerRef}>
      <div className='overflow-hidden'>
        <RichContent
          content={introText}
          className='uppercase text-24 md:text-20 leading-6 text-center font-550'
          style={{ lineHeight: '0.9', letterSpacing: '-0.04em' }}
          ref={textRef}
        />
        {/* <button className='absolute top-0 bg-error z-[1000000]' onClick={revert}>Revert</button>
        <button className='absolute top-8 bg-error z-[1000000]' onClick={animateIn}>Animate In</button> */}
      </div>
    </div>
  )
})

export default IntroTitle
