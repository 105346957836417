import { useCursor } from '@react-three/drei'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useRef, useState } from 'react'
import { linkToUrl, resolveLink } from '../../../lib/resolveLink'
import transform from '../../ResponsiveImage/transform'
import WebGLImage from '../FeedLayout/Image'
import { useIsMobile } from '../hooks'

function useTransformImage (image, width, aspect) {
  return useMemo(() => {
    if (!image) return null
    const source = transform(image, aspect, [256, 512, 1024, 2048])
    return { source }
  }, [image, aspect])
}

const DEFAULT_STATIC_CLICK_OPTIONS = { timeout: 300, pointerOffset: 0.1 }

const useStaticClick = (onClick, options = DEFAULT_STATIC_CLICK_OPTIONS) => {
  const localsRef = useRef({ pointerDown: false, pointerDownTimeout: null, initialPosition: null })
  const { timeout, pointerOffset } = options
  const onPointerUp = useCallback(() => {
    if (localsRef.current.pointerDown) {
      onClick?.()
    }
    localsRef.current.pointerDown = false
    localsRef.current.pointerInitialPosition = null
    window.clearTimeout(localsRef.current.pointerDownTimeout)
  }, [onClick])

  const onPointerDown = useCallback((e) => {
    localsRef.current.initialPosition = e.uv
    localsRef.current.pointerDown = true
    localsRef.current.pointerDownTimeout = setTimeout(() => {
      localsRef.current.pointerDown = false
    }, timeout)
  }, [timeout])

  const onPointerMove = useCallback((e) => {
    if (localsRef.current.pointerDown) {
      if (Math.abs(localsRef.current.initialPosition.x - e.uv.x) >= pointerOffset ||
        Math.abs(localsRef.current.initialPosition.y - e.uv.y) >= pointerOffset) {
        localsRef.current.pointerDown = false
        window.clearTimeout(localsRef.current.pointerDownTimeout)
      }
    }
  }, [pointerOffset])

  return {
    onPointerMove,
    onPointerDown,
    onPointerUp
  }
}

export default function Slide ({ link, video, image, logo, position, width, height, aspect }) {
  const router = useRouter()
  const isMobile = useIsMobile()

  const logoWidth = isMobile ? width * 0.65 : width * 0.25

  const transformedImage = useTransformImage(image, width, aspect)
  const transformedLogo = useTransformImage(logo, width)

  const [hovered, setHover] = useState()
  useCursor(hovered, 'pointer', 'auto')

  const staticClickProps = useStaticClick(useCallback(() => {
    if (!link) return
    router.push(linkToUrl(resolveLink(link)))
  }, []))
  if (!transformedImage) return

  return (
    <group position={position} {...staticClickProps}>
      <WebGLImage
        video={video}
        image={transformedImage.source}
        scale={[width, height, 1]}
        disableHover
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        animateDrag={false}
        preload
      />

      {transformedLogo && (
        <WebGLImage
          image={transformedLogo.source}
          scale={[logoWidth, logoWidth / transformedLogo?.source?.sourceAspect, 1]}
          position={[0, 0, 1]}
          disableHover
          animate={false}
          animateDrag={false}
          preload
          showPreview={false}
        />
      )}
    </group>
  )
}
