import { useRef } from 'react'
import Link from './Link'
import cn from 'clsx'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'

export default function UnderlineLink({ children, className, link, ...props}) {
  const lineRef = useRef()

  const fadeIn = () => {
    gsap.fromTo(lineRef.current, {
      x: '-110%',
    }, {
      x: 0,
      duration: 0.6,
      ease: 'power3.out',
      overwrite: true
    })
  }

  const fadeOut = () => {
    gsap.to(lineRef.current, {
      x: '110%',
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true
    })
  }

  const events = {
    onMouseEnter: primaryInput === 'touch' ? null : fadeIn,
    onMouseLeave: primaryInput === 'touch' ? null : fadeOut,
  }

  return (
    <Link link={link} className={cn('inline-block relative overflow-hidden', className)} {...events} {...props}>
      {children}
      <div ref={lineRef} className='w-full h-[1px] bg-current absolute bottom-0 left-0 right-0 -translate-x-[-110%]'/>
    </Link>
  )
}