import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
// import Head from 'next/head'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { leftPad } from '../../helpers/math'
import { isHomepage } from '../../helpers/pageContent'
import useDelayedState from '../../hooks/useDelayedState'
import useIsHovering from '../../hooks/useIsHovering'
import useOnClickOutside from '../../hooks/useOnOutsideClick'
import useScrollPosition from '../../hooks/useScrollPosition'
import { useTheme } from '../../state/theme'
import Link from '../Link'
import Logo from '../Logo.svg'
import NewsletterForm from '../Newsletter/NewsletterForm'
import Cart from '../Shop/Cart'
import GiftMessageForm from '../Shop/Cart/GiftMessageForm'
import { useCustomer, useIsAuthenticated } from '../Shop/accountState'
import { cartLineItemsTotalAtom, hasLineItemsAtom } from '../Shop/cartState'
import { useAccountActions } from '../Shop/useAccountActions'
import { useCartSettings, useLabels, useSite } from '../siteState'
import Footer from './Footer'
import Login from './Login'
import NavLink from './NavLink'
import NoiseBackground from './NoiseBackground'
import Search from './Search'
import SearchInput from './Search/SearchInput'
import { useClearSearch } from './Search/searchState'
import SecondaryNav from './SecondaryNav'
import HomeNav from './SecondaryNavs/HomeNav'
import {
  MENU_THEMES, NAV_VIEWS, SECONDARY_MENUS, geoPopupAtom, geoPopupDismissedAtom, hideMenuAtom, menuOpenAtom, menuOverrideThemeAtom, navViewAtom, secondMenuTypeAtom, useMenuHash
} from './menuState'

export const LINK_HOVER_CLASSES = 'uppercase opacity-100 hover:opacity-70 transition-opacity duration-250'

export default function Menu ({ site, page }) {
  const colorsRef = useRef()
  const rootRef = useRef()
  const ref = useRef()
  const [menuOpen, setMenuOpen] = useAtom(menuOpenAtom)
  const [view, setView] = useAtom(navViewAtom)
  const menuHidden = useAtomValue(hideMenuAtom)

  const theme = useTheme()
  const forcedTheme = useAtomValue(menuOverrideThemeAtom)
  const isDarkMode = forcedTheme === MENU_THEMES.none ? theme.dark : forcedTheme === MENU_THEMES.dark

  const secondNavType = useAtomValue(secondMenuTypeAtom)
  const menuVisuallyOpen = useDelayedState(menuOpen, 600)
  const menuVisuallyOpenDelay = useDelayedState(menuOpen, 200)

  const { logout } = useAccountActions()
  const [hasBlur, setHasBlur] = useState(isHomepage(page))

  const hasLineItems = useAtomValue(hasLineItemsAtom)
  const lineItemsCount = useAtomValue(cartLineItemsTotalAtom)
  const { shopLink, accountLink } = useSite()
  const { cartLabel, giftWrappingTitle } = useCartSettings()
  const { closeLabel, logoutLabel, loginLabel, backLabel } = useLabels()

  const { eventListeners: menuIconEvents, hovering: menuIconHovering } = useIsHovering()

  useOnClickOutside(rootRef, () => setMenuOpen(false), menuOpen)

  const updateBlur = useCallback(({ scroll }) => {
    setHasBlur(scroll > 10 || isHomepage(page))
  }, [page])

  useScrollPosition(updateBlur)
  useEffect(() => updateBlur({}), [updateBlur])

  const authenticated = useIsAuthenticated()
  const customer = useCustomer()

  useEffect(() => {
    gsap.to(colorsRef.current, {
      '--foreground': isDarkMode ? '#ffffff' : '#000000',
      '--background': isDarkMode ? 'rgba(51, 51, 51, 0.5)' : 'rgba(229, 229, 229, 0.4)',
      autoAlpha: menuHidden ? 0 : 1,
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true
    })
    gsap.to(document.documentElement, {
      '--menu-bg': isDarkMode ? 'rgba(51, 51, 51, 0.5)' : 'rgba(229, 229, 229, 0.4)',
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true
    })
  }, [isDarkMode, menuHidden])

  useEffect(() => {
    const timeline = gsap.timeline()

    timeline.set(ref.current, { overflowY: 'hidden' })
    timeline.to(ref.current, {
      height: menuOpen ? 'auto' : '0',
      duration: 0.6,
      ease: 'expo.out',
      onStart: () => {
        if (menuOpen) gsap.set(ref.current, { visibility: 'visible' })
      },
      onComplete: () => {
        gsap.set(ref.current, { overflowY: menuOpen ? 'auto' : 'hidden' })
        if (!menuOpen) gsap.set(ref.current, { visibility: 'hidden' })
      }
    })

    if (ref.current?.children?.[0]?.children) {
      timeline.to(ref.current.children[0].children, {
        y: menuOpen ? 0 : 10,
        opacity: menuOpen ? 1 : 0,
        duration: 0.6,
        ease: 'expo.out',
        stagger: 0.1,
        delay: 0.1,
        overwrite: true
      }, 0)
    }

    return () => {
      timeline.kill()
    }
  }, [menuOpen])

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false)
    return () => [document.removeEventListener('keydown', handleEsc, false)]
  }, [])

  function handleEsc (e) {
    if (e.keyCode === 27) {
      setMenuOpen(false)
    }
  }

  const clearSearch = useClearSearch()
  const toggleMenu = useCallback(() => {
    let timeoutId
    if (view === NAV_VIEWS.search && menuOpen) {
      clearSearch()
      return
    }
    if (view !== NAV_VIEWS.nav && view !== NAV_VIEWS.search) {
      if (!menuOpen) {
        setView(NAV_VIEWS.nav)
      } else {
        timeoutId = setTimeout(() => { setView(NAV_VIEWS.nav) }, 600)
      }
    }
    setMenuOpen(!menuOpen)
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [menuOpen, view])

  const toggleCart = useCallback(() => {
    setView(NAV_VIEWS.cart)
    setMenuOpen(!menuOpen)
  }, [menuOpen])

  const onLoginClick = useCallback(() => {
    setView(NAV_VIEWS.login)
  }, [])

  const onLogoutClick = useCallback(() => {
    logout()
  }, [])

  const { title, action } = useMemo(() => {
    if (view === NAV_VIEWS.giftMessage && menuOpen) {
      return {
        title: giftWrappingTitle,
        action: <button onClick={toggleCart} className={cn(LINK_HOVER_CLASSES, 'md:min-h-button')}>{closeLabel}</button>
      }
    }
    if (view === NAV_VIEWS.login && menuOpen) {
      return {
        title: loginLabel,
        action: <button onClick={() => setView(NAV_VIEWS.nav)} className={cn(LINK_HOVER_CLASSES, 'md:min-h-button')}>{backLabel}</button>
      }
    }
    if (view === NAV_VIEWS.resetPassword && menuOpen) {
      return {
        title: '',
        action: <button onClick={() => setView(NAV_VIEWS.login)} className={cn(LINK_HOVER_CLASSES, 'md:min-h-button')}>{backLabel}</button>
      }
    }
    if (view === NAV_VIEWS.search && menuOpen) {
      return {
        title: '',
        action: <Link className={LINK_HOVER_CLASSES} link={shopLink} data-event='site interaction' data-type='link' data-description='shop' data-location='header' />
      }
    }
    if (view === NAV_VIEWS.newsletter && menuOpen) {
      return {
        title: '',
        action: <button onClick={() => setView(NAV_VIEWS.nav)} className={cn(LINK_HOVER_CLASSES, 'md:min-h-button')}>{backLabel}</button>
      }
    }
    return {
      title: '',
      action: hasLineItems
        ? <button onClick={toggleCart} className={cn(LINK_HOVER_CLASSES, 'md:min-h-button')} data-event='site interaction' data-type='button' data-description='cart' data-location='header'>{`${cartLabel} `}<span className='font-serif font-250'>{leftPad(lineItemsCount, 2)}</span></button>
        : <Link className={LINK_HOVER_CLASSES} link={shopLink} data-event='site interaction' data-type='link' data-description='shop' data-location='header' />
    }
  }, [view, menuOpen, hasLineItems, toggleCart, lineItemsCount])

  // const linkWithDotClasses = 'flex gap-[2px] py-[0.125rem] md:py-[0.125rem] relative items-center before:static before:content-[\'\'] before:w-[0.5em] before:h-[0.5em] before:bg-[var(--foreground)] before:block before:rounded-full before:mt-[-1px] md:text-[12px]'

  const onWheel = useCallback((e) => {
    if (menuOpen) e.stopPropagation()
  }, [menuOpen])

  const homeLink = useMemo(() => ({ slug: 'home', site }), [site])

  const geoPopupDismissed = useAtomValue(geoPopupDismissedAtom)
  const geoPopup = useAtomValue(geoPopupAtom)
  const isGeoPopup = !!geoPopup && !geoPopupDismissed

  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  }, [setMenuOpen])

  useMenuHash()

  const [loadNavLinks, setLoadNavLinks] = useState(false)
  const onMouseEnter = useCallback(() => { setLoadNavLinks(true) }, [])

  return (
    <div ref={colorsRef} id="menu" onMouseEnter={onMouseEnter}>
      <nav
        ref={rootRef}
        className={
          cn(
            'w-[calc(100%-32px)] max-w-[max(20vw,288px)] md:max-w-[100%] md:min-h-[47px] fixed left-1/2 top-4 -translate-x-1/2 z-menu bg-transparent',
            (hasBlur || menuVisuallyOpenDelay) && 'backdrop-blur-menu backdrop-saturate-menu'
          )}
        style={{ color: 'var(--foreground)' }}
        onWheel={onWheel}
      >
        <div className='px-navPadding grid grid-cols-menu items-center relative'>
          <div className={cn('col-start-1 row-start-1 flex items-flex-start my-[0.625rem] md:my-[16px]')}>
            <AnimateHide hide={menuOpen && (view === NAV_VIEWS.nav || view === NAV_VIEWS.search)} direction={!title && !menuOpen ? 'up' : 'down'}>
              <Link link={homeLink} className={LINK_HOVER_CLASSES} data-event='site interaction' data-type='link' data-description='home' data-location='header'>
                <Logo isSmall className='w-auto h-[0.625rem] md:h-[0.875rem] md:w-[64px] transition-opacity duration-300 ease-out-expo' />
              </Link>
            </AnimateHide>
          </div>

          <button
            onClick={toggleMenu}
            aria-label={menuOpen ? 'close menu' : 'open menu'}
            className='place-self-center p-[10px] col-start-2 row-start-1 md:min-h-button md:min-w-button'
            data-event='site interaction'
            data-type='button'
            data-description='menu'
            data-location='header'
            {...menuIconEvents}
          >
            <MenuIcon open={menuOpen} hovering={menuIconHovering} />
          </button>

          <div className={cn(
            'col-start-3 row-start-1',
            'uppercase font-550 text-12 md:text-16 tracking-slight text-right transition-opacity duration-300 ease-out-expo'
          )}>
            <AnimateHide hide={menuOpen && (view === NAV_VIEWS.nav || view === NAV_VIEWS.search)}>
              {action}
            </AnimateHide>
          </div>
          <HomeNav show={(secondNavType === SECONDARY_MENUS.home || secondNavType === SECONDARY_MENUS.article) && !menuVisuallyOpen} />
        </div>

        <div ref={ref} className='overflow-y-auto overflow-x-hidden h-0 w-full col-span-full max-h-[calc(100vh-62px)] overscroll-contain' data-lenis-prevent>
          {(view === NAV_VIEWS.nav || view === NAV_VIEWS.search) && (
            <div className='w-full flex flex-col pt-[10px] pb-[2px] px-navPadding'>
              <SearchInput />
              {view === NAV_VIEWS.search && (
                <Search />
              )}
              {view === NAV_VIEWS.nav && (
                <>
                  <div className='grid grid-cols-menu-links py-[6.5rem]'>
                    <div className='uppercase font-550 text-14 md:text-18 leading-110 flex flex-col'>
                      {site?.mainLinks?.map(link => (
                        <NavLink load={loadNavLinks} key={link._key} link={link} className='py-[0.125rem] md:py-[0.25rem]' data-location='primary navigation' onClick={closeMenu} />
                      ))}
                    </div>
                    <div className='uppercase text-10 md:text-12 md:text-[12px] leading-none flex flex-col'>
                      {authenticated && (
                        <>
                          <NavLink
                            load={loadNavLinks}
                            className={'py-[0.125rem] md:py-[0.125rem] uppercase text-left'}
                            link={accountLink}>
                            <span className='flex items-center'><span className='inline-block rounded-full bg-current w-[0.5em] h-[0.5em] mr-[0.25em]' />{customer?.firstName}</span>
                          </NavLink>
                          <NavLink load={loadNavLinks} nonLinkTag='button' className={'py-[0.125rem] md:py-[0.125rem] uppercase text-left'} onClick={onLogoutClick} data-location='primary navigation'>{logoutLabel || 'Logout'}</NavLink>
                        </>
                      )}
                      {!authenticated && (
                        <>
                          <NavLink
                            load={loadNavLinks}
                            nonLinkTag='button'
                            className={'py-[0.125rem] md:py-[0.125rem] uppercase text-left'}
                            onClick={onLoginClick}
                            data-location='primary navigation'>
                            <span className='flex items-center'><span className='inline-block rounded-full bg-current w-[0.5em] h-[0.5em] mr-[0.25em]' />{loginLabel || 'Login'}</span>
                          </NavLink>
                          <NavLink load={loadNavLinks} className='py-[0.125rem] md:py-[0.125rem]' link={accountLink} />
                        </>
                      )}
                      {site?.secondaryLinks?.map(link => (
                        <NavLink load={loadNavLinks} key={link._key} link={link} className='py-[0.125rem] md:py-[0.125rem]' data-location='primary navigation' />
                      ))}
                    </div>
                  </div>

                  <Footer definitionLabels={site.definitionLabels} />
                </>
              )}
            </div>

          )}
          {view === NAV_VIEWS.cart && (
            <Cart />
          )}
          {view === NAV_VIEWS.giftMessage && (
            <GiftMessageForm />
          )}
          {view === NAV_VIEWS.login && (
            <Login />
          )}
          {view === NAV_VIEWS.newsletter && (
            <NewsletterForm />
          )}
        </div>

        <div className={cn(
          'fixed inset-0 opacity-0 transition-opacity duration-300 -z-1 ease-out-expo',
          (hasBlur || menuOpen) && '!opacity-100'
        )} style={{ background: 'var(--background)' }}/>
        <NoiseBackground light={isDarkMode} visible={hasBlur || menuOpen} />
      </nav>
      <SecondaryNav hasBlur={hasBlur} isDarkMode={isDarkMode} page={page} />
      <div style={{ background: 'var(--background)' }} className={cn(
        'pointer-events-none fixed inset-0 backdrop-blur-menu backdrop-saturate-menu z-geo-blur opacity-0 transition-opacity duration-[1.2s] ease-out-expo',
        isGeoPopup && '!pointer-events-auto !opacity-100'
      )}/>
    </div>
  )
}

function MenuIcon ({ open, hovering }) {
  const ref = useRef()

  useEffect(() => {
    gsap.to(ref.current.children[0], {
      rotate: open ? 45 : 0,
      scaleX: open ? 0.6 : 1,
      y: open ? 4 : hovering ? 2 : 0,
      transformOrigin: '50% 50%',
      duration: 0.6,
      ease: 'expo.out'
    })
    gsap.to(ref.current.children[1], {
      scaleX: open || hovering ? 0 : 1,
      opacity: open || hovering ? 0.2 : 1,
      transformOrigin: '50% 50%',
      duration: 0.3,
      ease: 'expo.out'
    })
    gsap.to(ref.current.children[2], {
      rotate: open ? -45 : 0,
      y: open ? -4 : hovering ? -2 : 0,
      scaleX: open ? 0.6 : 1,
      transformOrigin: '50% 50%',
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [open, hovering])

  return (
    <svg ref={ref} className="md:w-[2rem] md:h-auto overflow-visible" width="1.25rem" height="0.5625rem" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.5" x2="25" y2="0.5" stroke="var(--foreground)"/>
      <line y1="4.5" x2="25" y2="4.5" stroke="var(--foreground)"/>
      <line y1="8.5" x2="25" y2="8.5" stroke="var(--foreground)"/>
    </svg>
  )
}

export function AnimateHide ({ hide, direction = 'down', children }) {
  const ref = useRef()

  useEffect(() => {
    const amount = direction === 'up' ? '-110%' : '110%'

    gsap.to(ref.current.children, {
      y: hide ? amount : '0',
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true,
      onStart: () => {
        if (!hide) gsap.set(ref.current, { visibility: 'visible' })
      },
      onComplete: () => {
        if (hide) gsap.set(ref.current, { visibility: 'hidden' })
      }
    })
  }, [hide, direction])

  return <div ref={ref} className='overflow-hidden'>
    <div>
      {children}
    </div>
  </div>
}
