import forEach from 'lodash/forEach'
import { useLayoutEffect, useRef } from 'react'
import * as THREE from 'three'
import Random from '../../../helpers/Random'
import { getBoundingBoxHeight, getBoundingBoxWidth } from '../helpers/object'
import { useIsMobile, useViewport } from '../hooks'
import Block from '../ui/Block'
import Tile from './Tile'

const GAP = 0.2

const random = new Random(Math.random() * 10)

export default function Row ({ row, enabled }) {
  const ref = useRef()
  const isMobile = useIsMobile()
  const viewport = useViewport()

  const onReflow = () => {
    random.reset()

    const vwToWorldCoordinate = (value) => {
      return value * viewport.width
    }

    const tileElements = ref.current.children.filter(t => t.userData?.type === 'tile')

    if (isMobile) {
      const gap = GAP * viewport.width
      let top = 0
      forEach(tileElements, (element, i) => {
        const tile = row.tiles[i]
        if (isNaN(element.position.y)) {
          element.position.y = 0
        }
        const bbox = new THREE.Box3().setFromObject(element)
        const height = getBoundingBoxHeight(bbox)
        const width = getBoundingBoxWidth(bbox)
        const surroundingSpace = viewport.width - width - (viewport.width * 0.05)
        if (tile.tile._type === 'quote' || tile.tile._type === 'textItem' || tile.tile._type === 'footerTile') {
          element.position.y = top - (height / 2)
          element.position.x = 0
          element.position.z = 0
        } else {
          element.position.y = top - (height / 2)
          element.position.x = surroundingSpace > (viewport.width * 0.05) ? random.generate(-surroundingSpace / 8, surroundingSpace / 8) * 4 : 0
          element.position.z = tile.z
        }
        top = (element.position.y - (height / 2) - gap)
      })
    } else {
      forEach(tileElements, (element, i) => {
        const bbox = new THREE.Box3().setFromObject(element)
        const width = getBoundingBoxWidth(bbox)
        const minX = -(viewport.width / 2) + (width / 2) + (viewport.width * 0.01)
        const maxX = viewport.width / 2 - (width / 2) - (viewport.width * 0.01)

        const tile = row.tiles[i]
        const offset = tile.offset || { x: 0, y: 0 }
        const x = vwToWorldCoordinate(tile.position.x + offset.x) - (viewport.width / 2)
        const y = vwToWorldCoordinate(tile.position.y + offset.y) + (viewport.height / 2)
        element.position.x = tile._type === 'quote' ? x : Math.max(Math.min(x, maxX), minX)
        element.position.y = y
        if (tile.tile._type === 'quote' || tile.tile._type === 'textItem' || tile.tile._type === 'footerTile') {
          element.position.z = 0
        } else {
          element.position.z = tile.z
        }
      })
    }
  }

  useLayoutEffect(() => {
    onReflow()
  }, [isMobile, row.tiles, viewport.width])
  return (
    <Block ref={ref} onReflow={onReflow}>
      {row.tiles?.map((tile, i) => {
        return <Tile tile={tile} key={i} enabled={enabled} />
      }, [])}
    </Block>
  )
}
