import cn from 'clsx'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import sortBy from 'lodash/sortBy'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { resolveLink } from '../../lib/resolveLink'
import Link, { ExternalLinkIcon, isExternalLink } from '../Link'

gsap.registerPlugin(SplitText)

export default function NavLink ({ load = true, children, className, link, onClick, ...props }) {
  const { text, url } = useMemo(() => resolveLink(link), [link]) || {}
  const externalLink = isExternalLink(url)
  const ref = useRef()
  const textRef = useRef()
  const textCopyRef = useRef()
  const splitRef = useRef({ text: null, copy: null })
  const localsRef = useRef({ tl: null, loaded: false })

  useEffect(() => {
    if (load && !localsRef.current.loaded) {
      gsap.set(textCopyRef.current, { y: 0 })

      const splitTextItem = new SplitText(textRef.current, { type: 'chars' })
      splitRef.current.text = splitTextItem

      const splitCopyItem = new SplitText(textCopyRef.current, { type: 'chars' })
      gsap.set(splitCopyItem.chars, { y: '120%' })
      splitRef.current.copy = splitCopyItem
      localsRef.current.loaded = true
      localsRef.current.splitTextItem = splitTextItem
      localsRef.current.splitCopyItem = splitCopyItem
    }
  }, [load])

  useEffect(() => {
    const { splitTextItem, splitCopyItem } = localsRef.current
    return () => {
      splitTextItem?.revert()
      splitCopyItem?.revert()
    }
  }, [])

  const getOrderedCharsClosestTo = (chars, x) => {
    return sortBy(chars, char => {
      const rect = char.getBoundingClientRect()
      return Math.abs((rect.left + rect.width) - x)
    })
  }

  useEffect(() => {
    if (primaryInput === 'touch') return
    const element = ref.current
    const enter = (e) => {
      localsRef.current.tl?.kill()
      if (splitRef.current?.text?.chars && splitRef.current?.copy?.chars) {
        const tl = gsap.timeline()
        const duration = 0.25
        const chars = getOrderedCharsClosestTo(splitRef.current.text.chars, e.clientX)
        const copy = getOrderedCharsClosestTo(splitRef.current.copy.chars, e.clientX)
        tl.to(chars, { y: '-110%', stagger: 0.015, duration, ease: 'sine.out' })
        tl.to(copy, { y: '0%', stagger: 0.015, duration, ease: 'back.out(1.7)' }, 0.05)
        tl.set(chars, { y: 0 })
        tl.set(copy, { y: '120%' })
        localsRef.current.tl = tl
      }
    }
    const leave = () => {}
    element.addEventListener('mouseenter', enter)
    element.addEventListener('mouseleave', leave)
    return () => {
      element.addEventListener('mouseenter', enter)
      element.addEventListener('mouseleave', leave)
    }
  }, [])

  const handleClick = useCallback((e) => {
    if (splitRef.current?.text?.chars) {
      const chars = getOrderedCharsClosestTo(splitRef.current.text.chars, e.clientX)
      localsRef.current.tl?.kill()
      const tl = gsap.timeline()
      const duration = 0.2
      tl.set(splitRef.current.text.chars, { y: 0 })
      tl.set(splitRef.current.copy.chars, { y: '120%' })
      tl.to(chars, { y: '-80%', stagger: 0.015, duration, ease: 'back.out(1.7)' })
      tl.to(chars, { y: '0%', stagger: 0.015, duration, ease: 'back.out(1.7)' }, 0.1)
      localsRef.current.tl = tl
    }
    onClick?.(e)
  }, [onClick])

  return (
    <Link ref={ref} link={link} className='block relative overflow-hidden' showText={false} showExternalIcon={false} onClick={handleClick} {...props}>
      <span ref={textRef} className={cn(className, 'block')}>{children || text}{externalLink && <ExternalLinkIcon />}</span>
      <span ref={textCopyRef} className={cn(className, 'block absolute w-full h-full left-0 top-0 translate-y-[120%]')}>{children || text}{externalLink && <ExternalLinkIcon />}</span>
    </Link>
  )
}
