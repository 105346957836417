import Link from '../Link'
import Section from '../Section'
import { useShowSceneCallback, SCENES } from '../Canvas/sceneState'
import { useEffect } from 'react'

export default function ErrorPage ({ data }) {
  const { errorTextLeft, errorTextRight, link, image} = data
  const showScene = useShowSceneCallback()

  useEffect(() => {
    if (!image) return
    showScene(SCENES.ABOUT, { image, isErrorPage: true })
    return () => showScene(SCENES.NONE)
  }, [data, showScene])

  return (
    <Section noGutter noBottomMargin className='fixed top-0 left-0 right-0 bottom-0 flex px-4 flex-col justify-center gap-[3.125rem] md:gap-[2rem] z-[10]'>
      <div className='font-serif font-250 flex items-center tracking-[-0.75em] md:!tracking-[-0.3em] font-serif-feature-zero mt-[4.5rem]'>
        <div className='text-[calc((100vw-48px)*0.149)] leading-[0.65em]'>{errorTextLeft}</div>
        <div className="flex-1 h-[0.4em] md:h-[0.15em] bg-current w-full"/>
        <div className='text-[calc((100vw-48px)*0.149)] leading-[0.65em]'>{errorTextRight}</div>
      </div>
      {link && <Link link={link} className='self-center text-center uppercase flex gap-2 items-center text-12 font-550 tracking-slight'>
        <svg width="0.875rem" height="0.5625rem" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3536 4.85355C13.5488 4.65829 13.5488 4.34171 13.3536 4.14645L10.1716 0.964466C9.97631 0.769204 9.65973 0.769204 9.46447 0.964466C9.2692 1.15973 9.2692 1.47631 9.46447 1.67157L12.2929 4.5L9.46447 7.32843C9.2692 7.52369 9.2692 7.84027 9.46447 8.03553C9.65973 8.2308 9.97631 8.2308 10.1716 8.03553L13.3536 4.85355ZM0 5H13V4H0V5Z" fill="currentColor"/>
        </svg>
      </Link>}
    </Section>
  )
}
