import { useMemo } from 'react'
import Link from '../Link'
import Logo from '../Logo.svg'
import { useSite } from '../siteState'

export default function Footer ({ definitionLabels }) {
  const site = useSite()
  const homeLink = useMemo(() => ({ slug: 'home', site }), [site])
  return (
    <Link link={homeLink} className='block mb-[6px]'>
      <div>
        <Logo className='w-full h-auto' />
        <div className='w-full flex justify-between text-[0.5rem]  mt-[6px]'>
          <span className='font-serif font-thin italic text-[0.5rem] '>{definitionLabels?.wordClass}</span>
          <span className='font-serif font-thin italic text-[0.5rem] '>{definitionLabels?.phonetic}</span>
          <span className='font-550 text-[0.5rem]  uppercase'>{definitionLabels?.description}</span>
        </div>
      </div>
    </Link>
  )
}
