import gsap from 'gsap'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import forEach from 'lodash/forEach'
import { useCallback, useEffect, useRef } from 'react'
import useIsomorphicLayoutEffect from '../hooks/useIsomorphicLayoutEffect'
import { createPageThemeTimeline, getPageTheme } from '../state/theme'
import { canvasStateAtom } from './Canvas/canvasState'
import { useClearSearch } from './Menu/Search/searchState'
import { menuOpenAtom, secondMenuOpenAtom } from './Menu/menuState'
import { useIsAuthenticated } from './Shop/accountState'
import { pageContentAtom, useIsPagePreloaded, useRegisterPagePreload } from './pageContentState'
import { useSite } from './siteState'

const isHomepage = (page) => page.slug === 'home'

export const IMAGE_OPACITY_BY_NAME = {}

export const getMeshes = (obj) => {
  const meshes = []
  obj?.traverse(child => {
    if (child.isMesh && child.name !== 'text-placeholder-mesh') {
      meshes.push(child)
    }
  })
  return meshes
}

const useCanvasTransitionTimelineCallback = () => {
  const { sceneEffect, scene } = useAtomValue(canvasStateAtom)

  const localsRef = useRef({ sceneEffect, intensity: 0, opacity: 1 })

  useEffect(() => {
    localsRef.current.sceneEffect = sceneEffect
    localsRef.current.scene = scene
  }, [sceneEffect, scene])

  const addOutTransition = useCallback((tl, groupName, duration = 1.5, options = {}) => {
    let meshes = null
    let group = null
    const getCachedDate = () => {
      if (!group && localsRef.current.scene) {
        group = localsRef.current.scene.getObjectByName(groupName)
        meshes = group ? getMeshes(group) : []
      }
      return { group, meshes }
    }

    tl.fromTo(localsRef.current, { intensity: 0, opacity: 1 }, {
      intensity: 0.5,
      opacity: 0,
      duration,
      ease: 'power4.inOut',
      onUpdate: () => {
        const { sceneEffect } = localsRef.current
        if (sceneEffect) {
          sceneEffect.displacementIntensity = localsRef.current.intensity
        }
        const { group, meshes } = getCachedDate()
        if (group) {
          group.position.y = localsRef.current.intensity * (options.y || 10)
          forEach(meshes, mesh => {
            if (mesh.name) {
              IMAGE_OPACITY_BY_NAME[mesh.name] = localsRef.current.opacity
            }
            mesh.material.opacity = localsRef.current.opacity
            if (mesh.material?.uniforms?.opacity) mesh.material.uniforms.opacity.value = localsRef.current.opacity
          })
        }
      },
      onComplete: () => {
        const { group } = getCachedDate()
        if (group) {
          group.position.y = 0
        }
      }
    }, 0)

    return tl
  }, [])

  const addResetTransition = useCallback((tl, groupName) => {
    tl.to(localsRef.current, {
      intensity: -0.5,
      opacity: 0,
      duration: 0.00001,
      onStart: () => {
        if (!localsRef.current.scene) return
        const group = localsRef.current.scene.getObjectByName(groupName)
        const meshes = group ? getMeshes(group) : []
        const { sceneEffect } = localsRef.current
        if (sceneEffect) {
          sceneEffect.waveIntensity = 0
        }
        forEach(meshes, mesh => {
          if (mesh.name) {
            IMAGE_OPACITY_BY_NAME[mesh.name] = localsRef.current.opacity
          }
          // I set this property on the mesh as we change the texture when the image or video loads
          if (mesh.material) mesh.material.opacity = 0
          if (mesh.material?.uniforms?.opacity) mesh.material.uniforms.opacity.value = 0
        })
      }
    })
  }, [])

  const addInTransition = useCallback((tl, groupName, duration = 1.5, delay = 1.5, options = {}) => {
    let meshes = null
    let group = null
    const getCachedDate = () => {
      if (!group && localsRef.current.scene) {
        group = localsRef.current.scene.getObjectByName(groupName)
        meshes = group ? getMeshes(group) : []
      }
      return { group, meshes }
    }

    tl.set(localsRef.current, { intensity: -0.5, opacity: 0 }, delay)
    tl.to(localsRef.current, {
      intensity: 0,
      duration,
      ease: 'power4.out',
      onUpdate: () => {
        const { group } = getCachedDate()
        const { sceneEffect } = localsRef.current
        if (sceneEffect) {
          sceneEffect.displacementIntensity = localsRef.current.intensity
        }
        if (group) {
          group.position.y = localsRef.current.intensity * (options.y || 10)
        }
      }
    }, delay)
    tl.to(localsRef.current, {
      opacity: 1,
      duration: duration * 0.7,
      ease: 'power4.inOut',
      onUpdate: () => {
        const { meshes } = getCachedDate()
        forEach(meshes, mesh => {
          if (mesh.name) {
            IMAGE_OPACITY_BY_NAME[mesh.name] = localsRef.current.opacity
          }
          if (mesh.material) mesh.material.opacity = localsRef.current.opacity
          if (mesh.material?.uniforms?.opacity) mesh.material.uniforms.opacity.value = localsRef.current.opacity
        })
      }
    }, delay)

    return tl
  }, [])

  return {
    addOutTransition,
    addResetTransition,
    addInTransition
  }
}

const useCreatePageThemeTransition = () => {
  const authenticated = useIsAuthenticated()
  const site = useSite()
  return useCallback((tl, duration, currentPage, nextPage) => {
    const currentTheme = getPageTheme(currentPage, site, authenticated)
    const nextTheme = getPageTheme(nextPage, site, authenticated)
    createPageThemeTimeline(tl, duration, currentTheme, nextTheme, duration / 2)
  }, [authenticated, site])
}

const DURATION = 1.5

const usePageTransition = (page) => {
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const setSecondaryMenuOpen = useSetAtom(secondMenuOpenAtom)
  const clearSearch = useClearSearch()
  const registerPagePreload = useRegisterPagePreload()

  const { addOutTransition, addResetTransition, addInTransition } = useCanvasTransitionTimelineCallback()
  const addPageThemeTransition = useCreatePageThemeTransition()
  const [pageContent, setPageContent] = useAtom(pageContentAtom)

  const animateIn = (tl) => {
    const IN_DELAY = 0.2
    if (isHomepage(page)) {
      addInTransition(tl, 'feed', DURATION, IN_DELAY)
      tl.to(document.getElementById('canvas-feed-html'), { opacity: 1, pointerEvents: 'all', duration: DURATION, ease: 'power4.inOut' }, IN_DELAY)
    } else {
      addInTransition(tl, 'page-content', DURATION, IN_DELAY, { y: 5 })
    }
    tl.to(document.getElementById('page-content'), {
      opacity: 1,
      duration: DURATION,
      ease: 'sine.inOut'
    }, IN_DELAY)
  }

  const isPreloaded = useIsPagePreloaded(pageContent._id)

  useIsomorphicLayoutEffect(() => {
    const tl = gsap.timeline()
    if (isHomepage(page)) {
      addResetTransition(tl, 'feed')
    } else {
      addResetTransition(tl, 'page-content')
    }
    return () => { tl.kill() }
  }, [pageContent])

  useIsomorphicLayoutEffect(() => {
    if (isPreloaded) {
      const tl = gsap.timeline()
      animateIn(tl)
      return () => {
        tl.kill()
      }
    }
  }, [isPreloaded, pageContent])

  useIsomorphicLayoutEffect(() => {
    if (page._id === pageContent._id) return

    const tl = gsap.timeline()

    registerPagePreload(page)

    addPageThemeTransition(tl, DURATION, pageContent, page)

    if (isHomepage(pageContent)) {
      addOutTransition(tl, 'feed', DURATION)
      tl.to(document.getElementById('canvas-feed-html'), { opacity: 0, pointerEvents: 'none', duration: DURATION, ease: 'power4.inOut' }, 0)
    } else {
      addOutTransition(tl, 'page-content', DURATION, { y: 2 })
    }
    tl.to(document.getElementById('secondary-nav'), {
      autoAlpha: 0,
      duration: DURATION * 0.9,
      ease: 'sine.inOut'
    }, 0)
    tl.to(document.getElementById('page-content'), {
      opacity: 0,
      duration: DURATION * 0.9,
      ease: 'sine.inOut'
    }, 0)
    tl.set({}, {
      onComplete: () => {
        setPageContent(page)
      }
    })

    setMenuOpen(false)
    setSecondaryMenuOpen(false)
    clearSearch()

    return () => {
      tl.kill()
    }
  }, [page])

  return pageContent
}

export default usePageTransition
