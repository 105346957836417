import cn from 'clsx'
import gsap from 'gsap'
import { atom, useAtom, useSetAtom } from 'jotai'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import breakpoints from '../../../../theme/breakpoints'
import useScrollPosition from '../../../hooks/useScrollPosition'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useWindowResize from '../../../hooks/useWindowResize'
import { SECONDARY_MENUS, secondMenuTypeAtom } from '../../Menu/menuState'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent from '../../RichContent'
import Section from '../../Section'

import { primaryInput } from 'detect-it'
import forEach from 'lodash/forEach'
import { createPortal } from 'react-dom'
import { easeInCubic } from '../../../helpers/math'
import useIsMounted from '../../../hooks/useIsMounted'
import Button from '../../Button'
import { data as cursorData } from '../../Cursor.js'
import Link from '../../Link'

export const sessionAudioStateAtom = atom({
  isPlaying: false,
  duration: 0,
  current: 0
})

export default function SessionsHero ({ data, page }) {
  const { transcript } = data
  const ref = useRef()
  const metaBarRef = useRef()
  const mobileBtnWrapRef = useRef()
  const textRef = useRef()
  const localsRef = useRef({})

  const setSecondNavType = useSetAtom(secondMenuTypeAtom)
  const [audioState, setAudioState] = useAtom(sessionAudioStateAtom)

  const [mouseOverText, setMouseOverText] = useState(false)
  const [cursorVisible, setCursorVisible] = useState(false)

  const image = page?.articleFields?.images[0]
  const audioUrl = data.audio?.asset?.url

  useWindowResize(useThrottleCallback(() => {
    localsRef.current.innerWidth = window.innerWidth
    localsRef.current.innerHeight = window.innerHeight
  }, 200, []), true)

  useScrollPosition(useCallback(({ progress }) => {
    if (!ref.current) return
    const y = window.scrollY
    const el = ref.current.querySelector('.res-img')
    el.style.opacity = easeInCubic(1 - progress)

    const isMobile = localsRef.current.innerWidth < breakpoints.md
    el.style.transform = !isMobile ? `translate3d(0, ${-progress * 25}vh, 0)` : 'none'

    const metaEls = gsap.utils.toArray(metaBarRef.current.children).filter((x, i) => i !== 1)
    const textHeight = textRef.current.getBoundingClientRect().height + textRef.current.offsetTop - metaBarRef.current.offsetTop + metaBarRef.current.getBoundingClientRect().height - metaBarRef.current.children[0].getBoundingClientRect().height

    if (isMobile) {
      forEach(metaEls, el => { el.style.transform = null })
    } else {
      if (y < textHeight) {
        forEach(metaEls, el => { el.style.transform = `translate3d(0, ${y}px, 0)` })
      } else {
        forEach(metaEls, el => { el.style.transform = `translate3d(0, ${textHeight}px, 0)` })
      }
    }
  }, []))

  useEffect(() => {
    const audioEl = ref.current.querySelector('audio')
    if (!audioEl) return

    setSecondNavType(SECONDARY_MENUS.sessions)
    setAudioState({
      ...audioState
    })

    return () => setSecondNavType(SECONDARY_MENUS.none)
  }, [])

  const handleTimeUpdate = useCallback((e) => {
    setAudioState({
      ...audioState,
      current: e.target.currentTime,
      duration: e.target.duration
    })
  }, [audioState, setAudioState])

  const togglePlaying = (e) => {
    const isMobile = localsRef.current.innerWidth < breakpoints.md
    if (isMobile) return
    const blockedEls = gsap.utils.toArray(textRef.current.querySelectorAll('*'))
    if (blockedEls.find(x => x === e.target)) return

    if (e.target === textRef.current) return

    setAudioState({
      ...audioState,
      isPlaying: !audioState.isPlaying
    })
  }

  useEffect(() => {
    const audioEl = ref.current.querySelector('audio')
    const isMobile = localsRef.current.innerWidth < breakpoints.md
    if (!audioEl) return
    if (audioState.isPlaying && isMobile) {
      animateIntroMobile()
    }
    audioState.isPlaying ? audioEl.play() : audioEl.pause()
  }, [audioState.isPlaying])

  const textEvents = {
    onMouseEnter: primaryInput === 'touch' ? null : () => setMouseOverText(true),
    onMouseLeave: primaryInput === 'touch' ? null : () => setMouseOverText(false)
  }

  const bgEvents = {
    onMouseEnter: primaryInput === 'touch' || !audioUrl ? null : () => setCursorVisible(true),
    onMouseLeave: primaryInput === 'touch' || !audioUrl ? null : () => setCursorVisible(false)
  }

  const handleMobileClick = () => {
    animateIntroMobile()
    setAudioState({
      ...audioState,
      isPlaying: !audioState.isPlaying
    })
  }

  const animateIntroMobile = () => {
    gsap.to(mobileBtnWrapRef.current, {
      height: 0,
      duration: 'expo.out',
      duration: 1
    })
    gsap.to(mobileBtnWrapRef.current.children, {
      y: '10.5vh',
      autoAlpha: 0,
      duration: 'expo.out',
      duration: 0.6
    })
  }

  const handleLoadMetaData = () => {
    const audioEl = ref.current.querySelector('audio')
    if (!audioEl) return
    setAudioState({
      ...audioState,
      duration: audioEl.duration
    })
  }

  return (
    <Section grid noBottomMargin className='py-[50vh] md:py-[40vh] pb-[25vh] md:pb-16 relative' ref={ref} onClick={togglePlaying} {...bgEvents}>
      {image && <ResponsiveImage image={image} className='res-img !fixed inset-0 w-full h-screen upMd:h-[150vh] z-1 pointer-events-none' />}
      {audioUrl && <audio src={audioUrl} controls onTimeUpdate={handleTimeUpdate} playsInline className='session-audio hidden' onLoadedMetadata={handleLoadMetaData}/>}

      <SessionMetaBar page={page} className='z-10 relative' click={!!audioUrl} ref={metaBarRef}/>

      <div className='z-10 w-full col-span-full h-[45vh] flex items-center overflow-hidden upMd:hidden' ref={mobileBtnWrapRef}>
        <Button className='w-auto mx-auto' px='px-2' py='py-2 relative' onClick={handleMobileClick}>
          <span>Play Audio</span>
        </Button>
      </div>

      <div className='col-start-4 col-span-4 z-10 relative text-14 md:col-span-full' {...textEvents} ref={textRef}>
        <RichContent content={transcript} className='[&_p]:mb-16 [&_p:last-child]:mb-0 [&_strong]:font-550 [&_strong]:tracking-slight leading-[1.2em]' />
      </div>

      <SessionCursor text={audioState.isPlaying ? 'pause audio' : 'play audio'} show={!mouseOverText && !!audioUrl && cursorVisible} className='uppercase text-12 tracking-slight font-550'/>
    </Section>
  )
}

export function SessionUpNext ({ upNext }) {
  const upNextImage = upNext?.images?.featureImage

  return (
    <Link link={upNext} showText={false}>
      <Section grid noBottomMargin className='bg-black h-[30vh] relative overflow-hidden group'>
        {upNextImage && <ResponsiveImage image={upNextImage} className='res-img !absolute inset-0 w-full h-screen upMd:h-[150vh] z-1' />}
        <SessionMetaBar page={upNext} className='z-10 relative self-end' click={false} />
        <div className='z-10 relative uppercase font-550 tracking-slight text-12 py-3 w-full col-start-4 col-span-4 border border-white text-center self-start overflow-hidden group-hover:text-black transition-colors ease-out-expo duration-600'>
          open next session
          <div className='absolute inset-0 bg-white -z-1 translate-y-full group-hover:translate-y-0 transition-transform ease-out-expo duration-600'/>
        </div>
      </Section>
    </Link>
  )
}

export const SessionMetaBar = forwardRef(({ isSessionListing = false, page, className, onClick }, ref) => {
  return (
    <Section width='w-full' noGutter grid noBottomMargin className={cn('col-span-full text-12', className)} ref={ref} onClick={onClick}>
      <div className={cn('col-span-2 text-right uppercase font-550 tracking-slight font-feature-zero md:col-span-full md:text-left self-start md:mb-4', isSessionListing && 'md:text-10 md:flex md:gap-7 md:mb-0')}>
        <span>
          {page.articleFields?.code}
          <span className='inline-block w-[0.8em] h-[0.8em] rounded-full bg-current ml-2'/>
        </span>
        {isSessionListing && <div className='uppercase font-550 tracking-slight col-start-9 md:col-span-full hidden md:block'>
          {page?.articleFields?.interviewee}
        </div>}
      </div>

      <h2 className={cn('col-start-4 col-span-4 mb-16 uppercase font-550 tracking-slight leading-none md:col-start-1 md:col-span-4 max-w-[30em] md:mb-4', isSessionListing && 'md:!mb-0 md:text-16 md:col-span-full md:max-w-full')}>{page.title}</h2>

      <div className={cn('uppercase font-550 tracking-slight col-start-9 md:col-span-full', isSessionListing && 'md:hidden')}>
        {page?.articleFields?.interviewee}
      </div>

      <div className={cn('uppercase font-550 tracking-slight col-start-10 col-span-2 text-right md:row-start-2', isSessionListing && 'md:hidden')}>
        {page?.articleFields?.category?.title}
      </div>

    </Section>
  )
})

export const SessionCursor = ({ text, show = false, className }) => {
  const cursorRef = useRef()
  const localsRef = useRef({})
  const [isOffScreen, setOffScreen] = useState(false)
  const mounted = useIsMounted()

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current

    localsRef.current.windowWidth = window.innerWidth
    localsRef.current.textWidth = cursor.getBoundingClientRect().width

    gsap.to(cursor.children[0], {
      scale: show ? 1 : 0,
      duration: 0.3,
      ease: 'expo.out'
    })
    return () => {
      gsap.to(cursor.children[0], {
        scale: 0,
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  }, [show])

  useEffect(() => {
    const loop = () => {
      setOffScreen(cursorData.last.x > localsRef.current.windowWidth - localsRef.current.textWidth - 20)
    }
    gsap.ticker.add(loop)
    return () => {
      gsap.ticker.remove(loop)
    }
  }, [])

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current
    gsap.to(cursor.children[0], {
      x: isOffScreen ? '-100%' : 0,
      transformOrigin: isOffScreen ? '100% 0' : '0 0',
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [isOffScreen])

  if (!mounted) return

  return createPortal(
    <div ref={cursorRef} className={cn('pointer-events-none fixed top-2 whitespace-nowrap left-2 md:hidden')}>
      <div className={className}>{text}</div>
    </div>,
    document.getElementById('cursor')
  )
}
