import { useIsAuthenticated } from '../Shop/accountState'
import { UserProfile } from '../UserProfile'
import ActivateAccountPage from './ActivateAccountForm'
import CreateAccount from './CreateAccount'
import ResetPasswordForm from './ResetPasswordForm'

export default function Forms ({ data, page }) {
  const authenticated = useIsAuthenticated()

  if (data.formType === 'accountForm' && !authenticated) {
    return <CreateAccount data={data} page={page} />
  }
  if (data.formType === 'accountForm' && authenticated) {
    return <UserProfile data={data} page={page} />
  }
  if (data.formType === 'resetPasswordForm') {
    return <ResetPasswordForm data={data} page={page} />
  }
  if (data.formType === 'activateAccount') {
    return <ActivateAccountPage data={data} page={page} />
  }
  console.error('Cannot find the form', data.formType)
  return null
}
