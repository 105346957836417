'use client'

import builder from '@sanity/image-url'
import get from 'lodash/get'
import Head from 'next/head'
import React, { useMemo } from 'react'
import { resolveInternalLinkUrl } from '../lib/resolveLink'

export const imageBuilder = builder(null)
  .projectId(process.env.NEXT_PUBLIC_SANITY_PROJECT_ID)
  .dataset(process.env.NEXT_PUBLIC_SANITY_DATASET)

export const useMetaData = (site, page) => {
  const { seo = {}, title: pageTitle } = page
  const { siteTitle, siteUrl: baseUrl } = site

  const metadata = useMemo(() => {
    const defaultCanonicalUrl = `${baseUrl}${resolveInternalLinkUrl(page)}`
    let title = pageTitle || siteTitle
    if (title !== siteTitle) {
      title = `${title} | ${siteTitle}`
    }
    return {
      ...(seo || {}),
      ...{
        meta_title: seo?.meta_title || pageTitle,
        og_url: defaultCanonicalUrl,
        canonical_url: defaultCanonicalUrl,
        title,
        category: seo.category || (page._type === 'product' ? 'product' : page.pageType)
      }
    }
  }, [page])

  return metadata
}

export default function MetaData ({ site, page, variations }) {
  const { siteTitle, siteUrl: baseUrl } = site
  const { seo = {} } = page

  const metaImage = get(seo, ['meta_image', 'asset'])
  const imageUrl = metaImage && imageBuilder.image(metaImage).width(1200).height(630).url()

  const metadata = useMetaData(site, page)

  const metaDesc = metadata.meta_description
  const metaTitle = metadata.meta_title

  return (
    <Head>
      <title>{metadata.title}</title>
      {variations && variations.map((page) => {
        const hrefLang = `${page.site.lang}-${page.site.countryCode}`
        return (
          <link
            key={hrefLang}
            rel='alternate'
            hrefLang={hrefLang}
            href={`${baseUrl}${resolveInternalLinkUrl(page)}`}
            data-rh='true'
          />
        )
      })}
      <meta property='og:site_name' content={siteTitle} />
      <meta name='twitter:site' content={siteTitle} />
      {metadata.meta_keywords && <meta name='keywords' content={metadata.meta_keywords} />}
      {metadata.og_url && <meta property='og:url' content={metadata.og_url} />}
      {metaTitle && <meta property='title' content={metaTitle} />}
      {metaTitle && <meta property='og:title' content={metaTitle} />}
      {metaTitle && <meta name='twitter:title' content={metaTitle} />}
      {metaDesc && <meta name='description' content={metaDesc} />}
      {metaDesc && <meta name='og:description' content={metaDesc} />}
      {metaDesc && <meta name='twitter:description' content={metaDesc} />}
      {metaImage && <meta property='og:image' content={imageUrl} />}
      {metaImage && <meta property='og:image:width' content={1200} />}
      {metaImage && <meta property='og:image:height' content={630} />}
      {metaImage && <meta name='twitter:image' content={imageUrl} />}

      <link rel='canonical' href={metadata.canonical_url} />

      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='manifest' href='/site.webmanifest' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#000' />
    </Head>
  )
}
