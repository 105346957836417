import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { clamp, mapRange } from '../../../helpers/math'
import useLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import useScrollPosition from '../../../hooks/useScrollPosition'
import { SCENES, useShowSceneCallback } from '../../Canvas/sceneState'
import Link from '../../Link'
import Section from '../../Section'
import { SmoothScrollerContext } from '../../SmoothScroller'
import { SessionCursor, SessionMetaBar } from './SessionsHero'

export default function SessionListing ({ data }) {
  const { sessions } = data

  const ref = useRef()
  const [index, setIndex] = useState(0)
  const showScene = useShowSceneCallback()
  const [showCursor, setShowCursor] = useState(false)
  const scrollDownRef = useRef()

  useEffect(() => {
    showScene(SCENES.SESSIONS_LISTING, { session: data.sessions[index], sessions: data.sessions })
    return () => showScene(SCENES.NONE)
  }, [data, showScene, index])

  const setMetbarProgress = useCallback(({ progress }) => {
    if (!ref.current) return
    const length = sessions.length
    const els = gsap.utils.toArray(ref.current.children)
    els.forEach((section, i) => {
      const el = section.children[0].children[0]
      const sectionProgress = clamp(mapRange(1 / length * i, (i + 1) / length, progress, 0, 1), 0, 1)

      const isStart = i === 0
      const isEnd = i + 1 === length

      const yProgress = progress * -1 * (160 * (length - 1)) + (160 * i)

      el.style.transform = `translate3d(0px, ${yProgress}px, 0px)`
      el.style.opacity = gsap.utils.interpolate([isStart ? 1 : 0.2, 1, isEnd ? 1 : 0.2], sectionProgress)
    })
    gsap.to(scrollDownRef.current, { opacity: progress > 0.1 ? 0 : 1 })
  }, [sessions.length])

  useLayoutEffect(() => setMetbarProgress({ progress: 0 }), [])
  useScrollPosition(setMetbarProgress)

  const bgEvents = {
    onMouseEnter: primaryInput === 'touch' ? null : () => setShowCursor(true),
    onMouseLeave: primaryInput === 'touch' ? null : () => setShowCursor(false)
  }

  return (
    <Section width='w-full relative' noBottomMargin>
      <div ref={ref} {...bgEvents}>
        {sessions?.map((session, i) => (
          <SessionSection key={session._id} sessions={sessions} session={session} index={i} setIndex={setIndex} />
        ))}
      </div>

      <div ref={scrollDownRef} className='bottom-0 flex justify-center items-center fixed w-full p-8 text-10 uppercase opacity-100'>Scroll Down</div>
      <SessionCursor text='open session' show={showCursor} className='text-12 tracking-slight uppercase font-550'/>
    </Section>
  )
}

function SessionSection ({ sessions, session, index, setIndex }) {
  const metabarRef = useRef()
  const scrollContext = useContext(SmoothScrollerContext)

  const onClick = (e) => {
    const opacity = metabarRef?.current?.style?.opacity
    if (opacity > 0.3) return

    e.preventDefault()

    const lenis = scrollContext.current.lenis
    const height = lenis.contentHeight
    const vh = lenis.wrapperHeight
    const progress = index / (sessions.length - 1)

    lenis.scrollTo(height * progress - (vh / 2))
  }

  return (
    <Link link={session} onMouseEnter={() => setIndex(index)} showText={false}>
      <Section grid width='w-full' noBottomMargin className='relative h-[150vh] md:h-screen'>
        <SessionMetaBar ref={metabarRef} page={session} isSessionListing className='fixed top-1/2 md:top-[45vh] left-4 right-4 z-10 w-auto' onClick={onClick}/>
      </Section>
    </Link>
  )
}
