import { useFrame } from '@react-three/fiber'
import { primaryInput } from 'detect-it'
import { useAtomValue, useSetAtom } from 'jotai'
import first from 'lodash/first'
import last from 'lodash/last'
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { lerp } from 'three/src/math/MathUtils'
import { CustomScrollerContext, useScrollPosition } from '../CustomScroller'
import { canvasStateAtom } from '../canvasState'
import { useIsMobile, useViewport } from '../hooks'
import { SCENES, sceneAtom, wineriesSceneAtom } from '../sceneState'
import Slide from './Slide'

const ROW_GAP = 0.06
const ROW_GAP_MOBILE = 0.05

const getGap = (isMobile, viewport) => {
  return (isMobile ? ROW_GAP_MOBILE : ROW_GAP) * viewport.width
}

const useSceneEffect = (enabled, layoutRef, z = 0) => {
  const isMobile = useIsMobile()
  const localRef = useRef({ distortion: 0 })
  const { sceneEffect } = useAtomValue(canvasStateAtom)

  useFrame(() => {
    if (!sceneEffect || !enabled) return
    sceneEffect.distortXEnabled = 0
    const waveIntensity = isMobile ? -0.03 : -0.07
    sceneEffect.waveIntensity = lerp(sceneEffect.waveIntensity, waveIntensity - localRef.current.distortion, 0.1)
    layoutRef.current.position.z = lerp(layoutRef.current.position.z, z - (localRef.current.distortion * 25), 0.2)
  }, 1)

  useScrollPosition(useCallback(({ target, scroll }) => {
    if (!sceneEffect || !enabled) return
    const velocity = target.x + scroll.x
    localRef.current.distortion = Math.min(0.11, Math.abs(velocity) / 45)
  }, [sceneEffect, enabled]))

  useEffect(() => {
    if (!enabled && sceneEffect) {
      sceneEffect.waveIntensity = 0
      sceneEffect.distortXEnabled = 0
    }
  }, [sceneEffect, enabled])
}

export default function WineryListing ({ show = true }) {
  const layoutRef = useRef()
  const wineries = useAtomValue(sceneAtom)?.data[SCENES.WINERY_LISTING]
  const viewport = useViewport()
  const scroller = useContext(CustomScrollerContext)
  const isMobile = useIsMobile()
  const [z, setZ] = useState(0)
  const setWineriesScene = useSetAtom(wineriesSceneAtom)

  const { width, height, aspect } = useMemo(() => {
    if (isMobile) {
      const aspect = 0.612
      const width = viewport.width * 0.7
      const height = Math.min(width / aspect, viewport.height * 0.7)
      return {
        width,
        aspect,
        height
      }
    } else {
      const width = viewport.width * 0.6
      const aspect = 2.38
      const height = Math.min(width / aspect, viewport.height * 0.7)
      return {
        width,
        aspect,
        height
      }
    }
  }, [isMobile, viewport])

  useLayoutEffect(() => {
    if (!wineries) return
    const slideWidth = (width + getGap(isMobile, viewport))

    layoutRef.current.children.forEach((slide, i) => {
      slide.position.x = (i - 1) * slideWidth
    })

    scroller.current.max.x = (slideWidth * (wineries.length - 1)) + (slideWidth / 2)
    scroller.current.min.x = -slideWidth / 2
    scroller.current.loop.x = 1
    scroller.current.wheelDirection = 'x'

    scroller.current.dragMultiplier = primaryInput === 'touch' ? 0.04 : 0.008
    setWineriesScene({
      scroller,
      slideWidth
    })
  }, [isMobile, scroller, setWineriesScene, viewport, width, wineries])

  useSceneEffect(show, layoutRef, z)

  useEffect(() => {
    let downTimeout = null
    const mouseDown = () => {
      downTimeout = setTimeout(() => { setZ(-0.35) }, 200)
    }
    const mouseUp = () => {
      window.clearTimeout(downTimeout)
      setZ(0)
    }
    const canvasElement = window.document.getElementById('canvas')
    canvasElement.addEventListener('mousedown', mouseDown)
    canvasElement.addEventListener('mouseup', mouseUp)
    return () => {
      canvasElement.removeEventListener('mousedown', mouseDown)
      canvasElement.removeEventListener('mouseup', mouseUp)
    }
  }, [])

  const getVideo = (winery, isMobile) => {
    const prop = isMobile ? 'mobileVideo' : 'video'
    return winery[prop] || winery.video
  }

  return (
    <group ref={layoutRef} name='page-content'>
      <Slide link={last(wineries)} logo={last(wineries).logo} video={getVideo(last(wineries), isMobile)} image={last(wineries).images.featureImage} width={width} height={height} aspect={aspect} />
      {wineries?.map((winery, i) => <Slide key={i} link={winery} logo={winery.logo} video={getVideo(winery, isMobile)} image={winery.images.featureImage} width={width} height={height} aspect={aspect} />)}
      <Slide link={first(wineries)} logo={first(wineries).logo} video={getVideo(last(wineries), isMobile)} image={first(wineries).images.featureImage} width={width} height={height} aspect={aspect} />
    </group>
  )
}
