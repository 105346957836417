import pixelToViewport from '../../../helpers/pixelToViewport'
import { useViewport } from '../../../hooks'
import ArticleMeta from './ArticleMeta'
import ProductMeta from './ProductMeta'
import WineryMeta from './WineryMeta'

export default function Meta ({ tile, tileWidth, tileHeight, metadataWidth }) {
  const {
    _type,
    pageType
  } = tile
  const viewport = useViewport()

  if (_type === 'product') {
    return <ProductMeta
      product={tile}
      width={(metadataWidth * tileWidth)}
      height={tileHeight}
    />
  }

  if (_type === 'page' && pageType === 'winery') {
    return <WineryMeta winery={tile} width={tileWidth} height={tileHeight} />
  }

  return (
    <ArticleMeta
      article={tile}
      width={metadataWidth * tileWidth}
      position={[-(metadataWidth * tileWidth) / 2, (-tileHeight / 2) - pixelToViewport(4, viewport), 0]}
    />
  )
}
