import compact from 'lodash/compact'
import minBy from 'lodash/minBy'
import sum from 'lodash/sum'
import { useMemo } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { getProductPriceWithDiscount, getProductSubscriptionSellingPlan } from '../../../helpers/product'

export const productPriceFormatted = (product, quantity = 1) => {
  if (product.productCategory === 'bundle') {
    if (product?.bundle?.details.priceLabel) {
      return product?.bundle?.details.priceLabel
    }

    const bundleProducts = compact(product?.bundle?.products)
    if (product.bundle.details.bundleType === 'dynamic') {
      const cheapestProduct = minBy(bundleProducts, p => p.priceRange?.minVariantPrice)
      const productPrice = getProductPriceWithDiscount(cheapestProduct, getProductSubscriptionSellingPlan(cheapestProduct)).totalAmount
      return `From ${formatCurrency(productPrice * product.bundle.details.productCount)}`
    }
    return formatCurrency(sum(bundleProducts.map((p) => {
      return getProductPriceWithDiscount(p, getProductSubscriptionSellingPlan(p)).totalAmount
    })))
  }
  return formatCurrency(product?.priceRange?.minVariantPrice * quantity)
}

const useProductPriceFormatted = (product, quantity = 1) => {
  return useMemo(() => {
    return productPriceFormatted(product, quantity)
  }, [product, quantity])
}

export default useProductPriceFormatted
