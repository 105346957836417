import { useCallback, useEffect, useRef, useState } from 'react'
import { ButtonWithSpinner } from '../Button'
import Link from '../Link'
import { useAccountActions } from '../Shop/useAccountActions'
import { useLabels, useSite } from '../siteState'
import Footer from './Footer'
import Input from './Input'

const InputContainer = ({ name, label, ...props }) => {
  return (
    <div className='relative w-full flex items-center mb-4'>
      <label htmlFor='name' className='absolute text-10 md:text-12 w-20 pointer-events-none uppercase font-550'>{label}</label>
      <Input name={name} className='pl-20 text-10 md:text-12' mb='mb-0' {...props} />
    </div>
  )
}

export default function Login () {
  const ref = useRef()
  const { login } = useAccountActions()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const { accountLink, resetPasswordLink } = useSite()
  const { createAccountLabel, resetPasswordLabel, loginLabel, emailLabel, passwordLabel } = useLabels()

  const onFormSubmit = useCallback(async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage(null)
    try {
      const url = new URL(window.location.href.replace('reset-password', 'account'))
      // This will skip the intro when the users gets redirected back to the site
      url.hash = '#skip'
      await login({ email, password, returnUrl: url.toString() })
      // We leave the loading on and also the view as we are going to redirect
      // to the shopify to login to shopify as well
    } catch (e) {
      setLoading(false)
      setErrorMessage('Invalid email or password')
      console.error('error', e)
    }
  }, [login, email, password])

  const onEmailChange = useCallback((e) => { setEmail(e.target.value) }, [])
  const onPasswordChange = useCallback((e) => { setPassword(e.target.value) }, [])

  useEffect(() => {
    const el = ref.current.querySelector('input[name="email"]')
    if (!el) return
    el.focus()
  }, [])

  return (
    <div className='p-navPadding border-t border-border' ref={ref}>
      <form onSubmit={onFormSubmit} className='my-16'>
        <InputContainer autofocus name='email' type='email' value={email} onChange={onEmailChange} autocomplete='username' required label={emailLabel || 'Email'} />
        <InputContainer name='password' type='password' value={password} onChange={onPasswordChange} autocomplete='current-password' required label={passwordLabel || 'Password'} />
        {errorMessage && <div className='text-error text-10 text-12 mb-4'>{errorMessage}</div>}
        <div className='flex flex-col justify-start items-start'>
          <ButtonWithSpinner type='submit' className='mb-2' loading={loading} filled invert disabled={loading} border='border-none'>{loginLabel}</ButtonWithSpinner>
          <div className='flex justify-between w-full uppercase mt-4'>
            <Link className='text-10 md:text-12 opacity-50 hover:opacity-100' link={accountLink} showText={false}>{createAccountLabel}</Link>
            <Link className='text-10 md:text-12 opacity-50 hover:opacity-100' link={resetPasswordLink} showText={false}>{resetPasswordLabel}</Link>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  )
}
