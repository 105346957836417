import compact from 'lodash/compact'
import { useMemo } from 'react'

import ErrorBoundary from '../ErrorBoundary'
import Forms from '../Forms'
import About from './About'
import AnchorSlice from './AnchorSlice'
import ArticleHero from './Articles/ArticleHero'
import ArticleHotspot from './Articles/ArticleHotspot'
import ArticleQuote from './Articles/ArticleQuote'
import ArticleText from './Articles/ArticleText'
import ArticleUpNext from './Articles/ArticleUpNext'
import ArticleVideo from './Articles/ArticleVideo'
import Dropping from './Dropping'
import ErrorPage from './ErrorPage'
import FeaturedWinery from './FeaturedWinery'
import Feed from './Feed'
import Illuminate from './Illuminate'
import ImageCarousel from './ImageCarousel'
import ProductHero from './ProductHero'
// import ProductObjectHero from './ProductHero/ProductObjectHero'
import ProductListing from './ProductListing'
import ProductNotes from './ProductNotes'
import ProductText from './ProductText'
import ProductWineCode from './ProductWineCode'
import ProductWinery from './ProductWinery'
import Quote from './Quote'
import RelatedPages from './RelatedPages'
import SessionsHero from './Sessions/SessionsHero'
import SessionListing from './Sessions/SessionsListing'
import SubscriptionListing from './SubscriptionListing'
import SubscriptionOverview from './SubscriptionListing/SubscriptionOverview'
import SubscriptionPacks from './SubscriptionListing/SubscriptionPacks'
import SupportSlice from './SupportSlice'
import { RichTextSlice } from './SupportSlice/RichText'
import TextRows from './TextRows'
import WineryHero from './WineryHero'
import { WineryListing } from './WineryListing'
import ObjectProductBio from './ObjectProductBio'
import RichTextWithMedia from './RichTextWithMedia'

const sliceComponentSelector = {
  feedSlice: Feed,
  productHero: ProductHero,
  // objectProductHero: ProductObjectHero,
  productNotes: ProductNotes,
  productIncludes: ProductNotes, // uses the same slice as notes
  productWinery: ProductWinery,
  anchorSlice: AnchorSlice,
  productWineCode: ProductWineCode,
  productText: ProductText,
  relatedPages: RelatedPages,
  imageCarousel: ImageCarousel,
  quote: Quote,
  productListing: ProductListing,
  subscriptionListing: SubscriptionListing,
  subscriptionOverview: SubscriptionOverview,
  subscriptionPacks: SubscriptionPacks,
  featuredWinery: FeaturedWinery,
  supportSlice: SupportSlice,
  errorPage: ErrorPage,
  wineryHero: WineryHero,
  wineryListing: WineryListing,
  form: Forms,
  illuminate: Illuminate,
  about: About,
  textRows: TextRows,
  richText: RichTextSlice,
  sessionsListing: SessionListing,
  sessionsHero: SessionsHero,
  articleHero: ArticleHero,
  articleQuote: ArticleQuote,
  articleText: ArticleText,
  articleHotspot: ArticleHotspot,
  articleUpNext: ArticleUpNext,
  articleVideo: ArticleVideo,
  dropping: Dropping,
  objectProductBio: ObjectProductBio,
  richTextWithMedia: RichTextWithMedia
}

export default function Slices ({ page, slices }) {
  const sliceComponents = useMemo(() => {
    return compact(
      slices?.map((slice) => {
        const Component = sliceComponentSelector[slice._type]

        if (!Component) {
          return (
            <section
              key={slice._key}
              className='flex justify-center items-center h-24 mb-8 relative'
            >
              <div className='absolute inset-0 bg-green animate-pulse' />
              <div className='relative font-mono text-24'>
                The slice <strong>{slice._type}</strong> is missing
              </div>
            </section>
          )
        }

        return (
          <ErrorBoundary key={`slice-${page._id}-${slice._key}`}>
            <Component data={slice} page={page} />
          </ErrorBoundary>
        )
      })
    )
  }, [slices, page])

  return sliceComponents
}
