import forEach from 'lodash/forEach'
import React, { useCallback } from 'react'
import * as THREE from 'three'
import Block from './Block'

const isText = (element) => !!element.font

const Stack = ({ gap, anchorY = 'top', ...props }) => {
  const onReflow = useCallback((element) => {
    const gap = element.gap || 0
    let offsetTop = 0
    forEach(element.children, child => {
      child.position.y = offsetTop
      if (isText(child)) {
        if (child.textRenderInfo) {
          const height = Math.abs(child.textRenderInfo.blockBounds[3] - child.textRenderInfo.blockBounds[1])
          offsetTop -= (height + gap)
        }
      } else {
        const bbox = new THREE.Box3().setFromObject(child)
        const height = Math.abs(bbox.min.y - bbox.max.y)
        offsetTop -= (height + gap)
      }
    })
    const totalHeight = Math.abs(offsetTop) - gap
    if (anchorY === 'center') {
      forEach(element.children, child => {
        child.position.y += totalHeight / 2
      })
    }
    if (anchorY === 'bottom') {
      forEach(element.children, child => {
        child.position.y += totalHeight
      })
    }
  }, [anchorY])
  return (
    <Block uiType='stack' gap={gap} onReflow={onReflow} {...props} />
  )
}

export default Stack
