import { atom, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useEffect } from 'react'

export const menuOpenAtom = atom(false)
export const menuIsDarkAtom = atom(false)
export const hideMenuAtom = atom(false)

export const NAV_VIEWS = {
  nav: 'nav',
  cart: 'cart',
  giftMessage: 'gift-message',
  login: 'login',
  search: 'search',
  newsletter: 'newsletter'
}

export const navViewAtom = atom(NAV_VIEWS.nav)
export const navViewDataAtom = atom()

export const MENU_THEMES = {
  none: null,
  dark: 'dark',
  light: 'light'
}
export const menuOverrideThemeAtom = atom(MENU_THEMES.none)

export const SECONDARY_MENUS = {
  none: 'none',
  home: 'home',
  product: 'product',
  simpleProduct: 'simpleProduct',
  shopFilter: 'shopFilter',
  membersOnly: 'membersOnly',
  jumpTo: 'jumpTo',
  sessions: 'sessions',
  article: 'article',
  dropping: 'dropping',
  geoPopup: 'geoPopup'
}

// export const showSecondMenuAtom = atom(true)
export const secondMenuOpenAtom = atom(false)
export const secondMenuTypeAtom = atom(SECONDARY_MENUS.none)

export const showSecondMenuAtom = selectAtom(
  secondMenuTypeAtom,
  type => {
    return type !== SECONDARY_MENUS.none && type !== SECONDARY_MENUS.home && type !== SECONDARY_MENUS.article
  }
)

export const geoPopupAtom = atom(null)
export const geoPopupDismissedAtom = atom(false)

export function useMenuHash () {
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const setMenuView = useSetAtom(navViewAtom)

  function checkHash () {
    const hash = window.location.hash

    switch (hash) {
      case '#openMenu':
        setMenuOpen(true)
        setMenuView(NAV_VIEWS.nav)
        break
      case '#openCart':
        setMenuOpen(true)
        setMenuView(NAV_VIEWS.cart)
        break
      case '#openLogin':
        setMenuOpen(true)
        setMenuView(NAV_VIEWS.login)
        break
      case '#newsletter':
        setMenuOpen(true)
        setMenuView(NAV_VIEWS.newsletter)
        break
    }
  }

  useEffect(() => {
    checkHash()
    window.addEventListener('hashchange', checkHash)
    return () => window.removeEventListener('hashchange', checkHash)
  }, [])
}
