import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { useKeenSlider } from 'keen-slider/react'
import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import { useCallback, useMemo } from 'react'
import breakpoints from '../../../theme/breakpoints'
import { useSelectItemTracker, useTrackProductImpressionInView } from '../../gtm'
import { TRACKING_LIST_TYPES, TrackingContextProvider } from '../../gtm/TrackingContext'
import { formatCurrency } from '../../helpers/currencyFormatters'
import { formatDate } from '../../helpers/date'
import { span } from '../../helpers/grid'
import { leftPad } from '../../helpers/math'
import { isInStock, useIsReleased } from '../../helpers/product'
import { isMobileAtom } from '../../state/isMobile'
import Link from '../Link'
import ArrowIcon from '../Menu/SecondaryNavs/ArrowIcon'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import { useLabels } from '../siteState'
import { BOTTLE_WIDTHS, BluredProductImage, Dropping } from './ProductListing/ProductTile'
import { Tile as BundleTile } from './SubscriptionListing/SubscriptionPacks'

const Tile = ({ item, i }) => {
  const selectItemTracker = useSelectItemTracker()
  const onLinkClick = useCallback(() => {
    selectItemTracker(item)
  }, [item, selectItemTracker])

  const isArticle = item.pageType === 'article'
  const isProduct = item._type === 'product'

  const isFullBleed = item?.fullBleedImage || isArticle || !item?.images?.featureImage
  const image = item.images?.feedImage || item.images?.featureImage
  const bgImage = item.images?.featuredBgImage

  const isMobile = useAtomValue(isMobileAtom)
  const isBundleProduct = item?.productCategory === 'bundle'

  const isReleased = useIsReleased(item)
  const inStock = isInStock(item)
  const { soldOutLabel } = useLabels()

  const getWidth = () => {
    if (isBundleProduct) {
      return {
        md: span(4, 'md'),
        sm: '80%'
      }
    }
    if (i === 0) {
      return {
        md: span(4, 'md'),
        sm: '80%'
      }
    }
    return {
      md: span(3, 'md'),
      sm: '50%'
    }
  }

  const width = getWidth()
  const ref = useTrackProductImpressionInView(item, i)

  const showBlured = (!inStock || !isReleased) && isProduct

  return (
    <div
      className='keen-slider__slide group self-start'
      style={{
        minWidth: isMobile ? width.sm : width.md,
        maxWidth: isMobile ? width.sm : width.md
      }}
      ref={ref}
    >
      {!isBundleProduct && <Link link={item} showText={false} onClick={onLinkClick}>
        {!showBlured && isFullBleed && image && <div className='aspect-[340/453] h-auto flex flex-col w-full relative overflow-hidden'>
          <ResponsiveImage image={image} aspect={459 / 612} className='upMd:group-hover:scale-110 transition-transform duration-600 ease-out-expo' />
        </div>}
        {!showBlured && !isFullBleed && item?.images?.featureImage && <div className='aspect-[340/453] h-auto flex flex-col w-full relative overflow-hidden py-[3.6vw] md:py-[10vw]'>
          <ResponsiveImage
            className={cn(
              'w-full h-full z-[2]',
              isFullBleed && 'upMd:group-hover:scale-110 transition-transform duration-600 ease-out-expo'
            )}
            image={item?.images?.featureImage}
            contain={!isFullBleed}
            showPreview={isFullBleed}
            imageSizes={`(max-width: ${breakpoints.md}px) 30vw, 12vw`}
            widths={BOTTLE_WIDTHS}
          />
          {bgImage && (
            <ResponsiveImage
              className='!absolute top-0 left-0 right-0 bottom-0 z-[1] scale-100 upMd:group-hover:scale-110 transition-transform duration-600 ease-out-expo'
              image={bgImage}
              imageSizes={`(max-width: ${breakpoints.md}px) 60vw, 30vw`}
            />)}
        </div>}
        {showBlured && <div className='aspect-[340/453] h-auto flex flex-col w-full relative overflow-hidden'>
          <BluredProductImage product={item}>
            {!inStock && <div className='font-550 text-10 tracking-slight uppercase'>{soldOutLabel}</div>}
            {!isReleased && <Dropping releasedDate={item?.releasedDate} />}
          </BluredProductImage>
        </div>}
        {isArticle && <ArticleMeta article={item} /> }
        {isProduct && <ProductMeta product={item} /> }
      </Link>}
      {isBundleProduct && <BundleTile product={item} hasImage/>}
    </div>
  )
}

export default function RelatedPages ({ data, page }) {
  const { pages, title } = data

  const [refCallback, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto',
        spacing: () => {
          const windowWidth = window?.innerWidth
          return windowWidth > 1440 ? windowWidth * 0.011111 : 16
        }
      }
    },
    []
  )

  const counts = useMemo(() => {
    const count = {
      wines: 0,
      articles: 0
    }

    forEach(compact(pages), page => {
      if (page?.pageType === 'article') {
        count.articles = count.articles + 1
      } else {
        count.wines = count.wines + 1
      }
    })

    return count
  }, [pages])

  const onPrevClick = useCallback(() => {
    instanceRef.current.prev()
  }, [instanceRef])
  const onNextClick = useCallback(() => {
    instanceRef.current.next()
  }, [instanceRef])

  return (
    <TrackingContextProvider list={TRACKING_LIST_TYPES.relatedProducts}>
      <Section noGutter noBottomMargin className='overflow-hidden px-4 relative z-[2] pb-20 md:pb-16'>
        <div className='flex justify-between items-center mb-4'>
          <div className='uppercase font-550 text-16'>{title}</div>
          <div className='flex items-center gap-10 md:gap-5'>
            {page.pageType === 'winery' && (
              <div className='flex gap-12'>
                {counts.wines > 0 && (
                  <div className='uppercase text-10 font-feature-zero'>
                    <span className='mr-2'>{leftPad(counts.wines, 2)}</span>
                    <span>Wines</span>
                  </div>
                )}
                {counts.articles > 0 && (
                  <div className='uppercase text-10 font-feature-zero'>
                    <span className='mr-2'>{leftPad(counts.articles, 2)}</span>
                    <span>Articles</span>
                  </div>
                )}
              </div>
            )}
            <div className='flex gap-2'>
              <button onClick={onPrevClick} className='rotate-90 w-5 h-10 flex justify-center items-center hover:opacity-50'><ArrowIcon width='w-3' height='h-auto' /></button>
              <button onClick={onNextClick} className='-rotate-90 w-5 h-10 flex justify-center items-center hover:opacity-50'><ArrowIcon width='w-3' height='h-auto' /></button>
            </div>
          </div>
        </div>

        <div ref={refCallback} className="keen-slider !overflow-visible cursor-grab !active:cursor-grabbing">
          {compact(pages)?.map((item, i) => <Tile i={i} item={item} key={item._id} />)}
        </div>
      </Section>
    </TrackingContextProvider>
  )
}

const smallTextClasses = 'text-12 font-550 uppercase leading-none font-feature-zero'

export function ArticleMeta ({ article, className }) {
  return (
    <>
      <div className={cn('mt-1 flex gap-8', className)}>
        <div className={smallTextClasses}>{article.category}</div>
        <div className={smallTextClasses}>{formatDate(article.postedDate)?.toUpperCase()}</div>
      </div>
      <div className={cn(smallTextClasses, 'font-serif !font-250')}>
        <span className={'font-550 font-sans'}>{article.title}{article.subtitle && ': '}</span>
        {article.subtitle && <span>{article.subtitle}</span>}
      </div>
    </>
  )
}

export function ProductMeta ({ product, className }) {
  const { winery, range, priceRange, varietal, sales, yearVintage } = product
  const price = priceRange?.minVariantPrice

  const { nonVintageText } = useLabels()

  if (product?.productCategory === 'object') {
    return (
    <div className={cn('mt-1 flex justify-between', className)}>
      <div className={smallTextClasses}>{product?.title}</div>
      <div className='text-right'>
        <div className={smallTextClasses}>{formatCurrency(price)}</div>
        <div className={cn(smallTextClasses, '!font-250 font-serif')}>{sales}</div>
      </div>
    </div>
    )
  }

  return (
    <div className={cn('mt-1 flex justify-between', className)}>
      <div>
        <div className={smallTextClasses}>{winery}</div>
        <div className={smallTextClasses}>{range}</div>
      </div>
      <div>
        <div className={smallTextClasses}>{varietal}</div>
        <div className={smallTextClasses}>{yearVintage || nonVintageText}</div>
      </div>
      <div className='text-right'>
        <div className={smallTextClasses}>{formatCurrency(price)}</div>
        <div className={cn(smallTextClasses, '!font-250 font-serif')}>{sales}</div>
      </div>
    </div>
  )
}
