import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { useSite, useSiteId } from '../siteState'
import { accountAtom, ACCOUNT_STATES, useAccessTokenAtom } from './accountState'
import fetchRequest from './fetchRequest'

export function useAccountActions () {
  const siteId = useSiteId()
  const site = useSite()
  const accessTokenAtom = useAccessTokenAtom()
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
  const setAccount = useSetAtom(accountAtom)

  const login = useCallback(
    async (data) => {
      setAccount(account => ({
        ...account,
        state: ACCOUNT_STATES.loading
      }))

      const result = await fetchRequest(
        `/api/${siteId}/account`,
        'POST',
        data
      )

      // We set the account token into local storage then redirect to the multipass url
      setAccessToken({
        accessToken: result.accessToken,
        expiresAt: result.expiresAt
      })

      window.location.href = result.multipassUrl
    },
    [setAccessToken, setAccount, siteId]
  )

  const clear = useCallback(async () => {
    setAccount({
      customer: null,
      authenticated: false,
      state: ACCOUNT_STATES.ready
    })
    setAccessToken(null)
  }, [setAccessToken, setAccount])

  const logout = useCallback(async () => {
    await clear()
    window.location.href = `https://${site.shopify.domain}/account/logout`
  }, [clear, site.shopify.domain])

  const createAccount = useCallback(
    async (data) => {
      await fetchRequest(
        `/api/${siteId}/customer`,
        'POST',
        data
      )
    },
    [siteId]
  )

  const getAccount = useCallback(
    async () => {
      setAccount(account => ({
        ...account,
        state: ACCOUNT_STATES.loading
      }))

      const result = await fetchRequest(
        `/api/${siteId}/account?returnUrl=${encodeURI(window.location.href)}`,
        'GET',
        null,
        {
          Authorization: accessToken.accessToken
        }
      )

      setAccount(acc => ({
        ...acc,
        customer: result,
        authenticated: true,
        state: ACCOUNT_STATES.ready
      }))
    },
    [setAccount, siteId, accessToken]
  )

  const recover = useCallback(async (email) => {
    return await fetchRequest(
      `/api/${siteId}/account/recover`,
      'POST',
      {
        email
      }
    )
  }, [siteId])

  const resetPassword = useCallback(async ({ password, resetUrl }) => {
    return await fetchRequest(
        `/api/${siteId}/account/reset`,
        'POST',
        {
          password,
          resetUrl
        }
    )
  }, [siteId])

  const activateAccount = useCallback(async ({ password, activationUrl, returnUrl }) => {
    const result = await fetchRequest(
        `/api/${siteId}/account/activate`,
        'POST',
        {
          password,
          activationUrl,
          returnUrl
        }
    )
    setAccessToken({
      accessToken: result.accessToken,
      expiresAt: result.expiresAt
    })

    window.location.href = result.multipassUrl
  }, [setAccessToken, siteId])

  return useMemo(() => ({
    login,
    logout,
    createAccount,
    getAccount,
    clear,
    recover,
    resetPassword,
    activateAccount
  }), [clear, getAccount, createAccount, login, logout, recover, resetPassword, activateAccount])
}
