import { useAtomValue, useSetAtom } from 'jotai'
import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { formatDate } from '../../../helpers/date'
import Link from '../../Link'
import LoadingTicker from '../../LoadingTicker'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent from '../../RichContent'
import { useLabels, useSite } from '../../siteState'
import { searchIndexAtom, searchLoadingAtom, searchQueryAtom, searchResultsAtom } from './searchState'

const ProductMetadata = ({ item }) => {
  const { title, winery, range, varietal, yearVintage, priceRange, productCategory, formattedPrice } = item
  if (productCategory === 'wine') {
    return (
      <div className='flex w-full gap-2'>
        <div className='flex-grow w-1/2'>
          <div>{winery}</div>
          <div>{range}</div>
        </div>
        <div className='flex-grow w-1/2'>
          <div>{varietal}</div>
          <div>{yearVintage}</div>
        </div>
        <div className='flex-shrink-0'>
          {formatCurrency(priceRange?.minVariantPrice)}
        </div>
      </div>
    )
  }
  return (
    <>
      <div>
        {title}
      </div>
      <div>
        {formattedPrice}
        {/* {formatCurrency(priceRange?.minVariantPrice)} */}
      </div>
    </>
  )
}

const PageMetadata = ({ item }) => {
  const { title, pageType, articleType, postedDate, subtitle } = item
  const { winePageSearchLabel } = useLabels()
  const wineryLabel = winePageSearchLabel || 'winery page'
  const isWinery = pageType === 'winery'
  
  if (pageType === 'article') {
    return (
      <div className='w-full'>
        <div className='flex gap-x-2 mb-1'>
          <div className='w-1/2'>{articleType}</div>
          <div className='w-1/2'>{formatDate(postedDate)}</div>
        </div>
        <div className='w-full'>{title}{subtitle && <span className='font-serif font-250'>; {subtitle}</span>}</div>
      </div>
    )
  }
  return (
    <div>
      {title}
      {subtitle || isWinery && <span className='font-serif font-250'>; {subtitle || wineryLabel}</span>}
    </div>
  )
}

const Item = ({ item }) => {
  const { _type, image, productCategory } = item
  const containImage = productCategory === 'wine'
  return (
    <Link link={item} showText={false} className='mb-4 block hover:opacity-80 transition-opacity'>
      <article className='flex'>
        {image && <ResponsiveImage image={image} aspect={48 / 64} className='w-12 shrink-0' contain={containImage} showPreview={!containImage} />}
        {!image && <div className='w-12 shrink-0 aspect-[48/64] bg-border' />}
        <div className='pl-2 flex items-center font-550 text-10 w-full uppercase justify-between'>
          {_type === 'product' && <ProductMetadata item={item} />}
          {_type !== 'product' && <PageMetadata item={item} />}
        </div>
      </article>
    </Link>
  )
}

export default function SearchResults () {
  const query = useAtomValue(searchQueryAtom)
  const results = useAtomValue(searchResultsAtom)
  const loading = useAtomValue(searchLoadingAtom)
  const setSearchIndex = useSetAtom(searchIndexAtom)
  const { search: { emptyResultsCopy } = { } } = useSite()

  const { hits, nbPages, page } = results || {}

  const isSearchSynced = query === results.query || (!query && !results.query)

  const empty = isEmpty(hits) && !!results.query
  const hasMoreResults = page < (nbPages - 1) && !empty && !isSearchSynced

  const { ref: loadingMoreRef, inView } = useInView({ threshold: 0 })
  useEffect(() => {
    if (inView && hasMoreResults && !loading) {
      setSearchIndex(page + 1)
    }
  }, [inView, hasMoreResults, page, setSearchIndex])

  return (
    <div className='pt-4 relative min-h-[14rem] md:max-h-[calc(100svh-120px)]'>
      <div className='mb-4'>
        {hits?.map((item) => (
          <Item key={item.slug} item={item} />
        ))}
      </div>
      {empty && (
        <RichContent content={emptyResultsCopy} className='absolute inset-0 text-12 uppercase text-center font-550 py-24' />
      )}
      {hasMoreResults && (
        <LoadingTicker ref={loadingMoreRef} showBackground={false} className='absolute bottom-0 left-0 right-0'>Loading</LoadingTicker>
      )}
    </div>
  )
}
