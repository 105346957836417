import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import { useAtomValue } from 'jotai'
import last from 'lodash/last'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { lerp } from 'three/src/math/MathUtils'
import useScrollPosition from '../../../hooks/useScrollPosition'
import useWindowSize from '../../../hooks/useWindowSize'
import transform from '../../ResponsiveImage/transform'
import { useIsMobile, useScreenToWorldPosition, useViewport } from '../hooks'
import ProductHeroImage from '../ProductHero/Image'
import { sceneAtom, SCENES } from '../sceneState'

function DeformedImage ({ image, ...props }) {
  const isErrorPage = props?.data?.isErrorPage || false
  const isDroppingPage = props?.data?.isDroppingPage || false

  const groupRef = useRef()
  const secondGroupRef = useRef()
  const imageRef = useRef()
  const viewport = useViewport()
  const isMobile = useIsMobile()
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const mouseRef = useRef({
    x: 0,
    y: 0,
    scroll: 0
  })

  const aspect = image?.asset?.metadata?.dimensions?.aspectRatio || 1

  const imageWidth = viewport.width * 0.8
  const imageHeight = imageWidth / aspect

  const updateScrollPosition = useCallback(({ progress }) => {
    if (imageRef.current && secondGroupRef.current) {
      if (isErrorPage) {
        return
      }

      const startY = (viewport.height / 2) - (imageHeight / 2)
      const posY = -startY + gsap.utils.interpolate([
        isMobile ? 0 : -1.5,
        isMobile ? -(viewport.height / 2) * 1.3 : -viewport.height * 0.9,
        isMobile ? -(viewport.height / 2) * 1.3 : -6
      ], progress || 0)

      mouseRef.current.scroll = posY

      if (isMobile) {
        secondGroupRef.current.rotation.y = 0
        secondGroupRef.current.rotation.x = 0
        secondGroupRef.current.rotation.z = 0
        imageRef.current.rotation.y = gsap.utils.interpolate([0, 0.5, 0], progress || 0)
        imageRef.current.rotation.x = gsap.utils.interpolate([0, -1, 0], progress || 0)
        imageRef.current.rotation.z = gsap.utils.interpolate([0, 0.3, 0], progress || 0)
      } else {
        imageRef.current.position.y = posY
        secondGroupRef.current.rotation.y = gsap.utils.interpolate([0, 2], progress || 0) / 10
        secondGroupRef.current.rotation.x = gsap.utils.interpolate([0, 3], progress || 0) / 10
        secondGroupRef.current.rotation.z = gsap.utils.interpolate([0, 0.3], progress || 0) / 10
      }
    }
  }, [viewport, windowWidth, windowHeight, isMobile, isErrorPage])

  useScrollPosition(updateScrollPosition, true)

  useFrame(({ clock }) => {
    if (imageRef.current) {
      if (isErrorPage) {
        imageRef.current.position.y = -((viewport.height / 2) - (imageHeight / 2))
      }
      if (isDroppingPage) {
        imageRef.current.rotation.z = aspect < 1 ? 1.5 : 0
        // imageRef.current.position.z = 0
        imageRef.current.scale.x = imageWidth * 0.5
        imageRef.current.scale.y = imageHeight * 0.5
      }

      imageRef.current.material.displacementScale = 4
      const speed = isDroppingPage ? 1 : 0.2
      imageRef.current.material.displacementOffset = Math.sin(clock.elapsedTime * speed) * 0.02

      if (!isMobile) {
        const interpX = -1 + ((mouseRef.current.x / windowWidth) * 2)
        const interpY = -1 + ((mouseRef.current.y / windowHeight) * 2)

        imageRef.current.rotation.x = lerp(imageRef.current.rotation.x, interpY * 1.5, 0.003)
        imageRef.current.rotation.y = lerp(imageRef.current.rotation.y, interpX * 3, 0.003)
      } else {
        if (isErrorPage) return
        imageRef.current.position.y = lerp(imageRef.current.position.y, mouseRef.current.scroll, 0.8)
      }
    }
  })

  const position = useScreenToWorldPosition(0, '10%', '0%', imageWidth, imageHeight)

  const primaryImage = useMemo(() => {
    if (!image) return null
    return transform(image, aspect, [320, 420, 640, 1024, 1200])
  }, [aspect, image])

  const handleMouseMove = (e) => {
    mouseRef.current = {
      x: e.clientX,
      y: e.clientY
    }
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return <group position={position} ref={groupRef}>
    <group ref={secondGroupRef}>
      <ProductHeroImage
        ref={imageRef}
        url={last(primaryImage.sizes).url}
        width={primaryImage.width}
        height={primaryImage.height}
        scale={[imageWidth, imageHeight, 1]}
        displacementScale={4}
        position={[0, -imageHeight / 2, -2]}
        greyscale={false}
        // segments={180}

        {...props}
      />
    </group>
  </group>
}

export default function About () {
  const data = useAtomValue(sceneAtom)?.data[SCENES.ABOUT]
  const image = data?.image

  if (!data) return
  return <DeformedImage image={image} data={data} />
}
