import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
import { sumBy } from 'lodash'
import filter from 'lodash/filter'
import range from 'lodash/range'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { getSubscriptionProductPrice } from '../../../helpers/product'
import useIsMounted from '../../../hooks/useIsMounted'
import useOnClickOutside from '../../../hooks/useOnOutsideClick'
import { useTheme } from '../../../state/theme'
import Button from '../../Button'
import Link from '../../Link'
import NoiseBackground from '../../Menu/NoiseBackground'
import useAddProductToCartCallback from '../../Menu/SecondaryNavs/useAddProductCallback'
import ResponsiveImage from '../../ResponsiveImage'
import { siteAtom, useCartSettings, useCustomSubscriptionBundle, useLabels, useSite } from '../../siteState'
import { subscriptionCartAtom, subscriptionCartOpenAtom, subscriptionCartToolbarOpenAtom, useIsSubscriptionCartShown } from './subscriptionState'

function LineItem ({ className, product, onRemove }) {
  const { removeLabel } = useLabels()
  const { subtotalAmount, totalAmount } = getSubscriptionProductPrice(product)

  const handleRemove = useCallback(() => {
    onRemove?.(product)
  }, [onRemove, product])

  const image = product?.images?.featureImage || product?.productImages?.featureImage

  return (
    <div className='grid grid-cols-4 border-b border-border'>
      <div className='col-span-1 py-2 px-4'>
        {image && <ResponsiveImage image={image} showPreview={false} aspect={56 / 71} contain />}
      </div>
      <div className={cn('col-span-3 py-2 px-4 flex flex-col upMd:items-start upMd:justify-between md:justify-center', className)}>
        <div className='flex justify-between items-center w-full mb-2'>
          <div className='uppercase text-12 leading-none mr-2 font-550'>
            <div>{product.winery?.title || product.winery}</div>
            <div>{product.range?.title || product.range}</div>
            <div>{product.varietal?.title || product.varietal}</div>
          </div>
          <div className='flex flex-col justify-between h-full'>
            <span className='uppercase text-12 leading-none text-right flex flex-col'>
              {totalAmount !== subtotalAmount &&
                <div className='opacity-70 text-10 line-through mb-1'>{formatCurrency(subtotalAmount)}</div>
              }
              {formatCurrency(totalAmount)}
            </span>
            <button className='uppercase text-10 leading-none font-550 tracking-slight text-right upMd:hidden' onClick={handleRemove}
              data-event='site interaction'
              data-type='button'
              data-description={removeLabel}
              data-location='body'
            >
              {removeLabel}
            </button>
          </div>
        </div>

        <button className='uppercase text-10 leading-none font-normal tracking-slight hover:underline md:hidden' onClick={handleRemove}
          data-event='site interaction'
          data-type='button'
          data-description={removeLabel}
          data-location='body'
        >
          {removeLabel}
        </button>
      </div>
    </div>
  )
}

function EmptyLineItem ({ onClick, image }) {
  const { addWineLabel } = useLabels()
  const { shopLink } = useSite()

  return (
    <Link className='grid grid-cols-4 w-full m-0 border-b border-border'
      link={shopLink}
      showText={false}
      search='category=subscriptions-dynamic'
      onClick={onClick}
      data-event='site interaction'
      data-type='button'
      data-description={addWineLabel}
      data-location='body'
    >
      <div className='col-span-1 px-4 py-2'>
        {image && <ResponsiveImage className='opacity-20' image={image} showPreview={false} aspect={56 / 71} contain />}
      </div>
      <div className='col-span-3 py-2 px-4 flex flex-col items-center justify-between gap-2 h-full'>
        <div className='flex justify-between gap-4 w-full items-center uppercase text-12 leading-none h-full'>
          <span>
            {addWineLabel}
          </span>
          <span>
            -
          </span>
        </div>
      </div>
    </Link>
  )
}

function SubscriptionCart ({ bundle, visible }) {
  const { subscriptionCartLabel: cartLabel, subscriptionAddToCartLabel } = useLabels()
  const { bundle: { details: { productCount, subscriptionIntervalLabel } } } = bundle
  const ref = useRef()
  const rootRef = useRef()
  const [isOpen, setOpen] = useAtom(subscriptionCartOpenAtom)
  const [cart, setCart] = useAtom(subscriptionCartAtom)
  const settings = useAtomValue(siteAtom)
  const scrollContainerRef = useRef()
  const addProductToCart = useAddProductToCartCallback(bundle, 1, cart)
  const { bottlesLabel, cancelLabel } = useCartSettings()

  const theme = useTheme()
  const isDarkMode = theme.dark

  useEffect(() => {
    function handleEsc (e) {
      if (e.keyCode === 27) {
        setOpen(false)
      }
    }
    document.addEventListener('keydown', handleEsc, false)
    return () => [document.removeEventListener('keydown', handleEsc, false)]
  }, [])

  useOnClickOutside(rootRef, () => setOpen(false), isOpen, 'add-btn')

  useEffect(() => {
    const clear = setTimeout(() => {
      gsap.to(rootRef.current, {
        y: visible ? 0 : 20,
        autoAlpha: visible ? 1 : 0,
        duration: 0.6,
        ease: 'expo.out'
      })
    }, 1)
    return () => {
      clearTimeout(clear)
    }
  }, [visible])

  useEffect(() => {
    const timeline = gsap.timeline()
    timeline.to(ref.current, {
      height: isOpen ? 'auto' : '0',
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true,
      onStart: () => {
        if (isOpen) gsap.set(ref.current, { visibility: 'visible' })
        gsap.set(scrollContainerRef.current, { overflow: 'hidden' })
      },
      onComplete: () => {
        if (!isOpen) gsap.set(ref.current, { visibility: 'hidden' })
        if (isOpen) gsap.set(scrollContainerRef.current, { overflow: 'auto' })
      }
    })

    timeline.to(ref.current.children[0].children, {
      opacity: isOpen ? 1 : 0,
      duration: 0.6,
      ease: 'expo.out',
      stagger: 0.03,
      delay: 0.1,
      overwrite: true
    }, 0)
    timeline.to(ref.current.children, {
      opacity: isOpen ? 1 : 0,
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true
    }, 0)
    return () => {
      timeline.kill()
    }
  }, [isOpen])

  const cartTotal = useMemo(() => {
    return sumBy(cart, lineItem => getSubscriptionProductPrice(lineItem)?.totalAmount)
  }, [cart])

  const onRemove = useCallback((product) => {
    setCart(filter(cart, p => p !== product))
  }, [cart, setCart])

  const onAddToCart = useCallback(async () => {
    await addProductToCart()
    setCart([])
    setOpen(false)
  }, [addProductToCart, setCart, setOpen])

  const onCancel = useCallback(() => {
    setCart([])
    setOpen(false)
  }, [setCart, setOpen])

  const isPackFull = cart.length === productCount

  return (
    <div
      ref={rootRef}
      className={cn('w-[calc(100%-32px)] max-w-[max(20vw,288px)] md:max-w-[100%] min-h-[30px] md:min-h-[42px] fixed left-1/2 bottom-4 -translate-x-1/2 z-second-menu bg-transparent')}
      style={{ color: 'var(--foreground)', opacity: 0, visibility: 'hidden' }}
    >
      <div className='overflow-hidden h-0 max-h-[calc(100vh-12rem)] md:max-h-[calc(100vh-16rem)] w-full col-span-full flex flex-col pl-2' ref={ref} data-lenis-prevent>
        <div className='h-full overflow-hidden' ref={scrollContainerRef}>
          {cart.map((lineItem, i) => (
            <LineItem key={`${i}_${lineItem._id}`} product={lineItem} onRemove={onRemove} className={cn(i === productCount - 1 && 'pb-4')} />
          ))}
          {range(productCount - cart.length).map((_, i) => (
            <EmptyLineItem key={i} onClick={() => setOpen(false)} image={settings.subscriptionCartPlaceholderImage}/>
          ))}
        </div>
        <div className='text-center text-10 uppercase mt-8 mr-2'>
            {isPackFull ? 'Edition Full' : `Add ${productCount - cart.length} ${bottlesLabel}${productCount - cart.length !== 1 ? 's' : ''}`} <span className='opacity-50'>or</span> <Link className='uppercase underline hover:no-underline' nonLinkTag='button' onClick={onCancel}>{cancelLabel || 'Cancel'}</Link>
        </div>
        <Button onClick={onAddToCart}
          className='mr-2 mt-2 w-auto z-[2]' borderColor='border-border' border={!isPackFull && 'border'} text='text-10' px='px-4'
          disabled={!isPackFull} invert={isPackFull} filled={isPackFull}

        >
          <div className='mr-2'>{subscriptionAddToCartLabel || 'Confirm Selection'}</div>
          {isPackFull && <div>{formatCurrency(cartTotal)} / {subscriptionIntervalLabel}</div>}
          {/* {!isPackFull && <div>Add {productCount - cart.length} More</div>} */}
        </Button>
      </div>

      <button onClick={() => setOpen(!isOpen)} aria-label={isOpen ? 'close' : 'open'}
        className='relative flex items-center h-[max(2.08vw,30px)] w-full md:h-[42px] px-2'
        data-event='site interaction'
        data-type='button'
        data-description='toggle subscription popup'
        data-location='body'
      >
        <div className='flex-grow flex items-center justify-center gap-2'>
          <div className={cn('uppercase font-sans font-550 text-10 md:text-[14px] tracking-slight text-left')}>
            {cartLabel}
          </div>

          {/* <div className='flex gap-2 items-center uppercase font-250 font-serif tracking-slight text-10 md:text-14'>
            {padStart(cart.length, 2, '0')} / {padStart(productCount, 2, '0')}
          </div> */}
        </div>

        <div className='isolate absolute right-2'>
          <svg className='transition-transform duration-300 ease-in-out-strong w-[0.75rem] h-auto z-10' style={{ transform: isOpen ? 'rotateX(180deg) translateY(-1px)' : 'rotateX(0deg)' }} width="0.6rem" height="0.375rem" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.68142 0.586477C4.44248 3.11373 2.54867 4.72107 0 6.85678L0 7.41406C1.02655 6.88014 1.9646 6.32841 2.81416 5.75889C3.66372 5.22496 4.44248 4.67324 5.15044 4.10372L6 3.38002L6.84956 4.10403C7.55752 4.67355 8.33628 5.22496 9.18584 5.75889C10.0354 6.32841 10.9735 6.88014 12 7.41406V6.85678C9.45133 4.72107 7.55752 3.11373 6.31858 0.586477L5.68142 0.586477Z" fill="currentColor"/>
          </svg>
        </div>
      </button>

      <div
        className='blur-bg fixed inset-0 backdrop-blur-menu backdrop-saturate-menu -z-1 opacity-100'
        style={{ background: 'var(--background)' }}
      />
      <NoiseBackground light={isDarkMode} visible={true} />
    </div>
  )
}

export default function SubscriptionCartPortal () {
  const bundle = useCustomSubscriptionBundle()
  const toolbarOpen = useAtomValue(subscriptionCartToolbarOpenAtom)
  const mounted = useIsMounted()
  const showCart = useIsSubscriptionCartShown()
  const visible = toolbarOpen || showCart

  if (!mounted || !bundle) return null
  return createPortal(
    <SubscriptionCart bundle={bundle} visible={visible} />,
    document.getElementById('menu')
  )
}
