import { Text } from '@react-three/drei'
import { forwardRef, useMemo } from 'react'
import { useTheme } from '../../../../state/theme'
import { useIsMobile, useIsSmallDesktop } from '../../hooks'

const CHARS = 'QWERTYUIOPASDFGHJKLZXCVBNM1234567890`~!@#$%^&*()_+-={}[]:";\'<>,.?/'

const BodyFont = forwardRef((props, ref) => {
  const { weight = 'Medium', fontSizes, onSync, lineHeight = 1.1 } = props
  const theme = useTheme()

  const isMobile = useIsMobile()
  const isSmallDesktop = useIsSmallDesktop()
  const size = useMemo(() => {
    return isMobile ? fontSizes.sm : isSmallDesktop ? fontSizes.md : fontSizes.lg
  }, [isMobile, isSmallDesktop])

  return (
    <Text
      font={weight ? `/fonts/FKGroteskNeue-${weight}.woff` : '/fonts/FKGroteskNeue.woff'}
      anchorX='left'
      anchorY='top'
      textAlign='left'
      characters={CHARS}
      color={theme.foreground}
      lineHeight={lineHeight}
      onSync={onSync}
      ref={ref}
      {...props}
      fontSize={size}
    />
  )
}
)
export default BodyFont
