import cn from 'clsx'
import isNaN from 'lodash/isNaN'
import padStart from 'lodash/padStart'
import { useCallback, useEffect, useRef, useState } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import Link from '../../Link'
import ArrowIcon from '../../Menu/SecondaryNavs/ArrowIcon'
import useCanAddProductToCart from '../../Menu/SecondaryNavs/useCanAddProductToCart'
import ResponsiveImage from '../../ResponsiveImage'
import Spinner from '../../Spinner'
import { useLabels, useSite } from '../../siteState'
import { LINE_ITEM_STATES, useLineItemState } from '../cartState'
import { useCartActions } from '../useCartActions'
import BundleLineItems from './BundleLineItems'
import PlusIcon from './PlusIcon'

const QuantityButton = ({ className, dataDescription, ...props }) => {
  return (
    <button className={cn(className,
      'w-10 h-10 flex flex-shrink-0 flex-grow-0 justify-center items-center')}
      data-event='site interaction'
      data-type='button'
      data-description={dataDescription}
      data-location='cart'
      {...props}
    />
  )
}

const CustomAttributes = ({ lineItem }) => {
  const { attributes } = lineItem
  if (!attributes) return null
  return attributes
    .filter(({ key }) => !key?.startsWith('_') && key !== 'Bundle')
    .map(({ key, value }) => value
      ? (
    <div key={key} className='text-10 opacity-75 mt-2 max-h-[2em] overflow-hidden line-clamp-2 flex'><span>{key}: </span><span >{value}</span></div>
        )
      : null)
}

const SubscriptionLabel = ({ lineItem }) => {
  const subscriptionLabel = lineItem?.sellingPlanAllocation?.sellingPlan?.name
  return subscriptionLabel
    ? <div key={subscriptionLabel} className='text-10 opacity-75 mt-2 max-h-[2em] overflow-hidden line-clamp-2'><span>{subscriptionLabel}</span></div>
    : null
}

export default function LineItem ({ lineItem }) {
  const { quantity, cost } = lineItem
  const [incrementing, setIncrementing] = useState()
  const [decrementing, setDecrementing] = useState()
  const inputRef = useRef()
  const state = useLineItemState(lineItem.id)
  const [currentQuantity, setCurrentQuantity] = useState(quantity)
  const { soldOutLabel, removeLabel, viewWinesLabel, hideWinesLabel } = useLabels()
  const [showBundleProducts, setShowBundleProducts] = useState()
  const [removingBundle, setRemovingBundle] = useState()
  const site = useSite()

  const eligibleForCases = lineItem.product.eligibleForCases
  const caseSelected = eligibleForCases && lineItem.quantity % 6 === 0

  const { removeLineItems, updateLineItems } = useCartActions()

  useEffect(() => {
    setCurrentQuantity(padStart(quantity, 2, '0'))
  }, [quantity])

  const onIncrement = useCallback(async () => {
    const newQuantity = quantity + (caseSelected ? 6 : 1)
    setCurrentQuantity(padStart(newQuantity, 2, '0'))
    setIncrementing(true)
    try {
      await updateLineItems([{ ...lineItem, quantity: newQuantity }])
    } finally { setIncrementing(false) }
  }, [updateLineItems, lineItem, quantity, caseSelected])

  const onDecrement = useCallback(async () => {
    const newQuantity = quantity - (caseSelected ? 6 : 1)
    setCurrentQuantity(padStart(newQuantity, 2, '0'))
    if (newQuantity <= 0) {
      removeLineItems([lineItem])
    } else {
      setDecrementing(true)
      try {
        await updateLineItems([{ ...lineItem, quantity: newQuantity }])
      } finally { setDecrementing(false) }
    }
  }, [quantity, removeLineItems, lineItem, updateLineItems, caseSelected])

  const onRemoveItem = useCallback(async () => {
    if (lineItem.type === 'bundle') {
      setRemovingBundle(true)
      await removeLineItems(lineItem.lines)
    } else {
      removeLineItems([lineItem])
    }
  }, [lineItem, removeLineItems])

  const onInputBlur = useCallback((e) => {
    const quantity = parseInt(e.target.innerHTML)
    if (quantity <= 0) {
      setCurrentQuantity(padStart(0, 2, '0'))
      removeLineItems([lineItem])
    } else {
      setCurrentQuantity(padStart(quantity, 2, '0'))
      updateLineItems([{ ...lineItem, quantity }])
    }
  }, [lineItem, removeLineItems, updateLineItems])

  const onKeyDown = useCallback((e) => {
    const keyCode = e.keyCode || e.which
    const key = String.fromCharCode(keyCode)
    if (isNaN(parseInt(key))) {
      e.preventDefault()
    }
  }, [])

  const toggleBundleProducts = useCallback(() => {
    setShowBundleProducts(v => !v)
  }, [])

  const { featureImage, title, productCategory } = lineItem?.product || {}

  const isWine = productCategory === 'wine'
  const canAddItems = productCategory !== 'gift-wrapping'

  const isBundle = lineItem.type === 'bundle'
  const canAddCaseToCart = lineItem.product?.variants?.[0]?.inventoryQuantity >= 6
  const canAddMoreItems = useCanAddProductToCart(lineItem.product, caseSelected ? 6 : 1)

  const onCaseClick = useCallback(async () => {
    setIncrementing(true)
    try {
      await updateLineItems([{ ...lineItem, quantity: 6 }])
      setCurrentQuantity(padStart(6, 2, '0'))
    } finally { setIncrementing(false) }
  }, [updateLineItems, lineItem])

  const onBottleClick = useCallback(async () => {
    setIncrementing(true)
    try {
      await updateLineItems([{ ...lineItem, quantity: 1 }])
      setCurrentQuantity(padStart(1, 2, '0'))
    } finally { setIncrementing(false) }
  }, [updateLineItems, lineItem])

  if (state === LINE_ITEM_STATES.removing || removingBundle) return null

  const diyProductLink = site?.cart?.diySubscriptionPage
  const productLink = lineItem?.product?.bundleType === 'dynamic' ? diyProductLink : canAddItems && lineItem?.product

  return (
    <>
      <li className='grid border-y border-border mt-[-1px] py-4' style={{ gridTemplateColumns: 'min-content auto', gridTemplateRows: '1fr auto' }}>
        <div className={cn('row-span-2')}>
          <Link link={productLink} showText={false} className='relative'>
            <ResponsiveImage showPreview={false} image={featureImage} className='w-16' aspect={68 / 106} contain={isWine} />
          </Link>
        </div>
        <div className='text-12 ml-4'>
          <div className='flex justify-between gap-2'>
            <div>
              <Link link={productLink} showText={false}>
                <span className='font-semibold uppercase'>
                  {title}
                </span>
              </Link>
              <button onClick={onRemoveItem} className='uppercase font-normal text-10 h-6 block underline hover:no-underline'
                data-event='site interaction'
                data-type='button'
                data-description={removeLabel}
                data-location='cart'
              >
                {removeLabel}
              </button>
            </div>
            <span className='whitespace-nowrap flex flex-col'>
              {cost?.totalAmount?.amount !== cost?.subtotalAmount?.amount &&
                <span className='opacity-70 text-10 line-through'>{formatCurrency(cost?.subtotalAmount?.amount)}</span>
              }
              {formatCurrency(cost?.totalAmount?.amount)}
            </span>
          </div>
          <div>
            <CustomAttributes lineItem={lineItem} />
            <SubscriptionLabel lineItem={lineItem} />
          </div>
        </div>

        <div className='flex justify-between items-center text-10 ml-4 -mb-4 overflow-hidden'>
          {!isBundle && isWine && (
            <div className='flex gap-2'>
              <button
                onClick={onBottleClick}
                disabled={!caseSelected}
                className={cn('uppercase h-10 block hover:opacity-100 font-550', !caseSelected ? 'opacity-100' : 'opacity-50')}
                data-event='site interaction'
                data-type='button'
                data-description='Bottle'
                data-location='cart'
              >
                Bottle
              </button>
              {eligibleForCases && canAddCaseToCart && (
                <button
                  onClick={onCaseClick}
                  disabled={caseSelected}
                  className={cn('uppercase h-10 block hover:opacity-100 font-550', caseSelected ? 'opacity-100' : 'opacity-50')}
                  data-event='site interaction'
                  data-type='button'
                  data-description='Case'
                  data-location='cart'
                >
                  Case
                </button>
              )}
            </div>
          )}

          {isBundle && (
            <button className='uppercase font-normal h-10 hover:underline flex items-center' onClick={toggleBundleProducts}
              data-event='site interaction'
              data-type='button'
              data-description={showBundleProducts ? hideWinesLabel : viewWinesLabel}
              data-location='cart'
            >
              {showBundleProducts ? hideWinesLabel : viewWinesLabel}
              <ArrowIcon toggle={showBundleProducts} className='ml-2 mb-1' />
            </button>
          )}
          {!isBundle && canAddItems && (
            <div className='flex justify-between items-center -mr-2'>
              <QuantityButton onClick={onDecrement} disabled={decrementing} dataDescription='decrement' aria-label='decrement'>
                {decrementing && <Spinner size={16} />}
                {!decrementing && <PlusIcon minus />}
              </QuantityButton>
              <span className='uppercase text-12 flex-grow flex justify-center items-center'>
                <span className='inline-block'>
                  <span
                    className='outline-none border-none w-3 appearance-none text-current bg-transparent text-12'
                    role='textbox'
                    contentEditable
                    onBlur={onInputBlur}
                    onKeyDown={onKeyDown}
                    ref={inputRef}
                    dangerouslySetInnerHTML={{ __html: caseSelected ? padStart(currentQuantity / 6, 2, '0') : currentQuantity }}
                  />
                </span>
              </span>
              <QuantityButton
                className={cn(!canAddMoreItems && 'opacity-50')}
                onClick={onIncrement}
                disabled={incrementing || !canAddMoreItems}
                dataDescription='increment'
                aria-label='increment'
                title={canAddMoreItems ? 'Add item' : soldOutLabel}
              >
                {incrementing && <Spinner size={16} />}
                {!incrementing && <PlusIcon />}
              </QuantityButton>
            </div>
          )}
        </div>

      </li>
     {isBundle && <BundleLineItems bundle={lineItem} show={showBundleProducts} />}
   </>
  )
}
