import { cellWidths } from '../../../Slices/Feed/feedRows'
import { useIsMobile } from '../../hooks'
import FeatureTile from './FeatureTile'
import FooterTIle from './FooterTile'
import ImageTile from './ImageTile'
import ProductTile from './ProductTile'
import QuoteTile from './QuoteTile'
import TextTile from './TextTile'

const userData = { type: 'tile' }

const getTileComponent = (type, layout) => {
  switch (type) {
    case 'imageTile':
      return ImageTile
    case 'product':
    case 'page':
      return layout === 'feature' ? FeatureTile : ProductTile
    case 'quote':
      return QuoteTile
    case 'footerTile':
      return FooterTIle
    default:
      return TextTile
  }
}

export default function Tile ({ tile, enabled, preload }) {
  const isMobile = useIsMobile()

  const width = isMobile
    ? tile.tile.pageType === 'winery' ? cellWidths.winery.mobile : tile.width.mobile
    : tile.width.desktop

  const type = tile.tile._type
  const layout = tile.tile.layout

  const TileType = getTileComponent(type, layout)

  const metadataWidth = tile?.metadata?.width || 1

  return (
    <group userData={userData}>
      <TileType
        width={width}
        metadataWidth={metadataWidth}
        tile={tile.tile}
        floatingImagesConfig={tile.floatingImages}
        preload={preload || tile.preload}
        enabled={enabled}
      />
    </group>
  )
}
