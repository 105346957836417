import { Text } from '@react-three/drei'
import { forwardRef, useMemo } from 'react'
import { useTheme } from '../../../../state/theme'
import { useIsMobile, useIsSmallDesktop } from '../../hooks'

const CHARS = 'QWERTYUIOPASDFGHJKLZXCVBNM1234567890`~!@#$%^&*()_+-={}[]:";\'<>,.?/'

const SansSerifFont = forwardRef((props, ref) => {
  const theme = useTheme()
  const { weight = 'Light', fontSizes, onSync, textAlign = 'left', anchorX = 'left', anchorY = 'top' } = props
  const isMobile = useIsMobile()
  const isSmallDesktop = useIsSmallDesktop()
  const size = useMemo(() => {
    return isMobile ? fontSizes.sm : isSmallDesktop ? fontSizes.md : fontSizes.lg
  }, [isMobile, isSmallDesktop])

  return (
    <Text
      font={weight ? `/fonts/FKRomanStandard-${weight}.woff` : '/fonts/FKRomanStandard.woff'}
      anchorX={anchorX}
      anchorY={anchorY}
      textAlign={textAlign}
      characters={CHARS}
      color={theme.foreground}
      lineHeight='1.1'
      onSync={onSync}
      ref={ref}
      {...props}
      fontSize={size}
    />
  )
})

export default SansSerifFont
