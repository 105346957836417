import Link from '../Link'
import RichContent from '../RichContent'
import Section from '../Section'

export default function ProductText ({ data }) {
  const { copy, link } = data
  return (
    <Section grid className='gap-y-[4rem] md:mt-[8rem] z-[2] relative'>
      <RichContent
        content={copy}
        className='col-start-4 col-span-6 md:col-span-full text-center text-24 md:text-[1rem] font-serif font-250 [&>div>p>strong]:font-sans [&>div>p>strong]:font-550'
      />
      {link && <Link
        link={link}
        className='col-span-full text-center uppercase font-550 tracking-slight text-[1rem] self-center justify-self-center'
      />}
    </Section>
  )
}
