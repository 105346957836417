import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect } from 'react'

export const themes = {
  dark: {
    dark: true,
    background: '#000',
    foreground: '#FFF',
    foregroundInverted: '#000',
    border: '#BBBBBB',
    cream: '#777'
  },
  light: {
    dark: false,
    background: '#FFF',
    foreground: '#000',
    foregroundInverted: '#FFF',
    border: '#BBBBBB',
    cream: '#EBE6E2'
  }
}

export const themeAtom = atom(themes.light)

export const getPageTheme = (page, site, authenticated) => {
  if (!page.theme) return (page._type === 'product' && page.productCategory === 'wine') ? themes.dark : themes.light
  // There is a special case on the account page that changes color when they are logged in
  if (authenticated && page.slug === site?.accountLink?.page?.slug) {
    return themes.light
  }

  return themes[page.theme] || themes.light
}

export const useTheme = () => {
  return useAtomValue(themeAtom)
}

export const useSetTheme = (page) => {
  const setTheme = useSetAtom(themeAtom)
  useEffect(() => {
    if (page) {
      setTheme(getPageTheme(page))
    }
  }, [page, setTheme])
}

export const createPageThemeTimeline = (tl, duration, currentTheme, nextTheme, time = 0) => {
  const main = document.getElementById('main')
  const { background, foreground, border, foregroundInverted, cream } = nextTheme
  const props = { ...currentTheme }
  tl.to(props, {
    background,
    foreground,
    border,
    foregroundInverted,
    cream,
    ease: 'sine.inOut',
    duration: duration / 2,
    onUpdate: () => {
      main.style.setProperty('--foreground', props.foreground)
      main.style.setProperty('--background', props.background)
      main.style.setProperty('--border', props.border)
      main.style.setProperty('--foreground-invert', props.foregroundInverted)
      main.style.setProperty('--cream', props.cream)

      document.documentElement.style.setProperty('--foreground', props.foreground)
      document.documentElement.style.setProperty('--background', props.background)
      document.documentElement.style.setProperty('--border', props.border)
      document.documentElement.style.setProperty('--foreground-invert', props.foregroundInverted)
      document.documentElement.style.setProperty('--cream', props.cream)
    }
  }, time)
}
