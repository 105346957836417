import React from 'react'
import Section from '../../Section'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import { useState } from 'react'
import cn from 'clsx'
import { leftPad } from '../../../helpers/math'
import { primaryInput } from 'detect-it'
import breakpoints from '../../../../theme/breakpoints'

export default function ArticleUpNext ({ data }) {
  const [hoverIndex, setHover] = useState(-1)
  const { title, articles } = data
  const isTouch = primaryInput === 'touch'

  return (
    <Section className='flex flex-col gap-4'>
      {title && <div className='text-12 font-550 uppercase tracking-slight text-center mt-[2.5rem] mb-[11rem] md:mt-0 md:mb-[5.1875rem]'>
        {title}
      </div>}
      {articles?.map((article, i) => (
        <Link 
          key={article._id}
          link={article}
          showText={false}
          className={cn(
            'grid grid-cols-16 items-start justify-start gap-2 transition-opacity duration-600 ease-out-expo opacity-100',
            'md:grid-cols-8 md:grid-rows-upnext',
            i !== hoverIndex && hoverIndex !== -1 && 'opacity-20',
            i === hoverIndex && '!opacity-100'
          )}
          onMouseEnter={() => isTouch ? null : setHover(i)}
          onMouseLeave={() => isTouch ? null : setHover(-1)}
        >
          <div className='text-12 font-550 uppercase tracking-slight col-span-3 md:col-start-2 md:col-end-9'>{article?.category}</div>
          <div className='md:col-start-1 md:row-start-1 md:row-end-3'>
            <ResponsiveImage image={article?.images?.featureImage} aspect={64/90} imageSizes={`(max-width: ${breakpoints.md}px) 20vw, 20vw`}/>
          </div>
          <div className='uppercase font-550 text-[5rem] col-span-11 leading-[0.8em] md:text-24 md:col-start-2 md:col-end-9'>
            {article.title}
          </div>
          <div className='text-12 font-550 uppercase tracking-slight col-span-1 text-right md:hidden'>{leftPad(i + 1, 2)}/{leftPad(articles.length, 2)}</div>
        </Link>
      ))}
    </Section>
  )
}
