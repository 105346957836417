import { useKeenSlider } from 'keen-slider/react'
import padStart from 'lodash/padStart'
import React, { useCallback, useState } from 'react'
import ResponsiveImage from '../../ResponsiveImage'
import { getImageAspect } from '../../ResponsiveImage/imageHelper'
import cn from 'clsx'

export default function WineImageSlider ({ images, className }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: '1',
        origin: "center",
        spacing: 40,
      },
      slideChanged (slider) {
        setCurrentSlide(slider.track.details.rel)
      }
    }
  )

  const nextSlide = useCallback(() => { instanceRef.current.next() }, [instanceRef])
  const prevSlide = useCallback(() => { instanceRef.current.prev() }, [instanceRef])

  return (
    <div className={className}>
      <div className='flex' ref={sliderRef}>
        {images?.map((image, i) => (
          <div key={i} className='keen-slider__slide flex-shrink-0 pr-2 flex justify-center items-end' style={{'--aspect': getImageAspect(image)}}>
            <ResponsiveImage
              image={image}
              className='!w-[100%] !h-[120vw]'
              contain
              showPreview={false}
            />
          </div>
        ))}
      </div>
      {images.length > 1 && <div className='flex items-center justify-between !w-full mt-2 text-14'>
        <div className='flex items-center'>
          {padStart(currentSlide + 1, 2, '0')}
          <span className='inline-block mx-8 lg:mx-4 rounded-full bg-current w-[0.375rem] h-[0.375rem]'></span>
          {padStart(images.length, 2, '0')}
        </div>
        <div className='flex gap-20 lg:gap-10'>
          <button onClick={prevSlide} disabled={currentSlide === 0} className='font-550 uppercase hover:opacity-50 duration-200 disabled:opacity-30'>Prev</button>
          <button onClick={nextSlide} disabled={currentSlide >= images.length - 1} className='font-550 uppercase hover:opacity-50 duration-200 disabled:opacity-30'>Next</button>
        </div>
      </div>}
    </div>
  )
}
