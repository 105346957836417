import { useKeenSlider } from 'keen-slider/react'
import padStart from 'lodash/padStart'
import uniqBy from 'lodash/unionBy'
import React, { useCallback, useMemo, useState } from 'react'
import ResponsiveImage from '../../ResponsiveImage'

export default function BundleHeroSlider ({ page, overrideImages, className }) {
  const { images } = page
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto'
      },
      slideChanged (slider) {
        setCurrentSlide(slider.track.details.rel)
      }
    }
  )

  const _images = useMemo(() => {
    if (overrideImages) return overrideImages
    let imgs = images?.length ? images : [page.productImages?.featureImage, ...(page.productImages?.images || [])] || []
    if (page.productCategory === 'bundle') {
      const productImages = uniqBy(page.bundle?.products, p => p._id).map(x => x.images.feedImage || x.images.featureImage)
      imgs = [
        ...imgs,
        ...productImages
      ]
    }
    return imgs
  }, [overrideImages, images, page])

  const nextSlide = useCallback(() => {
    instanceRef.current.next()
  }, [instanceRef])

  const prevSlide = useCallback(() => {
    instanceRef.current.prev()
  }, [instanceRef])

  return (
    <div className={className}>
      <div className='flex' ref={sliderRef}>
        {_images?.map((image, i) => (
          <div key={i} className='keen-slider__slide w-[57%] md:w-[calc(100%+0.5rem)] flex-shrink-0 pr-2'>
            <ResponsiveImage
              image={image}
              aspect={450 / 645}
              className='w-full'
            />
          </div>
        ))}
      </div>
      {_images.length > 1 && <div className='flex items-center justify-between w-[calc(57%-+0.5rem)] md:w-full mt-2 text-14'>
        <div className='flex items-center'>
          {padStart(currentSlide + 1, 2, '0')}
          <span className='inline-block mx-8 lg:mx-4 rounded-full bg-current w-[0.375rem] h-[0.375rem]'></span>
          {padStart(_images.length, 2, '0')}
        </div>
        <div className='flex gap-20 lg:gap-10'>
          <button onClick={prevSlide} disabled={currentSlide === 0} className='font-550 uppercase hover:opacity-50 duration-200 disabled:opacity-30'>Prev</button>
          <button onClick={nextSlide} disabled={currentSlide >= _images.length - 1} className='font-550 uppercase hover:opacity-50 duration-200 disabled:opacity-30'>Next</button>
        </div>
      </div>}
    </div>
  )
}
