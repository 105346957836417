import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AnimateHide, LINK_HOVER_CLASSES } from '../..'
import Button from '../../../Button'
import { secondMenuOpenAtom } from '../../menuState'
import BooleanFilter from './BooleanFilter'
import RangeFilter from './RangeFilter'
import TagFilter from './TagFilter'
import TagFilterViewAll from './TagFilterViewAll'
import { resultsCountAtom, useHumanReadableFilterText, useMainFilters, useResetFilters } from './filterState'

export const VIEWS = {
  default: 'DEFAULT',
  viewAll: 'VIEW_ALL'
}

const filterComponentSelector = {
  tag: TagFilter,
  boolean: BooleanFilter,
  range: RangeFilter
}

export default function ShopFilter () {
  const ref = useRef()
  const [isOpen, setOpen] = useAtom(secondMenuOpenAtom)
  const [currentView, setView] = useState(VIEWS.default)
  const [viewAllView, setViewAllView] = useState(null)

  const mainFilters = useMainFilters()
  const resultsCount = useAtomValue(resultsCountAtom)
  const humanReadableFilterText = useHumanReadableFilterText()

  const handleResetFilters = useResetFilters()

  useEffect(() => {
    gsap.to(ref.current, {
      height: isOpen ? 'auto' : '0',
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true,
      onStart: () => {
        if (ref.current) {
          if (isOpen) gsap.set(ref.current, { visibility: 'visible' })
          gsap.set(ref.current.querySelector('.scroll-container'), { overflowY: 'hidden', overflowX: 'hidden' })
        }
      },
      onComplete: () => {
        if (ref.current) {
          if (!isOpen) gsap.set(ref.current, { visibility: 'hidden' })
          if (isOpen) gsap.set(ref.current.querySelector('.scroll-container'), { overflowY: 'auto', overflowX: 'hidden' })
        }
      }
    })

    gsap.to(ref.current.children[0].children, {
      y: isOpen ? 0 : 5,
      opacity: isOpen ? 1 : 0,
      duration: 0.6,
      ease: 'expo.out',
      stagger: 0.03,
      delay: 0,
      overwrite: true
    })
    gsap.to(ref.current.children, {
      opacity: isOpen ? 1 : 0,
      duration: isOpen ? 0 : 0.6,
      ease: 'expo.out',
      stagger: 0.05,
      delay: 0,
      overwrite: true
    })
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false)
    return () => [document.removeEventListener('keydown', handleEsc, false)]
  }, [])

  function handleEsc (e) {
    if (e.keyCode === 27) {
      setOpen(false)
    }
  }

  const setViewAll = useCallback((view) => {
    setViewAllView(view)
    setView(VIEWS.viewAll)
  }, [])

  return (
    <>
      <button onClick={() => setOpen(!isOpen)} aria-label={isOpen ? 'close shop filter' : 'open shop filter'}
        className={cn(LINK_HOVER_CLASSES, 'grid grid-cols-menu w-full items-center h-[max(2.0833vw,30px)] md:h-[47px]')}
        data-event='site interaction'
        data-type='button'
        data-description='toggle filter popup'
        data-location='filter'
      >
        <div className='uppercase font-sans font-550 text-12 md:text-16 tracking-slight text-left'>
          <AnimateHide hide={isOpen}>
            Filter
          </AnimateHide>
        </div>

        <div className='flex gap-2 items-center isolate' style={{ perspective: '10px' }}>
          <svg className='mt-[-2px] transition-transform duration-300 ease-in-out-strong md:w-[12px] md:h-auto mx-8 isolate z-[9999]' style={{ transform: isOpen ? 'rotateX(180deg) translateY(-1px)' : 'rotateX(0deg)' }} width="0.6rem" height="0.375rem" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.78761 5.27633C2.96165 3.5915 1.69911 2.51994 0 1.09613L0 0.724609C0.684366 1.08056 1.30973 1.44838 1.87611 1.82806C2.44248 2.18401 2.96165 2.55183 3.43363 2.93151L4 3.41397L4.56637 2.9313C5.03835 2.55162 5.55752 2.18401 6.12389 1.82806C6.69026 1.44838 7.31563 1.08056 8 0.724609V1.09613C6.30089 2.51994 5.03835 3.5915 4.21239 5.27633H3.78761Z" fill="currentColor"/>
          </svg>
        </div>

        <div className={cn('uppercase text-12 md:text-[16px] tracking-slight flex gap-2 justify-end shrink whitespace-nowrap grow-0 overflow-hidden')}>
          <AnimateHide hide={isOpen}>
            <div className='flex font-serif font-250 flex-1 text-ellipsis min-w-0 pl-2'>
              <div className='whitespace-nowrap overflow-hidden shrik text-ellipsis' title={humanReadableFilterText.join(' + ')}>
                {humanReadableFilterText.length > 0 && humanReadableFilterText.slice(0, 1)}
              </div>
              <div>{humanReadableFilterText.length > 1 && ` + [${humanReadableFilterText.length - 1}]`}</div>
              {humanReadableFilterText.length === 0 && 'all'}
            </div>
          </AnimateHide>
        </div>
      </button>

      <div className='overflow-hidden h-0 w-[calc(100%+1rem)] -ml-2 col-span-full' ref={ref} data-lenis-prevent>
        {currentView === VIEWS.default && <div className='pt-4 pb-2 pl-0 flex flex-col h-full max-h-[calc(var(--window-height)-8.5rem)] md:max-h-[calc(var(--window-height)-10.7rem)]'>
          <div className='scroll-container flex flex-col pb-6 overflow-y-auto pr-2 pl-2 small-scrollbar'>
            <div className='flex justify-center text-10 md:text-14 tracking-slight uppercase text-center mb-4'>
              <button className={cn(LINK_HOVER_CLASSES, 'font-550 uppercase opacity-100 transition-opacity', humanReadableFilterText.length === 0 && '!opacity-50')} onClick={() => handleResetFilters()}
                data-event='site interaction'
                data-type='button'
                data-description='reset'
                data-location='filter'
              >
                Reset
              </button>
            </div>

            {mainFilters.filter(x => x.visible !== false).map(filter => {
              const type = filter.type
              const Component = filterComponentSelector[type]
              if (!Component) return null
              return <Component filter={filter} key={filter.slug} setViewAll={setViewAll} />
            })}
          </div>

          <Button
            className='flex-shrink-0 max-w-[calc(100%-1rem)] ml-2 mt-2'
            filled
            invert
            border="border-none"
            text="text-10 md:text-16"
            onClick={() => setOpen(false)}
            data-event='site interaction'
            data-type='button'
            data-description='show results'
            data-location='filter'
          >
            <span>Show {resultsCount} results</span>
          </Button>
        </div>}

        {currentView === VIEWS.viewAll && <TagFilterViewAll filter={viewAllView} setView={setView} />}
      </div>

    </>
  )
}
