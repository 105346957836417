import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage, selectAtom } from 'jotai/utils'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import { useCallback, useMemo } from 'react'
import { useSiteId } from '../siteState'

export const CART_STATES = {
  uninitialized: 'uninitialized',
  loading: 'loading',
  ready: 'ready',
  error: 'error',
  updating: 'updating'
}

export const LINE_ITEM_STATES = {
  idle: 'idle',
  updating: 'updating',
  removing: 'removing',
  error: 'error'
}

export const cartAtom = atom({
  state: CART_STATES.uninitialized,
  stateData: null
})

const lineItemStateAtom = atom({})

export const useLineItemState = (id) => {
  return useAtomValue(lineItemStateAtom)[id] || LINE_ITEM_STATES.idle
}

export const useUpdateLineItemState = () => {
  const setLineItemState = useSetAtom(lineItemStateAtom)
  return useCallback((ids, newState) => {
    const updatedState = reduce(ids, (result, id) => {
      result[id] = newState
      return result
    }, {})
    setLineItemState(state => ({
      ...state,
      ...updatedState
    }))
  }, [setLineItemState])
}

const cartStateAtom = {}

export function useCartIdAtom () {
  const siteId = useSiteId()
  return useMemo(() => {
    const id = `vntnr_cartId_v2_${siteId}`
    if (!cartStateAtom[id]) {
      cartStateAtom[id] = atomWithStorage(id, null)
    }
    return cartStateAtom[id]
  }, [siteId])
}

export const hasLineItemsAtom = atom((get) => {
  const lineItems = get(cartAtom)?.lines?.filter?.(({ state, product }) => product && state !== LINE_ITEM_STATES.removing && product?.productCategory !== 'gift-wrapping')
  return !isEmpty(lineItems)
})

export const cartLineItemsTotalAtom = selectAtom(
  cartAtom,
  cart => cart.totalQuantity
)
