import { useCallback, useState } from 'react'
import { StringParam, withDefault } from 'use-query-params'
import routes from '../../../routes'
import useQueryParamsWithRoute from '../../hooks/useQueryParamsWithRoute'
import { ButtonWithSpinner } from '../Button'
import RichContent from '../RichContent'
import Section from '../Section'
import { useAccountActions } from '../Shop/useAccountActions'
import { useSite } from '../siteState'
import Input from './Input'

const ActivateAccountForm = ({ data, activationUrl }) => {
  const [loading, setLoading] = useState()
  const { activateAccount } = useAccountActions()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validationError, setValidationError] = useState()
  const { newPasswordFieldLabel, reenterPasswordFieldLabel, successCopy } = data.activateAccountForm
  const [success, setSuccess] = useState(false)
  const site = useSite()

  const onActivateAccount = useCallback(async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setValidationError('Your password and confirmed password do not match')
      return
    }
    try {
      setValidationError('')
      setLoading(true)
      const url = new URL(window.location.href)
      url.pathname = site?.countryCode !== routes.defaultRoute.countryCode ? site?.countryCode : null
      url.search = ''
      url.hash = '#skip'
      await activateAccount({ password, activationUrl, returnUrl: url.toString() })
      setSuccess(true)
    } catch (e) {
      setValidationError(e.toString().replace('Error: ', ''))
    } finally {
      setLoading(false)
    }
  }, [password, confirmPassword, activateAccount, activationUrl, site])

  return (
    <>
      {success && (<RichContent className='text-16 text-center' content={successCopy} />)}
      {!success && (
        <form onSubmit={onActivateAccount}>
          <Input uppercase={false} type='password' name='password' value={password} onChange={(e) => { setPassword(e.target.value) }} error={validationError} required label={newPasswordFieldLabel} className='py-5' containerClassName='mb-4' />
          <Input uppercase={false} type='password' name='reenterpassword' value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} error={validationError} required label={reenterPasswordFieldLabel} className='py-5' />
          {validationError && <div className='text-error text-10 mt-2'>{validationError}</div>}
          <div className='flex flex-col justify-start items-start mt-16 md:mt-4'>
            <ButtonWithSpinner type='submit' loading={loading} className='w-auto mx-auto mb-2'>Activate Account</ButtonWithSpinner>
          </div>
        </form>
      )}
    </>
  )
}

export default function ActivateAccountPage ({ data }) {
  const { title } = data

  const [query] = useQueryParamsWithRoute({
    activationUrl: withDefault(StringParam, null)
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true, skipUpdateWhenNoChange: true })

  const { activationUrl } = query

  return (
    <Section>
      <div className='mx-auto max-w-lg width-full font-bold text-40'>
        <h1 className='text-center uppercase mb-16 md:mb-4'>{title}</h1>
        <ActivateAccountForm data={data} activationUrl={activationUrl} />
      </div>
    </Section>
  )
}
