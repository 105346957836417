import cn from 'clsx'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'
import { SCENES, useShowSceneCallback } from '../Canvas/sceneState'
import RichContent from '../RichContent'
import Section from '../Section'

export default function About ({ data, settings }) {
  const showScene = useShowSceneCallback()
  const { textRows, image } = data

  useEffect(() => {
    showScene(SCENES.ABOUT, { image })
    return () => showScene(SCENES.NONE)
  }, [data, showScene])

  return (
    <Section className='pt-[30vh] relative z-[3]'>
      <Section as='div' grid noGutter noBottomMargin className={cn('md:flex md:justify-between mb-2 md:mb-7')}>
        <div className='uppercase text-10 md:text-14 col-span-4 font-550'><RichContent content={settings?.definitionLabels?.title} /></div>
        <div className='uppercase text-10 md:text-14 col-span-4 font-serif italic'>{settings?.definitionLabels?.wordClass}</div>
        <div className='uppercase text-10 md:text-14 col-span-3 font-serif italic'>{settings?.definitionLabels?.phonetic}</div>
        <div className='uppercase text-10 md:text-14 col-span-1'>{settings?.definitionLabels?.description}</div>
      </Section>
      {textRows?.map(row => {
        if (row._type === 'time') {
          return (
          <CountDown key={row._key}/>
          )
        }

        if (row.smallText) {
          return (
          <div
            key={row._key}
            className={cn('text-center uppercase text-10 tracking-slight',
              row.marginBottom && 'mb-[6rem] md:mb-[4.2rem]'
            )}
          >
            {row.text}
          </div>
          )
        }

        return (
          <div
            key={row._key}
            className={cn('font-serif font-250 tracking-[-0.02em] leading-[0.8em] text-[5rem] md:text-32 uppercase',
              row.align === 'right' && 'text-right max-w-[80vw] ml-auto', row.align === 'centre' && 'text-center',
              row.marginBottom && 'mb-[6rem] md:mb-[4.2rem]',
              row.indent && !row.indentTitle && 'indent-[20vw]',
              row.indent && row.indentTitle && 'relative'
            )}
          >
            {row.indent && row.indentTitle && <span className='inline-block w-[20vw] font-sans font-normal text-left uppercase text-10 tracking-slight md:opacity-0'>{row.indentTitle}</span>}
            {row.text}
          </div>
        )
      })}
    </Section>
  )
}

function CountDown () {
  const ref = useRef()

  useEffect(() => {
    let cancelToken
    const loop = () => {
      const date = dayjs().format('YYYY.MM.DD.HH.MM.ss.SSS')
      ref.current.innerHTML = date.split('').map(x => {
        if (x === '.') return `<span class="dot">${x}</span>`
        return `<span>${x}</span>`
      }).join('')
      cancelToken = window.requestAnimationFrame(loop)
    }
    loop()

    return () => window.cancelAnimationFrame(cancelToken)
  }, [])

  return (
    <div
      ref={ref}
      className={cn('font-serif font-250 tracking-[-0.02em] leading-[0.8em] text-[5rem] md:text-32 uppercase',
        'text-center [&>span]:inline-block [&>span]:w-[0.54em] [&>span]:text-center [&>.dot]:w-[0.2em] tracking-[-0.0em]'
      )}
    >
      date
    </div>
  )
}
