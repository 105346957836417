import { useFrame } from '@react-three/fiber'
import { primaryInput } from 'detect-it'
import { useAtomValue } from 'jotai'
import forEach from 'lodash/forEach'
import { createContext, useContext, useEffect, useLayoutEffect, useRef } from 'react'
import * as THREE from 'three'
import { Vector2 } from 'three'
import { clamp, lerp } from 'three/src/math/MathUtils'
import { menuOpenAtom } from '../Menu/menuState'

export const CustomScrollerContext = createContext()

let globalScrollListeners = []

export function useScrollPosition (handler) {
  const scrollerContext = useContext(CustomScrollerContext)
  useEffect(() => {
    if (scrollerContext?.current) {
      const scroller = scrollerContext?.current
      scroller.listeners.push(handler)
      return () => {
        scroller.listeners = scroller.listeners.filter(h => h !== handler)
      }
    } else {
      globalScrollListeners.push(handler)
      return () => {
        globalScrollListeners = globalScrollListeners.filter(h => h !== handler)
      }
    }
  }, [handler])
}

const CustomScroller = ({ enabled, children, lock = [1, 0], dragOnDesktopEnabled = false }) => {
  const containerRef = useRef()

  const scrollRef = useRef({
    previousScroll: new THREE.Vector2(0, 0),
    target: new THREE.Vector2(0, 0),
    prevTarget: new THREE.Vector2(0, 0),
    scroll: new THREE.Vector2(0, 0),
    wheelFactor: 0.007,
    ease: 0.15,
    easeMobile: 0.35,
    listeners: [],
    loop: new THREE.Vector2(1, 1),
    min: new THREE.Vector2(Infinity, 0),
    max: new THREE.Vector2(0, Infinity),
    velocity: new THREE.Vector2(0, 0),
    previousVelocity: new THREE.Vector2(0, 0),
    friction: 0.94,
    dragMultiplier: primaryInput === 'touch' ? 0.04 : 0.015,
    lock: new THREE.Vector2(...lock),
    mouse: {
      dragging: false,
      pointerDown: false,
      original: new THREE.Vector2(0, 0),
      prev: new THREE.Vector2(0, 0),
      current: new THREE.Vector2(0, 0)
    },
    disabled: false,
    wheelDirection: 'y'
  })

  const menuOpen = useAtomValue(menuOpenAtom)
  scrollRef.current.disabled = menuOpen && primaryInput === 'touch'

  useLayoutEffect(() => {
    if (!enabled) return
    const onWheel = (e) => {
      const scroller = scrollRef.current
      scroller.target[scroller.wheelDirection] += e.deltaY * scroller.wheelFactor
      scroller.prevTarget.copy(scroller.target)
    }

    const onTouchStart = (e) => {
      const { mouse, disabled } = scrollRef.current
      if (disabled) return
      mouse.pointerDown = true
      mouse.original.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
      mouse.original.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY
      mouse.current.copy(mouse.original)
      mouse.prev.copy(mouse.original)
      // scrollRef.current.target.y = containerRef.current.position.y
      // scrollRef.current.target.x = -containerRef.current.position.x
      e.stopPropagation()
    }

    const onTouchEnd = () => {
      const { mouse } = scrollRef.current
      mouse.pointerDown = false
      mouse.dragging = false
    }

    const onTouchMove = (e) => {
      const scroller = scrollRef.current
      const { mouse } = scroller

      mouse.prev.copy(mouse.current)
      mouse.current.x = e.changedTouches ? e.changedTouches[0].clientX : e.clientX
      mouse.current.y = e.changedTouches ? e.changedTouches[0].clientY : e.clientY

      if (!mouse.pointerDown) return

      if (!mouse.dragging) {
        const underDragThreshold = Math.abs(mouse.original.x - mouse.current.x) < 0.5 || Math.abs(mouse.original.y - mouse.current.y) < 0.5
        if (underDragThreshold) return
        mouse.dragging = true
      }

      scroller.prevTarget.copy(scroller.target)
      scroller.target.x += (mouse.prev.x - mouse.current.x) * scroller.dragMultiplier
      scroller.target.y += (mouse.prev.y - mouse.current.y) * scroller.dragMultiplier

      e.stopPropagation()
    }

    const domElement = document
    domElement.addEventListener('wheel', onWheel, { passive: true })
    domElement.addEventListener('touchstart', onTouchStart)
    domElement.addEventListener('touchend', onTouchEnd)
    domElement.addEventListener('touchmove', onTouchMove, { passive: true })
    domElement.addEventListener('mousemove', onTouchMove, { passive: true })

    if (dragOnDesktopEnabled) {
      domElement.addEventListener('mousedown', onTouchStart)
      domElement.addEventListener('mouseup', onTouchEnd)
    }

    document.documentElement.style.overscrollBehaviorY = 'none'
    document.body.style.overscrollBehaviorY = 'contain'
    document.body.style.overscrollBehaviorX = 'contain'
    document.body.style.touchAction = 'none'

    return () => {
      domElement.removeEventListener('wheel', onWheel)
      domElement.removeEventListener('touchstart', onTouchStart)
      domElement.removeEventListener('touchend', onTouchEnd)
      domElement.removeEventListener('touchmove', onTouchMove)
      domElement.removeEventListener('mousemove', onTouchMove)

      if (dragOnDesktopEnabled) {
        domElement.removeEventListener('mousedown', onTouchStart)
        domElement.removeEventListener('mouseup', onTouchEnd)
      }

      document.documentElement.style.overscrollBehaviorY = ''
      document.body.style.overscrollBehaviorY = 'inherit'
      document.body.style.overscrollBehaviorX = 'inherit'
      document.body.style.touchAction = ''
    }
  }, [enabled, dragOnDesktopEnabled])

  useFrame(() => {
    if (!enabled) return
    const scroller = scrollRef.current
    const { mouse } = scroller

    // When we are dragging we record the velocity, once we stop we apply the velocity to the velocity
    // to the drag to simulate momentum
    if (mouse.dragging) {
      scroller.velocity.x = scroller.target.x - scroller.prevTarget.x
      scroller.velocity.y = scroller.target.y - scroller.prevTarget.y
    } else {
      scroller.target.x += scroller.velocity.x
      scroller.target.y += scroller.velocity.y
      scroller.velocity.multiplyScalar(scroller.friction)
    }
  })

  useFrame(() => {
    if (!enabled) return
    const scroller = scrollRef.current

    if (!scroller.loop.x) {
      scroller.target.x = clamp(scroller.target.x, scroller.min.x, scroller.max.x)
    }
    if (!scroller.loop.y) {
      scroller.target.y = clamp(scroller.target.y, scroller.min.y, scroller.max.y)
    }

    const ease = primaryInput === 'touch' ? scroller.easeMobile : scroller.ease
    const newPos = new Vector2(0, 0)
    newPos.x = Math.round(lerp(-containerRef.current.position.x, scroller.target.x, ease) * 1000) / 1000
    newPos.y = Math.round(lerp(containerRef.current.position.y, scroller.target.y, ease) * 1000) / 1000

    const checkLoopBounds = (axis) => {
      if (scroller.loop[axis] && scroller.min[axis] !== Infinity) {
        if (newPos[axis] < scroller.min[axis]) {
          const diff = Math.abs(newPos[axis] - scroller.min[axis])
          const targetDiff = Math.abs(newPos[axis] - scroller.target[axis])
          newPos[axis] = scroller.max[axis] - diff
          scroller.target[axis] = scroller.max[axis] - targetDiff
        } else if (newPos[axis] > scroller.max[axis]) {
          const diff = Math.abs(newPos[axis] - scroller.max[axis])
          const targetDiff = Math.abs(newPos[axis] - scroller.target[axis])
          newPos[axis] = scroller.min[axis] + diff
          scroller.target[axis] = scroller.min[axis] + targetDiff
        }
      }
    }

    if (!scroller.lock.x) {
      checkLoopBounds('x')
      containerRef.current.position.x = -newPos.x
    }
    if (!scroller.lock.y) {
      checkLoopBounds('y')
      containerRef.current.position.y = newPos.y
    }

    // containerRef.current.updateWorldMatrix(true, false)
    scroller.scroll.copy(containerRef.current.position)
    if (!scroller.scroll.equals(scroller.previousScroll) || !scroller.velocity.equals(scroller.previousVelocity)) {
      forEach(scroller.listeners, listener => listener(scroller))
      forEach(globalScrollListeners, listener => listener(scroller))
    }
    scroller.previousScroll.copy(scroller.scroll)
    scroller.previousVelocity.copy(scroller.velocity)
  })

  return (
    <CustomScrollerContext.Provider value={scrollRef}>
      <group ref={containerRef}>
        {children}
      </group>
    </CustomScrollerContext.Provider>
  )
}

export default CustomScroller
