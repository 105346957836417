import { useFrame, useThree } from '@react-three/fiber'
import forEach from 'lodash/forEach'
import reverse from 'lodash/reverse'
import React, { forwardRef, useContext, useImperativeHandle, useLayoutEffect, useRef } from 'react'

export const LayoutManagerContext = React.createContext()

export const useInvalidateElement = () => {
  const context = useContext(LayoutManagerContext)
  return context?.invalidate
}

const LayoutManager = forwardRef(({ children }, forwardRef) => {
  const ref = useRef()
  const localsRef = useRef({ invalidated: true, paused: false })
  const { width } = useThree(({ size }) => size)

  const invalidate = (element) => {
    if (element) {
      localsRef.current.invalidated = true
      element.invalidated = true
      let parent = element.parent
      while (parent) {
        if (parent?.ui) {
          parent.invalidated = true
        }
        parent = parent.parent
      }
    }
  }

  const reflow = (force) => {
    if (ref.current && (force || localsRef.current.invalidated)) {
      if (localsRef.current.paused) {
        localsRef.current.pendingReflow = true
        return
      }
      localsRef.current.invalidated = false
      const elements = []
      // We need to traverse the tree, then reverse the order so we get the elements in the correct order
      ref.current.traverse(child => {
        if ((child.invalidated || !child.initialFlow) ||
          (child.initialFlow && force)) {
          elements.push(child)
        }
      })
      reverse(elements)
      forEach(elements, element => {
        element.invalidated = false
        element.initialFlow = true
        if (element.onReflow) {
          element.onReflow(element)
        }
      })
    }
  }

  useFrame(() => {
    reflow()
  })

  useLayoutEffect(() => {
    localsRef.current.invalidated = true
    reflow()
  }, [width])

  useImperativeHandle(
    forwardRef,
    () => ({
      reflow: (force) => {
        localsRef.current.invalidated = true
        reflow(force)
      },
      pauseReflow: () => { localsRef.current.paused = true },
      resumeReflow: (triggerReflow) => {
        localsRef.current.paused = false
        if (triggerReflow || localsRef.current.pendingReflow) {
          localsRef.current.pendingReflow = false
          reflow()
        }
      }
    }),
    []
  )

  return (
    <LayoutManagerContext.Provider value={{ invalidate }}>
      <group ref={ref}>
        {children}
      </group>
    </LayoutManagerContext.Provider>
  )
})

export default LayoutManager
