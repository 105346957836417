
import { getBackgroundColorFromImage } from '../../helpers/image'
import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import Section from '../Section'
import Video from '../Video'

function getTextClasses (layout) {
  if (layout === 'left') return 'col-start-7 col-span-5 md:col-span-full md:text-center'
  if (layout === 'right') return 'col-start-2 col-span-5 md:col-span-full md:text-center'
  if (layout === 'up') return 'col-start-4 col-span-6 upMd:text-center md:col-span-full md:text-center'
  if (layout === 'down') return 'col-start-4 col-span-6 upMd:text-center md:col-span-full md:text-center'
}

function getMediaClasses (layout) {
  if (layout === 'left') return 'col-start-2 col-span-4 row-start-1 md:col-start-2 md:col-span-6'
  if (layout === 'right') return 'col-start-8 col-span-4 row-start-1 md:col-start-2 md:col-span-6'
  if (layout === 'up') return 'col-start-5 col-span-4 row-start-1 md:col-start-2 md:col-span-6'
  if (layout === 'down') return 'col-start-5 col-span-4 md:col-start-2 md:col-span-6'
}

export default function RichTextWithMedia ({ data }) {
  const { layout, text, media, ctaLink } = data ?? {}

  
  if (layout === 'fullWidth') {
    return (
      <Link link={ctaLink} showText={false} showExternalIcon={false}>
        <Section grid noGutter className='relative'>
          <div className='!absolute z-[2] inset-0 flex items-center justify-center text-center uppercase font-550 -tracking-slight leading-none text-[5vw] text-white'>
            <RichContent content={text} className='max-w-[60vw]' />
          </div>
          <Media media={media} className='col-span-full'/>
        </Section>
      </Link>
    )
  }
  
  const textClasses = getTextClasses(layout)
  const mediaClasses = getMediaClasses(layout)

  return (
    <Section grid className='my-[6rem] upMd:my-[12.5rem] gap-y-[6rem] md:gap-y-[3rem] items-center'>
      <div className={textClasses}>
        <RichContent content={text} />
        {ctaLink && <Link link={ctaLink} className='uppercase !text-10 font-550 tracking-slight inline-flex items-center gap-1 mt-10 md:mt-4' />}
      </div>
      <Media media={media} className={mediaClasses} />
    </Section>
  )
}

function Media ({ media, ...props }) {
  const { type } = media 
  const isImage = type === 'image'
  const isVideo = type === 'video'
  return (
    <>
      {isImage && media.image && <ResponsiveImage image={media.image} {...props} />}
      {isVideo && media.video && <Video video={media.video} controls={false} loop autoPlay {...props}  />}
    </>
  )
}