import cn from 'clsx'
import gsap from 'gsap'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { clamp } from 'three/src/math/MathUtils'
import useIsomorphicLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import useScrollPosition from '../../../hooks/useScrollPosition'
import { getRect } from '../../Canvas/ProductHero'
import { SCENES, useShowSceneCallback } from '../../Canvas/sceneState'
import Section from '../../Section'
import Heading from '../../Typography/Heading'
import { useSite } from '../../siteState'
import ProductAddToCartButton from '../ProductAddToCartButton'
import { SmallTitle } from './BundleHero'
import WineImageSlider from './WineImageSlider'

export function WineCode ({ product, className }) {
  const site = useSite()
  const year = product.yearVintage || site.labels.nonVintageText

  return (
    <div className={cn('whitespace-nowrap text-10 uppercase col-span-1 text-right justify-self-end', className)}>
      <div className='font-serif font-250'>
        {product.winery?.code || product.wineryCode}.{product.range?.code || product.rangeCode}&mdash;{year}
      </div>
      <div className='font-serif font-250 flex justify-between'>
        <span>{product.varietal?.code || product.varietalCode}</span>
        <span>{product.country?.code || product.countryCode}</span>
      </div>
    </div>
  )
}

export default function WineHero ({ data, page }) {
  const showScene = useShowSceneCallback()
  const images = !isEmpty(data?.images) ? data?.images : [page.productImages?.featureImage]

  useEffect(() => {
    showScene(SCENES.PRODUCT_DETAILS, {
      ...data,
      images,
      image: images[0],
      pageId: page._id
    })
    return () => { showScene(SCENES.NONE) }
  }, [data, page, showScene, images])

  const imageAspect = images[0]?.asset?.metadata?.dimensions?.aspectRatio

  const rootRef = useRef()
  const sidebarRef = useRef()
  const localsRef = useRef({ imageTarget: 0, imageCurrent: 0 })

  const resize = useCallback(() => {
    const sidebarEl = sidebarRef.current.children[0]
    localsRef.current.vw = window.innerWidth
    localsRef.current.sidebarRect = sidebarEl.getBoundingClientRect()
    localsRef.current.containerRect = sidebarRef.current.getBoundingClientRect()
    localsRef.current.containerTop = sidebarRef.current.getBoundingClientRect().top + window.scrollY
  })

  useIsomorphicLayoutEffect(resize, [])

  useScrollPosition(useCallback(() => {
    if (!sidebarRef.current || !rootRef.current) return

    if (window.innerWidth !== localsRef.current.vw) {
      resize()
    }

    const sidebarEl = sidebarRef.current.children[0]
    const { sidebarRect, containerRect, containerTop } = localsRef.current ?? {}

    const s = clamp(window.scrollY - containerTop, 0, containerRect.height - (sidebarRect.height * 2))
    sidebarEl.style.transform = `translate3d(0, ${s}px, 0)`
  }, []))

  const imageSpacerHeight = useMemo(() => {
    return images?.reduce((current, image) => {
      const { imageHeight } = getRect(image, 1)
      return imageHeight + 0.5 + current
    }, 0)
  }, [images])

  return (
    <Section grid noBottomMargin ref={rootRef}>
      <div className='col-span-full hidden md:flex absolute top-[60vh] w-[calc(100%-2rem)] justify-center z-1 overflow-hidden'>
        <WineImageSlider images={images} className='w-full'/>
      </div>
      <div
        className='col-span-full md:[writing-mode:vertical-rl] md:!text-right md:rotate-180 md:origin-top-center md:ml-[-4px] md:pr-[8px] md:mb-[61px] md:overflow-x-hidden md:max-w-full invisible md:visible md:min-h-[calc(55vw/var(--aspect))]'
        style={{ '--aspect': imageAspect }}
      >
        {data.title?.map(titleRow => {
          const isSerif = titleRow.font === 'serif'
          const isRight = titleRow.align === 'right'

          return (
            <Heading
              as='div'
              styleAs={`h1${isSerif ? '-serif' : ''}`}
              key={titleRow._key}
              className={cn(isRight && 'text-right md:!text-right')}
              lineCount={data.title.length}
            >
              {titleRow.text}
            </Heading>

          )
        })}
      </div>

      {/* <div className='absolute top-[min(60vw,calc(100vh-2.5rem))] left-4 right-4 col-span-full md:hidden mix-blend-difference z-[10]' style={{ color: 'white' }}>
        <MetaBar data={data} page={page} className='col-span-full' />
      </div> */}

      {/* 50vw spacer for bottle webgl animation */}
      <div className='min-h-[calc(56vw*var(--height))] md:h-auto col-span-full' style={{ '--height': imageSpacerHeight }} ref={sidebarRef}>
        <Section className='mt-[15rem] md:mt-12 col-span-full !w-full' noGutter noBottomMargin grid>
          <div className='col-span-3 md:col-span-full'>
            <SmallTitle title={data?.sidebarTitle || data.title} />
            <p className='text-14 mt-6'>{data.description}</p>
            <ProductAddToCartButton product={page} className='mt-6' />
          </div>
          <div className='col-start-10 col-span-3 md:col-span-full'>
            <Specs specs={page.productSpecs} tags={data.tags} awards={page.productAwards} />
          </div>
        </Section>
      </div>

      <Details details={page.productDetails} />
      {/* <MetaBar data={data} page={page} className='upMd:hidden col-span-full w-full md:!flex md:justify-between z-10' /> */}

      <div className='col-span-full h-[1px] bg-current opacity-10 mt-2 md:hidden' />
    </Section>
  )
}

function MetaBar ({ data, page, className, ...props }) {
  const site = useSite()
  const year = page.yearVintage || site.labels.nonVintageText

  const title = data?.metaTextOverride?.length > 0 ? data.metaTextOverride : data.title

  return (
    <Section as='div' grid noGutter noBottomMargin className={cn('leading-none', className)} {...props}>
      {title?.slice(0, 3).map((titleRow, i) => (
        <div
          key={titleRow._key}
          className={cn(
            'text-10 font-550 uppercase col-span-4',
            i === 2 && 'col-span-3 md:max-w-[12em]',
            i === 3 && 'col-span-1 font-serif !font-250 text-right'
          )}
        >
          {titleRow.text}
          {i === 2 && <div>{year}</div>}
        </div>
      ))}

      <WineCode product={page} />
    </Section>
  )
}

function Specs ({ specs, tags, awards, className, ...props }) {
  return (
    <div className={cn('grid grid-cols-3 gap-4', className)} {...props}>
      <div className="col-span-1 text-10 uppercase tracking-[0.05em] leading-[130%] opacity-50 md:mt-[7.529rem]">
        {specs?.map(spec => <div key={spec._key} className={cn(spec.showSpace && 'mb-[1em]')}>{spec.title}</div>)}
        {tags && tags?.length < 0 && <div className='mt-6'>Categories</div>}
        {awards && <div className='mt-6'>Awards</div>}
      </div>

      <div className="col-span-2 text-10 uppercase tracking-[0.05em] leading-[130%] md:mt-[7.529rem]">
        {specs?.map(spec => <div key={spec._key} className={cn(spec.showSpace && 'mb-[1em]')}>{spec.text}</div>)}

        {tags && tags?.length < 0 && <div className='flex items-end md:col-span-full z-10 mt-6'>
          {tags?.map(tag => (
            <div key={tag._id} className={cn('text-8 font-550 uppercase tracking-[0.02em] py-1 pt-[0.3rem] px-[0.4375rem] border ml-[-1px]')}>{tag?.title}</div>
          ))}
        </div>}

        {awards && <Awards awards={awards} className='mt-6' />}
      </div>
    </div>
  )
}

function Awards ({ awards, className, ...props }) {
  const firstAwards = awards.slice(0, 1)
  const restAwards = awards.slice(1, awards.length)

  const accordionRef = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    gsap.to(accordionRef.current, {
      height: isOpen ? 'auto' : 0,
      duration: 0.6,
      ease: 'power2.inOut'
    })
  }, [isOpen])

  const toggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen])

  const buttonString = isOpen ? `show less awards` : `show more awards`

  return (
    <div className={className} {...props}>
      <div className='flex flex-col gap-2'>
        {firstAwards?.map(award => (
          <AwardItem key={award._id} award={award} />
        ))}
      </div>
      {restAwards.length > 0 && <>
        <div ref={accordionRef} className='h-0 overflow-hidden'>
          <div className='flex flex-col gap-2 mt-2'>
            {restAwards?.map(award => (
              <AwardItem key={award._id} award={award} />
            ))}
          </div>
        </div>
        <button onClick={toggle} className='mt-2 border text-8 font-550 uppercase tracking-[0.02em] py-1 pt-[0.3rem] px-[0.4375rem] hover:bg-foreground hover:text-background hover:border-foreground'>
          {buttonString}
        </button>
      </>}
    </div>
  )
}

function AwardItem ({ award }) {
  return (
    <div className='grid grid-cols-5 gap-4' >
      <span>{award.level}</span>
      <span>{award.year}</span>
      <span className='col-span-3'>{award.title}</span>
    </div>
  )
}

function Details ({ details }) {
  return (
    <Section className='col-span-full flex !flex-row justify-between !w-full mt-[6rem]' noGutter>
      {details?.map((detail) => (
        <div
          className={cn(
            'col-span-2 md:col-span-4 uppercase text-12 flex items-center gap-4',
            details.length === 3 && '!col-span-4'
          )}
          key={detail._key}
        >
          {/* {i === 0 && <div className='w-[0.5rem] h-[0.5rem] rounded-full bg-current md:hidden'/>} */}
          <div>
            <strong>{detail.title}</strong>
            <div className='font-serif font-250'>{detail.text}</div>
          </div>
        </div>
      ))}
    </Section>
  )
}
