import { useFrame, useThree } from '@react-three/fiber'
import lerp from 'lerp'
import { useCallback, useRef } from 'react'
import useScrollPosition from '../../hooks/useScrollPosition'
import { useViewport } from './hooks'

const LenisScroller = ({ children }) => {
  const viewport = useViewport()
  const containerRef = useRef()
  const camera = useThree(({ camera }) => camera)
  const targetRef = useRef(0)

  useScrollPosition(useCallback(({ scroll }) => {
    if (containerRef.current) {
      const offset = camera.isPerspectiveCamera ? scroll / viewport.factor : scroll
      targetRef.current = offset
    }
  }, [viewport.factor]))

  useFrame(() => {
    containerRef.current.position.y = lerp(containerRef.current.position.y, targetRef.current, 0.25)
  })

  return (
    <group ref={containerRef}>
      {children}
    </group>
  )
}

export default LenisScroller
