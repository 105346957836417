import { atom, useSetAtom } from 'jotai'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import { Fragment, useCallback, useContext, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import Slices from '..'
import { TRACKING_LIST_TYPES, TrackingContextProvider } from '../../../gtm/TrackingContext'
import { categories, layouts, useFilterQueryParams, useHumanReadableFilterText, useResetFilters } from '../../Menu/SecondaryNavs/ShopFilter/filterState'
import useGenerateFilterOptions from '../../Menu/SecondaryNavs/ShopFilter/useGenerateFilterOptions'
import { SECONDARY_MENUS, secondMenuTypeAtom } from '../../Menu/menuState'
import Section from '../../Section'
import { SmoothScrollerContext } from '../../SmoothScroller'
import { useGiftWrappingProducts } from '../../siteState'
import BrandBanner from './BrandBanner'
import ListView from './ListView'
import NoResults from './NoResults'
import ProductListingHeader from './ProductListingHeader'
import ProductTile from './ProductTile'
import SubscriptionsTab from './SubscriptionsTab'
import useFilterProducts from './useFilterProducts'
import useGenerateRows from './useGenerateRows'
import useSortProducts from './useSortProducts'

const rowsPerPage = 2

export const hasMoreProductsAtom = atom(undefined)

function Products ({ data, page }) {
  const { products, slices, curatedProducts, showFirstProductAsFeatured } = data
  const scrollContext = useContext(SmoothScrollerContext)
  const giftWrapProducts = useGiftWrappingProducts()

  const [query, setQuery] = useFilterQueryParams(page)
  const { category, layout, sortBy, page: currentPage } = query

  const setSecondaryNavType = useSetAtom(secondMenuTypeAtom)
  useEffect(() => {
    setSecondaryNavType(SECONDARY_MENUS.shopFilter)
    return () => setSecondaryNavType(SECONDARY_MENUS.none)
  }, [])

  const _filteredProducts = useMemo(() => {
    // remove gift wrap products
    return products.filter(({ shopifyId }) => !giftWrapProducts.find(item => item?.shopifyId === shopifyId))
  }, [giftWrapProducts, products])

  const sortedProduct = useSortProducts(_filteredProducts, sortBy, curatedProducts)

  useGenerateFilterOptions(sortedProduct)

  const filteredProducts = useFilterProducts(sortedProduct)
  const filterText = useHumanReadableFilterText()?.join('-')
  const isFiltered = filterText !== ''

  const filteredRows = useGenerateRows(filteredProducts, showFirstProductAsFeatured, slices, sortBy, category, isFiltered)

  const loadMore = useCallback(() => {
    setQuery({ page: currentPage + 1 })
  }, [currentPage, setQuery])

  useEffect(() => {
    if (scrollContext.current?.lenis) {
      scrollContext.current?.lenis.scrollTo(0, { immediate: true })
    }
  }, [filterText])

  const isList = layout === layouts[1].value
  const isGrid = !isList

  const paginatedRows = useMemo(() => {
    if (isList) return compact(filteredRows.slice(0, (currentPage + 1) * rowsPerPage * 2))
    return compact(filteredRows.slice(0, (currentPage + 1) * rowsPerPage))
  }, [filteredRows, currentPage, isList])

  const setHasMoreProducts = useSetAtom(hasMoreProductsAtom)
  const hasMore = filteredRows && filteredRows.length >= ((currentPage + 1) * rowsPerPage)

  useEffect(() => {
    setHasMoreProducts(hasMore)
    return () => {
      setHasMoreProducts(undefined)
    }
  }, [hasMore, setHasMoreProducts])

  if (isEmpty(filteredProducts)) {
    return <NoResults data={data.noResults} />
  }

  return (
    <>
      {<BrandBanner wineries={data?.wineries} query={query}/>}

      {isGrid && <GridView paginatedRows={paginatedRows} isFiltered={isFiltered} page={page} />}
      {isList && <ListView rows={paginatedRows} />}

      {hasMore && (
        <LoadMore onAppearInView={loadMore} />
      )}
    </>
  )
}

export default function ProductListing ({ data, page }) {
  const { showCategories, subscriptions: subscriptionsData } = data
  const resetFilers = useResetFilters()

  const [query, setQuery] = useFilterQueryParams(page)
  const { category, layout, sortBy } = query

  const setSecondaryNavType = useSetAtom(secondMenuTypeAtom)
  useEffect(() => {
    setSecondaryNavType(SECONDARY_MENUS.shopFilter)
    return () => setSecondaryNavType(SECONDARY_MENUS.none)
  }, [])

  const onSetCategory = useCallback((c) => {
    resetFilers({ category: c })
  }, [resetFilers])

  const onSubscriptionsClick = useCallback(() => {
    resetFilers({ category: 'subscriptions' })
  }, [resetFilers])

  const onSetLayout = useCallback((x) => {
    resetFilers({ layout: x })
  }, [resetFilers])

  const onSetSortBy = useCallback((sort) => {
    setQuery({ sortBy: sort })
  }, [setQuery])

  const subscriptionsTab = category === 'subscriptions' || category === 'subscriptions-dynamic'

  return (
    <>
      <TrackingContextProvider list={TRACKING_LIST_TYPES.shop}>
        <ProductListingHeader
          sortBy={sortBy}
          setSortBy={onSetSortBy}
          showCategories={showCategories}
          categories={categories}
          category={category}
          setCategory={onSetCategory}
          layout={layout}
          setLayout={onSetLayout}
          subscriptionsData={subscriptionsData}
          onSubscriptionsClick={onSubscriptionsClick}
          tab={category}
          products={data?.products}
        />

        {subscriptionsTab && (
          <SubscriptionsTab page={page} data={data} showDynamicBundle={category === 'subscriptions-dynamic'} />
        )}
        {!subscriptionsTab && (
          <Products page={page} data={data} />
        )}

      </TrackingContextProvider>
    </>
  )
}

function GridView ({ paginatedRows, isFiltered, page }) {
  return (
    paginatedRows?.map((row, rowI) => (
        <Fragment key={rowI + 'fragment'}>
          {!isFiltered && row.slice && <Slices page={page} slices={[row.slice]}/>}
          {(row.type === 'feature' || row.type === 'standard') && (
            <Section grid className='z-[2] relative'>
              {row.products?.map((product, i) => (
                <ProductTile key={product._id} product={product} i={i} rowIndex={row.rowIndex} rowType={row.type} animateIn />
              ))}
            </Section>
          )}
        </Fragment>
    ))
  )
}

function LoadMore ({ onAppearInView }) {
  const { ref } = useInView({
    threshold: 0,
    rootMargin: '0% 0px 50% 0px',
    onChange: useCallback(inView => {
      if (inView) onAppearInView?.()
    }, [onAppearInView])
  })

  return (
    <div ref={ref}></div>
  )
}
