import cn from 'clsx'
import { forwardRef } from 'react'

// Need to do it like this so the tailwind can find them and not exclude them from the build of the css
const styles = {
  h1: 'text-[calc((100vw-48px)*0.105)] md:text-[calc((100vw-48px)*(0.320/var(--line-count)*4))] tracking-[-0.02em] leading-[0.8] font-semibold uppercase',
  'h1-serif': 'font-serif text-[calc((100vw-48px)*0.105)] md:text-[calc((100vw-48px)*(0.320/var(--line-count)*4))] tracking-[-0.02em] leading-[0.8] font-250 uppercase',
  'h1-secondary': 'text-[calc((100vw-48px)*0.105)] md:text-[calc((100vw-48px)*0.13)] tracking-[-0.02em] leading-[0.75] md:leading-[0.8] font-semibold uppercase'
}

const Heading = forwardRef((props, ref) => {
  const { as = 'h1', styleAs, className, lineCount, ...rest } = props
  const Component = as
  const fontClasses = styles[`${styleAs || as}`]
  return (
    <Component
      ref={ref}
      className={cn(className, fontClasses)}
      style={{
        '--line-count': lineCount
      }}
      {...rest}
    />
  )
})

export default Heading
