import cn from 'clsx'
import gsap from 'gsap'
import { useAtom } from 'jotai'
import last from 'lodash/last'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Button from '../Button'
import RichContent from '../RichContent'
import { useLabels, useSite } from '../siteState'
import { rememberAgeGateResponseAtom } from './introState'

const NumberInput = ({ className, value, error, ...props }) => {
  return (
    <input
      type='number'
      maxLength={1}
      required
      pattern="[0-9]*"
      {...props}
      className={cn(
        className,
        'placeholder-opacity-40 hide-spinner text-16 bg-transparent text-current border border-current w-[4em] h-[4em] md:w-[3.5em] md:h-[3.5em] flex text-center outline-none appearance-none rounded-none',
        'focus:border-current',
        !value && 'border-[rgba(255,255,255,0.4)]',
        error && 'border-error'
      )} />
  )
}

const YearInput = ({ onYearChange, error }) => {
  const containerRef = useRef()
  const placeholder = useMemo(() => {
    return ((new Date().getFullYear()) + '')
  }, [])
  const [values, setValues] = useState(['', '', '', ''])
  const setValue = (e, index) => {
    const value = e.target.value
    const newValues = [...values]
    // Ensures that there is on one value in the input
    newValues[index] = last(value)
    e.target.value = newValues[index]

    setValues(newValues)
    if (value !== '') {
      // If we set a value, then we move to the next input if there is one to move to
      const nextInput = containerRef.current.children[index + 1]
      nextInput?.focus()
    }

    const year = newValues.join('')
    if (year.length >= 4) {
      // If we have a valid year then set it
      onYearChange?.(year)
    } else {
      onYearChange?.('')
    }
  }

  const onKeyDown = useCallback((e, index) => {
    const key = event.key
    if (index > 0) {
      if (key === 'Backspace' || key === 'Delete') {
        const isEmpty = !values[index]
        if (isEmpty) {
          const prevInput = containerRef.current.children[index - 1]
          prevInput?.focus()
        }
      }
    }
  }, [values])

  return (
    <div className='flex gap-8 md:gap-4 mb-5 md:mb-2' ref={containerRef}>
      {values.map((value, index) => (
        <NumberInput key={index} placeholder={placeholder[index]} value={value} error={error} onKeyDown={(e) => onKeyDown(e, index)} onChange={(e) => { setValue(e, index) }} />
      ))}
    </div>
  )
}

const AgeGate = forwardRef(({ onAgeGatePassed, isAndroid }, ref) => {
  const { ageGateQuestion, ageGateRemember, ageGateRestricted } = useLabels()
  const { definitionLabels } = useSite()
  const containerRef = useRef()
  const contentRef = useRef()
  const [year, setYear] = useState('')
  const [error, setError] = useState('')
  const logoRef = useRef()
  const formRef = useRef()

  const [rememberChoice, setRemember] = useAtom(rememberAgeGateResponseAtom)

  const toggleRemember = useCallback((e) => {
    e.preventDefault()
    setRemember(v => !v)
  }, [])

  const validate = useCallback(() => {
    const currentYear = new Date().getFullYear()
    const yearNumber = parseInt(year)
    if (yearNumber > currentYear - 18) {
      setError(ageGateRestricted)
      return false
    } else {
      setError('')
      return true
    }
  }, [ageGateRestricted, year])

  const submitForm = useCallback((e) => {
    e.preventDefault()
    if (validate()) {
      onAgeGatePassed?.()
    }
  }, [onAgeGatePassed, validate])

  useImperativeHandle(
    ref,
    () => ({
      animateInTimeline: (logoElementFlip) => {
        const tl = gsap.timeline()
        const { left, top } = logoElementFlip.getBoundingClientRect()
        const { left: finalLef, top: finialTop } = logoRef.current.getBoundingClientRect()

        tl.set(containerRef.current, { opacity: 1 })
        tl.set(logoElementFlip, { opacity: 0 })
        tl.set(logoRef.current, { opacity: 1, x: left - finalLef, y: top - finialTop })
        tl.to(logoRef.current, { x: 0, y: 0, duration: 1.2, ease: 'expo.out' }, 0)
        tl.fromTo(contentRef.current, {
          opacity: 0,
          rotate: 10,
          transformOrigin: 'bottom left',
          y: '100%'
        }, {
          opacity: 1,
          rotate: 0,
          y: '0%',
          duration: 1.2,
          ease: 'expo.out',
          stagger: 0.15
        }, 0)
        tl.to(formRef.current, {
          opacity: 1,
          duration: 1.2,
          ease: 'expo.out'
        }, 0)
        tl.set(containerRef.current, {
          pointerEvents: 'all',
          onComplete: () => {
            if (containerRef.current) {
              const input = containerRef.current.querySelectorAll('input')[0]
              input?.focus()
              input?.click()
            }
          }
        })
        return tl
      },
      animateOutTimeline: () => {
        const tl = gsap.timeline()
        tl.to(containerRef.current, { opacity: 0, duration: 0.5, ease: 'sine.in' })
        tl.set(containerRef.current, { pointerEvents: 'none' })
        return tl
      }
    }),
    []
  )

  const onYearChange = useCallback((year) => {
    setYear(year)
    setError('')
  }, [])

  return (
    <div className={cn(
      'fixed top-[calc(50%-1.5em)] bg-black text-white z-cursor pointer-events-none mb-20 opacity-0',
      isAndroid ? 'md:mb-8 md:bottom-[unset] md:top-8' : 'md:bottom-0 md:top-auto'
    )} ref={containerRef}>
      <div className='flex flex-col'>
        <div className='flex justify-between mb-10 md:mb-6 gap-2'>
          <div className='uppercase text-14 font-550 overflow-hidden opacity-0' ref={logoRef}>
            <div><RichContent content={definitionLabels?.title} /></div>
          </div>
          <div className='uppercase text-14 font-serif font-250 overflow-hidden'>
            <div ref={contentRef}>{ageGateQuestion}</div>
          </div>
        </div>
        <form onSubmit={submitForm} className='m-auto opacity-0' ref={formRef}>
          <YearInput onYearChange={onYearChange} error={error} />
          <div className={cn('text-error text-14 text-center uppercase mb-5 md:mb-2', !error && 'invisible')}>{error || 'All Good'}</div>
          <Button
            type='submit'
            className={cn('inner uppercase z-10 relative mb-10 md:mb-6', !year && 'opacity-40')}
            justify='justify-center'
            text='text-14'
            disabled={!year}
            filled
          >
            Enter
          </Button>
          <input type='checkbox' className='hidden' checked={rememberChoice} onChange={() => {}} />
          <button
            onClick={toggleRemember}
            type='button'
            className='uppercase inner flex gap-2 text-10 font-sans tracking-slight font-normal items-center transition-[top] duration-300 ease-out m-auto'
          >
            <div className={cn(
              'w-[0.9375rem] h-[0.9375rem] relative border border-current mt-[-2px] flex items-center justify-center',
              'after:absolute after:inset-0 after:bg-current after:transition-transform',
              rememberChoice ? 'after:scale-100' : 'after:scale-0'
            )}>
              <svg width="0.4375rem" height="0.375rem" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg" className='z-10'>
                <path d="M6.33333 1L2.66667 4.66667L1 3" stroke={rememberChoice ? 'black' : 'transparent'} strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            {ageGateRemember}
          </button>
        </form>
      </div>
    </div>
  )
})

export default AgeGate
