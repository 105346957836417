import { useContext, useEffect, useRef, useCallback } from 'react'
import { SmoothScrollerContext } from '../Components/SmoothScroller'
import gsap from 'gsap'
import { primaryInput } from 'detect-it'
import useWindowResize from './useWindowResize'
import useDebouncedCallback from './useDebounceCallback'
import lerp from 'lerp'
import round from 'lodash/round'

const useStickyCursorPosition = (options = { distance: 100, pageOffset: true, ease: 0.05 }) => {
  const scrollContext = useContext(SmoothScrollerContext)

  const boundsRef = useRef({ })
  const transformTarget = useRef({ currentX: 0, currentY: 0, x: 0, y: 0 })
  const ref = useRef()

  const checkSticky = (mouse, target, element) => {
    const getScrollY = scrollContext?.current?.lenis?.scroll
    const distance = {
      x: target.x - mouse.x,
      y: target.y - mouse.y - (target.pageOffset ? getScrollY : 0)
    }

    const a = Math.atan2(distance.x, distance.y)
    const h = Math.sqrt(distance.x * distance.x + distance.y * distance.y)

    if (h < options.distance) {
      target.stick = true
      const x = -Math.sin(a) * h / 2.5
      const y = -Math.cos(a) * h / 2.5
      transformTarget.current = { ...transformTarget.current, x, y }
    } else if (h > options.distance && target.stick) {
      target.stick = false
      transformTarget.current = { ...transformTarget.current, x: 0, y: 0 }
    }
  }

  useEffect(() => {
    if (primaryInput !== 'touch') {
      const tick = () => {
        if (ref.current) {
          const { x, y, currentX, currentY } = transformTarget.current
          const targetY = lerp(currentY, y, options.ease)
          const targetX = lerp(currentX, x, options.ease)
          ref.current.style.transform = `translate(${round(targetX, 1)}px, ${round(targetY, 1)}px)`
          transformTarget.current.currentX = targetX
          transformTarget.current.currentY = targetY
        }
      }
      gsap.ticker.add(tick)
      return () => {
        gsap.ticker.remove(tick)
      }
    }
  }, [])

  useEffect(() => {
    if (primaryInput !== 'touch') {
      const updateMousePosition = (evnt) => {
        const e = evnt.detail && evnt.detail.pageX ? evnt.detail : evnt
        const y = e.pageY - scrollContext?.current?.lenis?.scroll
        checkSticky({ x: e.pageX, y }, boundsRef.current, ref.current)
      }
      window.addEventListener('mousemove', updateMousePosition, { passive: true })
      window.addEventListener('dragover', updateMousePosition, { passive: true })
      return () => {
        window.removeEventListener('mousemove', updateMousePosition)
        window.removeEventListener('dragover', updateMousePosition)
      }
    }
  }, [])

  const updateElementPosition = useCallback(() => {
    const getScrollY = scrollContext?.current?.lenis?.scroll
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      boundsRef.current = {
        x: bounds.left + bounds.width / 2,
        y: (options.pageOffset ? (bounds.top + getScrollY) : bounds.top) + bounds.height / 2,
        pageOffset: options.pageOffset
      }
    }
  }, [])

  useWindowResize(useDebouncedCallback(updateElementPosition, 200, []))
  useEffect(updateElementPosition)
  return ref
}

export default useStickyCursorPosition