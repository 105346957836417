import dayjs from 'dayjs'
import forEach from 'lodash/forEach'
import sumBy from 'lodash/sumBy'
import { useMemo } from 'react'

export function isInStock (product) {
  return !!product?.variants?.find(({ inStock }) => inStock)
}

export function isProduct (product) {
  return product?._type === 'product'
}

export function isReleased (product) {
  if (!product) return true
  const { _type, releasedDate } = product
  if (_type !== 'product' || !releasedDate) return true
  return dayjs().isAfter(dayjs(releasedDate))
}

export function useIsReleased (product) {
  return useMemo(() => isReleased(product), [product])
}

export function getProductPriceWithDiscount (product, sellingPlan) {
  const subtotalAmount = product.productCategory === 'bundle'
    ? sumBy(product.bundle?.products, product => product.priceRange?.minVariantPrice)
    : product?.priceRange?.minVariantPrice
  let totalAmount = subtotalAmount
  if (product.productCategory === 'bundle') {
    forEach(sellingPlan?.priceAdjustments, ({ adjustmentPercentage = 0 }) => {
      totalAmount *= 1 - (adjustmentPercentage / 100)
    })
  }
  return { subtotalAmount, totalAmount }
}

export function getSubscriptionProductPrice (product) {
  const adjustmentPercentage = getSellingPlanPercentageDiscount(getProductSubscriptionSellingPlan(product))
  const subtotalAmount = product?.priceRange?.minVariantPrice
  let totalAmount = subtotalAmount
  if (adjustmentPercentage) {
    totalAmount *= 1 - (adjustmentPercentage / 100)
  }
  return { subtotalAmount, totalAmount }
}

export function getProductSubscriptionSellingPlan (product) {
  return product?.sellingPlanGroups?.[0]?.sellingPlans?.[0]
}

export function getSellingPlanPercentageDiscount (sellingPlan) {
  return sellingPlan?.priceAdjustments?.[0]?.adjustmentPercentage || 0
}

export function hasProductSubscriptionSellingPlan (product) {
  return !!product.sellingPlanGroups?.[0]?.sellingPlans?.[0]
}
