import cn from 'clsx'
import dayjs from 'dayjs'
import gsap from 'gsap'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { createPageThemeTimeline, themeAtom, themes } from '../../state/theme'
import Button from '../Button'
import Logo from '../Logo.svg'
import RichContent from '../RichContent'
import Section from '../Section'
import { useCustomer } from '../Shop/accountState'
import { useAccountActions } from '../Shop/useAccountActions'
import { siteAtom, useLabels } from '../siteState'
// import Tabs from '../Tabs'
// import Tab from '../Tabs/Tab'
import SubscriptionHistory from './SubscriptionHistory'

export function UserProfile ({ data }) {
  const { profilePage } = data

  const {
    profileDetailsTabTitle,
    subscriptionHistoryCopy,
    subscriptionHistoryEmptyResultsCopy,
    subscriptionHistoryLinks,
    subscriptionHistoryTabTitle,
    subscriptionHistoryTitle
  } = profilePage

  const site = useAtomValue(siteAtom)
  const customer = useCustomer()
  const { logout } = useAccountActions()
  const [theme, setTheme] = useAtom(themeAtom)
  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setTheme(themes.light)
      }
    })
    createPageThemeTimeline(tl, 0.25, theme, themes.light, 0)
    return () => {
      tl.kill()
    }
  }, [])

  const { logoutLabel } = useLabels()
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Section>
      {/* <Tabs index={selectedTab} moveToIndex={setSelectedTab} className='col-span-full justify-center mb-28 md:mb-14'>
        <Tab title={profileDetailsTabTitle} value={0} onClick={setSelectedTab} />
        <Tab title={subscriptionHistoryTabTitle} value={1} onClick={setSelectedTab} />
      </Tabs> */}

      {selectedTab === 0 && (
        <div className='mx-auto mb-28 md:mb-14 max-w-[24.19rem] p-2 bg-cream'>
          <div className={cn('font-serif font-250 uppercase leading-[0.8em] break-all', customer.firstName.length <= 7 ? 'text-[4rem]' : 'text-[3rem]')}>{customer.firstName}</div>
          {/* <div className='font-serif font-250 text-[4rem] uppercase leading-[0.8em]'>{customer.lastName}</div> */}
          <Logo className='w-auto h-[3.19rem] ml-auto'/>
          <div className='text-12 text-center leading-none tracking-slight mt-1 uppercase'>
            <RichContent content={site.labels.profileSubHeading} />
          </div>
          <div className='flex flex-col h-[12.25rem] justify-between items-center mt-12 mb-[9.125rem]'>
            <div className='w-[1rem] h-[1rem] bg-current rounded-full'/>
            <div className='w-[1rem] h-[1rem] bg-current rounded-full'/>
          </div>

          <div className='flex justify-between font-550 text-10 tracking-slight uppercase'>
            <div>VNTNR<sub className='align-sup text-half static font-550'>TM</sub></div>
            {customer.createdAt && <div className='font-feature-zero'>member est {dayjs(customer.createdAt).format('YYYY.MM')}</div>}
            <div>{site.labels.profileMemberText}</div>
          </div>
          <div className='flex justify-between font-serif font-250 text-10 tracking-slight uppercase mt-1'>
            <div>{customer.email}</div>
            <div>vntnr.co</div>
          </div>
        </div>
      )}

      {selectedTab === 1 && (
        <SubscriptionHistory
          className='mb-28 md:mb-14'
          title={subscriptionHistoryTitle}
          copy={subscriptionHistoryCopy}
          links={subscriptionHistoryLinks}
          emptyCopy={subscriptionHistoryEmptyResultsCopy}
        />
      )}

      <Button className='w-full max-w-[24.19rem] mx-auto items-center h-[2.8125rem] z-10' px='px-6' text='text-10' justify='justify-center' onClick={logout}>
        {logoutLabel}
      </Button>
    </Section>
  )
}
