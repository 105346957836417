import { useCursor } from '@react-three/drei'
import { useCallback, useState } from 'react'
import Quote from '../../../Slices/Quote'
import HtmlBlock from '../../ui/HtmlBlock'
import TextCursor from './TextCusrsor'

export default function QuoteTile ({ tile, ...props }) {
  const [hovered, setHover] = useState()
  const { link } = tile
  useCursor(link && hovered, 'pointer', 'auto')
  const onCloseCursor = useCallback(() => {
    setHover(false)
  }, [])

  return (
    <HtmlBlock
      {...props}
      name='quote'
      htmlProps={{ center: true, className: 'w-[calc(100vw-var(--scrollbarWidth))] select-none' }}
      onPointerMove ={() => setHover(true)}
      onPointerOut={() => setHover(false)}
    >
      <Quote data={tile} showCursor={false} />
      {link && <TextCursor show={hovered} link={link} onClose={onCloseCursor} />}
    </HtmlBlock>
  )
}
