import BlockContent from '@sanity/block-content-to-react'
import cn from 'clsx'
import React, { forwardRef } from 'react'
import Button from './Button'
import Link from './Link'
import ResponsiveImage from './ResponsiveImage'
import Section from './Section'
import { ProductMeta } from './Slices/ProductListing/ProductTile'
import Video from './Video'

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return React.createElement(style, { className: `heading heading-${level}` }, props.children)
  }

  if (style === 'label') {
    return React.createElement('span', { className: 'block uppercase text-10 font-550 mb-4' }, props.children)
  }

  if (style === 'indentedText') {
    return React.createElement('p', { className: 'indent-[10vw]' }, props.children)
  }

  return BlockContent.defaultSerializers.types.block(props)
}

function AsideBlock ({ node }) {
  const { notes } = node
  return (
    <aside className='absolute right-4 text-14 w-[32vw] mt-[0.15rem] pr-2 md:hidden'>
      {notes?.length && <ol className='flex flex-col gap-4'>
        {notes?.map((note, i) => (
          <li key={note._key} className='grid grid-cols-aisde'>
            <div>{i + 1}</div>
            <RichContent content={note.text} />
          </li>
        ))}
      </ol>}
    </aside>
  )
}

export const serializers = {
  types: {
    block: BlockRenderer,
    image: ({ node }) => {
      return <ResponsiveImage image={node} className='col-start-1 col-span-6 md:col-span-full' />
    },
    imageWithMeta: ({ node }) => {
      return <ResponsiveImage image={node} className='col-start-1 col-span-6 md:col-span-full' />
    },
    video: ({ node }) => {
      const { video, autoPlay, controls } = node
      return <Video className='mb-4 col-start-1 col-span-6 md:col-span-full' video={video} autoPlay={autoPlay} controls={controls} />
    },
    tags: ({ node }) => {
      return (
        <div className='flex flex-wrap justify-start md:justify-center gap-6 md:gap-8 uppercase text-[0.323em] md:text-10 mt-10 font-550 col-span-6 md:col-span-full'>
          {node.tags.map(tag => <span key={tag}>{tag}</span>)}
        </div>
      )
    },
    aside: AsideBlock,
    inlineProduct: ({ node }) => {
      const { product } = node
      const featuredBgImage = product.images?.featuredBgImage
      const bottleImage = product?.images?.featureImage
      return (
        <Link link={product} className={cn('mr-4 group', node.floatLeft && 'float-left w-[11vw] md:block md:w-[40vw] md:float-none md:mb-2 mb-1')} showText={false}>
          <div className='aspect-[340/453] h-auto flex flex-col w-full relative overflow-hidden py-[2vw] md:py-[10vw]'>
            {featuredBgImage && (
              <ResponsiveImage
                className='!absolute top-0 left-0 right-0 bottom-0 z-[1] group-hover:scale-110 scale-100 transition-transform duration-600 ease-out-expo'
                image={featuredBgImage}
              />)
            }
            {bottleImage && (
            <ResponsiveImage
              className='w-full h-full z-[2]'
              image={bottleImage}
              contain={true}
              showPreview={false}
            />)}
          </div>
          <ProductMeta product={product} isSmall />
        </Link>
      )
    },
    boxGrid: ({ node }) => {
      const boxes = node.boxGrid
      return (
        <Section as='div' grid noGutter className='mt-32 md:mt-12 col-span-full'>
          {boxes?.map(({ title, copy }) => (
            <div key={title} className='text-14 col-span-6 md:col-span-full border px-6 py-4 aspect-[696/245] flex flex-col justify-between'>
              <span className='block font-550 mb-8 uppercase'>{title}</span>
              <p>{copy}</p>
            </div>
          ))}
        </Section>
      )
    },
    ctaList: ({ node }) => {
      return (
        <div className='col-start-7 col-span-6 md:col-start-1 md:col-span-full flex justify-center'>
          <div className='max-w-sm flex flex-col items-center gap-4 w-full'>
            {node.list?.map(({ link, buttonLayout }, i) => {
              if (buttonLayout) {
                return <Button tag={Link} key={i} link={link} showText={false} invert={i === 0} filled borderColor='border-black'>{link.text}</Button>
              }
              return <Link key={i} link={link} className='uppercase underline hover:no-underline text-12' />
            })}
          </div>
        </div>
      )
    }
  },
  marks: {
    link: ({ mark, children }) => {
      return (
        <Link link={{ ...mark }} showText={false} className='underline font-700 hover:no-underline'>
          {children}
        </Link>
      )
    },
    serif: ({ children }) => {
      return (
        <span className='font-serif font-250'>
          {children}
        </span>
      )
    },
    sup: ({ children }) => {
      return (
        <sup className='align-super text-third static inline ml-[0.15em]'>{children}</sup>
      )
    },
    sub: ({ children }) => {
      return (
        <sub className='align-sup text-third static inline ml-[0.15em]'>{children}</sub>
      )
    },
    sessionIndent: ({ children }) => {
      return (
        <span className='inline-block w-[8.33vw] md:w-[24vw] font-550'>{children}</span>
      )
    }
  }
}

const RichContent = forwardRef(({ className, blockContentClassName, style, content, children, serializer = serializers }, ref) => {
  return (
    <div className={cn(className)} ref={ref} style={style}>
      {content && (
        <BlockContent className={blockContentClassName} renderContainerOnSingleChild blocks={content} serializers={serializer} />
      )}
      {children}
    </div>
  )
})

RichContent.displayName = 'RichContent'

export default RichContent
