import cn from 'clsx'
import { useCallback } from 'react'

const Tab = ({ className, title, value, onClick }) => {
  const handleClick = useCallback((e) => {
    onClick?.(value, e)
  }, [value, onClick])
  return (
    <button
      className={cn(className, 'uppercase whitespace-nowrap md:min-h-[42px]')}
      onClick={handleClick}
      data-event='site interaction'
      data-type='button'
      data-description={`tab|${title}`}
      data-location='body'
    >
      {title}
    </button>
  )
}

export default Tab
