/* eslint-disable camelcase */
import { useSetAtom } from 'jotai'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import _values from 'lodash/values'
import { useCallback, useRef, useState } from 'react'
import { isUnder18 } from '../../helpers/dateValidator'
import Button, { ButtonWithSpinner } from '../Button'
import CheckboxField from '../Inputs/CheckboxField'
import DateInput from '../Inputs/DateInput'
import InputField from '../Inputs/InputField'
import { NAV_VIEWS, menuOpenAtom, navViewAtom } from '../Menu/menuState'
import RichContent from '../RichContent'
import { useLabels, useSite, useSiteId } from '../siteState'
import { newsletterPopupActionedAtom } from './state'

export default function NewsletterForm () {
  const [errorMessage, setErrorMessage] = useState('')
  const [successful, setSuccessful] = useState(false)
  const [loading, setLoading] = useState(false)
  const { closeLabel } = useLabels()
  const setNavView = useSetAtom(navViewAtom)
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const setActioned = useSetAtom(newsletterPopupActionedAtom)

  const [birthDate, setBirthDate] = useState(null)

  const site = useSite()
  const siteId = useSiteId()
  const {
    emailLabel,
    firstNameLabel,
    lastNameLabel,
    mobileLabel,
    dateOfBirthLabel,
    acceptTCLabel,
    signupLabel,
    subscribeButtonLabel,
    successTitle,
    successCopy
  } = site?.newsletter?.form || {}

  const ref = useRef()
  const [formData, setFormData] = useState({
    values: {
      last_name: '',
      first_name: '',
      email: '',
      mobile: '',
      date_of_birth: null,
      newsletter_sign_up: true,
      terms: false
    },
    errors: {}
  })

  const onFieldChanged = useCallback((e) => {
    setFormData(state => ({
      ...state,
      values: {
        ...state.values,
        [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
      }
    }))
  }, [])

  const validateForm = useCallback((values) => {
    const { terms } = values
    const errors = {}
    if (!terms) {
      errors.terms = 'Please accept the terms and conditions'
    }
    if (!birthDate || !birthDate.isValid()) {
      errors.birthDate = 'Please enter a valid date of birth'
    } else if (isUnder18(birthDate)) {
      errors.birthDate = 'You must be over 18 to subscribe'
    }

    if (!isEmpty(errors)) {
      setFormData(state => ({
        ...state,
        errors
      }))
      setErrorMessage(first(_values(errors)))
      return errors
    }
    setFormData(state => ({ ...state, errors: {} }))
    return null
  }, [birthDate])

  const onFormSubmit = useCallback(async (e) => {
    e.preventDefault()
    const errors = validateForm(formData.values)
    if (errors) return

    try {
      setLoading(true)

      const data = omit({
        ...formData.values,
        date_of_birth: birthDate.format('YYYY-MM-DD')
      }, ['terms'])

      const response = await fetch(
        `/api/${siteId}/subscribe`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.ok) {
        setSuccessful(true)
        setActioned(true)
      } else {
        setErrorMessage('Server error')
      }
    } catch (e) {
      if (e) {
        setErrorMessage('Error submitting your request')
        console.error('error', e)
      }
    } finally {
      setLoading(false)
    }
  }, [formData, birthDate, validateForm, siteId])

  const onCloseClick = useCallback(() => {
    setNavView(NAV_VIEWS.nav)
    setMenuOpen(false)
  }, [setMenuOpen, setNavView])

  return (
    <div className='p-navPadding border-t border-border' ref={ref}>
      {successful && (
        <div className='mt-3'>
          <div className='text-12 uppercase mb-6 font-bold'>{successTitle}</div>
          <RichContent content={successCopy} className='text-12 mb-6' />
          <Button onClick={onCloseClick}>{closeLabel}</Button>
        </div>
      )}
      {!successful && (
        <form onSubmit={onFormSubmit} className='[&>div]:mt-6 [&>div:first-child]:mt-0 placeholder-opacity-40 w-full mt-3'>
          <InputField name='first_name' label={`${firstNameLabel}*`} onChange={onFieldChanged} required placeholder='REQUIRED' text='text-10' />
          <InputField name='last_name' label={`${lastNameLabel}*`} onChange={onFieldChanged} required placeholder='REQUIRED' text='text-10' />
          <InputField name='email' label={`${emailLabel}*`} type='email' onChange={onFieldChanged} required placeholder='REQUIRED' text='text-10' />
          <InputField name='mobile' label={`${mobileLabel}*`} type='number' onChange={onFieldChanged} required placeholder='REQUIRED' text='text-10' />
          <DateInput display='block' label={`${dateOfBirthLabel}*`} setValue={setBirthDate} error={formData.errors.birthDate} text='text-10' labelText='text-10' />
          <CheckboxField name='newsletter_sign_up' onChange={onFieldChanged} checked={formData.values.newsletter_sign_up} label={signupLabel} error={formData.errors.newsletter_sign_up} />
          <CheckboxField name='terms' onChange={onFieldChanged} checked={formData.values.terms} label={<RichContent content={acceptTCLabel} className='[&_a]:underline [&_a:hover]:opacity-50' />} error={formData.errors.terms} />
          {errorMessage && <div className='my-4 text-10 uppercase text-error'>{errorMessage}</div>}
          <ButtonWithSpinner className='mt-8 mb-4' loading={loading} disabled={loading}>{subscribeButtonLabel}</ButtonWithSpinner>
        </form>
      )}
    </div>
  )
}
