import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { VIEWS } from '.'
import Button from '../../../Button'
import { useLabels } from '../../../siteState'
import { Check } from './TagFilter'
import { filtersCountAtom, useFilterActions } from './filterState'

export default function TagFilterViewAll ({ filter, setView }) {
  const handleBack = () => setView(VIEWS.default)
  const [selected, handleAdd, handleRemove] = useFilterActions(filter?.slug)
  const { backLabel = 'Back' } = useLabels()
  const counts = useAtomValue(filtersCountAtom)?.[filter?.slug]?.count

  return (
    <div className='flex flex-col h-full max-h-[calc(var(--window-height)-6rem)]'>
      <div className='scroll-container flex flex-col gap-6 pt-4 pb-4 px-2 overflow-x-hidden overflow-y-auto'>
        <div className='flex justify-center text-10 md:text-14 tracking-slight uppercase'>
          <button className='font-550 uppercase flex gap-1 tracking-slight' onClick={handleBack}>
            {backLabel}
          </button>
        </div>
        <div className='flex flex-col items-start gap-2 md:gap-4'>
          {filter?.options?.map((option, i) => {
            const isSelected = selected?.indexOf(option) !== -1
            const count = counts?.[option]
            const disable = !count && !isSelected

            return (
            <button
              key={option + i}
              className={cn('flex gap-2 md:gap-3 items-center', disable && 'opacity-50')}
              onClick={() => isSelected ? handleRemove(option) : handleAdd(option)}
              data-event='site interaction'
              data-type='button'
              data-description={`toggle filter ${option}`}
              data-location='filter'
              disabled={disable}
            >
              <div className={cn('h-[0.9375rem] w-[0.9375rem] border-current border flex items-center justify-center', isSelected && 'bg-black text-white border-black')}>
                {isSelected && <Check />}
              </div>
              <span className='flex uppercase text-12 md:text-14 tracking-slight'>{option}<span className='text-[0.65em] opacity-75 block ml-[2px]'>({count})</span></span>
            </button>
            )
          })}
        </div>
      </div>

      <Button
        className='m-2 max-w-[calc(100%-1rem)]'
        onClick={handleBack}
        data-event='site interaction'
        data-type='button'
        data-description='apply'
        data-location='filter'
      >
        Apply
      </Button>
    </div>
  )
}
