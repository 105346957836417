import { useAtomValue, useSetAtom } from 'jotai'
import compact from 'lodash/compact'
import orderBy from 'lodash/orderBy'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { getProductSubscriptionSellingPlan, hasProductSubscriptionSellingPlan } from '../../../../helpers/product'
import { useHumanReadableFilterText } from '../../../Menu/SecondaryNavs/ShopFilter/filterState'
import useGenerateFilterOptions from '../../../Menu/SecondaryNavs/ShopFilter/useGenerateFilterOptions'
import useCanAddProductToCart from '../../../Menu/SecondaryNavs/useCanAddProductToCart'
import { SECONDARY_MENUS, secondMenuTypeAtom } from '../../../Menu/menuState'
import Section from '../../../Section'
import { SmoothScrollerContext } from '../../../SmoothScroller'
import { useCustomSubscriptionBundle, useLabels } from '../../../siteState'
import { useAddToSubscriptionCartCallback, useSubscriptionCartFull } from '../../SubscriptionListing/subscriptionHooks'
import { subscriptionCartAtom } from '../../SubscriptionListing/subscriptionState'
import NoResults from '../NoResults'
import ProductTile from '../ProductTile'
import useFilterProducts from '../useFilterProducts'
import useSortProducts from '../useSortProducts'

const itemsPerPage = 12

export const BundleProductTile = ({ product, ...props }) => {
  const { bundleFullLabel, addToBundleLabel, soldOutLabel } = useLabels()
  const subscriptionCart = useAtomValue(subscriptionCartAtom)
  const quantityInCart = subscriptionCart.filter(x => x.shopifyId === product.shopifyId).length
  const canAddToCart = useCanAddProductToCart(product, 1 + quantityInCart)
  const isPackFull = useSubscriptionCartFull()
  const disabled = isPackFull || !canAddToCart || !hasProductSubscriptionSellingPlan(product)
  const text = !hasProductSubscriptionSellingPlan(product)
    ? 'No subscription has been setup for this product in recharge'
    : isPackFull
      ? bundleFullLabel
      : canAddToCart ? addToBundleLabel : soldOutLabel

  return (
    <ProductTile
      isProductForBundle
      product={product}
      disableAdd={disabled}
      addText={text}
      animateIn
      {...props}
    />
  )
}

export default function DynamicBundleListing ({ data, sortBy = 'latest' }) {
  const bundle = useCustomSubscriptionBundle()
  const [currentPage, setPage] = useState(1)
  const scrollContext = useContext(SmoothScrollerContext)

  const products = bundle?.bundle?.products

  const setSecondaryNavType = useSetAtom(secondMenuTypeAtom)
  useEffect(() => {
    setSecondaryNavType(SECONDARY_MENUS.shopFilter)
    return () => {
      setSecondaryNavType(SECONDARY_MENUS.none)
    }
  }, [])

  useGenerateFilterOptions(products)
  const _filteredProducts = useFilterProducts(products)

  const filterText = useHumanReadableFilterText()?.join('-')
  useEffect(() => {
    if (scrollContext.current?.lenis && filterText) {
      let scrollY = 0
      const dynamicBundleContent = document.getElementById('dynamic')
      if (dynamicBundleContent) {
        scrollY = dynamicBundleContent.offsetTop
      }
      scrollContext.current?.lenis.scrollTo(scrollY, { immediate: true })
    }
  }, [filterText])

  const sortedProduct = useSortProducts(_filteredProducts, sortBy)

  const loadMore = useCallback(() => {
    setPage(currentPage + 1)
  }, [currentPage])

  const paginatedProducts = useMemo(() => {
    return compact(sortedProduct.slice(0, currentPage * itemsPerPage))
  }, [sortedProduct, currentPage])

  const onAddToCart = useAddToSubscriptionCartCallback()

  const noResults = _filteredProducts.length === 0
  const noResultsData = {
    ...(data?.noResults || {}),
    recommendedProducts: [...orderBy(products, ['releasedDate'], ['desc'])].slice(0, 5)
  }

  if (noResults) {
    return <NoResults data={noResultsData} isBundle onAddToCart={onAddToCart} sellingPlan={getProductSubscriptionSellingPlan}/>
  }

  return (
    <Section grid>
      {paginatedProducts?.map((product, index) => (
        <BundleProductTile
          key={`${product._id}_${index}`}
          product={product}
          onAddToCart={onAddToCart}
          sellingPlan={getProductSubscriptionSellingPlan(product)}
          rowType='bundle-item'
        />
      ))}
      <LoadMore onAppearInView={loadMore} />
    </Section>
  )
}

function LoadMore ({ onAppearInView }) {
  const onChange = useCallback(
    inView => {
      if (inView) onAppearInView?.()
    },
    [onAppearInView]
  )
  const mergedOptions = {
    threshold: 0,
    rootMargin: '0% 0px 50% 0px',
    onChange
  }
  const { ref } = useInView(mergedOptions)

  return (
    <div ref={ref}></div>
  )
}
