import { useAtomValue, useSetAtom } from 'jotai'
import sumBy from 'lodash/sumBy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTrackEcommerce } from '../../../gtm'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { ButtonWithSpinner } from '../../Button'
import Link from '../../Link'
import LoadingTicker from '../../LoadingTicker'
import { menuOpenAtom } from '../../Menu/menuState'
import RichContent from '../../RichContent'
import { pageContentAtom } from '../../pageContentState'
import { useCartSettings } from '../../siteState'
import { CART_STATES, hasLineItemsAtom } from '../cartState'
import useCart from '../useCart'
import { useCartActions } from '../useCartActions'
import FreeShipping from './FreeShipping'
import GiftWrappingButtons from './GiftWrappingButtons'
import LineItem from './LineItem'

const ShippingLineItem = () => {
  const cart = useCart()
  const { shippingLabel = 'Shipping' } = useCartSettings()
  const shippingCost = useMemo(() => {
    const cartTotal = cart?.cost?.totalAmount?.amount
    if (cartTotal === cart?.cost?.subtotalAmount?.amount) return 0
    const lineItemsTotal = sumBy(cart.lines, line => line.cost.totalAmount.amount)
    return cartTotal - lineItemsTotal
  }, [cart])
  return shippingCost > 0
    ? (
    <li className='flex border-y border-border mt-[-1px] px-2 py-4 justify-between w-full'>
      <span className='uppercase text-12 font-550'>{shippingLabel}</span>
      <span className='text-12'>{formatCurrency(shippingCost)}</span>
    </li>
      )
    : null
}

export default function Cart () {
  const cart = useCart()
  const hasLineItems = useAtomValue(hasLineItemsAtom)
  const { showGiftWrappingButton, checkoutLabel, backToShopLink, cartLoadingCopy, emptyCartCopy } = useCartSettings()
  const { updateCartAttributes } = useCartActions()
  const [loading, setLoading] = useState()
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const trackEcommerce = useTrackEcommerce()
  const page = useAtomValue(pageContentAtom)

  useEffect(() => {
    // We set the loading to false here when the page changes. I do this so that when the user clicks back
    // after they clicked the checkout button we do not get a checkout spinner forever
    setLoading(false)
  }, [page])

  const onCheckoutClick = useCallback(async () => {
    setLoading(true)
    try {
      trackEcommerce('begin_checkout')
      await updateCartAttributes()
      setLoading(false)
      window.location.href = cart.checkoutUrl
    } catch (e) { // if we get an error then stop the spinner, otherwise we are navigating away so there is no need to stop it
      console.error('error', e)
      setLoading(false)
    }
  }, [cart?.webUrl])

  const onBackToShopClick = useCallback(() => {
    setMenuOpen(false)
  }, [backToShopLink])

  const lines = useMemo(() => {
    return cart.lines
  }, [cart])

  const { state, cost } = cart

  if (state === CART_STATES.uninitialized || state === CART_STATES.loading) {
    return (
      <div className='h-72 flex flex-col justify-center items-center text-14'>
        <LoadingTicker className='w-full' showBackground={false}>{cartLoadingCopy}</LoadingTicker>
      </div>
    )
  }

  return (
    <div className='h-[calc(100vh-100px)] max-h-[36rem] relative flex flex-col'>
      {!hasLineItems && (
        <div className='px-navPadding flex justify-center items-center uppercase text-12 absolute inset-0'>
          {state !== CART_STATES.updating
            ? <RichContent content={emptyCartCopy} className='text-center [&>div>p>a]:mt-2 [&>div>p>a]:block' />
            : (
              <div className='flex flex-col justify-center items-center'>
                <LoadingTicker className='w-full' showBackground={false}>{cartLoadingCopy}</LoadingTicker>
              </div>
              )}
        </div>
      )}
      {hasLineItems && (
        <>
          <ul className='flex-grow flex-shrink overflow-auto pt-[1px] px-navPadding'>
            {backToShopLink && (
              <li className='uppercase px-4 py-6 text-center text-10 font-550 border-b border-border -mb-[1px]'>
                <Link link={backToShopLink} onClick={onBackToShopClick} />
              </li>
            )}
            {lines?.map((line) => (
              <LineItem key={line.id} lineItem={line} />
            ))}
            <ShippingLineItem />
            {showGiftWrappingButton && <GiftWrappingButtons />}
          </ul>
          <div className='mx-navPadding flex-grow-0 flex-shrink-0 border-t border-border'>
            <FreeShipping />
            <ButtonWithSpinner
              onClick={onCheckoutClick}
              className='mb-2 z-10'
              loading={loading}
              disabled={!cart?.checkoutUrl || loading}
              filled
              invert
              border='border-none'
              dataDescription={checkoutLabel} dataLocation='cart'
            >
              <div className='w-full flex justify-between'>
                <span className='block mx-1'>{formatCurrency(cost.totalAmount.amount)}</span>
                <span className='block mx-1'>{checkoutLabel}</span>
              </div>
            </ButtonWithSpinner>
          </div>
        </>
      )}
    </div>
  )
}
