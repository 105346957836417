export default class reCAPTCHA {
  constructor(siteKey, action) {
    loadReCaptcha(siteKey)
    this.siteKey = siteKey
    this.action = action
  }

  async getToken() {
    let token = ''
    await window.grecaptcha.execute(this.siteKey, { action: this.action })
      .then((res) => {
        token = res
      })
    return token
  }
}

const loadReCaptcha = (siteKey) => {
  if (typeof document === 'undefined') return
  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
  document.body.appendChild(script)
}