import { shaderMaterial, useTexture } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import * as React from 'react'
import * as THREE from 'three'

const ImageMaterialImpl = shaderMaterial(
  {
    color: new THREE.Color('white'),
    map: null,
    displacementMap: null,
    displacementScale: 0,
    displacementOffset: 0,
    time: 0,
    distort: 0.1,
    opacity: 1,
    greyscale: false
  },
  /* glsl */ `
  uniform sampler2D displacementMap;
  uniform float time;
  uniform float distort;
  uniform float displacementScale;
  uniform float displacementOffset;
  varying vec2 vUv;
  varying vec2 newUv; // create seperate uv map for displacement texture

  float noise(vec2 pos) {
    return fract(sin(dot(pos, vec2(12.9898, 78.233))) * 43758.5453);
  }

  void main() {
    vUv = uv;
    newUv = uv + displacementOffset;
    vec4 image = texture2D(displacementMap, newUv);
    
    float n = noise(uv * 2.0); 
    vec4 noiseColor = vec4(vec3(n), 1.0);

    float gamma = 0.3;
    image.rgb = pow(image.rgb, vec3(gamma));
    
    image = mix(image, noiseColor, 0.1);
  
    vec3 pos = position + normal * image.r * displacementScale;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`,
  /* glsl */ `
  varying vec2 vUv;
  uniform sampler2D map;
  uniform float opacity;
  uniform bool greyscale;

  void main() {
    vec4 img = texture2D(map, vUv);
    vec3 greyScale = vec3(0.2, 0.2, 0.2);

    if (greyscale) {
      gl_FragColor = vec4( vec3(dot( img.rgb, greyScale)), img.a);
    } else {
      gl_FragColor = img;
    }
    gl_FragColor.a *= opacity;

    #include <tonemapping_fragment>
    #include <encodings_fragment>
  }
`
)

const ImageBase = React.forwardRef(
  (
    {
      children,
      segments = 90,
      scale = 1,
      greyscale = false,
      texture,
      opacity,
      ...props
    },
    ref
  ) => {
    extend({ ImageMaterial: ImageMaterialImpl })

    return (
      <mesh ref={ref} scale={Array.isArray(scale) ? [...scale, 1] : scale} {...props}>
        <planeGeometry args={[1, 1, segments, segments]} />
        <imageMaterial
          map={texture}
          displacementMap={texture}
          displacementScale={0}
          displacementOffset={0}
          side={THREE.DoubleSide}
          distort={0.02}
          transparent
          greyscale={greyscale}
          opacity={opacity}
        />
        {children}
      </mesh>
    )
  }
)

export default React.forwardRef(({ url, onLoad, ...props }, ref) => {
  const texture = useTexture(url, onLoad)
  return <ImageBase {...props} texture={texture} ref={ref} />
})
