import { useEffect, useRef } from 'react'

export default function useStopKeyPropagation (selector = 'input, textarea, select') {
  const ref = useRef()

  function stopKeyPropagate (e) {
    e.stopPropagation()
  }

  useEffect(() => {
    const els = ref.current.querySelectorAll(selector)
    els.forEach(el => el.addEventListener('keydown', stopKeyPropagate))
    return () => {
      els.forEach(el => el.removeEventListener('keydown', stopKeyPropagate))
    }
  }, [])

  return ref
}
