
import { usePerformanceMonitor } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useAtom, useAtomValue } from 'jotai'
import { EffectComposer, EffectPass, FXAAEffect, RenderPass } from 'postprocessing'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { HalfFloatType } from 'three'
import useImageBitmapLoader from '../../hooks/useImageBitmapLoader'
import { SceneEffect } from './Pass/SceneEffect'
import { canvasStateAtom } from './canvasState'
import { SCENES, sceneAtom } from './sceneState'

const Effects = ({
  enabled = true,
  autoClear = true
}) => {
  const composerRef = useRef()
  const [canvasState, setCanvasStateAtom] = useAtom(canvasStateAtom)
  const sceneState = useAtomValue(sceneAtom)
  const [effectEnabled, setEffectEnabled] = useState(true)

  usePerformanceMonitor({
    onChange: (data) => {
      const { factor } = data
      setEffectEnabled(factor > 0.5)
    }
  })

  const { scene, camera, gl, size, viewport } = useThree()
  const displacementMapTexture = useImageBitmapLoader('/img/transition-out-map.webp')

  const effectsEnabled = effectEnabled && enabled

  useFrame((state, delta) => {
    if (composerRef.current) {
      if (enabled) {
        gl.autoClear = autoClear
        composerRef.current.render(delta)
      }
    }
  }, effectsEnabled ? 1 : 0)

  useLayoutEffect(() => {
    const effects = []
    // const noiseEffect = new NoiseEffect({ premultiply: false })
    // noiseEffect.blendMode.opacity.value = 0.7
    // noiseEffect.blendMode.blendFunction = BlendFunction.REFLECT
    // const gridEffect = new GridEffect({ scale: 1.6 })

    const fxaaEffect = new FXAAEffect()
    fxaaEffect.minEdgeThreshold = 0.01
    effects.push(fxaaEffect)

    const sceneEffect = new SceneEffect()
    sceneEffect.displacementMap = displacementMapTexture
    sceneEffect.distortXEnabled = 1
    effects.push(sceneEffect)

    const renderPass = new RenderPass(scene, camera)
    const effectPass = new EffectPass(camera, ...effects) // , noiseEffect, gridEffect)

    const composer = new EffectComposer(gl, {
      depthBuffer: false,
      stencilBuffer: false,
      multisampling: 0,
      frameBufferType: HalfFloatType
    })

    composer.addPass(renderPass)
    composer.addPass(effectPass)

    composerRef.current = composer
    setCanvasStateAtom(state => ({
      ...state,
      sceneEffect
    }))

    return () => {
      fxaaEffect?.dispose()
      sceneEffect?.dispose()
      effectPass?.dispose()
      renderPass?.dispose()
      composer?.dispose()
    }
  }, [])

  useEffect(() => {
    composerRef.current?.setSize(size.width, size.height)
  }, [gl, size, viewport.dpr])

  useEffect(() => {
    if (canvasState.sceneEffect) {
      if (sceneState.scene === SCENES.FEED_ORDER || sceneState.scene === SCENES.WINERY_LISTING || sceneState.scene === SCENES.SESSIONS_LISTING || sceneState.scene === SCENES.ABOUT) {
        canvasState.sceneEffect.uniforms.get('intensity').value = 1
      } else {
        canvasState.sceneEffect.uniforms.get('intensity').value = 0
      }
    }
  }, [sceneState.scene, canvasState.sceneEffect])

  return null
}

export default Effects
