import cn from 'clsx'
import dayjs from 'dayjs'
import gsap from 'gsap'
import map from 'lodash/map'
import { useEffect, useRef, useState } from 'react'
import UnderlineLink from '../../../UnderlineLink'
import RichContent from '../../../RichContent'
import Section from '../../../Section'
import { useSite } from '../../../siteState'
import HtmlBlock from '../../ui/HtmlBlock'
import {default as OldFooter} from '../../../Footer'

const Links = ({ className, links, classes }) => {
  return links?.slice(0, 4).map((linkBlock, i) => (
    <nav className={cn('uppercase text-12 mb-14 md:mb-10', className, classes[i])} key={i}>
      <div className='mb-4 md:mb-6 font-550'>{linkBlock.title}</div>
        <ul>
        {linkBlock.links?.map(link => {
          return (
          <li key={link._key} className='mb-[4px] overflow-hidden text-ellipsis'>
            <UnderlineLink link={link} className='whitespace-nowrap leading-none pb-[1px] mt-[-1px]' />
          </li>
          )
        })}
      </ul>
    </nav>
  ))
}

const Time = ({ className }) => {
  const ref = useRef()
  const [time, setTime] = useState('00:00:00')
  useEffect(() => {
    const loop = () => {
      setTime(dayjs().format('HH:mm:ss'))
    }
    const interval = window.setInterval(loop, 1000)
    return () => {
      window.clearInterval(interval)
    }
  }, [])
  return (
    <div className={cn('flex justify-center text-[20.3vw] md:text-[19vw] col-span-full font-serif font-180 text-center leading-[0.8] whitespace-nowrap pointer-events-none', className)} ref={ref}>
      {map(time, (c, i) => <span className={cn('inline-block w-[0.6em]', c === ':' && 'relative bottom-[0.1em]')} key={i}>{c}</span>)}
    </div>
  )
}

const Footer = () => {
  const ref = useRef()
  const site = useSite()
  const title = site.definitionLabels?.title

  const linkClasses = [
    'row-start-3 col-start-3 col-end-5 md:row-start-2 md:col-start-1 md:col-end-5',
    'row-start-2 col-start-5 col-end-7 md:row-start-2 md:col-start-5 md:col-end-9 md:text-right',
    'row-start-3 col-start-10 col-end-12 md:row-start-[8] md:col-start-1 md:col-end-5',
    'row-start-2 col-start-8 col-end-10 md:row-start-[8] md:col-start-5 md:col-end-9 md:text-right'
  ]

  const bottomLinkClasses = [
    'row-start-7 col-start-3 col-end-5 md:hidden',
    'row-start-[8] col-start-5 col-end-7 md:hidden',
    'row-start-7 col-start-10 col-end-12 md:hidden',
    'row-start-[8] col-start-8 col-end-10 md:hidden'
  ]

  useEffect(() => {
    gsap.to(ref.current, { opacity: 1, duration: 1.2 })
  }, [])

  return (
    <Section grid width='w-[calc(100vw-2rem)]' className='my-24 opacity-0' noGutter noBottomMargin ref={ref}>
      <div className='text-12 col-start-6 col-span-2 row-start-1 md:col-start-4 uppercase text-center mb-14 md:mb-10'><RichContent content={title} /></div>
      <div className='text-12 col-start-6 col-span-2 row-start-[9] md:col-start-4 uppercase text-center'><RichContent content={title} /></div>

      <Links classes={linkClasses} links={site.footerLinks} />
      <Links classes={bottomLinkClasses} links={site.footerLinks} />
      <Time className='row-start-4' />
      <Time className='row-start-6 rotate-180 mb-14 md:row-start-5 md:mb-10' />

      <div className='uppercase text-10 col-start-1 col-span-4 row-start-5 md:row-start-3 md:col-span-full md:text-center md:mx-6 md:mb-10'>
        <RichContent content={site.aoc} />
      </div>
      <div className='uppercase text-10 col-start-9 col-span-2 row-start-5 md:row-start-6 md:col-start-1 md:col-span-full md:text-center md:max-w-[200px] md:mx-auto'>{site.copyright.replace('{YEAR}', new Date().getFullYear())}</div>
      <div className='uppercase text-10 col-start-11 col-span-2 row-start-5 text-right md:row-start-7 md:col-start-1 md:col-span-full md:text-center  md:max-w-[200px] md:mx-auto md:mb-10'>Liquor Licence: {site.liquorLicence}</div>
    </Section>

  )
}

export default function FooterTIle () {
  const site = useSite()
  return (
    <HtmlBlock htmlProps={{ center: true }}>
      {/* <Footer /> */}
      <OldFooter settings={site}/>
    </HtmlBlock>
  )
}
