import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import RichContent from '../../RichContent'
import Section from '../../Section'
import ProductAddToCartButton from '../ProductAddToCartButton'
import BundleHeroSlider from './BundleHeroSlider'
import HeroTitle from './HeroTitle'
import Heading from '../../Typography/Heading'

export default function BundleHero ({ data, page, isObject }) {
  const { title, productProperties, leftContent, rightContent, tags, description } = data

  return (
    <Section grid className='relative'>
      <HeroTitle title={title} className='mb-24 md:mb-0' />

      <div className='col-span-3 md:!col-span-full lg:col-span-5 md:row-start-3 md:mb-12'>
        {title && isObject && <SmallTitle title={title} className='mb-8 md:hidden' />}
        <p className='mb-6 text-14'>{description}</p>
        <ProductAddToCartButton product={page} className='mb-24 md:mb-12' />
        {!isEmpty(productProperties) && (
          <div className='text-10 uppercase grid grid-cols-3 gap-x-4 gap-y-1 mb-12'>
            {productProperties?.map(({ name, value }, i) => (
              <React.Fragment key={name}>
                <label className='w-1/3 opacity-50 col-span-1' style={{ rowStart: i + 1 }}>{name}</label>
                <span className='w-2/3 col-span-2' style={{ rowStart: i + 1 }}>{value}</span>
              </React.Fragment>
            ))}
          </div>
        )}
        <div className='flex'>
          {tags?.map((tag, i) => (
            <div key={i} className={cn('text-8 uppercase tracking-[0.02em] py-1 pt-[0.3rem] px-[0.4375rem] border ml-[-1px]')}>{tag?.title}</div>
          ))}
        </div>
      </div>

      <BundleHeroSlider
        page={page}
        overrideImages={data?.images}
        className='col-start-6 col-span-7 mb-24 md:mb-12 md:col-span-full md:col-start-1 md:row-start-2 -mr-4 md:mr-0 overflow-hidden md:overflow-visible'
      />

      <div className={cn('col-span-6 md:col-span-full', rightContent && 'md:row-start-5')}>
        <RichContent content={leftContent} />
      </div>
      {rightContent && (
        <div className='col-start-9 col-span-4 md:col-start:1 md:col-span-full md:row-start-4 md:mb-12'>
          <RichContent content={rightContent} />
        </div>
      )}

    </Section>
  )
}

export function SmallTitle ({ title, className, ...props}) {
  return (
    <div className={cn('col-span-full', className)} {...props}>
      {title?.map((titleRow, i) => {
        const isSerif = titleRow.font === 'serif'

        return (
          <Heading
            key={i}
            styleAs={`h1${isSerif ? '-serif' : ''}`}
            className='!text-16 !leading-110'
          >
            {titleRow.text}
          </Heading>
        )
      })}
    </div>
  )
}
