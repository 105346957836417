import gsap from 'gsap'
import { forwardRef, useEffect, useRef } from 'react'
import useComposeRefs from '../../hooks/useComposeRefs'

const FadeInImage = forwardRef(({ preload, children, ...props }, ref) => {
  const localRef = useRef()

  function fadeIn (el) {
    gsap.to(el, {
      opacity: 1,
      duration: 0.5,
      ease: 'power2.inOut'
    })
  }

  useEffect(() => {
    if (preload) return
    const img = localRef.current.querySelector('img')

    if (!img) return

    if (img?.complete) {
      fadeIn(img)
    } else {
      img.onload = () => fadeIn(img)
    }
  }, [preload])

  return (
    <div {...props} ref={useComposeRefs(localRef, ref)}>
      {children}
    </div>
  )
})

export default FadeInImage
