export function leftPad (num, targetLength) {
  return num.toString().padStart(targetLength, 0)
}

export function mapRange(in_min, in_max, input, out_min, out_max) {
  return ((input - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3)
}

export function easeInCubic(x) {
  return x * x * x;
} 

export function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}