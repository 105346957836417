import { useAtomValue } from 'jotai'
import find from 'lodash/find'
import { useRouter } from 'next/router'
import { useMemo, useRef } from 'react'
import { useQueryParams } from 'use-query-params'
import { pageContentAtom } from '../Components/pageContentState'
import { useSite } from '../Components/siteState'

const useQueryParamsWithRoute = (...args) => {
  const site = useSite()
  const router = useRouter()
  const page = useAtomValue(pageContentAtom)
  const slug = page?.slug
  // Some pages have a slug in
  let sliceSlug = find(page.slices, slice => slice?.slug?.current)?.slug?.current
  if (sliceSlug) sliceSlug = `${slug}/${sliceSlug}`
  let pathname = router.asPath.indexOf('?') >= 0 ? router.asPath.substring(0, router.asPath.indexOf('?')) : router.asPath
  pathname = pathname.replace(`/${site.countryCode}/`, '/')
  const [value, setValue] = useQueryParams(...args)
  const previousvalue = useRef(value)
  const resolvedValue = useMemo(() => {
    if (pathname === `/${slug}` || (sliceSlug && pathname === `/${sliceSlug}`)) {
      previousvalue.current = value
    }
    return previousvalue.current
  }, [value, pathname, slug, sliceSlug])
  return [resolvedValue, setValue]
}

export default useQueryParamsWithRoute
