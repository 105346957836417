import { useAtomValue } from 'jotai'
import last from 'lodash/last'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import transform from '../../ResponsiveImage/transform'
import { usePagePreloaded } from '../../pageContentState'
import { useIsMobile, useScreenToWorldPosition, useViewport } from '../hooks'
import { SCENES, sceneAtom } from '../sceneState'
import LayoutManager from '../ui/LayoutManager'
import Stack from '../ui/Stack'
import BodyFont from '../ui/Text/BodyFont'
import SansSerifFont from '../ui/Text/SansSerifFont'
import ProductHeroImage from './Image'

export function getAspectMultiplier (aspect) {
  return (aspect < 0.4 ? 18 : 24)
}

export function getRect (image, vw) {
  const aspect = image?.asset?.metadata?.dimensions?.aspectRatio || 1

  const imageWidth = vw * (getAspectMultiplier(aspect) / 100)
  const imageHeight = imageWidth / aspect

  return {  aspect, imageWidth, imageHeight }
}

const gap = 0.5

function DeformedImage ({ image, data, index, onLoad, ...props }) {
  const groupRef = useRef()
  const imageRef = useRef()
  const viewport = useViewport()

  const { images } = data
  const { aspect, imageWidth, imageHeight } = getRect(image, viewport.width)

  const previousHeight = useMemo(() => {
    return images?.slice(0, index)?.reduce((current, image) => {
      const { imageHeight } = getRect(image, viewport.width)
      return imageHeight + gap + current
    }, 0)
  }, [images, index, viewport])

  const position = useScreenToWorldPosition(0, `${50 - (getAspectMultiplier(aspect) / 2)}%`, 150, imageWidth, imageHeight)

  const primaryImage = useMemo(() => {
    if (!image) return null
    return transform(image, aspect, [320, 420, 640, 800, 1024, 1200])
  }, [aspect, image])

  return (
    <group position={position} ref={groupRef}>
      <ProductHeroImage
        ref={imageRef}
        url={last(primaryImage.sizes).url}
        width={primaryImage.width}
        height={primaryImage.height}
        scale={[imageWidth, imageHeight, 1]}
        displacementScale={0}
        position={[0, -4-(previousHeight), 2]}
        onLoad={onLoad}
        {...props}
      />
    </group>
  )
}

export default function ProductHero () {
  const data = useAtomValue(sceneAtom)?.data[SCENES.PRODUCT_DETAILS]
  const viewport = useViewport()
  const isMobile = useIsMobile()
  const padding = (viewport.width * 0.02)
  const maxWidth = viewport.width - (padding * 2)
  const preloadedRef = useRef({ text: false, image: false })
  const preloaded = usePagePreloaded(data.pageId)

  const onFontSync = useCallback(() => {
    preloadedRef.current.font = true
    if (preloadedRef.current.image && preloadedRef.current.font) preloaded()
  }, [preloaded])
  const onImageLoaded = useCallback(() => {
    preloadedRef.current.image = true
    if (preloadedRef.current.image && (preloadedRef.current.font || isMobile)) preloaded()
  }, [preloaded, isMobile])

  useEffect(() => {
    if (isMobile) onImageLoaded()
  }, [isMobile])

  if (!data) return

  const { images, title } = data
  return (
    <>
      {!isMobile && (
        <group position={[(-viewport.width / 2) + padding, (viewport.height / 2) - (viewport.height * 0.2), 0]}>
          <LayoutManager>
            <Stack>
              {title.map(({ align, font, text }) => {
                const position = align === 'left' ? [0, 0, 0] : [viewport.width - (padding * 2), 0, 0]
                const ComponentFont = font === 'sans' ? BodyFont : SansSerifFont
                return (
                  <ComponentFont
                    key={text}
                    lineHeight={0.8}
                    fontSizes={{ lg: 1.5, md: 1.5, sm: 1.5 }}
                    position={position}
                    anchorX={align}
                    maxWidth={maxWidth}
                    onSync={onFontSync}
                    material-opacity={0}
                    mater
                    textAlign={align || 'left'}
                  >
                    {text.toUpperCase()}
                  </ComponentFont>
                )
              })}
            </Stack>
          </LayoutManager>
        </group>
      )}
      {!isMobile && (
        <>
          {images?.map((image, i) => (
            <DeformedImage key={i} image={image} data={data} onLoad={onImageLoaded} opacity={0} index={i} />
          ))}
        </>
      )}
    </>
  )
}
