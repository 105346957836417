
import { useState, useCallback, useRef, useEffect } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import { leftPad } from '../../helpers/math'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import cn from 'clsx'
import { useIsMobile } from '../../state/isMobile'

export default function ImageCarousel ({ data }) {
  const { images, layout } = data

  if (layout === 'columns') return <ColumnCarousel data={data} />

  const [currentSlide, setCurrentSlide] = useState(0)

  const [refCallback, instanceRef] = useKeenSlider(
    {
      slides: {
        perView: 1,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
        ScrollTrigger.refresh()
      },
    },
    []
  )

  const nextSlide = useCallback(() => {
    instanceRef.current.next()
  }, [instanceRef])

  const prevSlide = useCallback(() => {
    instanceRef.current.prev()
  }, [instanceRef])

  return (
    <Section noGutter>
      <div ref={refCallback} className="keen-slider border-y border-current">
        {images?.map(image => (
          <div key={image._key} className="keen-slider__slide cursor-grab active:cursor-grabbing">
            <ResponsiveImage image={image.image} aspect={1440/786} mobileAspect={393/214} />
          </div>
        ))}
      </div>
      <Section as="div" grid noBottomMargin className="py-4 relative z-[10] items-start">
        <Caption 
          currentIndex={currentSlide}
          items={images} 
          className='col-span-4 text-12 md:col-start-1 md:col-span-full md:row-start-2' 
        />

        <div className='col-start-7 col-span-2 flex items-center gap-4 justify-end font-feature-zero md:col-start-4 md:col-span-2 md:row-start-1 md:justify-center'>
          <Counter currentIndex={currentSlide} items={images} />
          <span className='block w-[0.5rem] h-[0.5rem] md:w-[0.25rem] md:h-[0.25rem] rounded bg-current' />
          <span className='text-40 md:text-14'>
            {leftPad(images.length, 2)}
          </span>
        </div>

        <button className='col-start-11 text-14 uppercase tracking-slight font-550 md:col-start-1 md:col-span-1 md:row-start-1' onClick={prevSlide}>Prev</button>
        <button className='text-14 uppercase tracking-slight font-550 text-right md:col-start-8 md:col-span-1 md:row-start-1' onClick={nextSlide}>Next</button>
      </Section>
    </Section>
  )
}

function Counter ({ currentIndex, items }) {
  const ref = useRef()
  
  useEffect(() => {
    const els = gsap.utils.toArray(ref.current.children)
    els.forEach((el, i) => {
      if (i === currentIndex) {
        gsap.fromTo(el, {
          yPercent: 110
        }, {
          yPercent: 0,
          duration: 0.6,
          ease: 'expo.out',
          overwrite: true
        })
      } else {
        gsap.to(el, {
          yPercent: -110,
          duration: 0.6,
          ease: 'expo.out'
        })
      }
    })
  }, [currentIndex])

  const maskStyles = {
    maskImage: 'linear-gradient(0deg,transparent,#000 10%,#000 95%,transparent)',
    WebkitMaskImage: 'linear-gradient(0deg,transparent,#000 10%,#000 95%,transparent)'
  }

  return (
    <span className='text-40 md:text-14 relative overflow-hidden w-[1.3em] h-[1em] md:mt-[-0.1em] md:pb-[1.129em]' ref={ref} style={maskStyles}>
      {items.map((x, i) => (
        <span key={i} className='absolute left-0 top-0 text-right w-full font-feature-zero-tabular'>{leftPad(i + 1, 2)}</span>
      ))}
    </span>
  )
}

function Caption ({ currentIndex, items, className }) {
  const ref = useRef()

  useEffect(() => {
    // gsap.to(ref.current, {
    //   height: ref.current.children[currentIndex].getBoundingClientRect().height,
    //   duration: 0.6,
    //   ease: 'expo.out',
    //   onComplete: () => ScrollTrigger.refresh()
    // })

    const els = gsap.utils.toArray(ref.current.children)
    els.forEach((el, i) => {
      const isActive = i === currentIndex

      gsap.set(el, {
        userSelect: isActive ? 'auto' : 'none',
        pointerEvents: isActive ? 'all' : 'none'
      })

      if (isActive) {
        gsap.fromTo(el, {
          y: 16,
          opacity: 0,
        }, {
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: 'expo.out',
          overwrite: true
        })
      } else {
        gsap.to(el, {
          opacity: 0,
          y: -16,
          duration: 0.6,
          ease: 'expo.out'
        })
      }
    })
  }, [currentIndex])

  return (
    <div className={cn(className, 'relative h-[4em] overflow-hidden line-clamp-3 text-ellipsis')} ref={ref}>
      {items?.map((item, i) => (
        <div key={i} className='absolute left-0 top-0'>
          {item.caption}
        </div>
      ))}
    </div>
  )
}

export function ColumnCarousel ({ data }) {
  const { images } = data
  const isMobile = useIsMobile()
  
  const [refCallback, instanceRef] = useKeenSlider({
    slides: {
      perView: 'auto',
      spacing: () => {
        const windowWidth = window?.innerWidth
        return windowWidth > 1440 ? windowWidth * 0.011111 : 16
      }
    },
  }, [])

  const getSlideStyles = (slide) => {
    const isTall = slide.tall
    const aspect = slide.aspect || slide?.image?.asset?.metadata?.dimensions?.aspectRatio
    const factor = isMobile ? 1.4 : 1
    const width = (isTall ? 40 : 25) * factor

    setTimeout(() => {
      if (instanceRef) {
        instanceRef?.current?.update()
      }
    }, 220)

    return {
      minWidth: width * aspect + 'vw',
      maxWidth: width * aspect + 'vw', 
    }
  }

  return (
    <Section noGutter className='px-4'>
      <div ref={refCallback} className="keen-slider !overflow-visible">
        {images?.map(slide => (
          <div
            key={slide._key}
            className="keen-slider__slide cursor-grab active:cursor-grabbing"
            style={getSlideStyles(slide)}
          >
            <ResponsiveImage image={slide.image} aspect={slide.aspect} imageSizes={`${getSlideStyles(slide).minWidth}`}/>
          </div>
        ))}
      </div>
    </Section>
  )
}