const routes = {
  routes: {
    nz: {
      path: '/nz',
      countryCode: 'nz'
    },
    au: {
      path: '/au',
      countryCode: 'au'
    }
  },
  defaultRoute: {
    path: '/au',
    countryCode: 'au'
  }
}

export default routes
