'use client'
import { useAtomValue, useSetAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useRef } from 'react'
import { useSiteId } from '../siteState'
import { useAccessTokenAtom } from './accountState'
import { cartAtom, CART_STATES, useCartIdAtom } from './cartState'
import fetchRequest from './fetchRequest'

export default function CartProvider ({ children }) {
  const localsRef = useRef({ running: false })
  const siteId = useSiteId()
  const accessToken = useAtomValue(useAccessTokenAtom())
  const cartIdAtom = useCartIdAtom()
  const setCartId = useSetAtom(cartIdAtom)
  const setCart = useSetAtom(cartAtom)
  const readCart = useAtomCallback(
    useCallback((get) => get(cartAtom), [])
  )

  const readCartId = useAtomCallback(useCallback((get) => get(cartIdAtom), [cartIdAtom]))

  const checkCart = useCallback(async () => {
    if (localsRef.current.running) return
    localsRef.current.running = true

    const cart = await readCart()
    const cartId = await readCartId()

    const createCart = async () => {
      const data = {
        cart: {
          lines: []
        },
        accessToken
      }
      const newCart = await fetchRequest(`/api/${siteId}/cart`, 'POST', data)
      if (isEmpty(newCart.errors)) {
        setCartId(newCart.cart.id)
        setCart({ ...newCart.cart, state: CART_STATES.ready })
      } else {
        setCart({ state: CART_STATES.error, stateData: newCart.errors })
      }
    }

    if (cart.state === CART_STATES.uninitialized) {
      setCart({ state: CART_STATES.loading })
      if (cartId === null) {
        await createCart()
      } else {
        try {
          const fetchCart = await fetchRequest(`/api/${siteId}/cart/${encodeURIComponent(cartId)}`)
          if (fetchCart && !fetchCart.completedAt) {
            setCart({ ...fetchCart, state: CART_STATES.ready })
          } else {
            await createCart()
          }
        } catch {
          await createCart()
        }
      }
    }
    localsRef.current.running = false
  }, [accessToken, siteId])

  useEffect(() => {
    checkCart()
  }) // This is here for dev so the cart isn't load on hot reloads

  return children
}
