import { useCallback, useState } from 'react'
import { BooleanParam, StringParam, withDefault } from 'use-query-params'
import useQueryParamsWithRoute from '../../hooks/useQueryParamsWithRoute'
import { ButtonWithSpinner } from '../Button'
import RichContent from '../RichContent'
import Section from '../Section'
import { useAccountActions } from '../Shop/useAccountActions'
import { useLabels } from '../siteState'
import Input from './Input'

const getErrorMessageFromPayload = async (e) => {
  return e.toString().replace('Error: ', '')
}

const RecoverForm = ({ label, placeholder }) => {
  const { recover } = useAccountActions()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const { resetPasswordLabel, resetPasswordSuccessMessage } = useLabels()
  const [success, setSuccess] = useState(false)
  const [validationError, setValidationError] = useState()

  const onFormSubmit = useCallback(async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      setValidationError('')
      await recover(email)
      setSuccess(true)
    } catch (e) {
      setValidationError(await getErrorMessageFromPayload(e))
    } finally {
      setLoading(false)
    }
  }, [recover, email])

  const onEmailChange = useCallback((e) => { setEmail(e.target.value) }, [])

  return (
    <form onSubmit={onFormSubmit} className='my-16'>
      <Input uppercase={false} name='email' type='email' value={email} onChange={onEmailChange} required placeholder={placeholder} label={label} className='py-5' />
      {validationError && <div className='text-error text-10 mt-2'>{validationError}</div>}
      <div className='flex flex-col justify-start items-start mt-16 md:mt-4'>
        <ButtonWithSpinner type='submit' loading={loading} disabled={loading || success} className='w-auto mx-auto mb-2'>{resetPasswordLabel}</ButtonWithSpinner>
        {success && (
          <div className='w-full uppercase mt-4 text-10 text-center font-normal'>
            {resetPasswordSuccessMessage}
          </div>
        )}
      </div>
    </form>
  )
}

const ResetForm = ({ data, resetUrl }) => {
  const [loading, setLoading] = useState(false)
  const { resetPassword } = useAccountActions()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validationError, setValidationError] = useState()
  const { newPasswordFieldLabel, reenterPasswordFieldLabel, passwordResetSuccessCopy } = data.resetPasswordForm
  const { resetPasswordLabel } = useLabels()
  const [success, setSuccess] = useState(false)

  const onResetPassword = useCallback(async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setValidationError('Your password and confirmed password do not match')
      return
    }
    try {
      setValidationError('')
      setLoading(true)
      await resetPassword({ password, resetUrl })
      setSuccess(true)
    } catch (e) {
      setValidationError(await getErrorMessageFromPayload(e))
    } finally {
      setLoading(false)
    }
  }, [password, confirmPassword, resetPassword, resetUrl])

  return (
    <>
      {success && (<RichContent className='text-16 text-center' content={passwordResetSuccessCopy} />)}
      {!success && (
        <form onSubmit={onResetPassword}>
          <Input uppercase={false} type='password' name='password' value={password} onChange={(e) => { setPassword(e.target.value) }} error={validationError} required label={newPasswordFieldLabel} className='py-5' containerClassName='mb-4' />
          <Input uppercase={false} type='password' name='reenterpassword' value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} error={validationError} required label={reenterPasswordFieldLabel} className='py-5' />
          {validationError && <div className='text-error text-10 mt-2'>{validationError}</div>}
          <div className='flex flex-col justify-start items-start mt-16 md:mt-4'>
            <ButtonWithSpinner type='submit' loading={loading} disabled={loading} className='w-auto mx-auto mb-2'>{resetPasswordLabel}</ButtonWithSpinner>
          </div>
        </form>
      )}
    </>
  )
}

export default function ResetPasswordForm ({ data }) {
  const { title } = data

  const [query] = useQueryParamsWithRoute({
    reset: withDefault(BooleanParam, false),
    resetUrl: withDefault(StringParam, null)
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true, skipUpdateWhenNoChange: true })

  const { reset, resetUrl } = query

  return (
    <Section>
      <div className='mx-auto max-w-lg width-full font-bold text-40'>
        <h1 className='text-center uppercase mb-16 md:mb-4'>{title}</h1>
        {reset && <ResetForm resetUrl={resetUrl} data={data} />}
        {!reset && <RecoverForm label={data.resetPasswordForm.emailFieldLabel} placeholder={data.resetPasswordForm.placeholder} />}
      </div>
    </Section>
  )
}
