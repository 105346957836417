import cn from 'clsx'
import dayjs from 'dayjs'
import { useCallback, useRef, useState } from 'react'

export default function DateInput ({ className, label, setValue, error, text = 'text-14', labelText = 'text-12', display = 'flex' }) {
  const dateClass = 'hide-spinner outline-0 py-1 text-12 uppercase placeholder-current bg-transparent text-current pl-2 md:font-550'
  const dayRef = useRef()
  const monthRef = useRef()
  const yearRef = useRef()
  const containerRef = useRef()

  const [year, setYear] = useState('')
  const [month, setMonth] = useState('')
  const [day, setDay] = useState('')

  const updateDate = (year, month, day) => {
    let dateValue = null
    if (year && month && day) {
      const dt = dayjs(`${year}-${month}-${day}`)
      if (dt.isValid()) {
        if (year === dt.format('YYYY') && month === dt.format('MM') && day === dt.format('DD')) {
          dateValue = dt
        }
      }
    }
    setValue(dateValue)
  }

  const handleYearChange = (e) => {
    const value = e.target.value
    setYear(value)
    updateDate(value, month, day)
  }

  const handleMonthChange = (e) => {
    const value = e.target.value
    setMonth(value)
    updateDate(year, value, day)
    if (value.length >= 2) {
      yearRef.current.focus()
    }
  }

  const handleDateChange = (e) => {
    const value = e.target.value
    setDay(value)
    updateDate(year, month, value)
    if (value.length >= 2) {
      monthRef.current.focus()
    }
  }

  const onKeyDown = useCallback((e, prevInputRef) => {
    const key = event.key
    if (key === 'Backspace' || key === 'Delete') {
      const isEmpty = !e.target.value
      if (isEmpty && prevInputRef) {
        prevInputRef.current?.focus()
      }
    }
  }, [])

  const containerClassName = cn('flex items-center border-l pl-2', error && 'border-error')

  return (
    <div className={cn(display, 'relative items-center border-b mb-8 pb-1', error && 'border-error')} ref={containerRef}>
      <span className={cn(labelText, 'font-550 uppercase whitespace-nowrap mr-4')}>{label}</span>
      <div className='flex items-center'>
        <div className={containerClassName} >
          <label className={cn(text, 'font-normal')} htmlFor='month'>D</label>
          <input name='day' ref={dayRef} type='number' pattern='[0-9]*' placeholder='24' min='0' maxLength='2' onChange={handleDateChange} className={cn(className, text, dateClass, 'w-[3.75em]')} onKeyDown={(e) => onKeyDown(e)} />
        </div>
        <div className={containerClassName}>
          <label className={cn(text, 'font-normal')} htmlFor='month'>M</label>
          <input name='month' ref={monthRef} type='number' pattern='[0-9]*' placeholder='04' min='0' maxLength='2' onChange={handleMonthChange} className={cn(className, text, dateClass, 'w-[3.75em]')} onKeyDown={(e) => onKeyDown(e, dayRef)} />
        </div>
        <div className={containerClassName}>
          <label className={cn(text, 'font-normal')} htmlFor='year'>Y</label>
          <input name='year' ref={yearRef} type='number' pattern='[0-9]*' placeholder='1980' min='0' maxLength='4' onChange={handleYearChange} className={cn(className, text, dateClass, 'w-full')} onKeyDown={(e) => onKeyDown(e, yearRef)} />
        </div>
      </div>
    </div>

  )
}
