import cn from 'clsx'
import gsap from 'gsap'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useOnOutsideClick from '../hooks/useOnOutsideClick'
import NoiseBackground from './Menu/NoiseBackground'

const DropdownItem = ({ value, text, selected, onSelected, classNames = {} }) => {
  const onOptionSelected = useCallback(() => {
    onSelected(value)
  }, [onSelected, value])

  return (
    <li className={cn('list-none p-0 m-0 cursor-pointer', classNames.dropdownItem, { selected })} onClick={onOptionSelected}>
      {text}
    </li>
  )
}

const Dropdown = ({ className, classNames = {}, options, value, onChange, placeholderText, padding = 'p-2', width = '' }) => {
  const [open, setOpen] = useState(false)
  const dropDownRef = useRef()
  const ref = useRef()

  const toggleOpen = () => {
    setOpen(!open)
  }

  useOnOutsideClick(ref, useCallback(() => {
    setOpen(false)
  }, []), true)

  const selectedText = useMemo(() => {
    return get(find(options, o => o.value === value), ['text'], placeholderText)
  }, [value, options, placeholderText])

  const onItemSelected = useCallback(value => {
    onChange(value)
  }, [onChange])

  useEffect(() => {
    if (dropDownRef.current) {
      gsap.to(dropDownRef.current, { height: open ? 'auto' : 0, duration: 0.15, ease: 'power2.inOut' })
    }
  }, [open])

  if (isEmpty(options)) return null

  return (
    <div ref={ref} className={cn('relative md:overflow-hidden', width, className)}>
      <div className={cn('absolute inline-block top-0 left-0 right-0 bottom-0 md:pointer-events-none bg-transparent -m-2 pb-0 mb-0 transition-colors', width, padding, classNames.dropdown, open && 'bg-whiteGlass')} onClick={toggleOpen}>
        <div className={cn('relative flex gap-2 z-[2] w-full bg-transparent h-full items-center cursor-pointer white-space-no-wrap', width, classNames.value, { open })}>
          <span className='text-ellipsis select-none whitespace-nowrap block overflow-hidden'>
            {selectedText}
          </span>
          <svg className={cn('shrink-0 transition-transform', { open })} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)' }}>
            <path d="M3.78761 4.55172C2.96165 2.86689 1.69911 1.79533 0 0.371525V0C0.684366 0.355951 1.30973 0.723767 1.87611 1.10345C2.44248 1.4594 2.96165 1.82722 3.43363 2.2069L4 2.68936L4.56637 2.20669C5.03835 1.82701 5.55752 1.4594 6.12389 1.10345C6.69026 0.723767 7.31563 0.355951 8 0V0.371525C6.30089 1.79533 5.03835 2.86689 4.21239 4.55172H3.78761Z" fill="currentColor"/>
          </svg>
        </div>
        <NoiseBackground light visible={open} className='absolute z-1' />
        <div className={cn('relative overflow-hidden h-0 z-[1] px-2 -mx-2 bg-transparent transition-colors', classNames.content, { open }, open && 'bg-whiteGlass')} ref={dropDownRef}>
          <ul className='list-none bg-transparent py-1 pb-2 mt-0 flex flex-col gap-1'>
            {map(options, (option, i) => <DropdownItem {...option} classNames={classNames} selected={option.value === value} key={i} onSelected={onItemSelected} />)}
          </ul>
          <NoiseBackground light visible={open} className='absolute' />
        </div>
      </div>
      <select className={cn('z-0 p-0 invisible md:visible uppercase md:opacity-0', classNames.select)} value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}

export default Dropdown
