import cn from 'clsx'
import RichContent from '../RichContent'
import Section from '../Section'
import ProductAddToCartButton from './ProductAddToCartButton'
import isEmpty from 'lodash/isEmpty'

export default function ProductNotes ({ data, page }) {
  const { copy, details, specs, includes, title } = data

  const isIncludesSlice = data._type === 'productIncludes'

  return <>
    <Section grid className='mt-[12.5rem] mb-[12.5rem] md:mt-32 md:mb-32'>
      <RichContent content={copy} className='col-span-6 md:col-span-full indent-[calc((100vw-40px)*0.08)] text-24 md:text-16 font-serif font-250 [&>div>p>strong]:font-sans [&>div>p>strong]:font-550'/>

      {!isIncludesSlice && specs && (
        <div className='col-span-4 col-start-9 md:col-span-full flex gap-12 flex-col'>
          <div className='grid grid-cols-4 gap-4'>
            <div className="col-span-2 text-10 uppercase tracking-[0.05em] leading-[130%] opacity-50 md:mt-[7.529rem]">
              {specs?.map(spec => (
                <div key={spec._key} className={cn(spec.showSpace && 'mb-[1em]')}>{spec.title}</div>
              ))}
            </div>

            <div className="col-span-2 text-10 uppercase tracking-[0.05em] leading-[130%] md:mt-[7.529rem]">
              {specs?.map(spec => (
                <div key={spec._key} className={cn(spec.showSpace && 'mb-[1em]')}>{spec.text}</div>
              ))}
            </div>
          </div>

          <ProductAddToCartButton product={page} />

        </div>
      )}

      {isIncludesSlice && includes && <div className="col-span-2 col-start-9 md:col-start-1 md:col-span-full md:row-start-1 md:mb-[4.7rem] text-10 uppercase tracking-[0.05em] leading-[130%] flex flex-col gap-y-8">
        <div className='text-8'>{title}</div>
        {includes.map(product => (
          <div key={product._id} className='leading-[1.1em]'>
            <div>{product.winery}</div>
            <div>{product.range}</div>
            <div>{product.varietal}</div>
            <div className='font-serif font-250'>{product.wineryCode}.{product.rangeCode}&mdash;{product.yearVintage}</div>
          </div>
        ))}
      </div>}
    </Section>

    {!isIncludesSlice && !isEmpty(details) && <Section grid className='mt-[12.5rem] mb-[12.5rem] md:mt-[7.529rem] md:mb-[7.529rem]'>
      {details?.map((detail) => (
        <div
          className={cn(
            'col-span-2 md:col-span-4 uppercase text-12 flex items-center gap-4',
            details.length === 3 && '!col-span-4'
          )}
          key={detail._key}
        >
          {/* {i === 0 && <div className='w-[0.5rem] h-[0.5rem] rounded-full bg-current md:hidden'/>} */}
          <div>
            <strong>{detail.title}</strong>
            <div className='font-serif font-250'>{detail.text}</div>
          </div>
        </div>
      ))}
    </Section>}
  </>
}
