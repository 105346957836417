import React, { useContext, useMemo } from 'react'

export const TRACKING_LIST_TYPES = {
  search: { id: 'search_results', name: 'search results' },
  feed: { id: 'homepage_highlight', name: 'highlight section' },
  shop: { id: 'products_catalogue', name: 'products catalogue' },
  relatedProducts: { id: 'related_products', name: 'related products' }
}

export const TrackingContext = React.createContext({})

export const TrackingContextProvider = ({ list, ...props }) => {
  const value = useMemo(() => ({ list }), [list])
  return (
    <TrackingContext.Provider value={value} {...props} />
  )
}

export const useTrackingContext = () => {
  return useContext(TrackingContext)
}
