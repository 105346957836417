import cn from 'clsx'
import Heading from '../../Typography/Heading'

export default function HeroTitle ({ title, className, lineCount, ...props }) {
  return (
    <div className={cn('col-span-full', className)} {...props}>
      {title?.map((titleRow, i) => {
        const isSerif = titleRow.font === 'serif'
        const isRight = titleRow.align === 'right'

        return (
          <Heading
            as='div'
            styleAs={`h1${isSerif ? '-serif' : ''}`}
            key={i}
            className={cn('md:!text-32', isRight && 'text-right md:text-left')}
            lineCount={lineCount}
          >
            {titleRow.text}
          </Heading>
        )
      })}
    </div>
  )
}
