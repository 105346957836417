import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useRef } from 'react'
import breakpoints from '../../theme/breakpoints'
import { mapRange } from '../helpers/math'
import { useLayoutEffect } from '../hooks/useIsomorphicLayoutEffect'
import useWindowSize from '../hooks/useWindowSize'

gsap.registerPlugin(ScrollTrigger)

export function Parallax ({
  className,
  style,
  children,
  speed = 1,
  mobileSpeed,
  id = 'parallax',
  position,
  rotate = false,
  start = 'top bottom',
  end = 'bottom top'
}) {
  const trigger = useRef()
  const target = useRef()

  const { width: windowWidth } = useWindowSize()

  useLayoutEffect(() => {
    const _speed = (windowWidth < breakpoints.md && mobileSpeed !== undefined) ? mobileSpeed : speed
    const y = windowWidth * _speed * 0.1

    const setY = gsap.quickSetter(target.current, 'y', 'px')
    const setRotate = gsap.quickSetter(target.current, 'rotate', 'deg')
    const set3D = gsap.quickSetter(target.current, 'force3D')

    const timeline = gsap.timeline({
      scrollTrigger: {
        id,
        trigger: trigger.current,
        scrub: true,
        start,
        end,
        // markers: true,
        onUpdate: (e) => {
          if (position === 'top') {
            setY(e.progress * y)
          } else {
            setY(-mapRange(0, 1, e.progress, -y, y))
          }
          if (rotate) {
            setRotate(-mapRange(0, 1, e.progress, 5, -5))
          }
          set3D(e.progress > 0 && e.progress < 1)
        }
      }
    })

    return () => {
      timeline.kill()
    }
  }, [id, speed, position, windowWidth, start, end])

  return (
    <div ref={trigger} className="parallax-root" style={style}>
      <div ref={target} className={className}>
        {children}
      </div>
    </div>
  )
}
