import { atom, useAtomValue, useSetAtom } from 'jotai'
import { useRef } from 'react'
import breakpoints from '../../theme/breakpoints'
import useThrottleCallback from '../hooks/useThrottleCallback'
import useWindowResize from '../hooks/useWindowResize'

export const isMobileAtom = atom(false)
export const useLayoutState = () => {
  const setIsMobile = useSetAtom(isMobileAtom)
  const previousValue = useRef(null)
  useWindowResize(
    useThrottleCallback(() => {
      const nextValue = window.innerWidth < breakpoints.md
      if (previousValue.current !== nextValue) {
        previousValue.current = nextValue
        setIsMobile(previousValue.current)
      }
    }, 200),
    true,
    true
  )
}

export function useIsMobile() {
  return useAtomValue(isMobileAtom)
}