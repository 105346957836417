import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { getBackgroundColorFromImage } from '../../helpers/image'
import { introCompletedAtom } from '../Intro/introState'
import { Parallax } from '../Parallax'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import SplitTextAnimation from '../SplitTextAnimation'
import Video from '../Video'

export function AnimatedTextRows ({ textRows, delay = 0.05 }) {
  const introComplete = useAtomValue(introCompletedAtom)
  const d = introComplete ? delay : 0.2 + delay

  const duration = 0.6
  const stagger = 0.08
  const s = duration - stagger

  return (
    <>
      {textRows?.map((row, i) => (
        <div
          key={row._key}
          className={cn(
            'tracking-[-0.06em] text-[calc((100vw-48px)*0.0938)] md:text-[calc((100vw-48px)*0.138)] uppercase leading-[0.8em]',
            row.font === 'sans' ? 'font-sans font-700' : 'font-serif font-250',
            row.align === 'right' && 'text-right',
            row.indent && '[&_.split-line:first-child_div:first-child]:indent-[20vw]'
            // [&_.pre-splittext]:indent-[20vw]
          )}
        >
          <SplitTextAnimation
            animateWhenInView={true}
            initialOpacity={0}
            duration={duration}
            stagger={s}
            delay={d + (row.text.split(' ').length * duration * stagger * i)}
            tag="div"
          >
            {row.text}
          </SplitTextAnimation>
        </div>
      ))}
    </>
  )
}

export default function TextRows ({ data }) {
  const { textRows, image, video } = data
  const hasVideo = video?.asset
  const backgroundColor = getBackgroundColorFromImage(image)

  return (
    <Section grid className=''>
      <div className='col-span-full'>
        <AnimatedTextRows textRows={textRows} />
      </div>
      {!hasVideo && image && (
        <div className='col-span-full overflow-hidden'>
          <Parallax speed={-0.5}>
            <ResponsiveImage image={image} aspect={1408 / 818} mobileAspect={364 / 396}/>
          </Parallax>
        </div>
      )}
      {hasVideo && (
        <div className='aspect-[1408/818] md:aspect-[364/396] relative col-span-full overflow-hidden'>
          {image && <ResponsiveImage image={image} position='absolute' className='inset-0' />}
          <Video video={video} background backgroundColor={backgroundColor} />
        </div>
      )}
    </Section>
  )
}
