import { useRef } from 'react'
import cn from 'clsx'
import dayjs from 'dayjs'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import Section from '../../Section'
import RichContent from '../../RichContent'
import ResponsiveImage from '../../ResponsiveImage'
import { Parallax } from '../../Parallax'
import useLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import { useAtomValue, useSetAtom } from 'jotai'
import { menuOverrideThemeAtom, MENU_THEMES } from '../../Menu/menuState'
import SplitTextAnimation from '../../SplitTextAnimation'
import { introCompletedAtom } from '../../Intro/introState'

gsap.registerPlugin(ScrollTrigger)

function Title ({ rows, i }) {
  const introCompleted = useAtomValue(introCompletedAtom)

  return (
    <h1>
      <SplitTextAnimation
        animateWhenInView={true}
        initialOpacity={0}
        duration={0.6}
        stagger={0.5}
        delay={(i === 0 && !introCompleted) ? 2 : 0.25}
        tag="div"
      >
      {rows?.map(titleRow => {
        const isSerif = titleRow.font === 'serif'
        const isCentre = titleRow.align === 'centre'

        return (
            <span
              key={titleRow._key}
              className={cn(
                'block font-bold tracking-[-0.03em] text-justify uppercase w-full leading-[0.8em] text-[9.4vw]',
                isCentre && '!text-center', 
                isSerif && 'font-serif !font-250 tracking-[-0.02em]',
                !isCentre && 'upMd:flex upMd:justify-between',

                'md:!text-center'
              )}
            >
              {!isCentre && titleRow.text.split(/( )/).map((x, i) => <span key={`${x}-${i}`}>{x}</span>)}
              {isCentre && titleRow.text}
            </span>
        )
      })}
      </SplitTextAnimation>
    </h1>
  )
}

function useOverrideMenuThemeWhenInView (theme) {
  const ref = useRef()
  const setMenuOverrideTheme = useSetAtom(menuOverrideThemeAtom)

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        start: 'top 10px',
        end: `${ref.current.offsetHeight - 50} top`,
        scrub: true,
        onEnter: () => setMenuOverrideTheme(theme),
        onLeave: () => setMenuOverrideTheme(MENU_THEMES.none),
        onEnterBack: () => setMenuOverrideTheme(theme),
        onLeaveBack: () => setMenuOverrideTheme(MENU_THEMES.none),
      }
    })

    return () => {
      tl.kill()
      setMenuOverrideTheme(MENU_THEMES.none)
    }
  }, [])

  return ref
}

export default function ArticleHero ({ data, page }) {
  const ref = useOverrideMenuThemeWhenInView(MENU_THEMES.dark)
  const { isTall, topTitle, bottomTitle, subHeading, image } = data
  const categoryTitle = page?.articleFields?.category?.title
  const postedDate = page?.articleFields?.postedDate

  return (
    <Section noGutter className='relative overflow-hidden bg-black' ref={ref}>
      {image && <Parallax start="top top" position="top" speed={3}>
        <ResponsiveImage
          image={image} 
          aspect={isTall ? 1440/2123 : 1440/1023} 
          mobileAspect={isTall ? 393/1280 : 393/860}
          className={cn(!isTall && 'md:opacity-30')}
        />
      </Parallax> }
      <div className='absolute inset-4 text-white pt-[8rem] flex flex-col justify-between'>
        <Title rows={topTitle} i={0}/>
  
        {subHeading && <RichContent content={subHeading} className='self-center text-center max-w-[62em] text-12 md:text-8 [&_strong]:uppercase md:z-10'/>}
        {image && !isTall && <ResponsiveImage
          image={image} 
          aspect={361/251}
          mobileAspect={361/251}
          className='upMd:hidden !absolute top-1/2 -translate-y-1/2 z-1'
        />}

        <div>
          <Title rows={bottomTitle} i={1}/>
          <div className='flex justify-between text-10 uppercase tracking-slight mt-4'>
            {categoryTitle && <div>{categoryTitle}</div>}
            {postedDate && <div className='font-feature-zero'>{dayjs(postedDate).format('DD.MM.YYYY')}</div>}
          </div>
        </div>
      </div>
    </Section>
  )
}