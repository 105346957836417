import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { useCallback, useRef } from 'react'
import useScrollPosition from '../../../hooks/useScrollPosition'
import { useTheme } from '../../../state/theme'
import { useScrollPosition as useFeedScrollPosition } from '../../Canvas/CustomScroller'
import { MENU_THEMES, SECONDARY_MENUS, menuOverrideThemeAtom, secondMenuTypeAtom } from '../menuState'

export default function HomeNav ({ show, className }) {
  const containerRef = useRef()
  const progressRef = useRef()
  const navType = useAtomValue(secondMenuTypeAtom)
  const theme = useTheme()
  const forcedTheme = useAtomValue(menuOverrideThemeAtom)
  const isDarkMode = forcedTheme === MENU_THEMES.none ? theme.dark : forcedTheme === MENU_THEMES.dark

  useFeedScrollPosition(useCallback(({ scroll, max }) => {
    if (!progressRef.current) return
    const percent = scroll.y / max.y * 100
    progressRef.current.style.transform = `translateX(${percent - 100}%)`
  }, []), true)

  useScrollPosition(useCallback((scroll) => {
    const { progress = 0 } = scroll || {}
    if (!progressRef.current || navType !== SECONDARY_MENUS.article) return
    const percent = progress * 100
    progressRef.current.style.transform = `translateX(${percent - 100}%)`
  }, [navType]), true)

  return (
    <div className={cn(className, 'inset-0 absolute overflow-hidden pointer-events-none transition-all duration-600 ease-out-expo', show ? 'opacity-100' : 'opacity-0')} ref={containerRef}>
      <div ref={progressRef} className={cn('absolute inset-0 -translate-x-full transition-opacity ease-out-expo duration-600', isDarkMode ? 'opacity-[0.08]' : 'opacity-20')} style={{ backgroundColor: isDarkMode ? '#fff' : '#000' }} />
    </div>
  )
}
