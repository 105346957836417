import { useMemo } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { useCartSettings } from '../../siteState'
import { useIsAuthenticated } from '../accountState'
import useCart from '../useCart'

export default function FreeShipping () {
  const { freeShippingDetails = {} } = useCartSettings()
  const cart = useCart()
  const authenticated = useIsAuthenticated()
  const { message, achievedMessage, cartAmountForNonMembers, cartAmountForMembers } = freeShippingDetails
  const { amountRemaining, percent } = useMemo(() => {
    const cartTotal = cart?.cost?.subtotalAmount?.amount || 0 // Excluding shipping
    const target = authenticated ? cartAmountForMembers : cartAmountForNonMembers
    return {
      amountRemaining: target - cartTotal,
      percent: Math.min(100, (cartTotal / target) * 100)
    }
  }, [cart, authenticated, cartAmountForMembers, cartAmountForNonMembers])

  const shippingMessage = useMemo(() => {
    if (amountRemaining <= 0) return achievedMessage
    // eslint-disable-next-line no-template-curly-in-string
    return message.replace('${amountRemaining}', formatCurrency(amountRemaining))
  }, [achievedMessage, amountRemaining, message])

  // Do not show the free shipping content if there is no value in the cart amount properties
  if ((authenticated && !cartAmountForMembers) || (!authenticated && !cartAmountForMembers)) return null

  return (
    <div className='mt-2'>
      <div className='text-center text-10 uppercase mb-1'>{shippingMessage}</div>
      <div className='h-1 w-full relative bg-[#BBBBBB] overflow-hidden'>
        <div className='absolute inset-0 bg-current -translate-x-full transition-all' style={{ transform: `translateX(-${100 - percent}%)` }}/>
      </div>
    </div>
  )
}
