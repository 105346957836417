import { useFrame, useThree } from '@react-three/fiber'
import { useAtomValue, useSetAtom } from 'jotai'
import first from 'lodash/first'
import forEach from 'lodash/forEach'
import last from 'lodash/last'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import * as THREE from 'three'
import { lerp } from 'three/src/math/MathUtils'
import { TRACKING_LIST_TYPES, TrackingContextProvider } from '../../../gtm/TrackingContext'
import { getMeshes } from '../../usePageTransition'
import { CustomScrollerContext, useScrollPosition } from '../CustomScroller'
import { canvasStateAtom } from '../canvasState'
import { getBoundingBoxHeight } from '../helpers/object'
import { useIsMobile, useViewport } from '../hooks'
import { SCENES, sceneAtom } from '../sceneState'
import Block from '../ui/Block'
import LayoutManager from '../ui/LayoutManager'
import Row from './Row'

const TOP_OFFSET = 0.2
const ROW_GAP = 0.06
const ROW_GAP_MOBILE = 0.2

const getGap = (isMobile, viewport) => {
  return (isMobile ? ROW_GAP_MOBILE : ROW_GAP) * viewport.width
}

const useSceneEffect = (enabled) => {
  const isMobile = useIsMobile()
  const localRef = useRef({ distortion: 0, mounted: true })
  const { scene, sceneEffect } = useAtomValue(canvasStateAtom)

  useFrame(() => {
    if (!sceneEffect || !enabled) return
    sceneEffect.distortXEnabled = 1
    sceneEffect.waveIntensity = lerp(sceneEffect.waveIntensity, localRef.current.distortion, 0.075)
  }, 1)

  useScrollPosition(useCallback(({ target, scroll }) => {
    if (!sceneEffect || !enabled) return
    const velocity = target.y - scroll.y
    localRef.current.distortion = Math.max(Math.min(velocity / (isMobile ? 45 : 65), 0.02), -0.02)
  }, [sceneEffect, enabled, isMobile]))

  useEffect(() => {
    if (!enabled && sceneEffect) {
      sceneEffect.waveIntensity = 0
      sceneEffect.distortXEnabled = 0
      const group = scene.getObjectByName('feed')
      const meshes = group ? getMeshes(group) : []
      forEach(meshes, mesh => {
        mesh.material.opacity = 0
      })
    }
  }, [sceneEffect, enabled, scene])
}

export default function FeedLayout ({ show, ...props }) {
  const layoutRef = useRef()
  const rows = useAtomValue(sceneAtom)?.data[SCENES.FEED_ORDER]
  const ref = useRef()
  const viewport = useViewport()
  const localsRef = useRef({ firstRow: null, lastRow: null, totalHeight: 0, scale: 1 })
  const size = useThree(({ size }) => size)
  const scroller = useContext(CustomScrollerContext)
  const isMobile = useIsMobile()
  const setCanvasState = useSetAtom(canvasStateAtom)

  const onScroll = useCallback(() => {
    if (scroller.current && localsRef.current.firstRow) {
      const { scroll } = scroller.current
      const gap = getGap(isMobile, viewport)
      const firstElement = localsRef.current.firstRow.element
      const lastElement = localsRef.current.lastRow.element
      const secondLastElement = localsRef.current.secondLastRow.element
      const lastHeight = getBoundingBoxHeight(localsRef.current.lastRow.bbox)
      const secondLastHeight = getBoundingBoxHeight(localsRef.current.secondLastRow.bbox)
      const firstHeight = getBoundingBoxHeight(localsRef.current.firstRow.bbox)
      const topOffset = TOP_OFFSET * viewport.width
      // The offsetY position is set in the reflow function
      if (scroll.y <= (firstHeight * 1.5)) {
        // move the last box to the top
        firstElement.position.y = firstElement.offsetY
        lastElement.position.y = lastElement.topTop + lastHeight - topOffset + gap
        secondLastElement.position.y = lastElement.position.y + lastHeight + gap + secondLastHeight
      } else {
        // move the first box to the bottom
        lastElement.position.y = lastElement.offsetY
        secondLastElement.position.y = secondLastElement.offsetY
        firstElement.position.y = firstElement.topTop + localsRef.current.totalHeight - topOffset
      }
    }
  }, [isMobile])

  useScrollPosition(onScroll)

  const onReflow = useCallback(() => {
    const topOffset = TOP_OFFSET * viewport.width
    const gap = getGap(isMobile, viewport)

    // Reset the positions to 0
    forEach(ref.current.children, (child) => {
      child.position.y = 0
    })

    let totalHeight = 0
    const bboxs = ref.current.children.map(child => ({
      bbox: new THREE.Box3().setFromObject(child),
      element: child
    })).filter(({ bbox }) => isFinite(bbox.min.y))

    forEach(bboxs, ({ bbox, element }) => {
      const height = Math.abs(bbox.min.y - bbox.max.y)
      const { scroll } = scroller.current
      // This will find the position where the top of the row is at the top of the viewport
      element.topTop = -(bbox.max.y - (viewport.height / 2)) + scroll.y
      // We now move it down the page based on the last element bottom position after the transform
      element.position.y = element.topTop + totalHeight - topOffset

      element.offsetY = element.position.y
      totalHeight -= (height + gap)
    })

    localsRef.current.firstRow = first(bboxs)
    localsRef.current.lastRow = last(bboxs)
    localsRef.current.secondLastRow = bboxs[bboxs.length - 2]
    localsRef.current.totalHeight = totalHeight

    scroller.current.max.y = Math.abs(totalHeight)
    scroller.current.loop.y = 1

    onScroll()
  }, [rows, viewport, size, isMobile])

  useSceneEffect(show)

  // Some code to move the camera around
  // const scrollerContext = useContext(CustomScrollerContext)

  // useFrame(() => {
  //   if (scrollerContext.current) {
  //     if (!localsRef.current.lookAt) {
  //       localsRef.current.lookAt = new THREE.Vector3(0, 0, 0)
  //     }
  //     const mouse = scrollerContext.current.mouse
  //     const x = (gsap.utils.normalize(0, size.width, mouse.current.x) - 0.5) * 2
  //     const y = (gsap.utils.normalize(0, size.height, mouse.current.y) - 0.5) * 2
  //     localsRef.current.lookAt.lerp(new THREE.Vector3(x * 0.5, y * 0.5, 0), 0.075)
  //     camera.position.x = localsRef.current.lookAt.x
  //     camera.position.y = -localsRef.current.lookAt.y
  //     camera.lookAt(-localsRef.current.lookAt.x * 0.5, localsRef.current.lookAt.y * 0.5, 0)
  //     // camera.lookAt(localsRef.current.lookAt)
  //   }
  // })

  useEffect(() => {
    layoutRef.current?.reflow?.()
    // The scene is now ready once the reflow has been done and we are showing the scene
    if (show) {
      setCanvasState(state => ({
        ...state,
        sceneReady: true,
        layoutRef
      }))
    }
  }, [show])

  if (!rows) return

  // We preload the first and lost row
  return (
    <TrackingContextProvider list={TRACKING_LIST_TYPES.feed}>
      <LayoutManager ref={layoutRef}>
        <Block onReflow={onReflow} name='rows'>
          <group ref={ref} name='feed' {...props}>
            {rows.map((row, i) => <Row key={i} row={row} enabled={show} />)}
          </group>
        </Block>
      </LayoutManager>
    </TrackingContextProvider>
  )
}
