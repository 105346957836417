import gsap from 'gsap'
import { useCallback, useEffect, useRef, useState } from 'react'
import ArrowIcon from '../ArrowIcon'

export default function FilterContainer ({ children, filter, setViewAll }) {
  const { title, options, viewAll = false } = filter
  const [expanded, setExpanded] = useState(filter.expanded)
  const optionsRef = useRef()
  const onViewAll = useCallback((e) => {
    setViewAll?.(filter)
    e.stopPropagation()
  }, [filter, setViewAll])
  const toggleExpanded = useCallback(() => {
    setExpanded(v => !v)
  }, [])

  useEffect(() => {
    gsap.to(optionsRef.current, { height: expanded ? 'auto' : 0, duration: 0.2, ease: 'power4.inOut' })
  }, [expanded])

  return (
    <div className='border-t [&:not(:last-child)]:border-b border-solid border-current -mt-px'>
      <button className='w-full flex justify-between text-8 md:text-14 tracking-slight uppercase py-4' onClick={toggleExpanded}>
        <div className='font-550'>{title}</div>
        {viewAll && options.length > 3 && (
          <button className='uppercase flex gap-1 items-center font-550 tracking-slight' onClick={onViewAll}>
            More
          </button>
        )}
        <ArrowIcon toggle={expanded} />
      </button>
      <div className='w-full h-0 overflow-hidden' ref={optionsRef}>
        {children}
      </div>
    </div>
  )
}
