import { useEffect, useRef } from 'react'
import gsap from 'gsap'

export default function PlusMinus ({ isOpen }) {
  const path = useRef()

  useEffect(() => {
    gsap.to(path.current, {
      scaleY: isOpen ? 0 : 1,
      duration: 0.6,
      ease: 'expo.out',
    })
  }, [isOpen])

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path ref={path} className="origin-[25%_25%]" d="M12 5V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}