import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { formatCurrency } from '../../../../../helpers/currencyFormatters'
import { formatCountdown } from '../../../../../helpers/date'
import { isInStock, useIsReleased } from '../../../../../helpers/product'
import { useLabels } from '../../../../siteState'
import pixelToViewport from '../../../helpers/pixelToViewport'
import { useIsMobile, useViewport } from '../../../hooks'
import Stack from '../../../ui/Stack'
import BodyFont from '../../../ui/Text/BodyFont'
import SansSerifFont from '../../../ui/Text/SansSerifFont'

// const TITLE_FONT_SIZES = { lg: 0.2, md: 0.28, sm: 0.7 }
// const FONT_SIZES = { lg: 0.12, md: 0.18, sm: 0.5 }
const TITLE_FONT_SIZES = { lg: 0.2, md: 0.224, sm: 0.7 }
const FONT_SIZES = { lg: 0.12, md: 0.1356, sm: 0.5 }

const Countdown = ({ releasedDate, ...props }) => {
  const [countdown, setCountDown] = useState(formatCountdown(releasedDate))
  useEffect(() => {
    const id = setInterval(() => {
      setCountDown(formatCountdown(releasedDate))
    }, 1000)
    return () => {
      clearInterval(id)
    }
  }, [])
  return (
    <SansSerifFont {...props} skipReflowSync>
      {countdown}
    </SansSerifFont>
  )
}

const ReleasedComponent = ({ releasedDate, width }) => {
  const isReleased = !releasedDate || dayjs().isAfter(dayjs(releasedDate))
  const { droppingInLabel = 'Dropping In' } = useLabels()
  if (isReleased) return null
  return (
    <Stack anchorY='center'>
      <SansSerifFont fontSizes={TITLE_FONT_SIZES} maxWidth={width}
        textAlign='center' anchorX='center' anchorY='center'
      >
        {droppingInLabel?.toUpperCase()}
      </SansSerifFont>
      <Countdown releasedDate={releasedDate} fontSizes={TITLE_FONT_SIZES} maxWidth={width}
        textAlign='center' anchorX='center' anchorY='center' />
    </Stack>
  )
}

const SoldOut = ({ width }) => {
  const { soldOutLabel } = useLabels()
  return (
    <Stack anchorY='center'>
      <SansSerifFont fontSizes={FONT_SIZES} maxWidth={width}
        textAlign='center' anchorX='center' anchorY='center'
      >
        {soldOutLabel?.toUpperCase()}
      </SansSerifFont>
    </Stack>
  )
}

export default function ProductMeta ({ product, width, height, ...props }) {
  const viewport = useViewport()
  const gap = pixelToViewport(2, viewport)
  const { title, yearVintage, productCategory, winery, range, priceRange, varietal, sales, releasedDate, membersOnly } = product
  const price = priceRange?.minVariantPrice
  const isMobile = useIsMobile()
  // const isSmallDesktop = useIsSmallDesktop()
  const position = [-width / 2, -height / 2 - pixelToViewport(4, viewport), 0]

  const inStock = isInStock(product)
  const { membersOnlyLabel, nonVintageText } = useLabels()

  const showPrice = useIsReleased(product)
  const showSoldOut = !inStock && showPrice

  if (productCategory === 'wine') {
    const widthPercent = width / viewport.width
    const showVarietal = isMobile ? widthPercent >= 0.5 : widthPercent > 0.15

    const wineryWidth = showVarietal ? width * 0.4 : width * 0.7
    const priceWidth = showVarietal ? width * 0.2 : width * 0.3
    const varietalWidth = width * 0.39
    const varietalPosition = width * 0.41
    const pricePosition = showVarietal ? width * 0.8 : width * 0.7

    const year = yearVintage || nonVintageText

    const tag = sales || (membersOnly ? membersOnlyLabel : '')

    return (
      <>
        <group position={position} {...props} name='meta'>
          <Stack gap={gap}>
            <BodyFont fontSizes={FONT_SIZES} maxWidth={wineryWidth} >
              {winery ? winery?.toUpperCase() : title?.toUpperCase()}
            </BodyFont>
            <BodyFont fontSizes={FONT_SIZES} maxWidth={wineryWidth}>
              {range?.toUpperCase()}
            </BodyFont>
          </Stack>
          {showVarietal && (
            <Stack gap={gap} position={[varietalPosition, 0, 0]}>
              <BodyFont fontSizes={FONT_SIZES} maxWidth={varietalWidth} >
                {varietal?.toUpperCase()}
              </BodyFont>
              <BodyFont fontSizes={FONT_SIZES} maxWidth={varietalWidth} >
                {year}
              </BodyFont>
            </Stack>
          )}
          <Stack gap={gap} position={[pricePosition, 0, 0]}>
            {showPrice && <BodyFont fontSizes={FONT_SIZES} maxWidth={priceWidth} textAlign='right' anchorX='right' position={[priceWidth, 0, 0]}>
              {formatCurrency(price)}
            </BodyFont>}
            <SansSerifFont fontSizes={FONT_SIZES} maxWidth={priceWidth} textAlign='right' anchorX='right' position={[priceWidth, -0.12 * 1.2, 0]}>
              {tag?.toUpperCase()}
            </SansSerifFont>
          </Stack>
        </group>
        <group position={[0, 0, 0.001]} name='meta'>
          {!showSoldOut && (
            <ReleasedComponent releasedDate={releasedDate} width={width} />
          )}
          {showSoldOut && <SoldOut width={width} />}
        </group>
      </>
    )
  }

  const titleWidth = width * 0.7
  const priceWidth = width * 0.3

  return (
    <>
      <group position={position} {...props} name='meta'>
        <Stack direction='row' gap={gap}>
          <BodyFont fontSizes={FONT_SIZES} maxWidth={titleWidth} >
            {title?.toUpperCase()}
          </BodyFont>
        </Stack>
        <Stack direction='row' gap={gap} position={[titleWidth, 0, 0]}>
          {showPrice && <BodyFont fontSizes={FONT_SIZES} maxWidth={priceWidth} anchorX='right' position={[priceWidth, 0, 0]}>
            {formatCurrency(price)}
          </BodyFont>}
          <SansSerifFont fontSizes={FONT_SIZES} maxWidth={priceWidth} anchorX='right' position={[priceWidth, -0.12 * 1.2, 0]}>
            {sales?.toUpperCase()}
          </SansSerifFont>
        </Stack>
      </group>
      <group position={[0, 0, 0.001]} name='meta'>
        {!showSoldOut && (
          <ReleasedComponent releasedDate={releasedDate} width={width} />
        )}
        {showSoldOut && <SoldOut width={width} />}
      </group>
    </>
  )
}
