import { createElement, Fragment, useEffect, useState } from 'react'

export const ClientOnly = ({ children }) => {
  const hasMounted = useIsMounted()

  if (!hasMounted) {
    return null
  }

  return createElement(Fragment, null, children)
}

export default function useIsMounted () {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return mounted
}
