import NextLink from 'next/link'
import React, { forwardRef, useMemo } from 'react'
import { resolveLink } from '../lib/resolveLink'

export const isExternalLink = (url) => {
  return url &&
    (url.indexOf('http') >= 0 ||
      url.indexOf('tel:') >= 0 ||
      url.indexOf('mailto:') >= 0)
}

export const ExternalLinkIcon = () => {
  return <span className='align-top inline-block ml-[1px] mt-[-2px]'>↗</span>
}

const Link = forwardRef(
  (
    {
      openInNewTab = true,
      nonLinkTag = 'span',
      link,
      to,
      children,
      showText = true,
      showExternalIcon = true,
      scroll = false,
      prefetch = true,
      queryParams,
      search,
      ...rest
    },
    ref
  ) => {
    const { url, text, hash, query } = useMemo(() => {
      if (to) return { url: to, queryParams }
      if (link) {
        return resolveLink({
          ...link,
          query: queryParams || link.query
        })
      }
      return {}
    }, [link, queryParams, to])

    // External Link
    if (isExternalLink(url)) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={url}
          target={openInNewTab ? '_blank' : ''}
          rel={openInNewTab ? 'noreferrer noopener' : ''}
          {...rest}
          ref={ref}
        >
          {showText && text}
          {children}
          {showExternalIcon && <ExternalLinkIcon />}
        </a>
      )
    }

    // No Link
    if (!url) {
      const Tag = nonLinkTag
      return (
        <Tag {...rest} ref={ref}>
          {showText && text}
          {children}
        </Tag>
      )
    }

    const dataDescription = link?.linkType === 'external' ? `follow|${(text || '(not set)').toLowerCase()}` : (text || '(not set)').toLowerCase()

    // Internal Link
    return (
      <NextLink
        href={{
          pathname: hash ? url + '#' + hash : url,
          search,
          query
        }}
        scroll={scroll}
        prefetch={prefetch ? undefined : false}
        data-event='site interaction'
        data-type='link'
        data-description={dataDescription}
        data-location='body'
        {...rest}
        ref={ref}
      >
        {showText && text}
        {children}
      </NextLink>
    )
  }
)

Link.displayName = 'Link'

export default Link
