import gsap from 'gsap'
import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useIsHovering from '../../../hooks/useIsHovering'
import useIsMounted from '../../../hooks/useIsMounted'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useWindowResize from '../../../hooks/useWindowResize'
import { data as cursorData } from '../../Cursor'
import Section from '../../Section'

const Video = dynamic(() => import('../../Video'), {
  ssr: false
})

export default function ArticleVideo ({ data }) {
  const { eventListeners, hovering } = useIsHovering()
  const [playing, setIsPlaying] = useState(false)
  const { video } = data
  const videoRef = useRef()

  const aspectRatio = video?.asset?.data?.aspect_ratio.split(':')
  const aspect = aspectRatio[0] / aspectRatio[1]

  const handlePlayEvent = () => setIsPlaying(true)
  const handlePauseEvent = () => setIsPlaying(false)

  useEffect(() => {
    if (!videoRef.current) return

    videoRef.current.addEventListener('play', handlePlayEvent)
    videoRef.current.addEventListener('pause', handlePauseEvent)

    return () => {
      videoRef.current.removeEventListener('play', handlePlayEvent)
      videoRef.current.removeEventListener('pause', handlePauseEvent)
    }
  }, [videoRef.current])

  return (
    <Section noGutter style={{ aspectRatio: aspect }} {...eventListeners}>
      {video && <Video videoRef={videoRef} video={video} className='align-bottom' style={{
        '--seek-backward-button': 'none',
        '--seek-forward-button': 'none',
        '--pip-button': 'none'
      }}/>}
      <TextCursor show={hovering} text={playing ? 'pause' : 'play'} />
    </Section>
  )
}

const TextCursor = ({ show, text }) => {
  const cursorRef = useRef()
  const localsRef = useRef({})
  const mounted = useIsMounted()

  const [isOffScreen, setOffScreen] = useState(false)

  useWindowResize(useThrottleCallback(() => {
    localsRef.current.windowWidth = window.innerWidth
  }, 200), true)

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current

    gsap.to(cursor.children[0], {
      scale: show ? 1 : 0,
      duration: 0.3,
      ease: 'expo.out'
    })

    localsRef.current.textWidth = cursor.getBoundingClientRect().width
  }, [show])

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current
    gsap.to(cursor.children[0], {
      x: isOffScreen ? '-100%' : 0,
      transformOrigin: isOffScreen ? '100% 0' : '0 0',
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [isOffScreen])

  useEffect(() => {
    const loop = () => setOffScreen(cursorData.last.x > localsRef.current.windowWidth - localsRef.current.textWidth)
    gsap.ticker.add(loop)
    return () => gsap.ticker.remove(loop)
  }, [])

  if (!mounted) return

  return createPortal(
    <div ref={cursorRef} className={`
      pointer-events-none fixed top-2 left-2 md:hidden  origin-top-left
    `}>
      <div className={`
        scale-0 uppercase text-12 px-2 py-1 inline-block text-white text-48 font-bold tracking-[-0.02em]
      `}>
        {text}
      </div>
    </div>,
    document.getElementById('cursor')
  )
}
