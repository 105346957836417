import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useKeenSlider } from 'keen-slider/react'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useState } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import Button from '../../Button'
import { navViewAtom, navViewDataAtom, NAV_VIEWS } from '../../Menu/menuState'
import ResponsiveImage from '../../ResponsiveImage'
import { useCartSettings } from '../../siteState'
import { useCartActions } from '../useCartActions'

const MAX_CHARACTERS = 200

const messageAtom = atom('')

export default function GiftMessageForm () {
  const [loading, setLoading] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [message, setMessage] = useAtom(messageAtom)
  const { addLineItems } = useCartActions()
  const setNavView = useSetAtom(navViewAtom)
  const product = useAtomValue(navViewDataAtom)
  const variant = product?.variants?.[0]
  const price = variant?.price
  const {
    giftWrappingMessageFieldLabel,
    giftWrappingMessageFieldPlaceholder,
    giftWrappingCharactersLabel,
    giftWrappingImages,
    addToCartLabel,
    addingToCartLabel,
    backToCartLabel
  } = useCartSettings()

  const onAddToCart = useCallback(async () => {
    setErrorMessage(null)
    setLoading(true)
    try {
      const result = await addLineItems({
        merchandiseId: `gid://shopify/ProductVariant/${variant.shopifyId}`,
        quantity: 1,
        product,
        attributes: message
          ? [
              {
                key: 'Message',
                value: message
              }
            ]
          : null
      })
      if (isEmpty(result.userErrors)) {
        setNavView(NAV_VIEWS.cart)
        setMessage('')
      } else {
        setErrorMessage(result.userErrors[0]?.message)
        console.error('Error adding item to cart', result.userErrors)
      }
    } finally {
      setLoading(false)
    }
  }, [addLineItems, setNavView, variant, message])

  const onChange = useCallback((e) => {
    setMessage(e.target.value.replace('|', ''))
  }, [])

  const [sliderRef] = useKeenSlider(
    {
      slides: {
        perView: 'auto'
      }
    }
  )

  const onBackToShopClick = useCallback(() => {
    setNavView(NAV_VIEWS.cart)
  }, [])

  return (
    <div className='p-navPadding border-t border-border'>
      <div className='uppercase px-4 py-4 text-center text-10 font-550'>
        <button className='uppercase' onClick={onBackToShopClick}>{backToCartLabel}</button>
      </div>
      <div className='block md:hidden'>
        {!isEmpty(giftWrappingImages) && (
          <div ref={sliderRef} className='overflow-hidden whitespace-nowrap mb-6 keen-slider'>
            {giftWrappingImages.map((image, i) => (
              <div key={i} className='keen-slider__slide max-w-[11rem] min-w-[11rem] md:max-w-[8.5rem] md:min-w-[8.5rem] pr-2'>
                <ResponsiveImage image={image} aspect={185 / 130} className='w-full' />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='flex uppercase text-10 justify-between items-center mb-2 mt-0 md:mt-2'>
        <span>{giftWrappingMessageFieldLabel}</span>
        <span className='color-border'>{message.length}/{MAX_CHARACTERS} {giftWrappingCharactersLabel}</span>
      </div>
      <textarea maxLength={MAX_CHARACTERS} className='w-full bg-transparent outline-none text-12 border border-solid p-2' placeholder={giftWrappingMessageFieldPlaceholder} rows={6} value={message} onChange={onChange} />
      {errorMessage && <div className='text-error mb-2 text-10'>Error adding item to cart: {errorMessage}</div>}
      <Button onClick={onAddToCart} disabled={loading} className='mb-2 z-10' border='border-none' invert filled>
        <span className='block mx-1'>{loading ? addingToCartLabel : addToCartLabel}</span>
        <span className='block mx-1 font-normal'>{formatCurrency(price)}</span>
      </Button>
    </div>
  )
}
