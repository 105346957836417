import { useAtomValue } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import debounce from 'lodash/debounce'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'
import sumBy from 'lodash/sumBy'
import { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMetaData } from '../Components/Meta'
import { pageContentAtom } from '../Components/pageContentState'
import { ACCOUNT_STATES, useAccountState, useCustomer } from '../Components/Shop/accountState'
import { cartAtom } from '../Components/Shop/cartState'
import { useSite } from '../Components/siteState'
import { useTrackingContext } from './TrackingContext'

const EMPTY = '(not set)'

const createUserData = (customer) => ({
  emailHashed: customer.emailHash,
  id: customer.id,
  brand: 'VNTNR',
  authState: 'auth',
  userType: 'customer',
  customerGroup: customer.tags || EMPTY
})

const createAnonymousUserData = () => ({
  emailHashed: EMPTY,
  id: EMPTY,
  brand: EMPTY,
  authState: 'unauth',
  userType: EMPTY,
  customerGroup: EMPTY
})

const createDebugData = () => ({
  environment: process.env.NEXT_PUBLIC_GTM_ENVIRONMENT || 'development',
  platform: process.env.NEXT_PUBLIC_GTM_PLATFORM || 'sanity'
})

const createSiteData = (site) => ({
  brand: 'vntnr',
  countryCode: site.countryCode,
  languageCode: site.lang
})

const createPageData = (page, metadata) => ({
  title: metadata.meta_title,
  url: window.location.href,
  category: metadata.category
})

const createCartData = (lineItems, list, promotion) => ({
  currency: first(lineItems).cost?.totalAmount.currencyCode,
  value: sumBy(lineItems, line => line.cost.totalAmount.amount),
  coupon: EMPTY,
  items: map(lineItems, (line, i) => ({
    item_id: line.product?.shopifyId,
    item_name: line.product?.title,
    discount: line.cost.subtotalAmount.amount - line.cost.totalAmount.amount,
    index: i,
    item_brand: line.product?.winery || EMPTY,
    item_category: line.product?.productCategory || EMPTY,
    item_category2: line.product?.type || EMPTY,
    item_category3: line.product?.yearVintage || EMPTY,
    item_category4: line.product?.country || EMPTY,
    item_category5: line.product?.varietal || EMPTY,
    item_list_id: list?.id || EMPTY,
    item_list_name: list?.name || EMPTY,
    item_variant: EMPTY,
    price: line?.cost?.totalAmount.amount,
    quantity: line.quantity,
    promotion_id: promotion?.id || EMPTY,
    promotion_name: promotion?.name || EMPTY
  }))
})

const getCategoryTitle = (category) => {
  return isString(category) ? category : category?.title
}

const createProductData = (product, list, index = 0) => ({
  item_id: product.shopifyId,
  item_name: product.title,
  discount: 0,
  index,
  item_brand: getCategoryTitle(product.winery),
  item_category: getCategoryTitle(product.productCategory),
  item_category2: getCategoryTitle(product.type),
  item_category3: `${product.yearVintage}`,
  item_category4: getCategoryTitle(product.country),
  item_category5: getCategoryTitle(product.varietal),
  item_list_id: list?.id || EMPTY,
  item_list_name: list?.name || EMPTY,
  item_variant: EMPTY,
  price: product.priceRange?.minVariantPrice,
  quantity: 1,
  promotion_id: EMPTY,
  promotion_name: EMPTY
})

const track = (data) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export const usePageNavigateTracker = () => {
  const accountState = useAccountState()
  const site = useSite()
  const page = useAtomValue(pageContentAtom)
  const customer = useCustomer()
  const metadata = useMetaData(site, page)

  useEffect(() => {
    const run = async () => {
      // Only track once the user has been loaded if they are logged in
      if (accountState === ACCOUNT_STATES.ready) {
        const trackingData = {
          event: 'cdl_page-navigate',
          page: createPageData(page, metadata),
          site: createSiteData(site),
          debug: createDebugData(),
          user: customer ? createUserData(customer) : createAnonymousUserData()
        }
        track(trackingData)

        if (page._type === 'product') {
          const productViewData = {
            event: 'view_item',
            ecommerce: {
              currency: site.currencyCode,
              value: page.priceRange?.minVariantPrice,
              items: [createProductData(page, null)]
            }
          }
          track(productViewData)
        }
      }
    }
    run()
  }, [accountState, page])
}

export const useSelectItemTracker = () => {
  const { list } = useTrackingContext() || {}
  const site = useSite()
  return useCallback((page) => {
    if (page._type === 'product') {
      const productSelectData = {
        event: 'select_item',
        ecommerce: {
          currency: site.currencyCode,
          item_list_id: list?.id,
          item_list_name: list?.name,
          items: [createProductData(page, list)]
        }
      }
      track(productSelectData)
    }
  }, [list, site.currencyCode])
}

export const useTrackEcommerce = () => {
  const readCart = useAtomCallback(useCallback((get) => get(cartAtom), []))
  return useCallback(async (event, lineItems, list, promotion) => {
    const cart = await readCart()
    if (!cart) return
    const lines = lineItems || cart.lines
    if (isEmpty(lines)) return
    const trackingData = {
      event,
      ecommerce: createCartData(lines, list, promotion)
    }
    track(trackingData)
  }, [readCart])
}

let trackProductImpressionData = null
const trackProductImpressionDebounced = debounce(() => {
  if (trackProductImpressionData) {
    track(trackProductImpressionData)
  }
  trackProductImpressionData = null
}, 100, { leading: false, trailing: true })

export const useTrackProductImpressionCallback = () => {
  const site = useSite()
  const { list } = useTrackingContext() || {}

  return useCallback((product, index) => {
    trackProductImpressionData = {
      event: 'view_item_list',
      ecommerce: {
        currency: site.currencyCode,
        item_list_id: list?.id || EMPTY,
        item_list_name: list?.name || EMPTY,
        items: [
          ...(trackProductImpressionData?.ecommerce?.items || []),
          createProductData(product, list, index)
        ]
      }
    }
    trackProductImpressionDebounced()
  }, [list, site.currencyCode])
}

export const useTrackProductImpressionInView = (product, index) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true, initialInView: false })
  const trackImpression = useTrackProductImpressionCallback()

  useEffect(() => {
    if (inView) {
      trackImpression(product, index)
    }
  }, [inView])

  return ref
}
