import { useAtomValue, useSetAtom } from 'jotai'
import { forEach } from 'lodash'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import { useCallback, useMemo } from 'react'
import { filtersAtom, filtersCountAtom, resultsCountAtom, useFilterQueryParams } from '../../Menu/SecondaryNavs/ShopFilter/filterState'

const filterProducts = (products, filters, query) => {
  let results = [...products]
  let filterCategory = query?.category
  if (filterCategory === 'all' || filterCategory === 'subscriptions' || filterCategory === 'subscriptions-dynamic') {
    filterCategory = (query.yearMin || query.yearMax || !isEmpty(query.type) || !isEmpty(query.winery) || !isEmpty(query.range) || !isEmpty(query.varietal) || !isEmpty(query.country) || !isEmpty(query.region)) ? 'wine' : filterCategory
  }

  results = filterByCategory(results, filterCategory)

  forEach(filters, filter => {
    const { type, slug } = filter
    if (filter.filter) {
      if (type === 'range') {
        results = filter.filter(results, query[`${slug}Min`], query[`${slug}Max`])
      } else {
        results = filter.filter(results, query[slug])
      }
    }
  })

  return results
}

const useUpdateFilterCountsCallback = () => {
  const [filtersState] = useFilterQueryParams()
  const filters = useAtomValue(filtersAtom)
  const setFilterCounts = useSetAtom(filtersCountAtom)
  return useCallback((products) => {
    const counts = keyBy(compact(map(filters, filter => {
      const { type, slug } = filter
      if (type === 'range') return null
      return {
        slug,
        count: reduce(filter.options, (result, option) => {
          result[option] = filterProducts(products, filters, {
            ...filtersState,
            [slug]: [option]
          }).length
          return result
        }, {})
      }
    })), x => x.slug)
    setFilterCounts(counts)
  }, [filters, filtersState, setFilterCounts])
}

const useFilterProducts = (products) => {
  const [filtersState] = useFilterQueryParams()
  const setResultsCount = useSetAtom(resultsCountAtom)
  const filterOptions = useAtomValue(filtersAtom)
  const updateFilterCounts = useUpdateFilterCountsCallback()

  return useMemo(() => {
    const results = filterProducts(products, filterOptions, filtersState)
    updateFilterCounts(products)
    setResultsCount(results.length)
    return results
  }, [products, filtersState])
}

export function filterByCategory (products, category) {
  if (category === 'all' || category === 'subscriptions' || category === 'subscriptions-dynamic') return products
  return products?.filter(product => product?.productCategory === category)
}

export default useFilterProducts
