import cn from 'clsx'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText'
import { useContext, useEffect, useRef } from 'react'
import Random from '../../../helpers/Random'
import RichContent from '../../RichContent'
import { SmoothScrollerContext } from '../../SmoothScroller'

gsap.registerPlugin(SplitText)
const rand = new Random()

export default function FeaturedText ({ product, isHovering, size }) {
  const ref = useRef()
  const splitRef = useRef()
  const localsRef = useRef({ tl: null })

  const scrollContext = useContext(SmoothScrollerContext)
  useEffect(() => {
    let animationId
    const loop = () => {
      if (localsRef.current.tl) {
        if (scrollContext.current.lenis?.isScrolling) {
          if (!localsRef.current.tl.paused()) { localsRef.current.tl.pause() }
        } else {
          if (localsRef.current.tl.paused()) { localsRef.current.tl.resume() }
        }
      }
      animationId = window.requestAnimationFrame(loop)
    }
    loop()
    return () => {
      window.cancelAnimationFrame(animationId)
    }
  }, [])

  useEffect(() => {
    gsap.set(ref.current, { opacity: 1 })
    if (splitRef.current || primaryInput === 'touch') return
    const splitItem = new SplitText(ref.current, { type: 'words', wordsClass: 'pt-[0.1em] -mt-[0.1em]' })
    gsap.set(splitItem.words, {
      opacity: 1
    })
    splitRef.current = splitItem
  }, [])

  useEffect(() => {
    if (primaryInput === 'touch') return
    const tl = gsap.timeline()
    tl.to(rand.shuffle([...splitRef.current.words]), {
      opacity: isHovering ? 0 : 1,
      duration: 0.5,
      ease: isHovering ? 'sine.in' : 'sine.out',
      stagger: 0.0035
    })
    localsRef.current.tl = tl
    return () => { tl.kill() }
  }, [isHovering])

  const textSizeClassNames = size === 'xs' ? 'text-[1vw] md:text-14' : size === 'sm' ? 'text-[1.4vw] md:text-14' : 'text-[1.9vw] md:text-16'

  return (
    <div className='absolute top-2 left-2 right-2 bottom-2 overflow-hidden pt-[0.1em] mt-[-0.1em]' ref={ref} style={{ opacity: 0 }}>
      <RichContent
        content={product.featuredText}
        className={cn(
          'uppercase leading-[0.806em] md:leading-[0.87em] text-justify font-serif font-250 [&>div>p>strong]:font-sans [&>div>p>strong]:font-550',
          textSizeClassNames
        )}
      />
    </div>
  )
}
