import Section from '../../Section'
import ProductTile from './ProductTile'
import { BundleProductTile } from './SubscriptionsTab/DynamicBundleListing'

export default function NoResults ({ data, isBundle, onAddToCart, sellingPlan }) {
  if (!data) return null
  const { text, recommendedTitle, recommendedProducts } = data = {}

  return (
    <Section noBottomMargin className=''>
      {text && <div className='text-center uppercase font-serif font-250 text-24 max-w-[25em] mx-auto mt-[8.5rem] mb-[8.5rem]'>
        {text}
      </div>}
      {recommendedTitle && <div className='uppercase font-550 text-14 tracking-slight mb-4'>{recommendedTitle}</div>}
      {/* className='md:[&>div:nth-child(5)]:hidden' */}
      {recommendedProducts && <Section grid noGutter noBottomMargin>
        {recommendedProducts?.map((product, i) => {
          if (isBundle) return <BundleProductTile key={product._id} product={product} i={i} rowIndex={2} rowType='standard' onAddToCart={onAddToCart} sellingPlan={sellingPlan(product)} />
          return <ProductTile key={product._id} product={product} i={i} rowIndex={2} rowType='standard' />
        })}
      </Section>}
    </Section>
  )
}
