import gsap from 'gsap'
import { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useScrollPosition } from '../../CustomScroller'

export default function TextCursor ({ show, link, onClose }) {
  const cursorRef = useRef()
  const localsRef = useRef({})

  useScrollPosition(useCallback(({ scroll }) => {
    localsRef.current.scroll = scroll.y
    if (show && Math.abs(localsRef.current.offset - scroll.y) > 2) {
      onClose?.()
    }
  }, [show, onClose]))

  useEffect(() => {
    if (show) {
      localsRef.current.offset = localsRef.current.scroll
    }
  }, [show])

  useEffect(() => {
    if (!cursorRef.current) return

    gsap.to(cursorRef.current, {
      scale: show ? 1 : 0,
      duration: 0.3,
      ease: 'expo.out'
    })
  }, [show])

  if (!link?.text) return

  return createPortal(
    <div ref={cursorRef} className={`
      pointer-events-none fixed top-2 left-2 md:hidden scale-0 w-64 origin-top-left
    `}>
      <div className={`
        bg-background text-current border border-current
        uppercase text-12 px-2 py-1 inline-block
      `}>
        {link.text}
      </div>
    </div>,
    document.getElementById('cursor')
  )
}
