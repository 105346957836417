'use client'

import Lenis from '@studio-freight/lenis'
import forEach from 'lodash/forEach'
import { useRouter } from 'next/router'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'
import { useScrollHistory } from './useScrollHistory'

export const SmoothScrollerContext = createContext()

export default function SmoothScroller ({ page, children }) {
  const smoothScrollRef = useRef({
    scrollListeners: []
  })
  const router = useRouter()
  const lenisRef = useRef()

  const restoreScrollState = useCallback((y) => {
    if (lenisRef.current) {
      lenisRef.current.setScroll(y)
    }
  }, [])
  const { set: setScrollHistory } = useScrollHistory(page, restoreScrollState)

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: 'vertical', // vertical, horizontal
      gestureDirection: 'vertical', // vertical, horizontal, both
      smooth: true,
      mouseMultiplier: 1,
      touchMultiplier: 2,
      smoothTouch: false
    })
    let cancelToken
    const loop = (time) => {
      lenis.raf(time)
      cancelToken = window.requestAnimationFrame(loop)
    }

    loop()
    smoothScrollRef.current.lenis = lenis

    const onScroll = (args) => {
      setScrollHistory(args.scroll)
      forEach(smoothScrollRef.current.scrollListeners, listener => {
        listener(args)
      })
    }
    lenis.on('scroll', onScroll)
    lenisRef.current = lenis

    return () => {
      lenis.off('scroll', onScroll)
      lenis.destroy()
      window.cancelAnimationFrame(cancelToken)
    }
  }, [setScrollHistory])

  // hash code based on https://github.com/studio-freight/lenis/blob/main/website/layouts/default/index.js
  const [hash, setHash] = useState()

  useLayoutEffect(() => {
    if (smoothScrollRef.current.lenis && hash) {
      if (hash.indexOf('=') >= 0) return
      try {
        const target = document.querySelector(hash)
        if (target) {
          smoothScrollRef.current.lenis.scrollTo(target, { offset: 0 })
        }
      } catch {
        // ignore the exception, the hash is probably a query string argument not a anchor link
      }
    }
  }, [smoothScrollRef.current.lenis, hash])

  useLayoutEffect(() => {
    const hash = window.location.hash
    if (hash) {
      // If the has contains a '&', then it is not a valid element id so we just take the first parameter
      setHash(hash.split('&')?.[0])
    }
  }, [router])

  return (
    <SmoothScrollerContext.Provider value={smoothScrollRef}>
      {children}
    </SmoothScrollerContext.Provider>
  )
}
