import { primaryInput } from 'detect-it'
import { BlendFunction, Effect } from 'postprocessing'
import { Uniform, Vector2 } from 'three'
import { lerp } from 'three/src/math/MathUtils'
import { data } from '../../Cursor'

const fragmentShader = `
  uniform float waveIntensity;
  uniform float displacementIntensity;
  uniform sampler2D displacementMap;
  uniform float distortXEnabled;
  uniform vec2 mouse;
  uniform float velocity;
  uniform float intensity;

  float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
    uv -= disc_center;
    float dist = sqrt(dot(uv, uv));
    return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
  }

  vec2 bellCurve(vec2 vUv, float offset, float invert) {
    float e = 2.71828;
    float bb = 0.05;
    float s = 35.0;

    float offsetY = vUv.y - offset;
    float bulgeOffset = -2. * (vUv.x - 0.5);
    float x = ((pow(e, -(pow(offsetY, 2.) / bb)) / s) * bulgeOffset * (waveIntensity * 25.0) * invert) + vUv.x;
    // float x = (((pow(e, -(pow(offsetY, 2.) / bb)) / s)) * bulgeOffset * invert) + vUv.x;
    vec2 br = vec2(x, vUv.y);

    return br;
  }

  void mainImage(const in vec4 inputColor, const in vec2 vUv, out vec4 outputColor) {
    vec2 br = bellCurve(vUv, 0.75, 1.); // Wave
    br = bellCurve(br, 0.25, -1.); // Wave trough

    vec4 dis = texture2D( displacementMap, vUv );
    vec2 uv = vec2(mix(vUv.x, br.x, distortXEnabled), br.y);

    float y = displacementIntensity == 0.0 ? uv.y : mod(uv.y + (dis.r * displacementIntensity) - (displacementIntensity / 2.0), 1.0); // Displacement effect
    uv = vec2(uv.x, y);

    float c = circle(uv, mouse, 0.0, 0.2);

    vec4 color = texture2D(inputBuffer, uv.xy += c * ((velocity * .5) * intensity));
    // RGB Shift Effect
    // float r = texture2D(inputBuffer, uv.xy += c * ((velocity * .5) * intensity)).x;
    // float g = texture2D(inputBuffer, uv.xy += c * ((velocity * .525) * intensity)).y;
    // float b = texture2D(inputBuffer, uv.xy += c * ((velocity * .55) * intensity)).z;
    // float a = texture2D(inputBuffer, uv).a;
    // vec4 color = vec4(r, g, b, a);

    outputColor = color;
  }
`

export class SceneEffect extends Effect {
  constructor () {
    super('SceneEffect', fragmentShader, {
      blendFunction: BlendFunction.NORMAL,
      uniforms: new Map([
        ['waveIntensity', new Uniform(0)],
        ['displacementIntensity', new Uniform(0)],
        ['displacementMap', new Uniform(null)],
        ['distortXEnabled', new Uniform(1)],
        ['mouse', new Uniform(new Vector2(0.5, 0.5))],
        ['velocity', new Uniform(0)],
        ['intensity', new Uniform(0)]
      ])
      //  attributes: EffectAttribute.CONVOLUTION
    })
    if (primaryInput !== 'touch') {
      this.loop()
    }
  }

  get waveIntensity () {
    return this.uniforms.get('waveIntensity').value
  }

  set waveIntensity (value) {
    this.uniforms.get('waveIntensity').value = value
  }

  get displacementIntensity () {
    return this.uniforms.get('displacementIntensity').value
  }

  set displacementIntensity (value) {
    this.uniforms.get('displacementIntensity').value = value
  }

  get displacementMap () {
    return this.uniforms.get('displacementMap').value
  }

  set displacementMap (value) {
    this.uniforms.get('displacementMap').value = value
  }

  get distortXEnabled () {
    return this.uniforms.get('distortXEnabled').value
  }

  set distortXEnabled (value) {
    this.uniforms.get('distortXEnabled').value = value
  }

  get mouse () {
    return this.uniforms.get('mouse').value
  }

  set mouse (value) {
    this.uniforms.get('mouse').value = value
  }

  get velocity () {
    return this.uniforms.get('velocity').value
  }

  set velocity (value) {
    this.uniforms.get('velocity').value = value
  }

  get intensity () {
    return this.uniforms.get('intensity').value
  }

  set intensity (value) {
    this.uniforms.get('intensity').value = value
  }

  dispose () {
    window.cancelAnimationFrame(this.animationFrame)
  }

  loop () {
    if (data.current.x !== undefined) {
      this.mouse = new Vector2(data.last.x / window.innerWidth, 1 - data.last.y / window.innerHeight)
      this.velocity = lerp(this.velocity, Math.min(0.05, ((Math.abs(data.fx.velocity) + Math.abs(data.fy.velocity)) / 2)), 0.1)
    }

    this.animationFrame = window.requestAnimationFrame(this.loop.bind(this))
  }
}
