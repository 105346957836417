import forEach from 'lodash/forEach'
import reverse from 'lodash/reverse'
import { useMemo } from 'react'

export default function useGenerateRows (filteredProducts, showFirstProductAsFeatured, slices, sortBy, category, isFiltered) {
  const filteredRows = useMemo(() => {
    const isStandard = category !== 'all' || sortBy !== 'latest' || isFiltered

    const rows = !isStandard && showFirstProductAsFeatured
      ? [{ type: 'feature', number: 3, products: [], rowIndex: 0 }]
      : [{ type: 'standard', number: 5, products: [], rowIndex: 0 }]

    const _products = [...filteredProducts]
    reverse(_products)

    let rowIndex = 0
    while (_products.length > 0) {
      const p = _products.pop()
      if (rows[rowIndex]?.number > rows[rowIndex]?.products.length) {
        rows[rowIndex].products.push(p)
      } else {
        rowIndex++
        if (!rows[rowIndex]) {
          rows.push({ type: 'standard', number: 5, products: [], rowIndex })
        }
        rows[rowIndex].products.push(p)
      }
    }

    forEach(slices, slice => {
      rows.splice(slice.index - 1, 0, { type: 'slice', slice })
    })

    return rows
  }, [category, sortBy, isFiltered, showFirstProductAsFeatured, filteredProducts, slices])

  return filteredRows
}
