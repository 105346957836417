export default async function fetchRequest (url, method, data, headers = []) {
  const mergedHeaders = {
    'Content-Type': 'application/json',
    ...headers
  }
  const response = await fetch(url,
    {
      method: method || 'GET',
      body: data ? JSON.stringify(data) : undefined,
      headers: mergedHeaders
    }
  )
  if (response.ok) {
    return response.json()
  }
  let errorData
  try {
    if (response.json) {
      errorData = await response.json()
    }
  } catch { }
  if (errorData?.[0]?.message) throw Error(errorData?.[0]?.message)
  if (errorData?.errors?.[0]?.message) throw Error(errorData?.errors?.[0]?.message)
  throw Error(`${response.status}:${response.statusText}`)
}
