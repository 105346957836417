import cn from 'clsx'
import { forwardRef } from 'react'

const Section = forwardRef(
  (
    {
      as: Component = 'section',
      grid = false,
      noGutter,
      noBottomMargin,
      className,
      width = 'w-auto',
      ...rest
    },
    ref
  ) => {
    const classes = cn(
      width,
      !noBottomMargin && 'mb-20 md:mb-16',
      grid && gridClasses,
      !noGutter && 'px-4 md:px-4 mx-auto'
    )

    return <Component ref={ref} className={cn(classes, className)} {...rest} />
  }
)

export const gridClasses = 'grid grid-cols-12 md:grid-cols-8 gap-4'

export default Section
