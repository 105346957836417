'use client'

import { NextAdapter } from 'next-query-params'
import { QueryParamProvider } from 'use-query-params'

import AccountProvider from './Shop/AccountProvider'
import CartProvider from './Shop/CartProvider'

export default function Provider ({ children }) {
  return (
    <AccountProvider>
      <CartProvider>
        <QueryParamProvider adapter={NextAdapter}>
          {children}
        </QueryParamProvider>
      </CartProvider>
    </AccountProvider>
  )
}
