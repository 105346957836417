import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '../../state/theme'
import Button from '../Button'
import NoiseBackground from '../Menu/NoiseBackground'
import { NAV_VIEWS, menuOpenAtom, navViewAtom } from '../Menu/menuState'
import { useLabels, useSite } from '../siteState'
import { newsletterPopupActionedAtom } from './state'
import { subscriptionCartOpenAtom, subscriptionCartToolbarOpenAtom } from '../Slices/SubscriptionListing/subscriptionState'
import { introCompletedAtom } from '../Intro/introState'

function useEvidonPassed () {
  const [passed, setPassed] = useState(false)

  const accept = useCallback(() => {
    setPassed(true)
  }, [setPassed])

  useEffect(() => {
    setTimeout(() => {
      const hasBanner = !!window?.evidon?.banner
      const isVisible = window?.evidon?.banner?.isVisible()

      if (hasBanner === false || (hasBanner && !isVisible)) {
        accept()
        return
      }
      const acceptBtn = document.getElementById('_evidon-accept-button')
      acceptBtn?.addEventListener('click', accept)
    }, 1000)

    return () => {
      const acceptBtn = document.getElementById('_evidon-accept-button')
      acceptBtn?.removeEventListener('click', accept)
    }
  }, [])

  return passed
}

export default function NewsletterPopup () {
  const site = useSite()
  const evidonDisplayed = useEvidonPassed()
  const active = site?.newsletter?.active

  const setNavView = useSetAtom(navViewAtom)
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const [actioned, setActioned] = useAtom(newsletterPopupActionedAtom)


  const [open, setOpen] = useState(site?.newsletter?.active)
  const theme = useTheme()
  const { closeLabel } = useLabels()
  const ref = useRef()

  const bundleCartOpen = useAtomValue(subscriptionCartToolbarOpenAtom)
  const introCompleted = useAtomValue(introCompletedAtom)

  const hidden = !(open && active) || actioned || !evidonDisplayed || bundleCartOpen || !introCompleted

  useEffect(() => {
    if (!ref.current) return
    const tl = gsap.timeline()
    tl.to(ref.current, { y: hidden ? '100%' : 0, duration: 0.25, ease: 'sine.out', autoAlpha: hidden? 0 : 1, delay: !hidden ? 3 : 0 })

    // if (active && !actioned) {
    // }
    // if (!active) return
    // if ((!open || actioned) && ref.current) {
    //   const tl = gsap.timeline()
    //   tl.to(ref.current, { y: '100%', duration: 0.25, ease: 'sine.out', autoAlpha: 0 })
    // }
  }, [hidden])

  const onClose = useCallback(() => {
    setOpen(false)
    setActioned(true)
  }, [setActioned])
  const onOpenNewsletterSignupForm = useCallback(() => {
    setOpen(false)
    setMenuOpen(true)
    setNavView(NAV_VIEWS.newsletter)
    setActioned(true)
  }, [setActioned, setMenuOpen, setNavView])

  const isDarkMode = theme.dark

  const popup = site?.newsletter?.popup || {}
  const { title, copy, signUpLabel } = popup

  if (!active) return null

  return (
    <div className='fixed bottom-4 left-1/2 -translate-x-1/2 z-newsletterPopup w-72 px-2 py-4 md:w-[calc(100%-2rem)]' ref={ref}>
      <div className='blur-bg fixed inset-0 backdrop-blur-menu backdrop-saturate-menu -z-1 opacity-100' />
      <NoiseBackground light={isDarkMode} visible={true} />
      <div className='text-center text-12'>
        <div className='uppercase mb-2 font-550'>{title}</div>
        <div>{copy}</div>
      </div>
      <div className={cn(isDarkMode ? 'bg-white' : 'bg-black', 'opacity-25 h-px w-full my-4 p-y')} />
      <Button border="" filled invert w='w-auto' className='mx-auto mb-4' px='px-3' py='py-2' onClick={onOpenNewsletterSignupForm}>{signUpLabel}</Button>
      <button className='mx-auto w-auto text-10 uppercase block hover:underline' onClick={onClose}>{closeLabel}</button>
    </div>
  )
}
