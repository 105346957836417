import { useSetAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import compact from 'lodash/compact'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { useCallback, useMemo } from 'react'
import { useTrackEcommerce } from '../../gtm'
import { useSite, useSiteId } from '../siteState'
import { useAccessTokenAtom, useCustomer } from './accountState'
import { CART_STATES, LINE_ITEM_STATES, cartAtom, useCartIdAtom, useUpdateLineItemState } from './cartState'
import fetchRequest from './fetchRequest'

export function useCartActions () {
  const site = useSite()
  const siteId = useSiteId()
  const setCart = useSetAtom(cartAtom)
  const cartIdAtom = useCartIdAtom()
  const setLineItemState = useUpdateLineItemState()
  const readCartId = useAtomCallback(useCallback((get) => get(cartIdAtom), [cartIdAtom]))
  const customer = useCustomer()
  const accessTokenAtom = useAccessTokenAtom()
  const readAccessToken = useAtomCallback(useCallback((get) => get(accessTokenAtom), []))
  const readCart = useAtomCallback(useCallback((get) => get(cartAtom), []))
  const trackEcommerce = useTrackEcommerce()

  // Line Items will have a variant Id and a quantity, see https://shopify.dev/api/storefront/2022-10/input-objects/CheckoutLineItemInput
  // { variantId: '1234', quantity: 10, customAttributes: [{ key, value }]  }

  const addLineItems = useCallback(
    async (lines) => {
      setCart(cart => ({
        ...cart,
        state: CART_STATES.updating
      }))

      const cart = await readCart()
      const previousCartLineItems = cart.lines || []
      const cartId = await readCartId()
      const result = await fetchRequest(
        `/api/${siteId}/cart/addLineItems`,
        'POST',
        {
          cartId,
          lines: (isArray(lines) ? lines : [lines]).map(line => omit(line, ['product']))
        }
      )

      if (!isEmpty(result.userErrors)) {
        // TODO: Handle errors
        // setCart(...cart)
      } else {
        const addedLineItems = result.cart.lines.filter(line => !previousCartLineItems.find(p => p.id === line.id))
        trackEcommerce('add_to_cart', addedLineItems)
        setCart(cart => ({
          ...cart,
          state: CART_STATES.ready,
          ...result.cart
        }))
      }
      return result
    },
    [readCartId, siteId, setCart]
  )

  const updateLineItems = useCallback(
    async (lines) => {
      const cartId = await readCartId()
      const ids = lines.map(({ id }) => id)
      setLineItemState(ids, LINE_ITEM_STATES.updating)

      const result = await fetchRequest(
        `/api/${siteId}/cart/updateLineItems`,
        'POST',
        {
          cartId,
          lines: lines.map(({ id, merchandise, quantity, sellingPlanId, attributes }) => ({
            attributes,
            id,
            merchandiseId: merchandise.id,
            quantity,
            sellingPlanId
          }))
        }
      )

      if (!isEmpty(result.userErrors)) {
        setLineItemState(ids, LINE_ITEM_STATES.error)
      } else {
        setCart(cart => ({
          ...cart,
          ...result.cart
        }))
        // TODO: Do i just track the entire cart when it changes
        // trackEcommerce('add_to_cart', result.cart.lines)
        setLineItemState(ids, LINE_ITEM_STATES.idle)
        return result
      }
    },
    [readCartId, setLineItemState, siteId, setCart]
  )

  const removeLineItems = useCallback(async (lines) => {
    const cartId = await readCartId()
    const ids = lines.map(({ id }) => id)
    setLineItemState(ids, LINE_ITEM_STATES.removing)

    const result = await fetchRequest(
      `/api/${siteId}/cart/removeLineItems`,
      'POST',
      {
        cartId,
        lineIds: lines.map(({ id }) => id)
      }
    )

    if (!isEmpty(result.checkoutUserErrors)) {
      setLineItemState(ids, LINE_ITEM_STATES.error)
      // TODO: Handle errors
      // setCart(...cart)
    } else {
      trackEcommerce('remove_from_cart', lines)
      setCart(cart => ({
        ...cart,
        ...result.cart
      }))
      setLineItemState(ids, LINE_ITEM_STATES.idle)
      return result
    }
  }, [readCartId, setCart, setLineItemState, siteId])

  // Using the customer metafields, set the cart attributes
  const updateCartAttributes = useCallback(async () => {
    const cart = await readCart()
    const hasStHugoNewsletter = site.countryCode === 'au'
    const getCartAttributes = () => {
      if (!customer && hasStHugoNewsletter) {
        const existingStHugoSubAttribute = cart.attributes.find(({ key }) => key === '_st_hugo_newsletter')
        if (!existingStHugoSubAttribute) {
          return [
            { key: '_st_hugo_newsletter', value: 'true' }
          ]
        }
        return null
      }
      const stHugoSubMetafield = customer?.st_hugo_subscribed
      const dateOfBirthMetafield = customer?.birth_date?.value
      return compact([
        hasStHugoNewsletter ? (stHugoSubMetafield ? { key: '_st_hugo_newsletter', value: stHugoSubMetafield.value } : { key: '_st_hugo_newsletter', value: 'true' }) : null,
        dateOfBirthMetafield ? { key: '_birth_date', value: dateOfBirthMetafield } : null
      ])
    }

    const cartId = await readCartId()
    const accessToken = await readAccessToken()
    const attributes = getCartAttributes()
    if (!isEmpty(attributes)) {
      const cart = await fetchRequest(`/api/${siteId}/cart/${encodeURIComponent(cartId)}`, 'PUT', { attributes, customerAccessToken: accessToken, email: customer?.email })
      setCart({ ...cart, state: CART_STATES.ready })
      return cart
    }
    return cart
  }, [customer, readCartId, setCart, siteId, site])

  return useMemo(() => ({
    addLineItems,
    updateLineItems,
    removeLineItems,
    updateCartAttributes
  }), [addLineItems, removeLineItems, updateLineItems, updateCartAttributes])
}
