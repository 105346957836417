import cn from 'clsx'
import dayjs from 'dayjs'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import { useCallback, useEffect, useRef, useState } from 'react'
import breakpoints from '../../../../theme/breakpoints'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { leftPad } from '../../../helpers/math'
import { getProductPriceWithDiscount } from '../../../helpers/product'
import Link from '../../Link'
import useAddProductToCartCallback from '../../Menu/SecondaryNavs/useAddProductCallback'
import useCanAddProductToCart from '../../Menu/SecondaryNavs/useCanAddProductToCart'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent from '../../RichContent'
import Section from '../../Section'
import { useIsAuthenticated } from '../../Shop/accountState'
import { useCartSettings, useSite } from '../../siteState'

function ListViewHeader () {
  return (
    <Section as='div' grid noBottomMargin noGutter className='border-b-2 border-current mb-[2.5rem] py-[0.625rem]'>
      <div className='text-10 uppercase tracking-slight font-550 upMd:hidden col-span-5'>
        Wine
      </div>
      <div className='text-10 uppercase tracking-slight font-550 flex gap-4 col-span-2 md:hidden'>
        <span className='w-[2em]'>#</span>
        <span>Code</span>
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-2 md:hidden'>
        Winery
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-2 md:hidden'>
        Range
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-3 md:hidden'>
        Varietal
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-1 md:col-span-2'>
        Vintage
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-1 md:hidden'>
        Origin
      </div>
      <div className='text-10 uppercase tracking-slight font-550 col-span-1 text-right'>
        Price
      </div>
    </Section>
  )
}

function LineItem ({ product, currentHover, setHover, i }) {
  const site = useSite()
  const { definitionLabels, labels } = site
  const { soldOutLabel, membersOnlyLabel } = labels
  const { addToCartLabel } = useCartSettings()

  const mobileSectionRef = useRef()
  const [isOpen, setOpen] = useState(false)

  const year = product?.yearVintage || site.labels.nonVintageText
  const { totalAmount } = getProductPriceWithDiscount(product)

  const canAddToCart = useCanAddProductToCart(product)
  const authenticated = useIsAuthenticated()
  const isMembersOnly = product?.membersOnly
  const _disableAdd = (isMembersOnly && !authenticated) || !canAddToCart

  const isTouch = primaryInput === 'touch'
  const events = {
    onMouseEnter: isTouch ? null : () => setHover(i),
    onMouseLeave: isTouch ? null : () => setHover(-1)
  }

  const isWine = product?.productCategory === 'wine'

  const handleClick = (e) => {
    if (window.innerWidth > breakpoints.md) return
    const nodeName = e.target.nodeName
    if (nodeName && nodeName === 'BUTTON' || nodeName === 'A') return
    e.preventDefault()
    setOpen(!isOpen)
  }

  useEffect(() => {
    gsap.to(mobileSectionRef.current, {
      height: isOpen ? 'auto' : 0,
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [isOpen])

  const addProductToCart = useAddProductToCartCallback(product, 1)
  const addToCart = useCallback(() => {
    addProductToCart()
  }, [addProductToCart, product])

  return (
    <Link link={product} showText={false} onClick={handleClick}>
      <Section
        as='div'
        grid noBottomMargin noGutter
        className={cn(
          'border-t-[1px] border-current pb-4 pt-1 transition-opacity duration-600 ease-out-expo relative',
          (currentHover !== -1 && currentHover !== i) && 'opacity-30',
          currentHover === i && 'opacity-100'
        )}
        {...events}
      >
        <div className='text-12 uppercase tracking-slight upMd:hidden col-span-5'>
          {isWine
            ? (<>
            <div>{product.winery}</div>
            <div>{product.range}</div>
            <div>{product.varietal}</div>
            <span className='text-12 leading-none font-serif font-250 uppercase'>
              {product.winery?.code || product.wineryCode}.{product.range?.code || product.rangeCode}&mdash;{year}
            </span>
          </>)
            : (
            <div className='min-h-[5em]'>{product.title}</div>
              )}
        </div>
        <div className='flex gap-4 col-span-2 md:hidden'>
          <span className='text-8 uppercase tracking-slight font-550 w-[2em] leading-none'>{leftPad(i + 1, 2)}</span>
          <span className='text-12 leading-none font-serif font-250 uppercase'>
            {product.winery?.code || product.wineryCode}.{product.range?.code || product.rangeCode}&mdash;{year}
          </span>
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-2 md:hidden'>
          {isWine ? product.winery : <Logo content={definitionLabels?.title}/>}
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-2 md:hidden'>
          {isWine ? product?.range : product?.title}
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-3 md:hidden'>
          {product.varietal ? product.varietal : '—'}
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-1 md:col-span-2'>
          {isWine ? year : dayjs(product.releasedDate).format('YYYY')}
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-1 md:hidden'>
          {isWine ? product.country : '—'}
        </div>
        <div className='text-12 uppercase tracking-slight leading-none col-span-1 text-right'>
          {formatCurrency(totalAmount)}
        </div>

        <div className={cn('absolute pointer-events-none md:hidden', currentHover !== i && 'opacity-0')}>
          {product?.images?.featureImage && <ResponsiveImage image={product?.images?.featureImage} className='absolute w-[12vw] -translate-y-1/2 aspect-[340/453] left-[6vw] z-10' showPreview={false} contain imageSies='12vw' />}
          <div className='absolute top-[0.1em] left-[42vw] text-[5.5rem] uppercase font-bold tracking-slight leading-[0.8em] -translate-y-1/2 w-[40vw]'>
            {isWine && product.varietal ? product.varietal : product.title}
          </div>
        </div>

        <div ref={mobileSectionRef} className='col-span-full upMd:hidden h-0 overflow-hidden'>
          <div className='flex justify-between w-full mt-16'>
            <Link className='uppercase text-12 font-550 tracking-slight leading-none' link={product} showText={false}>Learn More</Link>
            {product?.images?.featureImage && <ResponsiveImage
              image={product?.images?.featureImage}
              className={cn('!absolute pointer-events-none h-[70%] bottom-4 left-1/2 -translate-x-1/2 aspect-[340/453] opacity-0 duration-300 ease-out-expo', isOpen && 'opacity-100')}
              showPreview={false}
              contain
              imageSies='12vw'
            />}
            <button
              onClick={addToCart}
              disabled={_disableAdd}
              className={cn('uppercase text-12 font-550 tracking-slight leading-none', _disableAdd && 'opacity-50')}
            >
              {!canAddToCart ? soldOutLabel : isMembersOnly && !authenticated ? membersOnlyLabel : addToCartLabel}
            </button>
          </div>
        </div>

      </Section>
    </Link>
  )
}

function Logo ({ content }) {
  return <RichContent content={content} />
}

export default function ListView ({ rows }) {
  const [hover, setHover] = useState(-1)
  const products = compact(flatten(rows.map(row => row.products)))

  return (
    <Section className='z-[2] relative'>
      <ListViewHeader />
      {products?.map((product, i) => (
        <LineItem key={product._id} product={product} i={i} setHover={setHover} currentHover={hover}/>
      ))}
    </Section>
  )
}
