import dayjs from 'dayjs'
import reverse from 'lodash/reverse'

export function formatDate (date) {
  const dt = dayjs(date)
  return dt.format('YYYY.MM.DD')
}

export function formatCountdown (date) {
  if (!date) {
    return
  }

  const now = dayjs()
  const end = dayjs(date)

  if (now.isAfter(end)) {
    return
  }

  let t = end.diff(now)
  const countdown = reverse(
    [
      [1000, 60],
      [60, 60],
      [60, 24],
      [24, 0]
    ].map(([divisor, modulus]) => {
      t /= divisor
      return Math.floor(modulus ? t % modulus : t)
    })
  )

  return countdown
    .map(n => (n < 10 ? `0${n}` : `${n}`).substring(0, 3))
    .join(':')
}
