import { useAtom } from 'jotai'
import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { useMemo } from 'react'
import { filtersAtom } from './filterState'

export function getMinMax (numbers) {
  const min = Math.min(numbers) || 0
  const max = Math.max(numbers) || 0
  return { min, max }
}

export default function useGenerateFilterOptions (products) {
  const [filters, setFilters] = useAtom(filtersAtom)

  return useMemo(() => {
    const options = {}
    forEach(compact(products), product => {
      forEach(filters, filter => {
        const { slug, type } = filter
        if (product[slug] && type === 'tag') {
          options[slug] = compact([...(options[slug] || []), product[slug]])
        }
      })

      const maxPrice = Math.round(product.priceRange.minVariantPrice)
      options.priceMin = 0
      if (!options.priceMax || maxPrice > options.priceMax) {
        options.priceMax = maxPrice
      }

      const year = product.yearVintage
      if (year && (!options.yearMin || year < options.yearMin)) {
        options.yearMin = year
      }
      if (year && (!options.yearMax || year > options.yearMax)) {
        options.yearMax = year
      }
    })

    forEach(filters, filter => {
      const { slug } = filter
      if (options[slug]) {
        options[slug] = sortBy(uniq(options[slug]), x => x)
      }
    })

    const f = { ...filters }
    forEach(filters, filter => {
      const { type, slug } = filter
      if (type === 'range') {
        f[slug] = {
          ...f[slug],
          min: options[`${slug}Min`],
          max: options[`${slug}Max`]
        }
      } else if (type === 'tag') {
        f[slug] = {
          ...f[slug],
          options: options[slug]
        }
      }
    })

    f.year.visible = !!products.find(item => item?.productCategory === 'wine')

    setFilters(f)

    return options
  }, [products])
}
