import cn from 'clsx'
import { useSetAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { usePageNavigateTracker } from '../gtm'
import { isHomepage, isIlluminatePage, isRelevationsPage, isSessionsPage, isWineriesListingPage } from '../helpers/pageContent'
import { isReleased } from '../helpers/product'
import { useLayoutState } from '../state/isMobile'
import { getPageTheme, themeAtom, useSetTheme } from '../state/theme'
import AppCanvas from './Canvas'
import Cursor from './Cursor'
import Footer from './Footer'
import Intro from './Intro'
import Menu from './Menu'
import { SECONDARY_MENUS, secondMenuTypeAtom } from './Menu/menuState'
import MetaData from './Meta'
import NewsletterPopup from './Newsletter/NewsletterPopup'
import Providers from './Providers'
import { useIsAuthenticated } from './Shop/accountState'
import Slices from './Slices'
import SubscriptionCartPortal from './Slices/SubscriptionListing/SubscriptionCart'
import SmoothScroller from './SmoothScroller'
import { pageContentAtom, pagePreloadAtom } from './pageContentState'
import { siteAtom } from './siteState'
import usePageTransition from './usePageTransition'
import { useSSRHydrateAtoms } from './useSSRHydrateAtoms'

const useHydratedState = (site, data) => {
  const { page } = data
  useSSRHydrateAtoms([[siteAtom, site], [themeAtom, getPageTheme(page)], [pageContentAtom, page], [pagePreloadAtom, { [page._id]: false }]])
}

export default function Page ({ data = {}, className }) {
  const { site, variations } = data
  const mainRef = useRef()
  const setSecondNavType = useSetAtom(secondMenuTypeAtom)
  useHydratedState(site, data)
  const authenticated = useIsAuthenticated()

  const page = usePageTransition(data.page)

  usePageNavigateTracker(page)

  const initialThemeRef = useRef(getPageTheme(data.page, site, authenticated))
  useSetTheme(page)
  useLayoutState()

  const isProductPage = page._type === 'product'
  const isErrorPage = page.pageType === 'error'
  const hasCases = page.eligibleForCases
  const hasFooter = !isHomepage(page) && !isErrorPage && !isWineriesListingPage(page) && !isIlluminatePage(page) && !isSessionsPage(page)
  const isRelevations = isRelevationsPage(page)
  const isDropping = !isReleased(page)
  const hasPaddingTop = !isHomepage(page) && page.pageType !== 'winery' && page.pageType !== 'article' && !isSessionsPage(page) && !isDropping

  useEffect(() => {
    if (isDropping) {
      setSecondNavType(SECONDARY_MENUS.dropping)
      return
    }
    if (page.membersOnly && !authenticated) {
      setSecondNavType(SECONDARY_MENUS.membersOnly)
      return
    }
    if (isProductPage) {
      setSecondNavType(SECONDARY_MENUS.product)
      return
    }
    if (isRelevations) {
      setSecondNavType(SECONDARY_MENUS.article)
    }
  }, [page, authenticated, page.membersOnly, isProductPage, hasCases, isRelevations, isDropping])

  useEffect(() => {
    const theme = initialThemeRef.current
    document.documentElement.style.setProperty('--foreground', theme.foreground)
    document.documentElement.style.setProperty('--background', theme.background)
    document.documentElement.style.setProperty('--border', theme.border)
    document.documentElement.style.setProperty('--foreground-invert', theme.foregroundInverted)
    document.documentElement.style.setProperty('--cream', theme.cream)
  }, [])

  return (
      <Providers site={site}>
        <main id='main' className={cn(className, hasPaddingTop && 'pt-top md:pt-top-md w-full h-full')}
          style={{
            '--background': initialThemeRef.current.background,
            '--foreground': initialThemeRef.current.foreground,
            '--border': initialThemeRef.current.border,
            '--foreground-invert': initialThemeRef.current.foregroundInverted,
            '--cream': initialThemeRef.current.cream,
            backgroundColor: 'var(--background)',
            color: 'var(--foreground)',
            minHeight: '100svh'
          }}
          ref={mainRef}
        >
          <Cursor />
          <Intro page={page}/>
          <SubscriptionCartPortal />
          <NewsletterPopup />
          <div id='canvas-feed-html' className='z-canvas-html fixed' />
          <MetaData site={site} page={page} variations={variations} />
          <SmoothScroller page={page}>
            <AppCanvas />
            <Menu site={site} page={page} />
            <div id='page-content'>
              <Slices page={page} slices={page?.slices} />
              {hasFooter && <Footer settings={site} page={page}/> }
            </div>
          </SmoothScroller>
        </main>
    </Providers>
  )
}
