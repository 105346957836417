import gsap from 'gsap'
import findIndex from 'lodash/findIndex'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { StringParam, withDefault } from 'use-query-params'
import Slices from '..'
import useQueryParamsWithRoute from '../../../hooks/useQueryParamsWithRoute'
import { useHasProductsInCustomBundle } from '../../siteState'
import { SmoothScrollerContext } from '../../SmoothScroller'
import ProductListingHeader from '../ProductListing/ProductListingHeader'
import DynamicBundleListing from '../ProductListing/SubscriptionsTab/DynamicBundleListing'
import { dropdownOptions } from '../ProductListing/useSortProducts'
import { useTabs } from '../SupportSlice'

export function PageTransition ({ pageId, children, className }) {
  const ref = useRef()
  const [data, setData] = useState(children)
  const [previousPageId, setPageId] = useState(pageId)
  const localsRef = useRef({})

  function resetPage (page) {
    setData(page)
  }

  useEffect(() => {
    if (data === children) return
    if (previousPageId === pageId) {
      resetPage(children)
      return
    }
    if (localsRef.current.tl) localsRef.current.tl.kill()
    const tl = gsap.to(ref.current, {
      opacity: 0,
      duration: 0.6,
      ease: 'expo.out',
      onComplete: () => {
        resetPage(children)
        gsap.to(ref.current, {
          opacity: 1,
          duration: 0.6,
          ease: 'power4.out'
        })
        setPageId(pageId)
      }
    })
    localsRef.current.tl = tl
  }, [pageId, children])

  return (
    <div ref={ref} className={className}>
      {data}
    </div>
  )
}

export default function SubscriptionListing ({ data, page }) {
  const { createPageLabel, slug } = data
  const hasProductsInBundle = useHasProductsInCustomBundle()
  const pages = [
    ...data.tabs,
    hasProductsInBundle && {
      title: createPageLabel,
      slug
    }
  ]

  const [query, setQuery] = useQueryParamsWithRoute({ sortBy: withDefault(StringParam, dropdownOptions[0].value) })
  const { sortBy } = query

  const [currentPage, setPage] = useTabs(pages, page)
  const index = findIndex(pages, currentPage)
  const isListingPage = index === pages.length - 1 && hasProductsInBundle

  const scrollContext = useContext(SmoothScrollerContext)
  useEffect(() => {
    if (scrollContext.current?.lenis) {
      scrollContext.current?.lenis.scrollTo(0)
    }
  }, [currentPage])

  const handleSetCategory = (slug) => {
    setPage(pages.find(page => page?.slug?.current === slug))
  }

  const setSortBy = useCallback((value) => {
    setQuery({ sortBy: value })
  }, [setQuery])

  return (
    <>
      <ProductListingHeader
        sortBy={sortBy}
        setSortBy={setSortBy}
        showSortAndLayout={false}
        showCategories
        categories={pages}
        category={currentPage?.slug?.current}
        setCategory={handleSetCategory}
      />

      <PageTransition pageId={currentPage?.slug.current}>
        {!isListingPage && <Slices slices={pages[index].slices} page={page}/>}
        {isListingPage && <div>
          <DynamicBundleListing data={data} sortBy={sortBy} />
        </div>}
      </PageTransition>
    </>
  )
}
