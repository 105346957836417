import cn from 'clsx'
import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { isReleased } from '../helpers/product'
import Link from './Link'
import { NAV_VIEWS, menuOpenAtom, navViewAtom } from './Menu/menuState'
import RichContent from './RichContent'
import Section from './Section'
import Ticker from './Ticker'
import UnderlineLink from './UnderlineLink'
import { hasMoreProductsAtom } from './Slices/ProductListing'

export default function Footer ({ page, settings }) {
  const isProductPage = page?._type === 'product'
  const isDropping = !isReleased(page)
  const setNavView = useSetAtom(navViewAtom)
  const setMenuOpen = useSetAtom(menuOpenAtom)
  const hasMoreProducts = useAtomValue(hasMoreProductsAtom)

  const showTicker = (!isProductPage || isDropping)
  const {
    liquorLicence,
    liquorLicenceLink,
    copyright,
    aoc,
    footerMarqueeText,
    footerLinks
  } = settings

  const openNewsletterForm = useCallback(() => {
    setNavView(NAV_VIEWS.newsletter)
    setMenuOpen(true)
  }, [])

  return (
    <Section
      className={cn(
        'md:min-h-[unset] flex flex-col pt-[58px] justify-center overflow-hidden', 
        showTicker && 'min-h-screen',
        hasMoreProducts !== undefined && hasMoreProducts && 'opacity-0'
      )}
      width='w-screen-without-scrollbar'
      noBottomMargin
    >
      <Section as='div' grid noGutter noBottomMargin className={cn('md:flex md:justify-between md:gap-1', !showTicker && 'mb-[130px] md:mb-[72px]')}>
        <div className='uppercase text-10 md:text-14 col-span-4 font-550'><RichContent content={settings?.definitionLabels?.title} /></div>
        <div className='uppercase text-10 md:text-14 col-span-4'>{settings?.definitionLabels?.wordClass}</div>
        <div className='uppercase text-10 md:text-14 col-span-3'>{settings?.definitionLabels?.phonetic}</div>
        <div className='uppercase text-10 md:text-14 col-span-1'>{settings?.definitionLabels?.description}</div>
      </Section>

      {showTicker && footerMarqueeText && <div className='-ml-4 -mr-4 flex-1 self-center overflow-hidden col-span-full flex items-center md:my-[150px] md:max-w-[calc(100%+32px)]'>
        <Ticker fadeIn className='w-full' pxPerSecond={50}>
          <RichContent className='font-serif font-250 mr-20 ml-20 relative text-[5rem] md:text-[2rem] [&_a]:no-underline [&_a]:font-250' content={footerMarqueeText} />
        </Ticker>
      </div>}

      <Section grid noGutter noBottomMargin className='mb-[118px] md:mb-[72px] md:gap-y-[112px]'>
        {footerLinks?.map((linkBlock, i) => (
          <div className={cn('col-span-4 uppercase text-10 md:text-14', i === 2 && 'col-span-2 md:col-span-4', i === 3 && 'col-span-2 justify-self-end md:justify-self-auto md:col-span-4')} key={linkBlock._key}>
            <div className={cn('font-550 mb-6 md:mb-[1em]')}>{linkBlock.title}</div>
            <ul>
              {linkBlock.links?.map(link => {
                if (link._type === 'link') {
                  const linkText = (link?.text || link?.page?.title)?.toLowerCase()
                  const dataDescription = link.linkType === 'external' ? `follow|${linkText}` : `${linkBlock.title?.toLowerCase()}|${linkText}`
                  const dataType = linkBlock.title?.toLowerCase() === 'follow' ? 'social' : 'link'
                  return (
                  <li key={link._key} className='mb-[3px] overflow-hidden text-ellipsis leading-[1em]'>
                    <UnderlineLink
                      link={link}
                      className='whitespace-nowrap'
                      data-event='site interaction'
                      data-type={dataType}
                      data-description={dataDescription}
                      data-location='footer'
                    />
                  </li>
                  )
                }
                return (
                  <li key={link._key} className='mb-[3px] overflow-hidden text-ellipsis leading-[1em]'>
                    <UnderlineLink
                      onClick={openNewsletterForm}
                      className='whitespace-nowrap cursor-pointer'
                      data-event='site interaction'
                      data-location='footer'
                      data-type='link'
                      data-description={`${linkBlock.title?.toLowerCase()}|${link.title}`}
                    >
                      {link.title}
                    </UnderlineLink>
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </Section>

      <div className='col-span-full h-[1px] bg-current'/>

      <Section grid noGutter noBottomMargin className='mt-4 mb-12 md:mb-6'>
        <div className='uppercase text-8 md:text-10 col-span-5 max-w-[46em] md:col-span-full md:max-w-[unset] md:text-justify'>
          <RichContent content={aoc} />
        </div>
        <div className='uppercase text-8 md:text-10 col-start-9 col-span-2 max-w-[20em] md:col-start-1 md:col-span-6'>{copyright?.replace('{YEAR}', new Date().getFullYear())}</div>
        <div className='uppercase text-8 md:text-10 col-span-2 text-right'>
        {liquorLicence && <Link to={liquorLicenceLink}>Liquor Licence: {liquorLicence}</Link>}
        </div>
      </Section>
    </Section>
  )
}
