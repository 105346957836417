import SeededRand from 'seeded-rand'

export default class Random {
  constructor (seed) {
    this.rand = new SeededRand(seed)
    // this.seed = seed
    this.originalSeed = seed
  }

  generate (min, max) {
    return this.rand.inRange(min, max)
    // return Math.abs(Math.sin(this.seed++)) * (max - min) + min
  }

  sample (arr, count) {
    return this.rand.sample(arr, count)
  }

  shuffle (arr) {
    return this.rand.shuffle(arr)
  }

  reset () {
    this.rand = new SeededRand(this.originalSeed)
  }
}
