
import Section from '../Section'
import ResponsiveImage from '../ResponsiveImage'
import Link from '../Link'
import { Parallax } from '../Parallax'

export default function FeaturedWinery ({ data, page }) {
  const { winery, image, logo } = data
  return (
    <Link link={winery} showText={false}>
      <Section grid className='relative'>
        <Parallax speed={1.2} mobileSpeed={0} className='!absolute w-auto left-4 right-4 top-1/2 -translate-y-1/2 z-10'>
          <ResponsiveImage className='' image={logo} showPreview={false} contain/>
        </Parallax>
        <div className='col-start-5 col-end-[-1] overflow-hidden'>
          <Parallax speed={-1} mobileSpeed={0}>
            <ResponsiveImage image={image} aspect={932/870} mobileAspect={267/317} />
          </Parallax>
        </div>
      </Section>
    </Link>
  )
}