import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useCallback, useEffect, useRef, useState } from 'react'
import PlusMinus from './PlusMinus.svg'
import RichContent from './RichContent'

const Item = ({ title, content, onToggle }) => {
  const contentRef = useRef()
  const [open, setOpen] = useState()

  const handleClick = useCallback(() => {
    if (onToggle) onToggle(!open)
    setOpen(!open)
  }, [open])

  useEffect(() => {
    gsap.to(contentRef.current, {
      height: open ? 'auto' : 0,
      duration: 0.6,
      ease: 'power3.inOut',
      onComplete: () => {
        ScrollTrigger.refresh()
      }
    })
  }, [open])

  return (
    <div className="border-y -mt-px relative before:content-[''] after:content-[''] before:block after:block before:opacity-10 after:opacity-10 before:absolute after:absolute before:inset-x-0 after:inset-x-0 before:h-px after:h-px before:hidden before:top-0 after:bottom-0 [&:first-child&:before]:block">
      <button onClick={handleClick} className="reset-button flex w-full justify-between items-center h-[40px] md:h-[56px] text-[12px] [&>div]:mb-12 uppercase !font-550"
        data-event='site interaction'
        data-type='button'
        data-description='toggle accordion'
        data-location='body'
      >
        <span className="leading-none">{title}</span>
        <span className="min-w-[24px]">
          <PlusMinus isOpen={open} />
        </span>
      </button>
      <div className="overflow-hidden h-0 text-16 md:text-14 w-3/5 md:w-full" ref={contentRef}>
        <div className="h-[32px] md:h-[8px]" />
        <RichContent className="[&>div>p]:mb-4" content={content} />
      </div>
    </div>
  )
}

const AccordionComponent = ({ tabs = [] }) => {
  return (
    <div>
      {tabs.map(({ title, content }, index) => (
        <Item key={index} title={title} content={content} />
      ))}
    </div>
  )
}

export default AccordionComponent
