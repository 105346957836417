import cn from 'clsx'
import gsap from 'gsap'
import fill from 'lodash/fill'
import flatten from 'lodash/flatten'
import padStart from 'lodash/padStart'
import { useEffect, useMemo, useRef } from 'react'
import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'

const BundleLineItem = ({ lineItem, index }) => {
  const { product } = lineItem
  const { featureImage, title } = product
  return (
    <Link link={product} showText={false}>
      <li className='grid mt-[-1px]' style={{ gridTemplateColumns: 'min-content auto', gridTemplateRows: '1fr auto' }}>
        <div className={cn('row-span-2 px-2 py-2')}>
          <div className='w-16 flex justify-center'>
            <ResponsiveImage showPreview={false} image={featureImage} className='w-10' aspect={68 / 106} contain />
          </div>
        </div>
        <div className='p-2 text-10 flex items-center'>
          <div className='flex justify-between'>
            <span className='font-semibold mr-4 uppercase'>
              {title}
            </span>
            <span className='uppercase'>
              {padStart(index + 1, 2, '0')}
            </span>
          </div>
        </div>
      </li>
    </Link>
  )
}

export default function BundleLineItems ({ className, show, bundle }) {
  const { lines } = bundle
  const ref = useRef()
  useEffect(() => {
    gsap.to(ref.current, { height: show ? 'auto' : 0, duration: 0.8, ease: 'expo.out' })
  }, [show])
  const lineItems = useMemo(() => {
    // This will create a separate line item for each quantity in the the lines items
    // So if there are 3x Product 1, then it will create 3 lines items for Product 1
    return flatten(lines.map(line => fill(Array(line.quantity), line)))
  }, [lines])
  return (
    <ul style={{ height: 0 }} className={cn(className, 'overflow-hidden')} ref={ref}>
      {lineItems?.map((line, i) => <BundleLineItem key={i} lineItem={line} index={i} />)}
    </ul>
  )
}
