export default function Logo ({ className, isSmall }) {
  if (isSmall) {
    return (
      <svg className={className} width="1257" height="244" viewBox="0 0 1257 244" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.02959L86.9727 243.004H144.174L231.147 5.02959V0.00195312H179.465L117.58 186.025H114.235L52.1836 0.00195312H0V5.02959Z" fill="currentColor"/>
        <path d="M250.695 243.17H300.537V79.4367H303.882L404.236 243.002H457.256V0H407.581V163.566H404.236L304.05 0H250.863V243.17H250.695Z" fill="currentColor"/>
        <path d="M481.811 43.7404H563.598V243.17H615.447V43.7404H697.235V0H481.811V43.7404Z" fill="currentColor"/>
        <path d="M721.617 243.17H771.459V79.4367H774.804L875.157 243.002H928.177V0H878.503V163.566H875.157L774.804 0H721.617V243.17Z" fill="currentColor"/>
        <path d="M1148.41 177.978C1145.57 151.332 1136.2 136.584 1110.61 129.713V126.361C1136.37 120.161 1156.44 101.726 1156.44 67.7055C1156.44 20.7809 1118.8 0 1070.47 0H957.57V243.17H1009.42V148.483H1049.9C1084.52 148.483 1096.06 160.214 1098.23 187.028L1099.74 206.971C1101.24 225.238 1103.58 233.785 1108.43 243.17H1161.62V238.142C1155.1 228.925 1152.76 220.211 1151.08 204.122L1148.41 177.978ZM1064.95 38.0425C1089.87 38.0425 1106.26 50.2764 1106.26 74.409C1106.26 98.5417 1089.87 110.608 1064.95 110.608H1009.75V38.0425H1064.95Z" fill="currentColor"/>
        <path d="M1168 202H1205.42V209.631H1191.99V243H1181.43V209.631H1168V202Z" fill="currentColor"/>
        <path d="M1210.74 202H1224L1233.58 229.163H1234.16L1243.74 202H1257V243H1247.36V217.489H1246.78L1237.83 243H1229.91L1220.95 217.489H1220.38V243H1210.74V202Z" fill="currentColor"/>
      </svg>
    )
  }
  return (
    <svg className={className} width="1180" height="244" viewBox="0 0 1180 244" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5.02959L86.9727 243.004H144.174L231.147 5.02959V0.00195312H179.465L117.58 186.025H114.235L52.1836 0.00195312H0V5.02959Z" fill="currentColor"/>
      <path d="M240.695 243.17H290.537V79.4367H293.882L394.236 243.002H447.256V0H397.581V163.566H394.236L294.05 0H240.863V243.17H240.695Z" fill="currentColor"/>
      <path d="M461.811 43.7404H543.598V243.17H595.447V43.7404H677.235V0H461.811V43.7404Z" fill="currentColor"/>
      <path d="M691.617 243.17H741.459V79.4367H744.804L845.158 243.002H898.177V0H848.503V163.566H845.158L744.804 0H691.617V243.17Z" fill="currentColor"/>
      <path d="M1106.41 177.978C1103.57 151.332 1094.2 136.584 1068.61 129.713V126.361C1094.37 120.161 1114.44 101.726 1114.44 67.7055C1114.44 20.7809 1076.8 0 1028.47 0H915.57V243.17H967.419V148.483H1007.9C1042.52 148.483 1054.06 160.214 1056.23 187.028L1057.74 206.971C1059.24 225.238 1061.58 233.785 1066.43 243.17H1119.62V238.142C1113.1 228.925 1110.76 220.211 1109.08 204.122L1106.41 177.978ZM1022.95 38.0425C1047.87 38.0425 1064.26 50.2764 1064.26 74.409C1064.26 98.5417 1047.87 110.608 1022.95 110.608H967.754V38.0425H1022.95Z" fill="currentColor"/>
      <path d="M1126 218.418H1148.41V222.417H1139.91V243.673H1134.51V222.417H1126V218.418Z" fill="currentColor"/>
      <path d="M1151.32 218.418H1158.21L1164.97 236.588H1165.32L1172.07 218.418H1178.96V243.673H1174V226.451H1173.65L1167.26 243.673H1163.02L1156.63 226.451H1156.28V243.673H1151.32V218.418Z" fill="currentColor"/>
    </svg>
  )
}