import Link from '../Link'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import Section from '../Section'

export default function ObjectProductBio ({ data }) {
  const { title, image, copy, link } = data
  return (
    <Section grid className='md:mt-[8rem] mb-[16rem] z-[2] relative border-t border-[rgba(0,0,0,0.1)] pt-[2rem]'>
      <div className='col-span-2 md:col-span-3 md:row-start-1 md:row-end-3'>
        {image && <ResponsiveImage image={image} aspect={229/306}/>}
      </div>
      <h2 className='col-span-3 font-550 text-24 tracking-slight md:col-start-4 md:col-span-5 md:row-start-1'>{title}</h2>
      <div className='col-span-4 md:col-start-4 md:col-span-5 md:row-start-2'>
        <RichContent content={copy} className='text-14 tracking-[-0.01em]'/>
        {link && <Link link={link} className='uppercase !text-10 font-550 tracking-slight px-2 py-1 border inline-flex items-center gap-1 mt-6 hover:bg-foreground hover:text-background hover:border-foreground' />}
      </div>
    </Section>
  )
}
