import compact from 'lodash/compact'
import get from 'lodash/get'
import qs from 'query-string'
import routes from '../../routes'

function resolveSlug (document) {
  const slug = get(document, ['slug'], get(document, ['current'], document))
  if (slug !== 'home') { // home resolves to `/`
    return slug
  }
}

export function resolveInternalLinkUrl ({ slug, site }, subpageSlug) {
  const parts = [
    site?.countryCode !== routes.defaultRoute.countryCode ? site?.countryCode : null,
    resolveSlug(slug),
    subpageSlug || null
  ]
  return `/${compact(parts).join('/')}`
}

export function resolveQueryParams (params) {
  const urlParams = new URLSearchParams(params)
  return Object.fromEntries(urlParams)
}

export function resolveLink (linkOrPage) {
  if (!linkOrPage) return null
  if (linkOrPage.linkType) {
    if (linkOrPage.linkType === 'external') {
      return linkOrPage
    }
    return {
      text: get(linkOrPage, ['text'], get(linkOrPage, ['page', 'title'])),
      url: linkOrPage.page ? resolveInternalLinkUrl(linkOrPage.page, linkOrPage?.subpageSlug) : '',
      hash: get(linkOrPage, ['pageAnchor']),
      query: linkOrPage.query ? resolveQueryParams(linkOrPage.query) : null,
      subpageSlug: linkOrPage?.subpageSlug || null
    }
  }
  return {
    text: get(linkOrPage, ['title']),
    url: resolveInternalLinkUrl(linkOrPage),
    hash: get(linkOrPage, ['pageAnchor'])
  }
}

export const linkToUrl = (link) => {
  if (!link) return null
  let url = link.url
  if (link.query) {
    url = `${url}?${qs.stringify(link.query)}`
  }
  if (link.hash) {
    url = `${url}#${link.hash}`
  }
  return url
}
