import cn from 'clsx'
import gsap from 'gsap'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useIsHovering from '../../hooks/useIsHovering'
import useIsMounted from '../../hooks/useIsMounted'
import { data as cursorData } from '../Cursor.js'
import Link from '../Link'
import Section from '../Section'
import SplitTextAnimation from '../SplitTextAnimation'
import { ArticleMeta, ProductMeta } from './RelatedPages'

const QuoteCursor = ({ show, link }) => {
  const cursorRef = useRef()
  const localsRef = useRef({})
  const [isOffScreen, setOffScreen] = useState(false)
  const mounted = useIsMounted()

  // TODO: Add support for the other page links. Just show the title for the other pages
  const isProduct = link?._type === 'product'
  const isArticle = link?.pageType === 'article'

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current

    localsRef.current.windowWidth = window.innerWidth
    localsRef.current.textWidth = cursor.getBoundingClientRect().width

    gsap.to(cursor.children[0], {
      scale: show ? 1 : 0,
      duration: 0.3,
      ease: 'expo.out'
    })
    return () => {
      gsap.to(cursor.children[0], {
        scale: 0,
        duration: 0.3,
        ease: 'expo.out'
      })
    }
  }, [show])

  useEffect(() => {
    const loop = () => {
      setOffScreen(cursorData.last.x > localsRef.current.windowWidth - localsRef.current.textWidth - 20)
    }
    gsap.ticker.add(loop)
    return () => {
      gsap.ticker.remove(loop)
    }
  }, [])

  useEffect(() => {
    if (!cursorRef.current) return
    const cursor = cursorRef.current
    gsap.to(cursor.children[0], {
      x: isOffScreen ? '-100%' : 0,
      transformOrigin: isOffScreen ? '100% 0' : '0 0',
      duration: 0.6,
      ease: 'expo.out'
    })
  }, [isOffScreen])

  if (!link) return
  if (!isProduct || !isArticle) return
  if (!mounted) return

  return createPortal(
    <div ref={cursorRef} className={cn('pointer-events-none fixed top-2 left-2 w-80 md:hidden', isProduct && 'w-96')}>
      <div className='bg-background border border-r-current text-current p-2 text-10 scale-0 inline-block'>
        {link && isArticle && <ArticleMeta article={link} className='text-10' />}
        {link && isProduct && <ProductMeta product={link} className='text-10' />}
      </div>
    </div>,
    document.getElementById('cursor')
  )
}

const Quote = forwardRef(({ data, showCursor = true, ...props }, ref) => {
  const { quote, link, sidebarTextTop, sidebarTextBottom } = data

  const { eventListeners, hovering } = useIsHovering()

  const isProduct = link?._type === 'product'
  const isArticle = link?.pageType === 'article'

  return (
    <Section grid className='z-[2] relative' ref={ref} {...props}>
      {link && showCursor && <QuoteCursor show={hovering} link={link} />}

      <Link link={link} showText={false} className='col-span-full relative' {...eventListeners}>
        <blockquote className='md:max-w-full pr-8 md:pr-0 text-responsive text-[calc((100vw-48px)*0.09)] leading-[0.8em] tracking-[-0.005em] text-justify font-serif font-180 uppercase'>
          <SplitTextAnimation
            animateWhenInView={true}
            initialOpacity={0}
            duration={0.6}
            stagger={0.5}
            delay={0.25}
            tag="div"
          >
            {quote}
          </SplitTextAnimation>
        </blockquote>

        <div className='[writing-mode:vertical-lr] md:[writing-mode:horizontal-tb] origin-top-center absolute md:static -right-1 top-0 h-full md:h-auto flex flex-column md:flex-row justify-between text-12 font-400 uppercase md:mt-2'>
          {sidebarTextTop && !isProduct && <span>{sidebarTextTop}</span>}
          {sidebarTextBottom && !isProduct && <span>{sidebarTextBottom}</span>}

          {isProduct && <span>{link?.winery}</span>}
          {isProduct && <span>{link?.range}</span>}
          {isProduct && <span>{link?.varietal}</span>}
        </div>

        {link?.text && <div className={cn('hidden md:block w-full max-w-[16em] z-10 static mx-auto mt-4', isProduct && 'max-w-[25em]')}>
          <div className='bg-background border border-current text-current p-2 text-10 w-full'>
            {link && isArticle && <ArticleMeta article={link} className='text-10' />}
            {link && isProduct && <ProductMeta product={link} className='text-10' />}
          </div>
        </div>}

      </Link>

    </Section>
  )
})

export default Quote
