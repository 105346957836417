import { useAtom, useAtomValue } from 'jotai'
import { sessionAudioStateAtom } from '../../Slices/Sessions/SessionsHero'
import { leftPad } from '../../../helpers/math'
import { useEffect, useRef, useState } from 'react'

export function formatTime(seconds) {
  var s = parseInt(seconds % 60)
  var m = parseInt((seconds / 60) % 60)
  return leftPad(m, 2) + ':' + leftPad(s, 2)
}

export default function SessionsNav ({ page }) {
  const ref = useRef()
  const [audioState, setAudioState] = useAtom(sessionAudioStateAtom)
  const progress = audioState.current / audioState.duration
  const [isMouseDown, setMouseDown] = useState(false)

  const handleMouseMove = (e) => {
    if (!isMouseDown) return
    const rect = ref.current.getBoundingClientRect()
    const progress = ((e.clientX - rect.left) / rect.width)
    const audioEl = document.querySelector('audio')
    audioEl.currentTime = audioState.duration * progress
  }

  const mouseUp = () => setMouseDown(false)

  useEffect(() => {
    document.addEventListener('mouseup', mouseUp)
    return () => document.removeEventListener('mouseup', mouseUp)
  }, [])


  return (
    <>
      <div
        className='grid grid-cols-3 col-span-full items-center h-[1.875rem] md:min-h-[47px] -mx-2 px-2 overflow-hidden relative select-none'
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
        onMouseMove={handleMouseMove}
        ref={ref}
      >
        <div className='text-12 md:text-16 font-serif font-250 tracking-slight z-10'>
          {formatTime(audioState.current)}
        </div>
        <button className='text-12 md:text-16 font-550 tracking-slight uppercase z-10' onClick={() => setAudioState({...audioState, isPlaying: !audioState.isPlaying})}>
          {audioState.isPlaying ? 'pause' : 'start'}
        </button>
        <div className='text-12 md:text-16 font-serif font-250 tracking-slight text-right z-10'>
          {formatTime(audioState.duration)}
        </div>

        <div className='absolute inset-0 -translate-x-full opacity-[0.5] pointer-events-none z-1 backdrop-blur-menu' style={{ backgroundColor: '#000', transform: `translateX(${-(1 - progress) * 100}%)` }} />
      </div>
    </>
  )
}
