import { useCallback } from 'react'
import { Check } from './TagFilter'
import { useFilterQueryParams } from './filterState'

export default function BooleanFilter ({ filter }) {
  const { title, slug } = filter
  const [query, setQuery] = useFilterQueryParams()
  const checked = query[slug]
  const toggle = useCallback(() => {
    setQuery({
      ...query,
      [slug]: checked ? undefined : true
    })
  }, [checked, query, setQuery, slug])

  return (
    <div className='border-t [&:not(:last-child)]:border-b border-solid border-current -mt-px'>
      <button className='w-full flex justify-between text-8 md:text-14 tracking-slight uppercase py-4' onClick={toggle}>
        <div className='font-550'>{title}</div>
        <div className='border border-solid border-current w-4 h-4 flex justify-center items-center'>
          {checked && <Check />}
        </div>
      </button>
    </div>
  )
}
