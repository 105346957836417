import clsx from 'clsx'
import gsap from 'gsap'
import { forwardRef, useEffect, useRef } from 'react'
import noiseDark from './noise-black.png'
import noiseLight from './noise-white.png'

const Texture = forwardRef((props, ref) => {
  const { image, className, backgroundColor = '' } = props
  return (
    <div
      ref={ref}
      className={clsx('fixed inset-0 -z-1', className)}
      style={{
        background: `url(${image.src}) ${backgroundColor}`,
        backgroundSize: `${image.width / 2}px ${image.height / 2}px`,
        backgroundPosition: 'top left',
        backgroundRepeat: 'repeat'
      }}
    />
  )
})

export default function NoiseBackground ({ light, visible, className }) {
  const darkRef = useRef()
  const lightRef = useRef()

  useEffect(() => {
    gsap.to(darkRef.current, { opacity: !visible ? 0 : light ? 0 : 1, duration: 0.3 })
    gsap.to(lightRef.current, { opacity: !visible ? 0 : light ? 1 : 0, duration: 0.3 })
  }, [light, visible])

  return (
    <>
      <Texture image={noiseDark} backgroundColor='rgba(229, 229, 229, 0.7)' className={clsx('opacity-0', className)} ref={darkRef} />
      <Texture image={noiseLight} className={clsx('opacity-0', className)} ref={lightRef} />
    </>
  )
}
