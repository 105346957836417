import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { LINK_HOVER_CLASSES } from '../../index'
import FilterContainer from './FilterContainer'
import { filtersCountAtom, useFilterActions } from './filterState'

export default function TagFilter ({ filter, setViewAll }) {
  const { title, slug, options = false } = filter
  const [selected, handleAdd, handleRemove] = useFilterActions(slug)
  const counts = useAtomValue(filtersCountAtom)?.[slug]?.count

  return (
    <FilterContainer filter={filter} setViewAll={setViewAll}>
      <div className='flex flex-wrap gap-1 pb-4'>
        {options?.map((option, i) => {
          const isSelected = selected.indexOf(option) !== -1
          const count = counts?.[option]
          const disable = !count && !isSelected
          return (
          <button
            key={option + i}
            className={cn(
              !disable && LINK_HOVER_CLASSES,
              'py-2 px-3 uppercase text-8 md:text-14 font-550 border border-current whitespace-nowrap flex gap-1 items-center md:leading-none md:px-[0.875rem]',
              isSelected && 'bg-black text-white border-black',
              disable && 'opacity-50'
            )}
            onClick={() => isSelected ? handleRemove(option) : handleAdd(option)}
            data-event='site interaction'
            data-type='button'
            data-description={`${title}|toggle filter ${option}`}
            data-location='filter'
            disabled={disable}
          >
            <span className='flex align-top'>{option}<span className='text-[0.75em] opacity-75 block ml-[0.125rem]'>({count})</span></span>
            {isSelected && <Check />}
          </button>
          )
        })}
      </div>
    </FilterContainer>
  )
}

export const Check = () => (
  <svg width="0.5rem" height="0.5rem" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66665 2L2.99998 5.66667L1.33331 4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
