import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import { useEffect, useMemo } from 'react'
import isBrowser from '../../../helpers/isBrowser'
import { SCENES, useShowSceneCallback } from '../../Canvas/sceneState'
import { generateFeedRows } from './feedRows'
import ScreenReaderTile from './ScreenReaderTile'

let cachedFeed = null

function useFeedRows (slice) {
  // We only need to get the feed once, we are not paging anything anymore
  return useMemo(() => {
    if (!cachedFeed || !isBrowser) {
      let items = [...(slice.feedItems?.items || [])]

      const mapItem = (item) => {
        switch (item._type) {
          case 'productTile':
          case 'articleTile':
            return {
              ...(item.product || item.article),
              layout: item.layout,
              featureLayout: item
            }
          default: {
            return item
          }
        }
      }

      forEach((slice.feed || []), feedItem => {
        items = compact([
          ...items.slice(0, feedItem.position - 1),
          mapItem(feedItem),
          ...items.slice(feedItem.position - 1)
        ])
      })

      cachedFeed = {
        items,
        rows: generateFeedRows(items)
      }
    }
    return cachedFeed
  }, [])
}

export default function Feed ({ data, page }) {
  const { rows, items } = useFeedRows(data)
  const showScene = useShowSceneCallback()
  useEffect(() => {
    showScene(SCENES.FEED_ORDER, rows)
    return () => {
      showScene(SCENES.NONE, rows)
    }
  }, [rows, showScene])

  const { title } = data

  return (
    <section className='flex flex-col justify-center pointer-events-none'>
      <div className='h-screen hidden md:hidden' id='feed-scroll-container' />
      <h1 className='srOnly'>{title || page.title}</h1>
      <ul className='srOnly'>
        {items?.map((page, i) => {
          const { _type } = page
          if (_type === 'product' || _type === 'page') {
            const { _id } = page
            return (
              <ScreenReaderTile page={page} key={`${_id}-${i}`} />
            )
          }
          return null
        }
        )}
      </ul>
    </section>
  )
}
