import { PerformanceMonitor } from '@react-three/drei'
import { Canvas, useThree } from '@react-three/fiber'
import cn from 'clsx'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { LinearToneMapping } from 'three'
import isBrowser from '../../helpers/isBrowser'
import About from './About'
import CustomScroller from './CustomScroller'
import Effects from './Effects'
import FeedLayout from './FeedLayout'
import LenisScroller from './LenisScroller'
import ProductHero from './ProductHero'
import SessionListing from './SessionListing'
import WineryListing from './WineryListing'
import { canvasStateAtom } from './canvasState'
import { SCENES, nextSceneAtom, sceneAtom } from './sceneState'

const Scene = () => {
  const nextScene = useAtomValue(nextSceneAtom)
  const [scene, setScene] = useAtom(sceneAtom)
  const setCanvasState = useSetAtom(canvasStateAtom)

  const camera = useThree(({ camera }) => camera)
  const size = useThree(({ size }) => size)
  const _scene = useThree(({ scene }) => scene)

  useEffect(() => {
    setCanvasState(state => ({
      ...state,
      scene: _scene
    }))
  }, [_scene, setCanvasState])

  useEffect(() => {
    const { scene, data } = nextScene
    setScene(state => ({
      scene,
      data: {
        ...state.data,
        [scene]: data
      }
    }))
  }, [nextScene])

  useLayoutEffect(() => {
    if (camera) {
      if (camera.isPerspectiveCamera) {
        camera.scale.set(size.height / size.width, size.height / size.width, 1)
      }
      camera.far = 10000
      camera.aspect = size.width / size.height
      camera.updateProjectionMatrix()
    }
  }, [size])

  const showFeed = (scene.scene === SCENES.FEED_ORDER || scene.scene === SCENES.FEED_CHAOS)
  const showWinery = scene.scene === SCENES.WINERY_LISTING

  return (
    <>
      <CustomScroller enabled={showFeed}>
        <FeedLayout show={showFeed} />
      </CustomScroller>
      <CustomScroller enabled={showWinery} lock={[0, 1]} dragOnDesktopEnabled>
        {showWinery && (
          <WineryListing show={showWinery} />
        )}
      </CustomScroller>
      <LenisScroller>
        {scene.scene === SCENES.PRODUCT_DETAILS && (
          <group name='page-content'>
            <ProductHero />
          </group>
        )}
        {scene.scene === SCENES.ABOUT && (
          <group name='page-content'>
            <About />
          </group>
        )}
        {scene.scene === SCENES.SESSIONS_LISTING && (
          <group name='page-content'>
            <SessionListing />
          </group>
        )}
      </LenisScroller>
    </>
  )
}

export default function AppCanvas () {
  const [dpr, setDpr] = useState(isBrowser ? window.devicePixelRatio : 1)
  const onPerformanceChange = useCallback((data) => {
    const { factor } = data
    if (window.devicePixelRatio > 1) {
      setDpr(factor >= 0.8 ? window.devicePixelRatio : 1)
    }
  }, [])

  const scene = useAtomValue(sceneAtom)

  const feedScene = (scene.scene === SCENES.FEED_ORDER || scene.scene === SCENES.FEED_CHAOS)
  const disablePointerEvents = !feedScene && scene.scene !== SCENES.WINERY_LISTING

  return (
    <div className={cn('fixed top-0 left-0 w-full h-screen-large', disablePointerEvents && '!pointer-events-none z-1')}>
      <Canvas
        dpr={dpr}
        resize={{ scroll: false, debounce: 50 }}
        camera={{ position: [0, 0, 10], near: 0.1, far: 10000, manual: true, fov: 75 }}
        gl={{
          alpha: true,
          antialias: false,
          stencil: false,
          depth: false,
          toneMapping: LinearToneMapping
        }}
        className={cn(disablePointerEvents && '!pointer-events-none')}
        id='canvas'
      >
        <PerformanceMonitor onChange={onPerformanceChange} factor={1} step={0.1}>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Scene />
          {/* <Stats /> */}
          <Effects />
        </PerformanceMonitor>
      </Canvas>
    </div>
  )
}
