import cn from 'clsx'
import isString from 'lodash/isString'
import useIsHovering from '../hooks/useIsHovering'
import Spinner from './Spinner'

export default function Button ({
  className, disabled, invert,
  px = 'px-12', py = 'py-4', justify = 'justify-center',
  borderColor,
  border = 'border',
  text = 'text-12',
  w = 'w-full',
  filled,
  children,
  dataDescription,
  dataLocation,
  tag = 'button',
  ...props
}) {
  const Component = tag || 'button'
  const dataDescriptionValue = dataDescription || (isString(children) ? children.toLowerCase() : '')

  const { eventListeners: events, hovering: isHovering } = useIsHovering()

  return (
    <Component
      className={cn('flex uppercase overflow-hidden !relative',
        w,
        py,
        px,
        justify,
        borderColor || (invert ? 'border-foreground-invert' : 'border-foreground'),
        border,
        text,
        'border-solid font-semibold',
        disabled
          ? '!text-border'
          : invert ? 'hover:text-foreground' : 'hover:text-foreground-invert',
        invert && 'text-foreground-invert',
        'transition-colors duration-600 ease-out-expo',
        filled ? (invert ? 'bg-foreground' : 'bg-foreground-invert') : 'bg-transparent',
        className
      )}
      disabled={disabled}
      data-event='site interaction'
      data-type='button'
      data-description={dataDescriptionValue}
      data-location={dataLocation || 'body'}
      {...events}
      {...props}
    >
      <div className={cn(
        'absolute inset-[-1px] z-0 translate-y-[110%] transition-translate duration-600 ease-out-expo',
        invert ? 'bg-foreground-invert' : 'bg-foreground',
        isHovering && 'translate-y-[0]'
      )}/>
      <div className={cn(justify, 'w-full flex z-1')}>
        {children}
      </div>
    </Component>
  )
}

export function ButtonWithSpinner ({ children, className, loading, disabled, ...props }) {
  return (
    <Button disabled={disabled} className={cn(className, 'relative')} {...props} filled>
      {loading && <div className='absolute left-4 top-1/2 -translate-y-1/2'><Spinner size='2em' /></div>}
      {children}
    </Button>
  )
}
