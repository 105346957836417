import cn from 'clsx'
import RichContent from '../../RichContent'
import Section from '../../Section'

export default function RichText ({ text, className }) {
  return (
    <div className={cn('col-start-5 col-end-9 md:col-span-full', className)}>
      <RichContent className="[&>div>p]:mb-4 [&>div>p]:min-h-[1rem] text-14 [&_ol]:list-decimal [&_ul]:list-disc [&_ol]:ml-[1em] [&_ul]:ml-[1em] [&_a]:underline [&_a:hover]:opacity-50 " content={text} />
    </div>
  )
}

export function RichTextSlice ({ data }) {
  const { text } = data
  return (
    <Section grid>
      <RichText text={text} className='col-start-4 col-end-10'/>
    </Section>
  )
}
