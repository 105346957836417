import { useMemo } from 'react'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { formatDate } from '../../../helpers/date'
import Link from '../../Link'

export default function ScreenReaderTile ({ page }) {
  const { title, _type, pageType, productCategory } = page
  const metaData = useMemo(() => {
    if (_type === 'product' && productCategory === 'wine') {
      const { yearVintage, winery, range, priceRange, varietal, sales } = page
      const price = priceRange?.minVariantPrice
      return (
        <div>
          <dl>
            <dt>Range</dt>
            <dd>{range}</dd>
            <dt>Winery</dt>
            <dd>{winery}</dd>
            <dt>Varietal</dt>
            <dd>{varietal}</dd>
            <dt>Vintage</dt>
            <dd>{yearVintage}</dd>
            <dt>Price</dt>
            <dd>{formatCurrency(price)}</dd>
          </dl>
          <span>{sales}</span>
        </div>
      )
    }
    if (_type === 'product' && productCategory !== 'wine') {
      const { sales, priceRange } = page
      const price = priceRange?.minVariantPrice
      return (
        <div>
          <dl>
            <dt>Price</dt>
            <dd>{formatCurrency(price)}</dd>
          </dl>
          <span>{sales}</span>
        </div>
      )
    }

    if (pageType === 'winery') {
      const { subtitle, established, owner, location } = page
      return (
        <div>
          <h3>{subtitle}</h3>
          <dl>
            <dt>Winery Location</dt>
            <dd>{location}</dd>
            <dt>Established</dt>
            <dd>{established}</dd>
            <dt>Owner</dt>
            <dd>{owner}</dd>
          </dl>
        </div>
      )
    }
    const { subtitle, postedDate, category } = page
    return (
      <div>
          <h3>{subtitle}</h3>
          <dl>
          <dt>Category</dt>
          <dd>{category}</dd>
          <dt>Posted Date</dt>
          <dd>{formatDate(postedDate)}</dd>
        </dl>
      </div>
    )
  }, [page])

  return (
    <li>
      <article>
        <Link className='h-56 flex items-center' link={page} showText={false}><h2>{title}</h2></Link>
        {metaData}
      </article>
    </li>
  )
}
