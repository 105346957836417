import { useContext, useEffect } from 'react'
import { SmoothScrollerContext } from '../Components/SmoothScroller'

export default function useScrollPosition (handler, updateOnMount) {
  const smoothScrollerContext = useContext(SmoothScrollerContext)
  useEffect(() => {
    if (smoothScrollerContext?.current) {
      const smoothScroller = smoothScrollerContext?.current
      smoothScroller.scrollListeners.push(handler)
      return () => {
        smoothScroller.scrollListeners = smoothScroller.scrollListeners.filter(h => h !== handler)
      }
    }
  }, [handler])

  useEffect(() => {
    if (smoothScrollerContext?.current && updateOnMount) {
      handler(smoothScrollerContext.current.lenis)
    }
  }, [updateOnMount, handler, smoothScrollerContext])
}
