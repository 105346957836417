import { formatDate } from '../../../../../helpers/date'
import pixelToViewport from '../../../helpers/pixelToViewport'
import { useViewport } from '../../../hooks'
import Block from '../../../ui/Block'
import Stack from '../../../ui/Stack'
import BodyFont from '../../../ui/Text/BodyFont'
import SansSerifFont from '../../../ui/Text/SansSerifFont'

// const FONT_SIZES = { lg: 0.12, md: 0.18, sm: 0.5 }
const FONT_SIZES = { lg: 0.12, md: 0.1356, sm: 0.5 }

export default function ArticleMeta ({ article, width, ...props }) {
  const viewport = useViewport()
  const gap = pixelToViewport(2, viewport)
  const { title, subtitle, postedDate, category } = article

  return (
    <Stack gap={gap} {...props} name='meta'>
      <Block>
        <BodyFont
          fontSizes={FONT_SIZES}
          position={[0, 0, 0]}
          maxWidth={width / 2}
        >
          {category?.toUpperCase()}
        </BodyFont>
        <BodyFont
          fontSizes={FONT_SIZES}
          position={[(width / 2) + gap, 0, 0]}
          maxWidth={width / 2}
        >
          {formatDate(postedDate)?.toUpperCase()}
        </BodyFont>
      </Block>
      <BodyFont fontSizes={FONT_SIZES} maxWidth={width}>{title?.toUpperCase()}</BodyFont>
      <SansSerifFont fontSizes={FONT_SIZES} maxWidth={width}>{subtitle?.toUpperCase()}</SansSerifFont>
    </Stack>
  )
}
