import { Texture } from 'three'

class BlurTexture {
  canvas = null
  ctx = null
  size = null
  texture = null
  blur = 5

  constructor ({
    size = { width: 256, height: 256 },
    blur = 5,
    texture = null,
    backgroundColor = 'black'
  }) {
    this.size = size
    this.blur = blur
    this.texture = texture
    this.backgroundColor = backgroundColor
    this.initTexture()
  }

  initTexture () {
    if (this.texture) {
      this.canvas = document.createElement('canvas')
      this.canvas.width = this.size.width
      this.canvas.height = this.size.height
      this.ctx = this.canvas.getContext('2d')
      this.ctx.filter = `blur(${this.blur}px)`
      this.ctx.drawImage(this.texture.source.data, 0, 0, this.canvas.width, this.canvas.height)
      this.canvas.style.width = `${this.canvas.width}px`
      this.canvas.style.height = `${this.canvas.height}px`
      this.blurTexture = new Texture(this.canvas)
      this.blurTexture.needsUpdate = true
    }
  }
}

export default BlurTexture
