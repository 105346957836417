import gsap from 'gsap'
import { forwardRef, useEffect, useRef } from 'react'
import Ticker from './Ticker'

const LoadingTicker = forwardRef(({ className, showBackground = true, children }, ref) => {
  const bgRef = useRef()
  useEffect(() => {
    if (showBackground) {
      const tl = gsap.timeline({ repeat: -1 })
      tl.fromTo(bgRef.current, { x: '0%' }, { x: '25%', duration: 2, ease: 'linear' })
      return () => {
        tl.kill()
      }
    }
  }, [showBackground])

  return (
    <div className={className} ref={ref}>
      {showBackground && (
        <div ref={bgRef} style={{ background: 'repeating-linear-gradient(135deg, transparent, transparent 30px, currentColor 30px, currentColor 50px)' }} className='absolute w-[200%] -left-1/2 top-0 bottom-0 -mx-navPadding opacity-30' />
      )}
      <Ticker className='w-full max-w-full'><div className='font-550 text-10 px-2 uppercase'>{children}</div></Ticker>
    </div>
  )
})

export default LoadingTicker
