import { getDynamicBundleItems } from '@rechargeapps/storefront-client'
import { useSetAtom } from 'jotai'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { useCallback } from 'react'
import { generateUUID } from 'three/src/math/MathUtils'
import { getProductSubscriptionSellingPlan } from '../../../helpers/product'
import { useCartActions } from '../../Shop/useCartActions'
import { useSite } from '../../siteState'
import { NAV_VIEWS, menuOpenAtom, navViewAtom, secondMenuOpenAtom } from '../menuState'

const createSubscriptionVariantLineItem = (product, quantity, bundle, bundleId, uuid, title) => {
  const variantId = product.variants[0]?.shopifyId
  // If it has a selling plan then it is a subscription bundle, otherwise it is a once off bundle purchase
  const sellingPlan = getProductSubscriptionSellingPlan(product)

  const lineItem = {
    merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
    quantity,
    attributes: [
      ...map(bundle[0].properties, (value, key) => ({ key, value })).filter(({ value }) => value)
    ]
  }

  lineItem.sellingPlanId = sellingPlan?.id
  lineItem.attributes = compact([
    ...lineItem.attributes,
    { key: '_bundle_id', value: bundleId },
    { key: '_bundle_uuid', value: uuid }, // We use this to group the bundle items together
    !sellingPlan && { key: 'Bundle', value: title }
  ])

  return lineItem
}

const getBundleData = async (bundle, collectionId, products) => { // collection id - '414896849117'
  const b = {
    externalVariantId: bundle.variants[0].shopifyId,
    externalProductId: bundle.shopifyId,
    selections: products.map(product => ({
      collectionId, // collection_id from variant - shopify collection id
      externalProductId: product.shopifyId, // product id from above shopify collection
      externalVariantId: product.variants[0]?.shopifyId, // variant id from above product
      quantity: product.quantity
    }))
  }
  return await getDynamicBundleItems(b)
}

const useAddProductToCartCallback = (product, quantity, dynamicBundleProducts) => {
  const { addLineItems } = useCartActions()
  const { shopify: { bundleCollectionId } } = useSite()
  const setOpen = useSetAtom(secondMenuOpenAtom)
  const setMainNavOpen = useSetAtom(menuOpenAtom)
  const setMainNavView = useSetAtom(navViewAtom)

  return useCallback(async () => {
    if (product.productCategory === 'bundle') {
      const uuid = generateUUID()
      const bundleProducts = compact(product.bundle.details.bundleType === 'dynamic' ? dynamicBundleProducts : product.bundle.products)
      const productsWithQuantity = map(groupBy(bundleProducts, p => p.shopifyId), (items) => ({ ...items[0], quantity: items.length }))
      const bundleData = await getBundleData(product, bundleCollectionId, productsWithQuantity)

      const lineItems = map(
        productsWithQuantity,
        ({ quantity, ...p }) => createSubscriptionVariantLineItem(p, quantity, bundleData, product._id, uuid, product.title)
      )
      addLineItems(lineItems)
    } else {
      const id = product.variants[0]?.shopifyId
      const lineItem = {
        merchandiseId: `gid://shopify/ProductVariant/${id}`,
        quantity,
        product
      }

      addLineItems([lineItem])
    }
    setOpen(false)
    setMainNavOpen(true)
    setMainNavView(NAV_VIEWS.cart)
  }, [product, setOpen, setMainNavOpen, setMainNavView, dynamicBundleProducts, addLineItems, quantity])
}

export default useAddProductToCartCallback
