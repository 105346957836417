import cn from 'clsx'
import isEmpty from 'lodash/isEmpty'
import { useCallback } from 'react'
import ReactSlider from 'react-slider'
import { LINK_HOVER_CLASSES } from '../..'
import { formatCurrency } from '../../../../helpers/currencyFormatters'
import FilterContainer from './FilterContainer'
import { Check } from './TagFilter'
import { useFilterQueryParams } from './filterState'

export default function RangeFilter ({ filter }) {
  const { options } = filter
  return (
    <FilterContainer filter={filter}>
      {!isEmpty(options) && <RangeOptionsFilter filter={filter} />}
      {isEmpty(options) && <RangeSlider filter={filter} />}
    </FilterContainer>
  )
}

const RangeOptionsFilter = ({ filter }) => {
  const { options, slug } = filter
  const [query, setQuery] = useFilterQueryParams()
  const minValue = query[`${slug}Min`]
  const onChange = useCallback((option, isSelected) => {
    if (isSelected) {
      setQuery({
        ...query,
        [`${slug}Min`]: undefined,
        [`${slug}Max`]: undefined
      })
    } else {
      setQuery({
        ...query,
        [`${slug}Min`]: option.min,
        [`${slug}Max`]: option.max
      })
    }
  }, [query, setQuery, slug])

  return (
    <div className='flex flex-wrap gap-1 pb-4'>
      {options.map((option, i) => {
        const title = option.max ? `$${option.min} - $${option.max - 1}` : `$${option.min}+`
        const isSelected = minValue !== null && (minValue >= option.min && (!option.max || minValue < option.max))
        return (
          <button
            key={i}
            className={cn(LINK_HOVER_CLASSES, 'py-2 px-3 uppercase text-8 md:text-14 font-550 border border-current whitespace-nowrap flex gap-1 items-center md:leading-none md:px-[14px]', isSelected && 'bg-black text-white border-black')}
            onClick={() => onChange(option, isSelected)}
            data-event='site interaction'
            data-type='button'
            data-description={title}
            data-location='filter'
          >
            <span className='flex align-top'>{title}</span>
            {isSelected && <Check />}
          </button>
        )
      })}
    </div>
  )
}

const RangeSlider = ({ filter }) => {
  const [query, setQuery] = useFilterQueryParams()
  const { slug, min, max, isCurrency = false } = filter

  const currentMin = query[`${slug}Min`] || min
  const currentMax = query[`${slug}Max`] || max

  const onChange = useCallback((values) => {
    const newMin = Math.floor(values[0])
    const newMax = Math.ceil(values[1])
    setQuery({
      ...query,
      [`${slug}Min`]: newMin === min ? null : newMin,
      [`${slug}Max`]: newMax === max ? null : newMax
    })
  }, [max, min, query, setQuery, slug])

  return (
    <div className='mb-4'>
      <div className='flex gap-1'>
        <ReactSlider
          className='w-full h-3 mb-2'
          onChange={onChange}
          thumbClassName='thumb'
          renderThumb={(props) => <div {...props} className={cn(props.className, 'h-3 w-3 bg-black rounded-full cursor-grab active:cursor-grabbing')}/>}
          trackClassName='track'
          renderTrack={(props) => <div {...props} className={cn(props.className, 'h-[2px] bg-[rgba(0,0,0,0.1)] translate-y-[0.35rem] [&.track-1]:bg-black')}/>}
          min={Math.floor(min)}
          max={Math.ceil(max)}
          step={10}
          value={[currentMin, currentMax]}
          defaultValue={[min, max]}
          ariaLabel={['Lower thumb', 'Upper thumb']}
          ariaValuetext={state => `Thumb value ${state.valueNow}`}
          pearling
          minDistance={1}
        />
      </div>
      <div className='flex justify-between text-8 md:text-12 tracking-slight uppercase'>
        <div>{isCurrency ? formatCurrency(currentMin) : currentMin}</div>
        <div>{isCurrency ? formatCurrency(currentMax) : currentMax}</div>
      </div>
    </div>
  )
}
