import { useCallback } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { atom, useSetAtom } from 'jotai'
import { getAlgoliaIndexName } from '../../../helpers/algoliaIndex'
import { siteAtom } from '../../siteState'
import { navViewAtom, NAV_VIEWS } from '../menuState'

const EMPTY_RESULTS = { hts: [], query: '' }

export const search = async (site, query, index, limit) => {
  const algoliaIndex = algolia.initIndex(getAlgoliaIndexName(site))
  const opts = {
    page: index,
    hitsPerPage: limit,
    optionalWords: query,
    filters: 'NOT status:draft AND NOT excludeFromShop:true'
  }
  return await algoliaIndex.search(query, opts)
}

export const searchInputAtom = atom('')
export const searchLimitAtom = atom(10)
export const searchResultsAtom = atom(EMPTY_RESULTS)
export const searchLoadingAtom = atom(false)

const _searchQueryAtom = atom('')
export const searchQueryAtom = atom((get) => get(_searchQueryAtom), async (get, set, value) => {
  set(searchLoadingAtom, true)
  set(_searchQueryAtom, value)
  set(_searchIndexAtom, 0)
  const site = get(siteAtom)
  const limit = get(searchLimitAtom)
  try {
    if (!value) {
      set(searchResultsAtom, EMPTY_RESULTS)
    } else {
      const results = await search(site, value, 0, limit)
      set(searchResultsAtom, results)
    }
  } finally {
    set(searchLoadingAtom, false)
  }
})

const _searchIndexAtom = atom(0)
export const searchIndexAtom = atom((get) => get(_searchIndexAtom), async (get, set, value) => {
  const loading = get(searchLoadingAtom)
  if (loading) return

  set(searchLoadingAtom, true)
  set(_searchIndexAtom, value)
  const site = get(siteAtom)
  const limit = get(searchLimitAtom)
  const query = get(searchQueryAtom)
  const searchResults = get(searchResultsAtom)
  try {
    const results = await search(site, query, value, limit)
    set(searchResultsAtom, {
      ...results,
      hits: [
        ...searchResults.hits,
        ...results.hits
      ]
    })
  } finally {
    set(searchLoadingAtom, false)
  }
})

const algolia = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

export const useClearSearch = () => {
  const setInput = useSetAtom(searchInputAtom)
  const setView = useSetAtom(navViewAtom)
  const setSearchQuery = useSetAtom(searchQueryAtom)
  return useCallback(() => {
    setInput('')
    setView(NAV_VIEWS.nav)
    setSearchQuery('')
  }, [])
}
