export function isHomepage (page) {
  return page?.slug === 'home'
}

export function isWineriesListingPage (page) {
  if (!page.slices) return false
  return page.slices?.[0]?._type === 'wineryListing'
}

export function isIlluminatePage (page) {
  if (!page.slices) return false
  return page.slices?.[0]?._type === 'illuminate'
}

export function isAboutPage (page) {
  if (!page.slices) return false
  return page.slices?.[0]?._type === 'about'
}

export function isSessionsPage (page) {
  if (!page.slices) return false
  return page.slices?.[0]?._type === 'sessionsHero' || page.slices?.[0]?._type === 'sessionsListing'
}

export function isRelevationsPage (page) {
  return page.articleFields?.articleType === 'revelation'
}
