import cn from 'clsx'
import gsap from 'gsap'
import { useAtomValue } from 'jotai'
import sortBy from 'lodash/sortBy'
import { useCallback, useMemo, useRef } from 'react'
import useLayoutEffect from '../../../../hooks/useIsomorphicLayoutEffect'
import Button from '../../../Button'
import { introCompletedAtom } from '../../../Intro/introState'
import Link from '../../../Link'
import { useResetFilters } from '../../../Menu/SecondaryNavs/ShopFilter/filterState'
import useAddProductToCartCallback from '../../../Menu/SecondaryNavs/useAddProductCallback'
import useCanAddProductToCart from '../../../Menu/SecondaryNavs/useCanAddProductToCart'
import useProductPriceFormatted from '../../../Menu/SecondaryNavs/useProductPriceFormatted'
import ResponsiveImage from '../../../ResponsiveImage'
import Section from '../../../Section'
import { useLabels, useSite } from '../../../siteState'

export function Tile ({ product, animateIn, i }) {
  const tileRef = useRef()
  const canAddToCart = useCanAddProductToCart(product)
  const addProductToCart = useAddProductToCartCallback(product, 1)
  const isDynamicBundle = product.bundle?.details?.bundleType === 'dynamic'
  const resetFilers = useResetFilters()
  const { shopLink } = useSite()

  const onDynamicBundleClick = useCallback(() => {
    window.scrollTo({ left: 0, top: 0 })
    resetFilers({ category: 'subscriptions-dynamic' })
  }, [resetFilers])

  const handleAddToCartClick = useCallback((e) => {
    addProductToCart()
    e.preventDefault()
    e.stopPropagation()
  }, [addProductToCart])

  const { soldOutLabel } = useLabels()

  const data = product.bundle?.details
  const priceFormatted = useProductPriceFormatted(product)

  const introCompleted = useAtomValue(introCompletedAtom)

  useLayoutEffect(() => {
    if (!animateIn) return
    if (!introCompleted) {
      gsap.set(tileRef.current, { opacity: 0 })
      return
    }

    const tl = gsap.fromTo(tileRef.current, {
      y: 40,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      delay: 0.1 * (i % 3),
      ease: 'expo.out',
      duration: 1.2,
      scrollTrigger: {
        trigger: tileRef.current,
        start: 'top bottom',
        scrub: false
      }
    })

    return () => tl.revert()
  }, [animateIn, introCompleted])

  const linkProps = useMemo(() => isDynamicBundle
    ? { onClick: onDynamicBundleClick, link: shopLink, search: 'category=subscriptions-dynamic' }
    : { link: product },
  [isDynamicBundle, onDynamicBundleClick, product, shopLink])

  return (
    <div ref={tileRef} className={cn('col-span-4 md:col-span-full relative group', isDynamicBundle ? 'bg-white text-black border aspect-[458/595]' : 'bg-black text-white')} style={{ opacity: 0 }}>
      <div className={cn('flex flex-col h-full p-4 absolute top-0 left-0 z-[10] w-full')}>
        <Link nonLinkTag='div' className='flex flex-col h-full' showText={false} {...linkProps} >
          <div className='flex-1 flex flex-col justify-center items-center gap-4 text-center'>
            <div className='uppercase font-550 tracking-slight text-32 leading-none'>{data.tileTitle || product.title}</div>
            <div className='font-serif uppercase font-250 tracking-[-0.02em] text-center leading-none flex items-end whitespace-pre-wrap'>
              {data?.subheading}
            </div>
          </div>
          <Button
            justify='justify-center'
            py='py-2'
            px='px-2'
            invert
            className={cn('mb-3 transition-opacity duration-700 hover-hover:opacity-0 group-hover:hover-hover:opacity-100 hover-hover:hover:bg-white ', isDynamicBundle && 'invisible')}
            disabled={!canAddToCart || isDynamicBundle}
            onClick={handleAddToCartClick}
          >
            {canAddToCart ? data?.buttonLabel : soldOutLabel}
          </Button>
          <div className='flex justify-between uppercase tracking-slight font-550 text-12'>
            <div>{data?.productCountLabel}</div>
            <div>{data?.priceLabel || priceFormatted} / {data?.subscriptionIntervalLabel}</div>
          </div>
        </Link>
      </div>
      {!isDynamicBundle && product?.images.featureImage && (
        <>
          <div className='absolute inset-0 bg-black opacity-20 group-hover:opacity-40 z-1 transition-opacity duration-600 ease-out-expo'/>
          <ResponsiveImage className='h-full z-0 relative align-bottom overflow-hidden' image={product?.images?.featureImage} aspect={458 / 595} />
        </>
      )}
    </div>
  )
}

export default function PresetBundleListing ({ data }) {
  const { products } = data
  const sortedProducts = useMemo(() => {
    return sortBy(products, product => product.bundle?.details?.bundleType === 'dynamic' ? 100 : 0)
  }, [products])

  return (
    <Section grid>
      {sortedProducts?.map((product, i) => {
        return (
          <Tile
            i={i}
            animateIn
            key={product._id}
            product={product}
          />
        )
      })}
    </Section>
  )
}
