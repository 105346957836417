import Button from '../../Button'
import Link from '../../Link'
import { Parallax } from '../../Parallax'
import ResponsiveImage from '../../ResponsiveImage'
import RichContent from '../../RichContent'
import Section from '../../Section'
import Video from '../../Video'
import { AnimatedTextRows } from '../TextRows'

export default function SubscriptionOverview ({ data }) {
  const { textRows, features, smallFeatures, image, video, description, links } = data
  const hasVideo = !!video?.asset
  return (
    <Section grid className=''>
      <div className='col-span-full'>
        <AnimatedTextRows textRows={textRows} />
      </div>
      {smallFeatures && <div className='col-span-full text-center uppercase text-12 tracking-slight'>
        [{smallFeatures?.join(' — ')}]
      </div>}
      {hasVideo && (
        <div className='col-span-full overflow-hidden relative aspect-[1408/818]'>
          <Video video={video} background />
        </div>
      )}
      {!hasVideo && image && (
        <div className='col-span-full overflow-hidden'>
          <Parallax speed={-0.5}>
            <ResponsiveImage image={image} aspect={1408 / 818} mobileAspect={364 / 396}/>
          </Parallax>
        </div>
      )}

      {(description || links) && <Section noGutter grid className='col-span-full mb-[14rem] md:mb-[5rem] md:mt-2'>
        <RichContent content={description} className='col-span-6 md:col-span-full pr-4 text-24 md:text-16 font-serif font-250 [&_strong]:font-sans [&_strong]:font-550 indent-indent-sm md:indent-indent-lg'/>
        {links && <div className='col-span-6 col-start-1 flex gap-2 mt-12 md:flex-col md:col-span-full md:mt-0'>
          {links.map(link => (
            <Button
              key={link._key}
              tag={Link}
              link={link}
              className='z-[2]'
              px='px-12'
              py='py-4'
              w='w-auto'
              text='text-14 tracking-slight leading-none'
              showText={false}
            >
              <span>{link.text || link.page?.text}</span>
            </Button>
          ))}
        </div>}
      </Section>}

      {features?.map(block => (
        <div key={block} className='flex flex-col justify-between col-span-6 md:col-span-full aspect-[696/245] md:aspect-[361/208] border-current border px-6 md:px-4 py-4 leading-[0.9em] tracking-[-0.02em] text-14 md:text-16'>
          <div className='max-w-[20em] md:max-w-[15em] uppercase font-550 '>{block}</div>
          <div className='text-14 leading-none'>{block}</div>
        </div>
      ))}
    </Section>
  )
}
