/* eslint-disable jsx-a11y/alt-text */
import { useCursor } from '@react-three/drei'
import Color from 'color'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { useSelectItemTracker } from '../../../../gtm'
import { isLandscapeImage } from '../../../../helpers/image'
import { linkToUrl, resolveLink } from '../../../../lib/resolveLink'
import transform from '../../../ResponsiveImage/transform'
import pixelToViewport from '../../helpers/pixelToViewport'
import { useViewport } from '../../hooks'
import HtmlBlock from '../../ui/HtmlBlock'
import Stack from '../../ui/Stack'
import BodyFont from '../../ui/Text/BodyFont'
import Draggable from '../Draggable'
import Image from '../Image'
import TextCursor from './TextCusrsor'

const FONT_SIZES = { lg: 0.12, md: 0.1356, sm: 0.5 }

export default function ImageTile ({ enabled, tile, width, preload, ...props }) {
  const viewport = useViewport()
  const router = useRouter()
  const { link } = tile

  const image = useMemo(() => {
    const img = tile.image
    if (!img) return null
    const size = width[isLandscapeImage(img) ? 'landscape' : 'portrait'] || width.landscape
    const source = transform(img, size.aspect, [256, 512, 1024, 2048])
    return { source, size }
  }, [tile, width])

  const backgroundColor = useMemo(() => {
    const img = tile.image
    const bg = img?.asset?.metadata?.palette?.dominant?.background
    if (bg) {
      return [...Color(bg).unitArray(), 1]
    }
  }, [tile])

  const [hovered, setHover] = useState()
  useCursor(link && hovered, 'pointer', 'auto')
  const onCloseCursor = useCallback(() => {
    setHover(false)
  }, [])

  const selectItemTracker = useSelectItemTracker()
  const onClick = useCallback(() => {
    if (enabled && link) {
      selectItemTracker(tile)
      router.push(linkToUrl(resolveLink(link)))
    }
  }, [enabled, selectItemTracker, tile, router, link])

  if (!image) return null

  const imageWidth = viewport.width * image?.size?.width
  const imageHeight = imageWidth / image?.size?.aspect

  const resolvedLinkText = link?.text || link?.page?.title

  const gap = pixelToViewport(2, viewport)
  const metaPosition = [-imageWidth / 2, -imageHeight / 2 - pixelToViewport(8, viewport), 0]

  return (
    <>
      <Draggable enabled={enabled} onClick={onClick}>
        {() => (
          <group name='tile'>
            <Image
              image={image.source}
              scale={[imageWidth, imageHeight, 1]}
              onPointerMove ={enabled ? () => setHover(true) : null}
              onPointerOut={enabled ? () => setHover(false) : null}
              disableHover={true}
              backgroundColor={backgroundColor}
              preload={preload}
              segments={16}
              name='tile-image'
              {...props}
            />
            <Stack gap={gap} name='meta' position={metaPosition}>
              <BodyFont fontSizes={FONT_SIZES} maxWidth={imageWidth}>{resolvedLinkText?.toUpperCase()}</BodyFont>
            </Stack>
          </group>
        )}
      </Draggable>
      <HtmlBlock name='image-cursor' htmlProps={{ center: true }}>
        <TextCursor show={hovered} link={link} onClose={onCloseCursor} />
      </HtmlBlock>
    </>
  )
}
