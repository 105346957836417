import Link from '../../Link'
import ResponsiveImage from '../../ResponsiveImage'
import Section from '../../Section'

export default function BrandBanner ({ wineries, query }) {
  if (query.winery.length !== 1) return null

  const brand = query.winery[0]
  const brandData = wineries.find(item => item.title === brand)

  if (!brandData) return null
  if (!brandData.logo && !brandData.description) return

  return (
    <Section grid className='md:mt-[-3rem]'>
      <div className="col-span-full h-[1px] bg-current opacity-50"/>
      <div className="col-start-1 col-span-3 md:col-span-full">
       {brandData?.logo && <ResponsiveImage image={brandData.logo} className='max-w-[9.375rem] md:mx-auto' showPreview={false}/>}
      </div>

      <div className="col-start-4 col-span-6 text-12 upMd:indent-[8vw] md:col-span-full md:text-center">
        {brandData?.description}
      </div>

      <div className='col-start-10 col-span-3 text-right md:col-span-full md:text-center leading-none'>
        <Link link={brandData} showText={false}>
          <span className="inline-flex items-center gap-2 uppercase text-12 font-550 tracking-slight">
            Discover {brandData?.title}
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3536 4.85355C13.5488 4.65829 13.5488 4.34171 13.3536 4.14645L10.1716 0.964466C9.97631 0.769204 9.65973 0.769204 9.46447 0.964466C9.2692 1.15973 9.2692 1.47631 9.46447 1.67157L12.2929 4.5L9.46447 7.32843C9.2692 7.52369 9.2692 7.84027 9.46447 8.03553C9.65973 8.2308 9.97631 8.2308 10.1716 8.03553L13.3536 4.85355ZM0 5H13V4H0V5Z" fill="black"/>
            </svg>
          </span>
        </Link>
      </div>
    </Section>
  )
}
