import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

export const SCENES = {
  NONE: 'NONE',
  FEED_ORDER: 'FEED_ORDER',
  FEED_CHAOS: 'FEED_CHAOS',
  PRODUCT_DETAILS: 'PRODUCT_DETAILS',
  WINERY_LISTING: 'WINERY_LISTING',
  ABOUT: 'ABOUT',
  SESSIONS_LISTING: 'SESSIONS_LISTING'
}

export const sceneAtom = atom({
  scene: SCENES.NONE,
  data: {}
})

export const nextSceneAtom = atom({
  scene: null,
  data: null
})

export const useShowSceneCallback = () => {
  const [nextScene, setNextScene] = useAtom(nextSceneAtom)
  return useCallback((scene, data) => {
    if (scene === nextScene.scene) return
    setNextScene({
      scene,
      data
    })
  }, [nextScene, setNextScene])
}

export const wineriesSceneAtom = atom({})
