import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'

export const pageContentAtom = atom()

export const pagePreloadAtom = atom({})

export const useRegisterPagePreload = () => {
  const [pagePreloaded, setPagePreloaded] = useAtom(pagePreloadAtom)
  return useCallback(async (page) => {
    const productHero = page.slices?.find(s => s._type === 'productHero' && page.productCategory === 'wine')
    if (productHero) {
      const existingState = pagePreloaded[page._id]
      setPagePreloaded(state => ({
        ...state,
        [page._id]: !!existingState
      }))
    } else {
      setPagePreloaded(state => ({ ...state, [page._id]: true }))
    }
  }, [pagePreloaded, setPagePreloaded])
}

export const usePagePreloaded = (id) => {
  const setPagePreloaded = useSetAtom(pagePreloadAtom)
  return useCallback(() => {
    setPagePreloaded(state => ({
      ...state,
      [id]: true
    }))
  }, [id, setPagePreloaded])
}

export const useIsPagePreloaded = (id) => {
  const state = useAtomValue(pagePreloadAtom)[id]
  if (state === false) return false
  return true
}
