
import { getBackgroundColorFromImage } from '../../helpers/image'
import Link from '../Link'
import { Parallax } from '../Parallax'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'
import Video from '../Video'

export default function ProductWinery ({ data }) {
  const { winery, image, leftText, rightText, bottomText, video } = data
  const hasVideo = !!video?.asset
  const backgroundColor = getBackgroundColorFromImage(image)
  return (
    <Section grid className='mt-[12.5rem] mb-[12.5rem]'>
      <Link link={winery} className='relative col-start-5 col-span-4 md:col-start-2 md:col-span-6' showText={false}>
        <div className="absolute z-10 text-12 uppercase font-550 tracking-slight top-6 left-1/2 -translate-x-1/2">{winery?.title}</div>

        <div className="absolute z-10 text-[3.125rem] md:text-[1.5rem] uppercase font-550 tracking-slight top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[150%] md:w-[120%]">
          <Parallax speed={-0.5} className='flex justify-between w-full'>
            <span>{leftText}</span>
            <span>{rightText}</span>
          </Parallax>
        </div>
        <div className='aspect-[458/641] overflow-hidden relative'>
          {image && <ResponsiveImage image={image} position='absolute' className='inset-0' aspect={458 / 641} />}
          {hasVideo && <Video video={video} background backgroundColor={backgroundColor} />}
        </div>
        <div className="absolute z-10 text-12 uppercase font-550 tracking-slight bottom-6 left-1/2 -translate-x-1/2">{bottomText}</div>
      </Link>
    </Section>
  )
}
