import { useAtom } from 'jotai'
import findIndex from 'lodash/findIndex'
import last from 'lodash/last'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { resolveInternalLinkUrl } from '../../../lib/resolveLink'
import { supportTabsAtom } from '../../Menu/SecondaryNavs/JumpTo/jumpToState'
import Section from '../../Section'
import { SmoothScrollerContext } from '../../SmoothScroller'
import UnderlineLink from '../../UnderlineLink'
import { PageTransition } from '../SubscriptionListing'
import Accordion from './Accordion'
import RichText from './RichText'
import SupportSliceHeader from './SupportSliceHeader'

export function useTabs (tabs, page) {
  const router = useRouter()
  const currentTabRef = useRef()

  const tab = useMemo(() => {
    const subPageSlug = last(router.asPath.split('?')[0].split('/'))
    const subPage = tabs.find(tab => tab?.slug?.current === subPageSlug)

    // If we cannot find the sub page, then we fallback to the current tab
    if (!subPage) return currentTabRef.current
    currentTabRef.current = subPage
    return subPage
  }, [tabs, router.asPath])

  const setTab = (currentPage) => {
    const url = resolveInternalLinkUrl(page, currentPage?.slug.current)
    router.push(url, null, { shallow: true })
  }

  return [tab, setTab]
}

export default function SupportSlice ({ data, page }) {
  const [tabs, setTabs] = useAtom(supportTabsAtom)
  const [tab, setTab] = useTabs(tabs, page)

  const index = findIndex(tabs, tab)
  const activeRef = useRef([])

  useEffect(() => {
    setTabs(data?.tabs)
  }, [])

  const scrollContext = useContext(SmoothScrollerContext)
  useEffect(() => {
    if (scrollContext.current?.lenis) {
      scrollContext.current?.lenis.scrollTo(0)
    }
  }, [tab])

  const currentTab = tabs[index]

  return (
    <>
      <SupportSliceHeader
        tabs={tabs}
        setTab={setTab}
        index={index}
        activeRef={activeRef}
      />
      <PageTransition pageId={tab?._key}>
        {currentTab && (
          <Section grid className='!w-full md:!mb-8' key={`section-${index}`} ref={activeRef}>
            {currentTab._type === 'contact' && <GorgiasContactForm left={currentTab?.left} right={currentTab?.right} />}
            {currentTab._type === 'accordion' && <Accordion items={currentTab?.accordionArray} />}
            {currentTab._type === 'richText' && <RichText text={currentTab?.text} />}
          </Section>
        )}
      </PageTransition>
    </>
  )
}

function GorgiasContactForm ({ left, right }) {
  const { gorgiasFormLink } = right
  return (
    <div className='col-start-3 col-end-12 md:flex-col md:col-span-full flex justify-between'>
      <Head>
        <script defer type='text/javascript' src={gorgiasFormLink || 'https://contact.gorgias.help/api/contact-forms/loader.js?uid=mb6nj7o9&locale=en-US'} data-gorgias-contact-form='script'></script>
        {/*
          AU FORM: https://contact.gorgias.help/api/contact-forms/loader.js?uid=mb6nj7o9&locale=en-US
          NZ FORM: https://contact.gorgias.help/api/contact-forms/loader.js?uid=qk8i0roc&locale=en-US
          <link rel='stylesheet' href='https://vntnr.gorgias.help/api/contact-form-loader.css' />
        */}
      </Head>
      <div className='w-[25%] md:w-full'>
        <div className='font-550 uppercase mb-4'>{left?.title}</div>
        <div className='text-14 mb-8'>{left?.text}</div>
        <div className='h-px w-full bg-black opacity-50 mb-5' />
        <div className='md:mb-20'>
          {left?.socials.map((link, k) => (
            <UnderlineLink link={link} key={`socials-${k}`} className='inline-flex font-550 items-center uppercase text-12 [&>span]:rotate-45 [&>span]:ml-2' />
          ))}
        </div>
      </div>
      <div className='w-[55%] md:w-full'>
        <div className='font-550 uppercase mb-4'>{right?.title}</div>
        <div className='text-14 mb-8'>{right?.text}</div>
        <div data-gorgias-contact-form='container'></div>
      </div>
    </div>
  )
}
