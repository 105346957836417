import cn from 'clsx'
import gsap from 'gsap'
import { useAtomValue } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import breakpoints from '../../../../theme/breakpoints'
import { useSelectItemTracker, useTrackProductImpressionInView } from '../../../gtm'
import { formatCurrency } from '../../../helpers/currencyFormatters'
import { formatCountdown } from '../../../helpers/date'
import { getProductPriceWithDiscount, getSubscriptionProductPrice, isInStock, useIsReleased } from '../../../helpers/product'
import useComposeRefs from '../../../hooks/useComposeRefs'
import useIsHovering from '../../../hooks/useIsHovering'
import useLayoutEffect from '../../../hooks/useIsomorphicLayoutEffect'
import { isMobileAtom } from '../../../state/isMobile'
import Button from '../../Button'
import { introCompletedAtom } from '../../Intro/introState'
import Link from '../../Link'
import NoiseBackground from '../../Menu/NoiseBackground'
import { useOpenLogin } from '../../Menu/SecondaryNavs/MembersOnly'
import useAddProductToCartCallback from '../../Menu/SecondaryNavs/useAddProductCallback'
import useCanAddProductToCart from '../../Menu/SecondaryNavs/useCanAddProductToCart'
import ResponsiveImage from '../../ResponsiveImage'
import { DEFAULT_WIDTHS } from '../../ResponsiveImage/transform'
import { useIsAuthenticated } from '../../Shop/accountState'
import { useCartSettings, useLabels, useSite } from '../../siteState'
import { WineCode } from '../ProductHero/WineHero'
import FeaturedText from './FeaturedText'

export const BOTTLE_WIDTHS = [
  80,
  120,
  220,
  320,
  420,
  640,
  1024
]

export function ProductMeta ({ product, isReleased, isProductForBundle, sellingPlan, isSmall = false, className }) {
  const site = useSite()
  const { totalAmount, subtotalAmount } = !isProductForBundle
    ? getProductPriceWithDiscount(product, sellingPlan)
    : getSubscriptionProductPrice(product)

  const isBundle = product.productCategory === 'bundle'
  const isWine = product.productCategory === 'wine'
  const year = product?.yearVintage || site.labels.nonVintageText

  return (
    <div className={cn('mt-1 flex justify-between text-12 font-550 uppercase leading-none font-feature-zero', className)}>
      {!isWine && !isBundle && <div>{product?.title}</div>}
      {isBundle && (
        <>
          <div>
            <div>{product?.bundle?.tileTitle || product?.title}</div>
          </div>
          {!isSmall && (
            <div>Bundle</div>
          )}
        </>
      )}
      {isWine && (
        <>
          <div>
            <div>{product?.winery}</div>
            <div>{product?.range}</div>
            {isSmall && <div>{product?.varietal}</div>}
            {isSmall && <div>{year}</div>}
          </div>
          {!isSmall && <div>
            <div>{product?.varietal}</div>
            <div>{year}</div>
          </div>}
        </>
      )}
      <div className='text-right'>
        {isReleased && <>
          {totalAmount !== subtotalAmount &&
            <div className='opacity-70 text-10 line-through'>{formatCurrency(subtotalAmount)}</div>
          }
          {formatCurrency(totalAmount)}
          <div className='font-250 font-serif'>
            {product?.membersOnly ? site?.labels?.membersOnlyLabel : product?.sales}
          </div>
        </>}
      </div>
    </div>
  )
}

export function ArticleProductMeta ({ product, sellingPlan, className }) {
  const site = useSite()
  const { totalAmount, subtotalAmount } = getProductPriceWithDiscount(product, sellingPlan)

  const isWine = product.productCategory === 'wine'
  const year = product?.yearVintage || site.labels.nonVintageText

  return (
    <div className={cn('flex flex-col justify-between text-12 font-550 uppercase leading-none font-feature-zero', className)}>
      <div className='grid grid-cols-2 w-full'>
        {(product?.sales || product?.membersOnly) && <span className='font-250 font-serif'>
          {product?.membersOnly ? site?.labels?.membersOnlyLabel : product?.sales}
        </span>}
        <span className={cn('font-250 font-serif text-right', !product?.sales && !product?.membersOnly && '!text-left')}>
          {totalAmount !== subtotalAmount &&
            <div className='opacity-70 text-10 line-through'>{formatCurrency(subtotalAmount)}</div>
          }
          {formatCurrency(totalAmount)}
        </span>
      </div>

      <div className='text-24 leading-none'>
        {!isWine && <div>{product?.title}</div>}
        {isWine && (
          <>
            <div>
              <div>{product?.winery}</div>
              <div>{product?.range}</div>
            </div>
            <div>
              <div>{product?.varietal}</div>
              <div className='font-serif font-250'>{product?.wineryCode}.{product?.rangeCode}&mdash;{year}</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default function ProductTile ({ className, product, sellingPlan, i, rowIndex, rowType, onAddToCart, disableAdd = false, addText, isProductForBundle = false, animateIn = false }) {
  const { soldOutLabel } = useLabels()
  const { addToCartLabel } = useCartSettings()
  const isMobile = useAtomValue(isMobileAtom)

  const site = useSite()
  const authenticated = useIsAuthenticated()

  const isReleased = useIsReleased(product)
  const inStock = isInStock(product)

  const isFullBleed = product?.fullBleedImage || product.productCategory === 'bundle'
  const isMembersOnly = product?.membersOnly

  const canAddToCart = useCanAddProductToCart(product)
  const addProductToCart = useAddProductToCartCallback(product, 1)
  const openSignUp = useOpenLogin()

  const addToCart = useCallback(() => {
    if (isMembersOnly && !authenticated) {
      openSignUp()
      return
    }
    if (onAddToCart) {
      onAddToCart(product)
      return
    }
    addProductToCart()
  }, [addProductToCart, onAddToCart, product, isMembersOnly, authenticated, openSignUp])

  const isFeatured = !!product.isFeatured
  const featuredBgImage = product.images?.featuredBgImage
  const { eventListeners, hovering } = useIsHovering()

  const selectItemTracker = useSelectItemTracker()
  const onLinkClick = useCallback(() => {
    selectItemTracker(product)
  }, [product, selectItemTracker])

  const impressionRef = useTrackProductImpressionInView(product, i)

  const introCompleted = useAtomValue(introCompletedAtom)
  const tileRef = useRef()
  const composedRefs = useComposeRefs(tileRef, impressionRef)

  useLayoutEffect(() => {
    if (!animateIn) return
    if (!introCompleted) {
      gsap.set(tileRef.current, { opacity: 0 })
      return
    }

    const tl = gsap.fromTo(tileRef.current, {
      y: 40,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      delay: 0.1 * (i % 5),
      ease: 'expo.out',
      duration: 1.2,
      scrollTrigger: {
        trigger: tileRef.current,
        start: 'top bottom',
        scrub: false
      }
    })

    return () => tl.revert()
  }, [animateIn, introCompleted])

  const isSmall = rowType === 'bundle-item' || (
    isMobile
      ? (
          rowType === 'standard' && (
            (rowIndex % 2 === 0 && i === 0) ||
            (rowIndex % 2 === 0 && i === 1) ||
            (rowIndex % 2 === 0 && i === 3) ||
            (rowIndex % 2 === 1 && i === 0) ||
            (rowIndex % 2 === 1 && i === 1) ||
            (rowIndex % 2 === 1 && i === 4)
          ) || rowType === 'feature' && (
            rowIndex === 1 && i === 1 ||
            rowIndex === 0 && i === 2
          )
        )
      : (
          rowType === 'standard' && (
            (rowIndex % 2 === 0 && i === 0) ||
            (rowIndex % 2 === 0 && i === 2) ||
            (rowIndex % 2 === 0 && i === 3) ||
            (rowIndex % 2 === 1 && i === 0) ||
            (rowIndex % 2 === 1 && i === 3) ||
            (rowIndex % 2 === 1 && i === 4)
          )
        ))

  const isTiny = isMobile && rowType === 'standard' && (
    (rowIndex % 2 === 0 && i === 3) ||
    (rowIndex % 2 === 1 && i === 4)
  )

  const _disableAdd = disableAdd || !canAddToCart
  const hasText = (isFeatured || product.showFeaturedTextInTile) && product?.featuredText

  // TODO: The size of the tile should not be managed here, this should be done in the grid that is laying the tiles out
  return (
    <article
      className={cn(
        className,
        'col-span-2 group self-start mb-4 pb-2 mb:pb-0',
        rowType === 'bundle-item' && '!col-span-3 md:!col-span-4',
        rowType === 'feature' && rowIndex === 0 && i === 0 && '!col-span-5 md:!col-span-full',
        rowType === 'feature' && rowIndex === 0 && i === 1 && '!col-span-3 md:!col-span-5',
        rowType === 'feature' && rowIndex === 0 && i === 2 && '!col-span-4 md:!col-span-3',

        rowType === 'feature' && rowIndex === 1 && i === 0 && '!col-span-4 md:!col-span-5',
        rowType === 'feature' && rowIndex === 1 && i === 1 && '!col-span-3 md:!col-span-3',
        rowType === 'feature' && rowIndex === 1 && i === 2 && '!col-span-5 md:!col-span-full md:row-start-1',

        rowType === 'feature' && rowIndex === 2 && i === 0 && '!col-span-4 md:!col-span-5',
        rowType === 'feature' && rowIndex === 2 && i === 1 && '!col-span-5 md:!col-span-full md:row-start-1',
        rowType === 'feature' && rowIndex === 2 && i === 2 && '!col-span-3 md:!col-span-3',

        rowType === 'standard' && rowIndex % 2 === 1 && i === 0 && 'md:!col-span-4',
        rowType === 'standard' && rowIndex % 2 === 1 && i === 1 && '!col-span-3 md:!col-span-4',
        rowType === 'standard' && rowIndex % 2 === 1 && i === 2 && '!col-span-3 md:!col-span-full',
        rowType === 'standard' && rowIndex % 2 === 1 && i === 3 && 'md:!col-span-5',
        rowType === 'standard' && rowIndex % 2 === 1 && i === 4 && 'md:!col-span-3',

        rowType === 'standard' && rowIndex % 2 === 0 && i === 0 && 'md:!col-span-4',
        rowType === 'standard' && rowIndex % 2 === 0 && i === 1 && '!col-span-3 md:!col-span-4',
        rowType === 'standard' && rowIndex % 2 === 0 && i === 2 && 'md:!col-span-5',
        rowType === 'standard' && rowIndex % 2 === 0 && i === 3 && 'md:!col-span-3',
        rowType === 'standard' && rowIndex % 2 === 0 && i === 4 && '!col-span-3 md:!col-span-full'
      )}
      {...eventListeners}
      ref={composedRefs}
    >
      <Link nonLinkTag='div' link={product} search={isProductForBundle ? '?subscription=1' : ''} showText={false} onClick={onLinkClick}>
        <div className={cn(
          'aspect-[340/453] h-auto bg-[#F6F5F5] flex flex-col w-full relative overflow-hidden',
          !isFullBleed && !isSmall && 'py-[3.6vw] md:py-[10vw]',
          !isFullBleed && isSmall && 'py-[3.6vw] md:py-[10vw]',
          !isFullBleed && isTiny && 'py-[3.6vw] md:py-[7vw]'
        )}>
          {(!isReleased || !inStock) && <BluredProductImage product={product} rowType={rowType}>
            {(isReleased && !inStock) && <div className='font-550 text-10 tracking-slight uppercase'>{soldOutLabel}</div>}
            {!isReleased && <Dropping releasedDate={product?.releasedDate} isSmall={isSmall} />}
          </BluredProductImage>}
          {isReleased && inStock && <ProductImage product={product} hovering={hovering} isFullBleed={isFullBleed} hasText={hasText} featuredBgImage={featuredBgImage} rowType={rowType} isSmall={isSmall} />}
        </div>
        <ProductMeta isReleased={isReleased} product={product} sellingPlan={sellingPlan} isProductForBundle={isProductForBundle} isSmall={isSmall} />
      </Link>

      {isReleased && <Button onClick={addToCart} disabled={_disableAdd}
        className={cn(
          'add-btn tracking-slight mt-6 opacity-0 md:opacity-100 translate-y-2 md:translate-y-0 group-hover:translate-y-0 transition-all',
          !_disableAdd && 'group-hover:opacity-100',
          _disableAdd && 'group-hover:opacity-100 md:opacity-100 cursor-not-allowed'
        )}
        borderColor={_disableAdd ? 'border-border' : 'border-current'}
        text='text-10' px='px-2' py='py-2' justify='justify-center'
      >
        {!canAddToCart ? soldOutLabel : (isMembersOnly && !authenticated) ? site?.labels?.membersLoginLabel : (addText || addToCartLabel)}
      </Button>}
    </article>
  )
}

export const DroppingCountdown = ({ releasedDate, isDroppingPage = false, ...props }) => {
  const [countdown, setCountDown] = useState('00:00:00')

  useEffect(() => {
    const updateCountdown = () => {
      setCountDown(formatCountdown(releasedDate))
    }
    const id = setInterval(updateCountdown, 1000)
    updateCountdown()
    return () => { clearInterval(id) }
  }, [releasedDate])

  if (isDroppingPage) {
    return (
      <div {...props}>
        {countdown?.split(/(:)/).map((section, i) => (
          <span key={i}>{section}</span>
        ))}
      </div>
    )
  }

  return (
    <div {...props}>
      {countdown}
    </div>
  )
}

export function Dropping ({ releasedDate, isSmall }) {
  const { droppingInLabel = 'Dropping In' } = useLabels()

  const classes = cn(
    'font-serif font-250 tracking-[-0.03em] text-24 md:text-14 uppercase leading-none',
    isSmall && '!text-16'
  )

  return (
    <>
      <div className={classes}>{droppingInLabel}</div>
      <DroppingCountdown className={classes} releasedDate={releasedDate}/>
    </>
  )
}

function ProductImage ({ product, isFullBleed, hasText, featuredBgImage, rowType, isSmall, hovering }) {
  return (
    <>
      {!hasText && !featuredBgImage && (
        <WineCode
          product={product}
          className={cn(
            'text-32 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 md:hidden',
            'opacity-0 transition-opacity group-hover:opacity-100 duration-600 ease-out-expo',
            isSmall && '!text-[1.6vw]'
          )}
        />
      )}
      {hasText && <FeaturedText product={product} isHovering={hovering} size={rowType === 'feature' ? 'md' : isSmall ? 'xs' : 'sm'} />}
      {product?.images?.featureImage && (
        <ResponsiveImage
          className={cn(
            'w-full h-full z-[2]',
            isFullBleed && 'upMd:group-hover:scale-110 transition-transform duration-600 ease-out-expo'
          )}
          image={product?.images?.featureImage}
          contain={!isFullBleed}
          showPreview={isFullBleed}
          imageSizes={
            hasText
              ? `(max-width: ${breakpoints.md}px) 40vw, 22vw`
              : isFullBleed
                ? `(max-width: ${breakpoints.md}px) 40vw, 80vw`
                : `(max-width: ${breakpoints.md}px) 30vw, ${isSmall ? '8vw' : '12vw'}`}
          widths={isFullBleed ? DEFAULT_WIDTHS : BOTTLE_WIDTHS}
        />)}
      {!hasText && featuredBgImage && (
        <ResponsiveImage
          className='!absolute top-0 left-0 right-0 bottom-0 z-[1] opacity-0 scale-110 group-hover:opacity-100 upMd:group-hover:scale-100 md:!opacity-100 transition-all duration-600 ease-out-expo'
          image={featuredBgImage}
          imageSizes={
            rowType === 'feature'
              ? `(max-width: ${breakpoints.md}px) 100vw, 60vw`
              : `(max-width: ${breakpoints.md}px) 60vw, 30vw`
          }
        />)}
    </>
  )
}

export function BluredProductImage ({ product, rowType, opacity = 0.75, children }) {
  const image = product?.images?.feedImage
  return (
    <div className='absolute inset-0 bg-black'>
      <div className='z-10 absolute inset-0 flex flex-col justify-center items-center text-center text-white'>
        {children}
      </div>
      <NoiseBackground light={true} visible className='z-10 !absolute'/>
      {image && <ResponsiveImage
        className='!absolute top-0 left-0 right-0 bottom-0 z-[1] overflow-hidden [&_img]:blur-xl '
        image={image}
        showPreview={false}
        style={{ opacity }}
        imageSizes={
          rowType === 'feature'
            ? `(max-width: ${breakpoints.md}px) 100vw, 60vw`
            : `(max-width: ${breakpoints.md}px) 60vw, 30vw`
        }
      />}
    </div>
  )
}
