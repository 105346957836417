import cn from 'clsx'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import { useKeenSlider } from 'keen-slider/react'
import findIndex from 'lodash/findIndex'
import { useCallback, useEffect, useRef } from 'react'
import useWindowResize from '../../hooks/useWindowResize'

const containerClassNames = 'text-10 md:text-16 font-550 uppercase tracking-slight flex upMd:gap-12 relative md:justify-self-center md:mb-24 md:mt-14'

export default function Tabs ({ index, className, children }) {
  const ref = useRef()
  const dotRef = useRef()
  const firstRunRef = useRef(true)

  function animate (index, firstRun) {
    if (index === -1) {
      gsap.to(dotRef.current, {
        scale: 0,
        duration: 0.6,
        ease: 'expo.out',
        overwrite: true
      })
      return
    }

    const elements = Array.from(ref.current.children).slice(0, ref.current.children.length - 1)
    const element = elements[index]
    if (!element) return
    const x =
      -ref.current.getBoundingClientRect().left +
      element.getBoundingClientRect().left +
      element.offsetWidth / 2

    gsap.to(dotRef.current, {
      x,
      scale: 1,
      duration: firstRun ? 0 : 0.6,
      ease: 'expo.out',
      overwrite: true
    })
    firstRunRef.current = false
  }

  useEffect(() => {
    animate(index, firstRunRef.current)
  }, [index])

  useWindowResize(useCallback(() => {
    animate(index, true)
  }, [index]), false, true)

  const handleMouse = (e) => {
    if (primaryInput === 'touch') return
    const childEls = gsap.utils.toArray(ref.current.children)
    const elIndex = findIndex(childEls, e.target)
    if (elIndex < 0 || elIndex > childEls.length - 2) return
    animate(elIndex, false)
  }

  const handleMouseLeave = () => {
    if (primaryInput === 'touch') return
    animate(index, false)
  }

  return (
    <>
      <div ref={ref} className={cn('md:hidden', containerClassNames, className)} onMouseMove={handleMouse} onMouseLeave={handleMouseLeave}>
        {children}
        <div className="w-1 h-1 rounded-full bg-current absolute -top-2 md:top-[110%] left-0 ml-[-0.15rem]" ref={dotRef} />
      </div>
      <MobileSelector index={index} className={cn(containerClassNames, className)}>
        {children}
      </MobileSelector>
    </>
  )
}

const MutationPlugin = (slider) => {
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function () {
      slider.update()
    })
  })
  const config = { childList: true }

  slider.on('created', () => {
    observer.observe(slider.container, config)
  })
  slider.on('destroyed', () => {
    observer.disconnect()
  })
}

function MobileSelector ({ index, className, children }) {
  const [sliderRef] = useKeenSlider({
    initial: index,
    loop: false,
    mode: 'snap',
    slides: {
      origin: 'auto',
      spacing: 32,
      perView: 'auto'
    }
  }, [MutationPlugin])

  return (
    <div className={cn(className, 'keen-slider !hidden md:!flex pt-2 px-4')} ref={sliderRef}>
      {children?.map((child, i) => (
        <div key={i} className='keen-slider__slide !max-w-fit !min-w-fit font-550 text-16 relative'>
          {child}
          <div className={cn('w-1 h-1 rounded-full bg-current absolute top-0 left-1/2 -transform-x-[0.175rem] transition-transform duration-300', index === i ? 'scale-100' : 'scale-0')} />
        </div>
      ))}
    </div>
  )
}
