import { Html } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { useViewport } from '../hooks'
import Block from './Block'
import { useInvalidateElement } from './LayoutManager'

export default function HtmlBlock ({ children, htmlProps, padding = 0, glChildren = null, ...props }) {
  const meshRef = useRef()
  const htmlRef = useRef()
  const blockRef = useRef()
  const viewport = useViewport()
  const size = useThree(({ size }) => size)
  const localsRef = useRef({ htmlMounted: false })
  const [containerSize, setContainerSize] = useState()

  const invalidate = useInvalidateElement()

  const resizeMesh = () => {
    if (htmlRef.current) {
      localsRef.current.htmlMounted = true

      const { height, width } = htmlRef.current.getBoundingClientRect()
      const worldHeight = (height / size.height) * viewport.height - (viewport.width * padding)
      const worldWidth = (width / size.width) * viewport.width
      meshRef.current.scale.x = worldWidth
      meshRef.current.scale.y = worldHeight
      setContainerSize({ width: worldWidth, height: worldHeight })
      invalidate(blockRef.current)
    }
  }

  useEffect(() => {
    resizeMesh()
  }, [size.width])

  useFrame(() => {
    // We need to run this until the html is mounted
    if (!localsRef.current.htmlMounted) {
      resizeMesh()
    }
  })

  return (
    <Block {...props} ref={blockRef}>
      <mesh ref={meshRef} name='text-placeholder-mesh'>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
      {containerSize && glChildren && glChildren(containerSize)}
      <Html ref={htmlRef} {...htmlProps} portal={{ current: document.getElementById('canvas-feed-html') }} >
        {children}
      </Html>
    </Block>
  )
}
