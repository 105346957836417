import cn from 'clsx'
import gsap from 'gsap'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSite } from '../../siteState'
import Spinner from '../../Spinner'
import { menuOpenAtom, navViewAtom, NAV_VIEWS } from '../menuState'
import { searchLoadingAtom, searchQueryAtom, searchInputAtom, useClearSearch } from './searchState'

export default function SearchInput () {
  const localsRef = useRef({ tl: null })
  const menuOpen = useAtomValue(menuOpenAtom)
  const verticalTickerRef = useRef()
  const setView = useSetAtom(navViewAtom)
  const { search: { label, labelItems, placeholder } = { } } = useSite()

  const loading = useAtomValue(searchLoadingAtom)
  const setSearchQuery = useSetAtom(searchQueryAtom)
  const [input, setInput] = useAtom(searchInputAtom)
  const debouncedSetInput = useCallback(debounce((value) => setSearchQuery(value), 200, { leading: false, trailing: true }), [])

  const onChange = useCallback((e) => {
    setInput(e.target.value)
    setView(e.target.value ? NAV_VIEWS.search : NAV_VIEWS.nav)
    debouncedSetInput(e.target.value)
  }, [setInput, setView, debouncedSetInput])

  const clear = useClearSearch()

  const empty = !input

  useEffect(() => {
    if (labelItems?.length > 2) {
      const item = verticalTickerRef.current.children[0]
      const copy = verticalTickerRef.current.children[1]

      const tl = gsap.timeline({ repeat: -1 })
      const obj = { index: 0, currentIndex: 0 }
      tl.fromTo(obj, { index: 0 }, {
        index: labelItems?.length - 1,
        ease: `steps(${labelItems?.length - 1})`,
        duration: (labelItems?.length - 1) * 2,
        onUpdate: () => {
          if (obj.currentIndex !== obj.index) {
            obj.currentIndex = obj.index
            const nextText = labelItems[obj.currentIndex % labelItems.length]
            copy.innerText = nextText
            gsap.to(item, { y: '-100%', duration: 0.5 })
            gsap.to(copy, {
              y: '-100%',
              duration: 0.5,
              onComplete: () => {
                item.innerText = nextText
                gsap.set(item, { y: 0 })
                gsap.set(copy, { y: 0 })
              }
            })
          }
        }
      })
      localsRef.current.tl = tl
      return () => {
        tl.kill()
      }
    }
  }, [labelItems])

  useEffect(() => {
    if (localsRef.current.tl) {
      if (menuOpen) {
        localsRef.current.tl.play()
      } else {
        localsRef.current.tl.pause()
      }
    }
  }, [menuOpen, labelItems])

  const [focused, setFocused] = useState(false)

  const onFocus = useCallback(() => {
    if (input) {
      setView(NAV_VIEWS.search)
    }
    setFocused(true)
  }, [input, setView])
  const onBlur = useCallback(() => {
    setFocused(false)
  }, [])

  return (
    <div className='relative w-full'>
      <div className={cn((focused || input) && 'opacity-0', 'w-full flex text-10 h-[1em] overflow-hidden justify-start absolute top-1/2 left-0 -translate-y-1/2 uppercase pointer-events-none')}>
        <SearchIcon className='mr-1 mt-[-1px]'/>
        <span className='opacity-50'>{label}</span>
        <div className='ml-1 transition-transform duration-150 h-[1em] opacity-50' ref={verticalTickerRef}>
          <span className='block h-[1em]'>{labelItems?.[0]}</span>
          <span className='block h-[1em]' />
        </div>
      </div>
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        value={input}
        onChange={onChange}
        placeholder={focused ? placeholder : ''}
        className='w-full uppercase text-10 bg-transparent border-b py-2 outline-none pr-[2.5rem] placeholder-current placeholder:opacity-50 rounded-none'
      />
      {!loading && !empty && (
        <button onClick={clear} className='absolute right-0 top-1/2 -translate-y-1/2 uppercase text-10'>Clear</button>
      )}
      {loading && <div className='absolute right-0 top-1/2 -translate-y-1/2'><Spinner size={16} /></div>}
    </div>
  )
}


function SearchIcon ({ ...props }) {
  return (
    <svg width="0.625rem" height="0.625rem" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4.58333 7.91667C6.42428 7.91667 7.91667 6.42428 7.91667 4.58333C7.91667 2.74238 6.42428 1.25 4.58333 1.25C2.74238 1.25 1.25 2.74238 1.25 4.58333C1.25 6.42428 2.74238 7.91667 4.58333 7.91667Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.75 8.75L6.9375 6.9375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}