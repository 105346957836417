const formatters = {}

const createFormatters = (currency, locale, currencyDisplay) => {
  return {
    wholeNumber: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: currencyDisplay || 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }),
    fraction: new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: currencyDisplay || 'narrowSymbol'
    })
  }
}

export const formatCurrency = (price, locale, currency = 'AUD', currencyDisplay) => {
  const key = `${locale}-${currency}-${currencyDisplay || 'default'}`
  if (!formatters[key]) {
    try {
      formatters[key] = createFormatters(currency, locale, currencyDisplay)
    } catch {
      formatters[key] = createFormatters(currency, locale, 'symbol')
    }
  }
  if (isNaN(price)) return null

  const formatter = price % 1 === 0 ? formatters[key].wholeNumber : formatters[key].fraction
  return formatter.format(price)
}

export function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const hasFromPrice = product => {
  if (!product) return
  if (!product.variants) return

  const total = product.variants.reduce((previous, current) => {
    return previous + current.calculated_price
  }, 0)

  return total / product.variants.length !== product.calculated_price
}
