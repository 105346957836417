import { useThree } from '@react-three/fiber'
import isNumber from 'lodash/isNumber'
import { useLayoutEffect, useMemo, useState } from 'react'
import breakpoints from '../../../theme/breakpoints'

export const useViewport = () => {
  const viewport = useThree(({ viewport }) => viewport)
  const camera = useThree(({ camera }) => camera)
  return useMemo(() => camera.isOrthographicCamera
    ? viewport
    : {
        distance: viewport.distance,
        aspect: viewport.aspect,
        factor: viewport.factor * viewport.aspect,
        width: viewport.height,
        height: (1 / viewport.aspect) * viewport.height
      }, [camera.isOrthographicCamera, viewport])
}

export const useScreenBounds = (z, elementWidth, elementHeight) => {
  const [bounds, setBounds] = useState({ top: 0, let: 0, bottom: 0, right: 0, width: 0, height: 0 })
  const size = useThree(({ size }) => size)
  const camera = useThree(({ camera }) => camera)

  useLayoutEffect(() => {
    if (camera.isOrthographicCamera) {
      setBounds({
        top: camera.top - (elementHeight / 2),
        left: camera.left + (elementWidth / 2),
        bottom: camera.bottom + (elementWidth / 2),
        right: camera.right - (elementWidth / 2)
      })
      return
    }
    const aspect = (size.width / size.height)
    const widthBoundsRatio = Math.tan(camera.fov / 2.0 * Math.PI / 180.0) * 2.0
    const heightBoundsRatio = (1 / aspect) * widthBoundsRatio
    const distance = Math.abs(camera.position.z - z)

    const width = widthBoundsRatio * distance
    const height = heightBoundsRatio * distance

    setBounds({
      top: height / 2 - (elementHeight / 2),
      bottom: -height / 2 + (elementHeight / 2),
      left: -width / 2 + (elementWidth / 2),
      right: width / 2 - (elementWidth / 2),
      width,
      height
    })
  }, [size, z, elementWidth])

  return bounds
}

// Supports % values as well as pixels
export const useScreenToWorldPosition = (z, left, top, width, height) => {
  const bounds = useScreenBounds(z, width, height)
  const size = useThree(({ size }) => size)

  return useMemo(() => {
    const leftFactor = isNumber(left) ? size.width : 100
    const topFactor = isNumber(top) ? size.height : 100
    const x = bounds.left + (bounds.width * parseInt(left) / leftFactor)
    const y = bounds.top - (bounds.height * parseInt(top) / topFactor)
    return [x, y, z]
  }, [size, bounds, left, top, z])
}

export const useIsMobile = () => {
  const size = useThree(({ size }) => size)
  return size.width < breakpoints.md
}

export const useIsSmallDesktop = () => {
  const size = useThree(({ size }) => size)
  return size.width < breakpoints.lg && size.width >= breakpoints.md
}
