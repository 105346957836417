import cn from 'clsx'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useRef, useState } from 'react'
import breakpoints from '../../../theme/breakpoints'
import useLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'
import useScrollPosition from '../../hooks/useScrollPosition'
import useThrottleCallback from '../../hooks/useThrottleCallback'
import useWindowResize from '../../hooks/useWindowResize'
import { useIsMobile } from '../../state/isMobile'
import ResponsiveImage from '../ResponsiveImage'
import RichContent from '../RichContent'
import Section from '../Section'

const Video = dynamic(() => import('../Video'), {
  ssr: false
})

export default function WineryHero ({ data, page }) {
  const { slides, wineCount, details } = data
  const rootRef = useRef()
  const scrollDownRef = useRef()
  const metaRef = useRef()
  const [hideScrollDown, setHideScrollDown] = useState(false)
  const localsRef = useRef({})

  useWindowResize(useThrottleCallback(() => {
    localsRef.current.innerWidth = window.innerWidth
    localsRef.current.innerHeight = window.innerHeight
  }, 200, []), true)

  useLayoutEffect(() => {
    const mm = gsap.matchMedia()
    mm.add(`(min-width: ${breakpoints.md}px)`, () => {
      ScrollTrigger.create({
        trigger: document.body,
        pin: metaRef.current,
        start: 'top top',
        end: () => `${rootRef.current.getBoundingClientRect().height - metaRef.current.getBoundingClientRect().height}px`,
        pinSpacing: false,
        invalidateOnRefresh: true,
        markers: false
      })
    })

    return () => mm.revert()
  }, [])

  useScrollPosition(useCallback(({ scroll }) => {
    const { innerHeight } = localsRef.current
    setHideScrollDown(scroll > (innerHeight / 4))
  }, []))

  useEffect(() => {
    const { innerWidth, innerHeight } = localsRef.current
    gsap.to(scrollDownRef.current, {
      opacity: hideScrollDown ? 0 : 1,
      y: hideScrollDown ? 20 : 0,
      duration: 0.6,
      ease: 'expo.out',
      overwrite: true
    })

    if (innerWidth > breakpoints.md) {
      gsap.to(metaRef.current.children, {
        y: hideScrollDown ? ((innerHeight / 2) - 25) : 0,
        duration: 1.4,
        ease: 'expo.out'
      })
    } else {
      gsap.set(metaRef.current.children, { y: 0 })
    }
  }, [hideScrollDown])

  const isMobile = useIsMobile()
  const getVideo = (winery) => {
    const prop = isMobile ? 'mobileVideo' : 'video'
    return winery[prop] || winery.video
  }

  return (
    <>
      <Section noGutter className='z-[2] md:mt-[-100vh]' ref={rootRef}>
        <Section ref={metaRef} grid noBottomMargin width='w-screen-without-scrollbar' className='absolute left-0 right-0 h-screen top-0 uppercase font-550 text-10 z-[4] items-center md:items-end md:pb-4 font-feature-zero md:sticky'>
          <div className='col-span-3 md:col-span-2'>{page.title}</div>
          <div className='col-span-5 md:col-span-2'>{wineCount} wines</div>
          <div className='text-right md:col-span-2'>{page.wineryFields?.established}</div>
          <div className='col-span-3 text-right md:col-span-2'>{page.wineryFields?.location}</div>
        </Section>
        <div ref={scrollDownRef} className={cn('absolute bottom-6 left-1/2 -translate-x-1/2 uppercase text-8 z-[10] md:hidden')}>
          scroll down
        </div>
        <Slide image={page.articleFields.images[0]} video={getVideo(data) || getVideo(page?.articleFields)} text={page.title} logo={page.wineryFields?.logo} titleTag='h1' />
        {slides?.map(slide => (
          <Slide key={slide._key} image={slide.image} video={getVideo(slide)} text={slide.text} titleTag='h2' />
        ))}
      </Section>
      <Section grid>
        <div className='col-start-5 col-span-4 flex flex-col gap-16 md:col-start-1 md:col-span-full'>
          <div className='flex justify-between font-serif font-250 uppercase text-10'>
            <div>{page.wineryFields?.established}</div>
            <div className='text-right'>{page.wineryFields?.location}</div>
          </div>
          <div className='text-14 text-justify'>
            <RichContent content={details} className='[&>div>p]:mb-4' />
          </div>
        </div>
      </Section>
    </>
  )
}

function Slide ({ image, video, text, logo, titleTag }) {
  const imageRef = useRef()
  const videoRef = useRef()
  const titleRef = useRef()
  const containerRef = useRef()
  const offsetRef = useRef()
  const localsRef = useRef({})

  const parallax = useCallback(({ scroll }) => {
    if (imageRef.current) {
      const isDesktop = localsRef.current?.innerWidth >= breakpoints.md
      const offsetScroll = scroll - offsetRef.current
      if (isDesktop) {
        imageRef.current.style.transform = `translate3d(0, ${offsetScroll / 2.5}px, 0)`
        titleRef.current.style.transform = `translate3d(0, ${offsetScroll * 0.7}px, 0)`
      } else {
        imageRef.current.style.transform = 'translate3d(0, 0px, 0)'
        titleRef.current.style.transform = 'translate3d(0, 0px, 0)'
      }
      if (videoRef.current) {
        videoRef.current.style.transform = imageRef.current.style.transform
        videoRef.current.style.top = 0
        videoRef.current.style.left = 0
      }
    }
  }, [])

  useWindowResize(useThrottleCallback(() => {
    localsRef.current.innerWidth = window.innerWidth
    localsRef.current.innerHeight = window.innerHeight

    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect()
      offsetRef.current = top + window.scrollY
      parallax({ scroll: window.scrollY })
    }
  }, 200, [parallax]), true)

  useScrollPosition(parallax)

  const Tag = titleTag

  // useEffect(() => {
  //   if (primaryInput === 'touch') {
  //     containerRef.current.height = `${containerRef.current.getBoundingClientRect().height}px`
  //   }
  // }, [])

  return (
    <Section noGutter noBottomMargin className='!overflow-hidden w-full h-screen relative translate-x-0' ref={containerRef}>
      {image && <ResponsiveImage ref={imageRef} image={image} className='absolute left-0 top-0 w-full h-full' />}
      {video && <Video videoRef={videoRef} video={video} title={text} background style={{height: '100vh'}}/>}
      <div className='absolute top-0 left-0 w-full h-full z-[3] flex flex-col items-center justify-center text-center uppercase font-550 text-[4.5rem] md:text-24 leading-[0.9em] tracking-[-0.02em]'>
        <div ref={titleRef}>
          {logo && <img className='w-[20vw] md:w-[70vw]' src={logo.asset.url} alt={logo.alt || text} />}
          <Tag className={cn('whitespace-pre-wrap', logo && 'sr-only')}>{text}</Tag>
        </div>
      </div>
    </Section>
  ) 
}
