import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import { useSetAtom } from 'jotai'
import { useKeenSlider } from 'keen-slider/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import breakpoints from '../../../theme/breakpoints'
import { leftPad } from '../../helpers/math'
import useWindowSize from '../../hooks/useWindowSize'
import { hideMenuAtom } from '../Menu/menuState'
import ResponsiveImage from '../ResponsiveImage'
import Section from '../Section'

function easeOutExpo (x) {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x)
}

export default function Illuminate ({ data, page }) {
  const { slides } = data
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState(null)
  const setMenuHidden = useSetAtom(hideMenuAtom)

  const { width: windowWidth } = useWindowSize()

  const [refCallback, instanceRef] = useKeenSlider(
    {
      slides: slides.length,
      slideChanged (slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      detailsChanged (s) {
        setDetails(s.track.details)
      },
      defaultAnimation: {
        duration: 600,
        easing: (x) => easeOutExpo(x)
      }
    },
    []
  )

  const nextSlide = useCallback(() => {
    instanceRef.current.next()
  }, [instanceRef])

  const prevSlide = useCallback(() => {
    instanceRef.current.prev()
  }, [instanceRef])

  function scaleStyle (idx) {
    if (!details) return {}
    const isMobile = windowWidth < breakpoints.md

    const slide = details.slides[idx]
    const scaleSize = isMobile ? 0.1 : 0.5
    const rotateAmount = 15
    const opacityAmount = 1
    const xAmount = isMobile ? 40 : 20

    const scale = 1 - (scaleSize - scaleSize * slide.portion)
    const rotate = 0 - (rotateAmount - rotateAmount * slide.portion) * slide.distance * -1
    const x = 0 - (xAmount - xAmount * slide.portion) * slide.distance * -1
    const opacityOutput = 1 - (opacityAmount - opacityAmount * (easeOutExpo(slide.portion)))

    return {
      transform: `scale(${scale}) rotate(${rotate}deg) translateX(${x}rem)`,
      WebkitTransform: `scale(${scale}) rotate(${rotate}deg) translateX(${x}rem)`,
      opacity: opacityOutput,
      zIndex: slides.length + (idx * -1) + (currentSlide === idx ? 1 : 0)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKey, false)
    return () => [document.removeEventListener('keydown', handleKey, false)]
  }, [])

  function handleKey (e) {
    if (e.keyCode === 37) {
      prevSlide()
    }
    if (e.keyCode === 39) {
      nextSlide()
    }
  }

  const router = useRouter()
  const handleBack = () => {
    router.back()
  }

  useEffect(() => {
    setMenuHidden(true)
    return () => setMenuHidden(false)
  }, [])

  return (
    <Section noGutter noBottomMargin className='fixed top-0 left-0 right-0 bottom-0 w-full flex flex-col'>
      <CloseButton onClick={handleBack} />

      <div className='flex-1 overflow-hidden flex justify-center'>
        <div ref={refCallback} className="!h-full upMd:!w-auto upMd:aspect-[9/16] md:w-full select-none relative">
          {slides?.map((slide, i) => (
            <div key={slide._key} className="!absolute top-0 left-0 right-0 bottom-0 cursor-grab active:cursor-grabbing !overflow-visible ">
              {slide.image && <div style={scaleStyle(i)} className='relative md:h-full'>
                {slide.text && <div className='absolute z-10 bottom-[8rem] md:bottom-8 left-1/2 -translate-x-1/2 text-center max-w-[20em] border border-white p-2 bg-[rgba(0,0,0,0.3)] backdrop-blur-xl text-12'>
                  {slide.text}
                </div>}
                {slide.image && <ResponsiveImage image={slide.image} aspect={385 / 761} className='md:absolute md:h-screen md:w-screen'/>}
              </div>}
              {!slide.image && <div style={scaleStyle(i)} className='relative flex flex-col bg-black text-white items-center justify-center h-full gap-8 px-8'>
                <div className='uppercase font-550 text-24 text-center leading-none tracking-slight'>
                  <span className='font-serif font-250 mr-[0.5em]'>[{leftPad(i + 1, 2)}]</span>
                  {slide.title}
                </div>
                {slide.text && <div className='text-center text-14 tracking-[-0.01em]'>{slide.text}</div>}
              </div>}
            </div>
          ))}
        </div>
        <BgImages slides={slides} currentSlide={currentSlide} />
      </div>
      <div className='bg-white text-black flex w-full items-center border-t'>
        <button className='uppercase p-4 font-550 text-12 tracking-[0.02em] leading-none transition-opacity disabled:opacity-50 disabled:cursor-not-allowed' onClick={prevSlide} disabled={currentSlide === 0}>Prev</button>
        <div className='flex-1 border-l border-r px-3 py-4 text-center font-250 font-serif leading-none'>
          <Counter currentIndex={currentSlide} items={slides} />
        </div>
        <button className='uppercase p-4 font-550 text-12 tracking-[0.02em] leading-none transition-opacity disabled:opacity-50 disabled:cursor-not-allowed' onClick={nextSlide} disabled={currentSlide === slides.length - 1}>Next</button>
      </div>
    </Section>
  )
}

function BgImages ({ slides, currentSlide }) {
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return
    const slides = gsap.utils.toArray(ref.current?.children)
    slides.map((slide, i) => {
      gsap.to(slide, {
        opacity: i === currentSlide ? 1 : 0,
        duration: 0.6,
        ease: 'expo.out',
        overwrite: true
      })
    })
  }, [currentSlide])

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 z-[-1] opacity-50 md:hidden' ref={ref}>
      {slides?.map((slide, i) => {
        if (slide.image) {
          const image = slide.bgImage || slide.image
          return <ResponsiveImage image={image} className='!absolute top-0 left-0 right-0 bottom-0 h-full opacity-0'/>
        }
        return <div className='!absolute top-0 left-0 right-0 bottom-0 bg-black'/>
      })}
    </div>
  )
}

function Counter ({ currentIndex, items }) {
  const ref = useRef()

  useEffect(() => {
    const els = gsap.utils.toArray(ref.current.children)
    els.forEach((el, i) => {
      if (i === currentIndex) {
        gsap.fromTo(el, {
          yPercent: 110
        }, {
          yPercent: 0,
          duration: 0.6,
          ease: 'expo.out',
          overwrite: true
        })
      } else {
        gsap.to(el, {
          yPercent: -110,
          duration: 0.6,
          ease: 'expo.out'
        })
      }
    })
  }, [currentIndex])

  const maskStyles = {
    maskImage: 'linear-gradient(0deg,transparent,#000 10%,#000 95%,transparent)',
    WebkitMaskImage: 'linear-gradient(0deg,transparent,#000 10%,#000 95%,transparent)'
  }

  return (
    <div className='relative  h-[1em]' ref={ref} style={maskStyles}>
      {items.map((x, i) => (
        <span key={i} className='absolute left-0 top-0 w-full font-feature-zero-tabular uppercase text-12'>[{i + 1}] {items[i].title}</span>
      ))}
    </div>
  )
}

function CloseButton ({ onClick }) {
  const ref = useRef()
  const localsRef = useRef({})

  const onMouseEnter = useCallback(() => {
    const el = ref.current
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const tl = gsap.timeline()
    tl.fromTo(el, {
      rotate: 0
    }, {
      rotate: 90,
      duration: 0.3,
      ease: 'power2.out'
    })
    tl.set(el, { rotate: 0 })
    localsRef.current.timeline = tl
  }, [])

  const onMouseLeave = useCallback(() => {
    const el = ref.current
    if (localsRef.current.timeline) localsRef.current.timeline.kill()
    const tl = gsap.timeline()
    tl.to(el, {
      rotate: 90,
      duration: 0.3,
      ease: 'power2.out'
    })
    tl.set(el, { rotate: 0 })
    localsRef.current.timeline = tl
  }, [])

  const eventListeners = {
    onMouseLeave: primaryInput === 'touch' ? null : onMouseLeave,
    onMouseEnter: primaryInput === 'touch' ? null : onMouseEnter
  }

  return (
    <button className='fixed top-8 left-1/2 -translate-x-1/2 z-[999]' onClick={onClick} {...eventListeners}>
      <svg ref={ref} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.843 9.02539L30.9742 10.1566L10.1566 30.9726L9.02539 29.8422L29.843 9.02539Z" fill="white"/>
        <path d="M10.1566 9.02539L30.9742 29.8414L29.843 30.9734L9.02539 10.1574L10.1566 9.02539Z" fill="white"/>
      </svg>
    </button>
  )
}
