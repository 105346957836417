/* eslint-disable camelcase */
import { useAtomValue } from 'jotai'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { formatDate } from '../../helpers/date'
import Link from '../Link'
import useProductPriceFormatted from '../Menu/SecondaryNavs/useProductPriceFormatted'
import RichContent from '../RichContent'
import Section from '../Section'
import { useAccessTokenAtom } from '../Shop/accountState'
import { useSite } from '../siteState'
import Spinner from '../Spinner'

const SubscriptionRow = ({ subscription }) => {
  const { id, status, product, product_title, next_charge_scheduled_at, order_interval_frequency, order_interval_unit } = subscription
  const frequency = useMemo(() => {
    return `${order_interval_frequency} ${order_interval_unit}${order_interval_frequency > 1 ? 's' : ''}`
  }, [order_interval_frequency, order_interval_unit])

  const formattedPrice = useProductPriceFormatted(product)

  const onEditSubscription = useCallback(async () => { }, [])

  return (
    <tr className='text-12 uppercase border-b border-solid [&_td]:py-3 [&_td]:px-2 [&_td:first-child]:pl-0 [&_td:last-child]:pr-0'>
      <td>{id}</td>
      <td className='font-serif font-250'>{product_title}</td>
      <td>{status}</td>
      <td>{frequency}</td>
      <td>{formatDate(next_charge_scheduled_at)}</td>
      <td>{formattedPrice}</td>
      <td>
        <div className='flex gap-2 justify-end'>
          <button className='uppercase underline hover:no-underline' onClick={onEditSubscription}>Edit Subscription</button>
        </div>
      </td>
    </tr>
  )
}

const Header = ({ title, copy, links, ...props }) => {
  return (
    <div className='border-t border-solid pt-4' {...props}>
      <Section as='div' grid noGutter noBottomMargin className='mb-32 md:mb-8'>
        <h3 className='uppercase font-550 text-18 md:text-14 col-span-3 md:col-span-4 row-start-1'>{title}</h3>
        <RichContent content={copy} className='col-span-6 md:col-span-full text-14 md:text-12 indent-indent-sm md:indent-indent-lg' />
        <div className='flex gap-4 col-span-3 md:col-span-4 md:row-start-1 justify-self-end self-start'>
          {links?.map((link, i) => (
            <Link key={i} link={link} className='uppercase font-550 text-14 md:text-12 hover:underline' />
          ))}
        </div>
      </Section>
    </div>
  )
}

export default function SubscriptionHistory ({ className, title, copy, emptyCopy, links }) {
  const localsRef = useRef({ loaded: false })
  const [subscriptions, setSubscriptions] = useState(null)
  const [loading, setLoading] = useState(false)
  const site = useSite()
  const accessToken = useAtomValue(useAccessTokenAtom())

  const getSubscriptions = useCallback(async () => {
    try {
      setLoading(true)
      const response = await fetch(`api/${site._id}/recharge/subscriptions`, {
        method: 'GET',
        headers: {
          Authorization: accessToken.accessToken
        }
      })
      if (response.ok) {
        setSubscriptions(await response.json())
      } else {
        // TODO: handle the error
      }
    } finally {
      setLoading(false)
      localsRef.current.loaded = true
    }
  }
  , [accessToken, site._id])

  useEffect(() => {
    if (loading) return
    getSubscriptions()
  }, [getSubscriptions])

  const empty = isEmpty(subscriptions)

  return (
    <div className={className}>
      <Header title={title} copy={copy} links={links} />
      <div className='relative min-h-[1rem]'>
        {loading && <Spinner className='!absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2' />}
        {empty && localsRef.current.loaded && (
          <RichContent content={emptyCopy} className='text-center' />
        )}
        {!empty && (
          <table className='w-full'>
            <thead>
              <tr className='text-left text-10 font-550 uppercase border-b-2 border-solid [&_th]:p-2 [&_th:first-child]:pl-0 [&_th:last-child]:pr-0'>
                <th>
                  Order ID
                </th>
                <th>
                  Type
                </th>
                <th>
                  Status
                </th>
                <th>
                  Frequency
                </th>
                <th>
                  Next Delivery
                </th>
                <th>
                  Cost
                </th>
                <th className='text-right'>
                  Settings
                </th>
              </tr>
            </thead>
            <tbody>
              {subscriptions?.map(subscription => (
                <SubscriptionRow key={subscription.id} subscription={subscription} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
