import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { siteAtom } from '../siteState'

export const ageGateLocalAtom = atom({ passed: false })

const ageGateAtomsBySite = {}

export const rememberAgeGateResponseAtom = atom(true)

export const ageGatePassedAtom = atom(
  (get) => {
    const site = get(siteAtom)
    if (!ageGateAtomsBySite[site.countryCode]) {
      // We create a new storage atom for each site
      ageGateAtomsBySite[site.countryCode] = atomWithStorage(`ageGate-${site.countryCode}`, { passed: false })
    }
    const storedAgeGate = get(ageGateAtomsBySite[site.countryCode])
    const localAgeGate = get(ageGateLocalAtom)
    return storedAgeGate?.passed || localAgeGate?.passed
  },
  (_get, set, value) => {
    const site = _get(siteAtom)
    const remember = _get(rememberAgeGateResponseAtom)
    if (remember) {
      if (!ageGateAtomsBySite[site.countryCode]) {
        ageGateAtomsBySite[site.countryCode] = atomWithStorage(`ageGate-${site.countryCode}`, { passed: value })
      }
      set(ageGateAtomsBySite[site.countryCode], { passed: value })
    }
    set(ageGateLocalAtom, { passed: value })
  }
)

export const pageReadyAtom = atom(false)
export const introCompletedAtom = atom(false)
