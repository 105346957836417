import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { useCustomSubscriptionBundle } from '../../siteState'
import { subscriptionCartAtom, subscriptionCartOpenAtom } from './subscriptionState'

export function useAddToSubscriptionCartCallback () {
  // const [subscriptionCart, setSubscriptionCart] = useAtom(subscriptionCartAtom)
  const setSubscriptionCart = useSetAtom(subscriptionCartAtom)
  const setSubscriptionCartOpen = useSetAtom(subscriptionCartOpenAtom)
  return useCallback((product) => {
    setSubscriptionCart(c => [...c, { ...product }])
    setSubscriptionCartOpen(true)
  }, [setSubscriptionCart, setSubscriptionCartOpen])
}

export function useSubscriptionCartFull () {
  const subscriptionCart = useAtomValue(subscriptionCartAtom)
  const bundle = useCustomSubscriptionBundle()
  const productCount = bundle?.bundle?.details?.productCount
  return subscriptionCart.length >= productCount
}
