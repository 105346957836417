import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { siteAtom } from '../siteState'

const newsletterPopupAtomBySite = {}

export const newsletterPopupActionedAtom = atom(
  (get) => {
    const site = get(siteAtom)
    if (!newsletterPopupAtomBySite[site.countryCode]) {
      // We create a new storage atom for each site
      newsletterPopupAtomBySite[site.countryCode] = atomWithStorage(`newsletter-popup-${site.countryCode}`, { actioned: false })
    }
    const state = get(newsletterPopupAtomBySite[site.countryCode])
    return state?.actioned
  },
  (_get, set, value) => {
    const site = _get(siteAtom)
    if (!newsletterPopupAtomBySite[site.countryCode]) {
      newsletterPopupAtomBySite[site.countryCode] = atomWithStorage(`newsletter-popup-${site.countryCode}`, { actioned: value })
    } else {
      set(newsletterPopupAtomBySite[site.countryCode], { actioned: value })
    }
  }
)
