/* eslint-disable jsx-a11y/alt-text */
import { useCursor } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useDrag } from '@use-gesture/react'
import { primaryInput } from 'detect-it'
import isFunction from 'lodash/isFunction'
import React, { forwardRef, useCallback, useRef, useState } from 'react'
import * as THREE from 'three'
import { lerp } from 'three/src/math/MathUtils'
import useComposeRefs from '../../../hooks/useComposeRefs'

const Draggable = forwardRef(({ enabled = true, children, onClick, onPointerDown }, ref) => {
  const internalRef = useRef()
  const localsRef = useRef({ targetPosition: null })

  const [dragging, setDragging] = useState()
  useCursor(dragging, 'grabbing', 'auto')

  const floorPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0)
  const planeIntersectPoint = new THREE.Vector3()

  useFrame(() => {
    if (primaryInput === 'touch' || !enabled) return
    if (internalRef.current) {
      if (localsRef.current.targetPosition) {
        const { x, y } = localsRef.current.targetPosition
        const imagePosition = internalRef.current.position
        imagePosition.x = lerp(imagePosition.x, x, 0.3)
        imagePosition.y = lerp(imagePosition.y, y, 0.3)

        if (imagePosition.y === y && imagePosition.x === x) {
          localsRef.current.targetPosition = null
        }
      }
    }
  })

  const bind = useDrag(
    ({ active, timeStamp, event }) => {
      if (!enabled) return
      if (active) {
        event.ray.intersectPlane(floorPlane, planeIntersectPoint)
        if (!internalRef.current.dragging) {
          internalRef.current.initialIntersectionPoint = planeIntersectPoint.clone()
          internalRef.current.initialPosition = internalRef.current.position.clone()
        }
        const xDiff = planeIntersectPoint.x - internalRef.current.initialIntersectionPoint.x
        const yDiff = planeIntersectPoint.y - internalRef.current.initialIntersectionPoint.y
        localsRef.current.targetPosition = {
          x: xDiff + internalRef.current.initialPosition.x,
          y: yDiff + internalRef.current.initialPosition.y
        }
      }
      internalRef.current.dragging = active
      setDragging(active)

      return timeStamp
    },
    { delay: true }
  )

  const { onPointerMove: onDragMove, onPointerDown: onDragPointerDown, onPointerUp: onDragPointerUp, ...events } = bind()
  const onPointerMove = useCallback((e) => {
    if (primaryInput === 'touch') return
    e.stopPropagation()
    onDragMove(e)
  }, [onDragMove])

  const _onPointerDown = useCallback((e) => {
    if (primaryInput === 'touch' || !enabled) return
    e.stopPropagation()
    onDragPointerDown(e)
    localsRef.current.clickActive = true
    localsRef.current.clickTimeoutId = window.setTimeout(() => {
      localsRef.current.clickActive = false
    }, 200)

    onPointerDown?.(e)
  }, [onDragPointerDown, onPointerDown, enabled])

  const onPointerUp = useCallback((e) => {
    if (primaryInput === 'touch' || !enabled) return
    onDragPointerUp(e)
    window.clearTimeout(localsRef.current.clickTimeoutId)
    if (localsRef.current.clickActive) {
      onClick?.()
    }
  }, [onDragPointerUp, onClick, enabled])

  const composedRefs = useComposeRefs(internalRef, ref)

  return (
    <group ref={composedRefs} {...events} onPointerMove={onPointerMove} onPointerDown={_onPointerDown} onPointerUp={onPointerUp} onClick={primaryInput === 'touch' ? onClick : null}>
      {isFunction(children) ? children(dragging) : children}
    </group>
  )
})

export default Draggable
