import pixelToViewport from '../../../helpers/pixelToViewport'
import { useIsMobile, useViewport } from '../../../hooks'
import Stack from '../../../ui/Stack'
import BodyFont from '../../../ui/Text/BodyFont'
import SansSerifFont from '../../../ui/Text/SansSerifFont'

// const TITLE_FONT_SIZES = { lg: 0.21, md: 0.28, sm: 0.8 }
// const FONT_SIZES = { lg: 0.1, md: 0.12, sm: 0.4 }
const TITLE_FONT_SIZES = { lg: 0.21, md: 0.24, sm: 0.8 }
const FONT_SIZES = { lg: 0.1, md: 0.12, sm: 0.4 }

export default function WineryMeta ({ winery, width, height }) {
  const viewport = useViewport()
  const padding = pixelToViewport(16, viewport)
  const { title, subtitle, established, owner, location } = winery
  const isMobile = useIsMobile()

  return (
    <group position={[0, 0, 0.001]} name='meta'>
      <Stack anchorY='center'>
        <BodyFont
          fontSizes={TITLE_FONT_SIZES}
          maxWidth={width - (isMobile ? padding * 8 : padding * 2) }
          textAlign='center'
          anchorX='center'
          anchorY='center'
        >
          {title?.toUpperCase()}
        </BodyFont>
        <SansSerifFont
          fontSizes={TITLE_FONT_SIZES}
          maxWidth={width - (isMobile ? padding * 8 : padding * 2) }
          textAlign='center'
          anchorX='center'
          anchorY='center'
        >
          {subtitle?.toUpperCase()}
        </SansSerifFont>
      </Stack>
      <group position={[-width / 2, -height / 2, 0]} name='meta'>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[padding, padding, 0]} anchorY='bottom' >
          {location?.toUpperCase()}
        </BodyFont>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[width / 2, padding, 0]} anchorY='bottom' anchorX='center' textAlign='center' >
          {established?.toUpperCase()}
        </BodyFont>
        <BodyFont fontSizes={FONT_SIZES} maxWidth={width / 3} position={[width - padding, padding, 0]} anchorY='bottom' anchorX='right' textAlign='left'>
          {owner?.toUpperCase()}
        </BodyFont>
      </group>
    </group>
  )
}
