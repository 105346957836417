import BundleHero from './BundleHero'
import WineHero from './WineHero'

export default function ProductHero ({ data, page }) {
  if (page.productCategory === 'wine') {
    return <WineHero data={data} page={page} />
  }
  if (page.productCategory === 'bundle') {
    return <BundleHero data={data} page={page} />
  }
  return <BundleHero data={data} page={page} isObject />
}
