import gsap from 'gsap'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import useComposeRefs from '../../hooks/useComposeRefs'
import RichContent from '../RichContent'

const IntroBanner = forwardRef((props, ref) => {
  const logoRef = useRef()
  useImperativeHandle(
    ref,
    () => ({
      animateInTimeline: () => {
        const tl = gsap.timeline()
        const elements = containerRef.current.querySelectorAll('.inner')
        tl.set(containerRef.current, { opacity: 1 })
        tl.fromTo(elements, {
          opacity: 0,
          rotate: 10,
          transformOrigin: 'bottom left',
          y: '100%'
        }, {
          opacity: 1,
          rotate: 0,
          y: '0%',
          duration: 1.2,
          ease: 'expo.out',
          stagger: 0.15
        })
        return tl
      },
      animateOutTimeline: (animateLogoOut = true) => {
        const tl = gsap.timeline()
        let elements = gsap.utils.toArray(containerRef.current.querySelectorAll('.inner'))
        if (!animateLogoOut) {
          elements = elements.slice(1)
        }
        tl.to(elements, {
          opacity: 0,
          transformOrigin: 'bottom left',
          y: '-100%',
          duration: 1.2,
          ease: 'expo.out',
          stagger: 0.05
        })
        return tl
      },
      animateLogoOutTimeline: () => {
        const tl = gsap.timeline()
        const logo = containerRef.querySelectorAll('.inner')[0]
        tl.set(logo, { opacity: 0 })
      },
      getLogoElement: () => {
        return logoRef.current
      }
    }),
    []
  )

  const containerRef = useRef()
  const composedRef = useComposeRefs(ref, containerRef)

  return (
    <div className='fixed top-[calc(50%-1.5em)] flex flex-wrap justify-center px-4 opacity-0 gap-14 md:gap-7 pointer-events-none' ref={composedRef}>
      <div className='uppercase text-14 font-550 overflow-hidden' ref={logoRef}>
        <div className="inner whitespace-nowrap"><RichContent content={props?.definitionLabels?.title} /></div>
      </div>
      <div className='uppercase text-14 font-serif font-250 overflow-hidden md:hidden'>
        <div className="inner whitespace-nowrap">{props.definitionLabels?.wordClass}</div>
      </div>
      <div className='uppercase text-14 font-serif font-250 italic overflow-hidden'>
        <div className="inner whitespace-nowrap">{props.definitionLabels?.phonetic}</div>
      </div>
      <div className='uppercase text-14 font-550 overflow-hidden'>
        <div className="inner whitespace-nowrap">{props.definitionLabels?.description}</div>
      </div>
    </div>
  )
})

export default IntroBanner
